import type { LazyExoticComponent } from "react";

import type { StepProps } from "../steps/step.types";

import type { FunnelInputsNameMap } from "./config.types";
import { lazyLoadStep } from "./config.utils";

export const modelName = "funnel-step-v-2";

export const DELAY_MS = 2000;

// steps - pool of all possible steps

export const steps = {
  "service-type": lazyLoadStep("service-type"),
  "zip-code": lazyLoadStep("zip-code"),
  "location-confirmation": "location-confirmation",
  "more-info": lazyLoadStep("more-info"),
  "case-urgency": lazyLoadStep("case-urgency"),
  unsupported: "unsupported",
  "unsupported-confirmed": "unsupported-confirmed",
  "create-meeting": lazyLoadStep("create-meeting"),
  "contact-info": lazyLoadStep("contact-info"),
  "contact-info-reschedule": lazyLoadStep("contact-info-reschedule"),
  "contact-info-call-now": lazyLoadStep("contact-info-call-now"),
  "contact-info-dlss": lazyLoadStep("contact-info-dlss"),
  "state-selection": lazyLoadStep("state-selection"),
  confirmation: "confirmation",
  "green-card-status": lazyLoadStep("green-card-status"),
  "green-card-type": lazyLoadStep("green-card-type"),
  "fiance-relationship-status": "fiance-relationship-status",
  "partner-interaction": lazyLoadStep("partner-interaction"),
  "married-to-citizen": lazyLoadStep("married-to-citizen"),
  "green-card-duration": lazyLoadStep("green-card-duration"),
  "applicant-type": lazyLoadStep("applicant-type"),
  "visa-eligibility": lazyLoadStep("visa-eligibility"),
  "tourist-visa-status": lazyLoadStep("tourist-visa-status"),
  "sponsor-info": lazyLoadStep("sponsor-info"),
  "sponsor-type": lazyLoadStep("sponsor-type"),
  "sponsor-status": lazyLoadStep("sponsor-status"),
  "child-age": lazyLoadStep("child-age"),
  "child-marital-status": lazyLoadStep("child-marital-status"),
  "job-offer": lazyLoadStep("job-offer"),
  "advanced-degree": lazyLoadStep("advanced-degree"),
  "exceptional-ability": lazyLoadStep("exceptional-ability"),
  "humanitarian-status": lazyLoadStep("humanitarian-status"),
  "funding-source": lazyLoadStep("funding-source"),
  "beneficiary-region": lazyLoadStep("beneficiary-region"),
  "lss-checkout": lazyLoadStep("lss-checkout"),
  "has-minor-children": lazyLoadStep("has-minor-children"),
  "children-info": lazyLoadStep("children-info"),
  "children-safety": lazyLoadStep("children-safety"),
  "child-protective-services": lazyLoadStep("child-protective-services"),
  "opposing-party": lazyLoadStep("opposing-party"),
  "has-upcoming-court-event": lazyLoadStep("has-upcoming-court-event"),
  "upcoming-event-date": lazyLoadStep("upcoming-event-date"),
  "currently-represented": lazyLoadStep("currently-represented"),
  "criminal-history": lazyLoadStep("criminal-history"),
  "deportation-or-removal": lazyLoadStep("deportation-or-removal"),
  "book-lss": lazyLoadStep("book-lss"),
  "slot-is-taken": "slot-is-taken",
  "payment-declined": "payment-declined",
  "out-of-scope": "out-of-scope",
  "unsupported-county": "unsupported-county",
  "intake-confirmation": "intake-confirmation",
  "intake-confirmation-call-now": "intake-confirmation-call-now",
  "lss-confirmation": "lss-confirmation",
  "lss-intro": "lss-intro",
  "additional-info": lazyLoadStep("additional-info"),
} as const satisfies Record<
  string,
  string | LazyExoticComponent<(props: StepProps) => JSX.Element>
>;

/*
 * steps names - pool of all possible steps names
 * (similar to original steps object above but since some values and eventually all will be components)
 */
export const stepsNames = Object.keys(steps).reduce(
  (acc, key) => {
    return { ...acc, [key]: key };
  },
  {} as { [K in keyof typeof steps]: K },
);

/*
 * We need to know what are shared steps
 * to be able to query them without knowing the sub-practice they belong to
 */
export const sharedSteps: Array<string> = [
  stepsNames["zip-code"],
  stepsNames["case-urgency"],
  stepsNames["state-selection"],
  stepsNames["location-confirmation"],
  stepsNames["more-info"],
  stepsNames["create-meeting"],
  stepsNames["contact-info"],
  stepsNames["contact-info-call-now"],
  stepsNames["contact-info-dlss"],
  stepsNames["unsupported-confirmed"],
  stepsNames.confirmation,
  stepsNames.unsupported,
  stepsNames["visa-eligibility"],
  stepsNames["applicant-type"],
  stepsNames["funding-source"],
  stepsNames["beneficiary-region"],
  stepsNames["lss-checkout"],
  stepsNames["has-minor-children"],
  stepsNames["children-info"],
  stepsNames["children-safety"],
  stepsNames["child-protective-services"],
  stepsNames["opposing-party"],
  stepsNames["has-upcoming-court-event"],
  stepsNames["upcoming-event-date"],
  stepsNames["currently-represented"],
  stepsNames["criminal-history"],
  stepsNames["deportation-or-removal"],
  stepsNames["book-lss"],
  stepsNames["slot-is-taken"],
  stepsNames["payment-declined"],
  stepsNames["out-of-scope"],
  stepsNames["unsupported-county"],
  stepsNames["intake-confirmation"],
  stepsNames["intake-confirmation-call-now"],
  stepsNames["lss-confirmation"],
  stepsNames["lss-intro"],
  stepsNames["contact-info-dlss"],
  stepsNames["additional-info"],
  stepsNames["sponsor-info"],
];

// practices - pool of all possible practices and their sub-practices

export const practices = {
  family: [
    "child-support",
    "child-custody",
    "divorce",
    "alimony",
    "else",
    "reschedule",
  ] as const,
  immigration: [
    "green-card",
    "citizenship",
    "fiance-visa",
    "else",
    "reschedule",
  ] as const,
} satisfies Record<string, Array<string>>;

export const funnelInputsNameMap: FunnelInputsNameMap = {
  // v2/funnel
  practice: "practice",

  // v2/funnel/[practice]
  subPractice: "subPractice",

  // v2/funnel/[practice]/[subPractice]
  campaignId: "campaignId",

  // service-type
  serviceType: "serviceType",

  // sponsor-info
  sponsorFirstName: "sponsorFirstName",
  sponsorLastName: "sponsorLastName",
  sponsorPhone: "sponsorPhone",

  // sponsor-type
  sponsorType: "sponsorType",

  // sponsor-status
  sponsorStatus: "sponsorStatus",

  // child-age

  isChildUnderTwentyOne: "isChildUnderTwentyOne",

  // child-marital-status

  isChildMarried: "isChildMarried",

  // job-offer

  jobOffer: "jobOffer",

  // advanced-degree

  advancedDegree: "advancedDegree",

  //exceptional-ability

  exceptionalAbility: "exceptionalAbility",

  // humanitarian-status

  humanitarianStatus: "humanitarianStatus",

  // green-card-status
  greenCardStatus: "greenCardStatus",

  // green-card-type
  greenCardType: "greenCardType",

  // fiance-relationship-status
  fianceRelationshipStatus: "fianceRelationshipStatus",

  // partner-interaction
  partnerInteraction: "partnerInteraction",

  // applicant-type
  applicantType: "applicantType",

  // married-to-citizen
  marriedToCitizen: "marriedToCitizen",

  // green-card-duration
  greenCardDuration: "greenCardDuration",

  // visa-eligibility
  visaEligibility: "visaEligibility",

  // tourist-visa-status
  touristVisaStatus: "touristVisaStatus",

  // zip-code
  county: "county",
  zipCode: "zipCode",
  stateCode: "stateCode",
  stateDisplayText: "stateDisplayText",

  // zip-code & state-selection
  state: "state",

  // post contact-info
  isQualifiedForLss: "isQualifiedForLss", // calculateLssQualification
  funnelActionId: "funnelActionId", // createLead
  leadId: "leadId",
  accountId: "accountId",
  oldLeadId: "oldLeadId",

  // case-urgency
  caseUrgency: "caseUrgency",

  // funding-source
  fundingSource: "fundingSource",

  // beneficiary-region
  beneficiaryRegion: "beneficiaryRegion",

  // more-info
  moreInfo: "moreInfo",
  skip: "skip",

  // post more-info
  leadScore: "leadScore", // calculateLeadScore
  leadIntent: "leadIntent", // calculateLeadScore
  sessionStartTime: "sessionStartTime", // calculateLeadScore
  visitsCount: "visitsCount", // calculateLeadScore

  // CMN eligibility
  isEligibleForCmn: "isEligibleForCmn",

  // create-meeting
  eventStartTime: "eventStartTime",
  timezone: "timezone",

  // book-lss
  lssStartTime: "lssStartTime",

  // create-meeting & book-lss
  meetingType: "meetingType",

  // contact-info & contact-info-reschedule && contact-info-dlss
  firstName: "firstName",
  lastName: "lastName",
  phone: "phone",
  email: "email",
  approvedTerms: "approvedTerms",

  // has-minor-children
  hasMinorChildren: "hasMinorChildren",

  // children-info
  childrenInfo: "childrenInfo",

  // children-safety
  childrenSafety: "childrenSafety",

  // child-protective-services
  childProtectiveServices: "childProtectiveServices",

  // opposing-party
  opposingPartyFirstName: "opposingPartyFirstName",
  opposingPartyLastName: "opposingPartyLastName",
  opposingPartyPhone: "opposingPartyPhone",

  // has-upcoming-court-event
  hasUpcomingCourtEvent: "hasUpcomingCourtEvent",

  // upcoming-event-date
  upcomingEventDate: "upcomingEventDate",

  // currently-represented
  currentlyRepresented: "currentlyRepresented",

  // criminal-history
  criminalHistory: "criminalHistory",

  // deportation-or-removal
  deportationOrRemoval: "deportationOrRemoval",

  // post currently-represented
  visitorStatusCode: "visitorStatusCode", // updateVisitorStatusCode

  // lss-checkout
  checkoutStatus: "checkoutStatus",
} as const;
