import * as Sentry from "@sentry/nextjs";

import { analytics } from "@flare/analytics";

import type { VisitorStatusCode } from "@/edge/contract";
import { jsonRequest } from "@/lib/network/json-request";
import { queryClient } from "@/lib/react-query-client";
import { BiEventsNames } from "@/services/analytics/event-names";

import type { FunnelAnswers } from "../config";

type GetVisitorStatusCodeArgs = {
  answers: FunnelAnswers;
  currentStep: string;
};

export async function getVisitorStatusCode({
  answers,
  currentStep,
}: GetVisitorStatusCodeArgs) {
  try {
    const getVisitorStatusQuery = await queryClient.fetchQuery({
      queryKey: ["getVisitorStatus"],
      queryFn: async () =>
        jsonRequest<VisitorStatusCode>("/api/vinny/visitors/status/raw", {
          method: "POST",
          body: answers,
        }),
    });

    return getVisitorStatusQuery!;
  } catch (error) {
    analytics.track(BiEventsNames.WebFunnelError, {
      current_step_key: currentStep,
      error_type: "error-get-visitor-status-code-for-navigation",
      error_message: error instanceof Error ? error.message : "unknown error",
    });

    Sentry.captureException(error, {
      extra: {
        message: `error fetching visitor status code for funnel navigation`,
      },
    });

    return "ERROR";
  }
}

export function isVisitorStatusCodeAllowLss(
  statusCode: VisitorStatusCode | "ERROR",
) {
  // allow lss for client who get is eligible for lss or get an error during getVisitorStatusCode validation
  return statusCode === "ELG1" || statusCode === "ERROR";
}
