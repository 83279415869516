import { useEffect, useState } from "react";
import { isBrowser } from "@marbletech/utils";
import axios from "axios";

import {
  baseDevUrl,
  CLOUDFRONT_CITY_HEADER,
  CLOUDFRONT_COUNTRY_CODE_HEADER,
  CLOUDFRONT_COUNTRY_NAME_HEADER,
  CLOUDFRONT_STATE_HEADER,
  EMPTY_GEOLOCATION,
  previewUrlSuffix,
} from "./geo-location-consts";
import type { GeoLocation } from "./geo-location-context-types";
import type { GeoLocationProps } from "./geo-location-context-types";

// DO NOT DELETE! this pixel is for user's location according to his ip.
export const getUserLocation = async (): Promise<GeoLocation> => {
  let pixelUrl = `/pixel.png?${new Date().getTime()}`;
  if (isBrowser() && window.location?.host?.includes(previewUrlSuffix)) {
    pixelUrl = `${baseDevUrl}${pixelUrl}`;
  }
  try {
    const res = await axios.get(pixelUrl);
    const city = res?.headers?.[CLOUDFRONT_CITY_HEADER];
    const state = res?.headers?.[CLOUDFRONT_STATE_HEADER];

    return {
      city,
      ipCity: city,
      ipState: state,
      ipCountryCode: res?.headers?.[CLOUDFRONT_COUNTRY_CODE_HEADER],
      ipCountryName: res?.headers?.[CLOUDFRONT_COUNTRY_NAME_HEADER],
    };
  } catch (err) {
    console.warn("Failed to fetch location headers");
  }
  return {
    city: "",
  };
};

export const getLocationFromUrlParam = () => {
  if (isBrowser()) {
    // We will take the user location param when the user's search will be different from his location.
    // (for example, will search lp/family-az and the location will be la)
    const params = new URLSearchParams(window.location?.search);
    return params?.get("marble_location") ?? "";
  }
};

export const useGeoLocationContext = (): GeoLocationProps => {
  const [geoLocation, setGeoLocation] =
    useState<GeoLocation>(EMPTY_GEOLOCATION);
  const location = getLocationFromUrlParam();

  useEffect(() => {
    const _getUserLocation = async function () {
      const { city, ...rest } = await getUserLocation();
      setGeoLocation({ city: location || city, ...rest });
    };

    _getUserLocation();
  }, [location]);

  return {
    geoLocation,
    setGeoLocation,
  };
};

export const initialState: GeoLocationProps = {
  geoLocation: EMPTY_GEOLOCATION,
};
