import { isEmail } from "class-validator";

export function isQualifiedInternalEmail(email?: string, prefixTag?: string) {
  return (
    isEmail(email, {
      host_whitelist: ["themis-tech.io", "helloflare.com"],
      require_tld: true,
    }) &&
    (!prefixTag || email?.includes(prefixTag))
  );
}
export const testStateCode = "VA";
export const testCounty = "accomack county";
