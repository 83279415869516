import type { SubPractices } from "../config.types";

export const immigrationReschedule: SubPractices = {
  firstScreen: "create-meeting",
  screens: {
    "create-meeting": {
      next: async () => "contact-info-reschedule",
    },
    "contact-info-reschedule": {
      next: async () => "intake-confirmation",
      sideEffects: ["createLead"],
      sanitize: true,
    },
    "intake-confirmation": {
      next: async () => undefined,
    },
  },
};
