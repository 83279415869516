import * as l from "react";
import { jsx as c, Fragment as d } from "react/jsx-runtime";
function m(e, n) {
  typeof e == "function" ? e(n) : e != null && (e.current = n);
}
function p(...e) {
  return (n) => e.forEach((r) => m(r, n));
}
function S(...e) {
  return l.useCallback(p(...e), e);
}
var y = l.forwardRef((e, n) => {
  const { children: r, ...t } = e, o = l.Children.toArray(r), i = o.find(h);
  if (i) {
    const s = i.props.children, a = o.map((u) => u === i ? l.Children.count(s) > 1 ? l.Children.only(null) : l.isValidElement(s) ? s.props.children : null : u);
    return /* @__PURE__ */ c(f, { ...t, ref: n, children: l.isValidElement(s) ? l.cloneElement(s, void 0, a) : null });
  }
  return /* @__PURE__ */ c(f, { ...t, ref: n, children: r });
});
y.displayName = "Slot";
var f = l.forwardRef((e, n) => {
  const { children: r, ...t } = e;
  if (l.isValidElement(r)) {
    const o = R(r);
    return l.cloneElement(r, {
      ...C(t, r.props),
      // @ts-ignore
      ref: n ? p(n, o) : o
    });
  }
  return l.Children.count(r) > 1 ? l.Children.only(null) : null;
});
f.displayName = "SlotClone";
var g = ({ children: e }) => /* @__PURE__ */ c(d, { children: e });
function h(e) {
  return l.isValidElement(e) && e.type === g;
}
function C(e, n) {
  const r = { ...n };
  for (const t in n) {
    const o = e[t], i = n[t];
    /^on[A-Z]/.test(t) ? o && i ? r[t] = (...a) => {
      i(...a), o(...a);
    } : o && (r[t] = o) : t === "style" ? r[t] = { ...o, ...i } : t === "className" && (r[t] = [o, i].filter(Boolean).join(" "));
  }
  return { ...e, ...r };
}
function R(e) {
  var t, o;
  let n = (t = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : t.get, r = n && "isReactWarning" in n && n.isReactWarning;
  return r ? e.ref : (n = (o = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : o.get, r = n && "isReactWarning" in n && n.isReactWarning, r ? e.props.ref : e.props.ref || e.ref);
}
export {
  y as S,
  g as a,
  S as u
};
