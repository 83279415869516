/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";

export function useAsyncEffect(
  effect: (isMounted: () => boolean) => any | Promise<any>,
  inputs?: any[],
): void;
export function useAsyncEffect<V>(
  effect: (isMounted: () => boolean) => V | Promise<V>,
  destroy?: ((result?: V) => void) | any[],
  inputs?: any[],
) {
  const hasDestroy = typeof destroy === "function";
  const arr = hasDestroy ? inputs : destroy;

  useEffect(() => {
    let result: V;
    let mounted = true;
    const maybePromise = effect(function () {
      return mounted;
    });

    Promise.resolve(maybePromise).then(function (value) {
      result = value;
    });

    return () => {
      mounted = false;

      if (hasDestroy) {
        destroy(result);
      }
    };
  }, arr);
}
