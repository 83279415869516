import { Builder } from "@builder.io/react";

import { V2_TEXT } from "@/builder/components-sections";
import type { TextProps } from "@/components/v2/Text/Text";
import { Text } from "@/components/v2/Text/Text";

import type { BuilderAttributes } from "../../types";

type BuilderTextProps = TextProps & {
  attributes?: BuilderAttributes;
};

export const BuilderText = ({
  alignment = "start",
  as = "p",
  balanceText,
  children,
  color = "blue",
  maxLines,
  variant = "text-1",
  attributes,
}: BuilderTextProps) => {
  const { key, ...restAttributes } = attributes || {};
  return (
    <Text
      alignment={alignment}
      as={as}
      balanceText={balanceText}
      variant={variant}
      color={color}
      maxLines={maxLines}
      key={key}
      {...restAttributes}
    >
      {children}
    </Text>
  );
};

Builder.registerComponent(BuilderText, {
  name: V2_TEXT,
  noWrap: true,
  inputs: [
    {
      name: "balanceText",
      type: "boolean",
      defaultValue: false,
      helperText:
        "Balances the number of characters on each line, enhancing layout quality and legibility",
    },
    {
      name: "children",
      friendlyName: "Text",
      type: "string",
      defaultValue: "Text placeholder",
      helperText: "Displayed text",
    },
    {
      name: "as",
      friendlyName: "Tag",
      type: "string",
      defaultValue: "p",
      enum: ["p", "span"],
      helperText: "HTML tag to be used",
    },
    {
      name: "variant",
      type: "string",
      defaultValue: "text-1",
      enum: [
        "text-1",
        "text-2",
        "text-3",
        "text-4",
        "text-5",
        "text-6",
        "label",
      ],
      helperText: "Variant of the text",
    },
    {
      name: "color",
      type: "string",
      defaultValue: "blue",
      enum: ["blue", "beige", "green", "white", "gray", "light-gray"],
      helperText: "Color of the text",
    },
  ],
});
