export function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" color="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 11.252C5.21079 11.252 4.875 11.5877 4.875 12.002C4.875 12.4162 5.21079 12.752 5.625 12.752L16.5646 12.752L13.2922 16.0244C12.9993 16.3173 12.9993 16.7921 13.2922 17.085C13.5851 17.3779 14.0599 17.3779 14.3528 17.085L18.9053 12.5325C19.046 12.3919 19.125 12.2011 19.125 12.0022C19.125 11.8033 19.046 11.6125 18.9053 11.4719L14.3528 6.91936C14.0599 6.62646 13.5851 6.62646 13.2922 6.91936C12.9993 7.21225 12.9993 7.68712 13.2922 7.98002L16.5641 11.252L5.625 11.252Z"
        fill="currentColor"
      />
    </svg>
  );
}
