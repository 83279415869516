export function ChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.755 6.523a.7.7 0 0 1 .005 1.06l-5.023 4.59a.234.234 0 0 0 .002.354l5.015 4.496a.7.7 0 0 1 .006 1.06.891.891 0 0 1-1.172.006l-5.015-4.496c-.759-.68-.765-1.789-.013-2.475l5.023-4.59a.891.891 0 0 1 1.171-.005Z"
      ></path>
    </svg>
  );
}
