import { useSessionStorage } from "usehooks-ts";

import { FUNNEL_DATA_SESSION_STORAGE_KEY } from "@/services/storage";

import type { FunnelAnswers } from "../config";

/**
 * This hook is used to get and set the funnel data in the session storage
 */

export function useFunnelAnswers() {
  const [funnelAnswers, setFunnelAnswers] = useSessionStorage<FunnelAnswers>(
    FUNNEL_DATA_SESSION_STORAGE_KEY,
    {},
  );

  return { funnelAnswers, setFunnelAnswers };
}
