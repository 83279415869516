import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

export function sendOpenChangeEvent(e: boolean) {
  analytics.track(BiEventsNames.WebModalAction, {
    modal_name: "Limited-scope-video",
    action: e ? "open" : "close",
  });
}
