import { useState } from "react";

import {
  CAMPAIGN_DATA_STORAGE_KEY,
  getParsedItem,
  setParsedItem,
} from "../../storage";

import type {
  CampaignData,
  CampaignDataProps,
} from "./campaign-data-context.types";
import { DEFAULT_CAMPAIGN_DATA } from "./campaign-data-context-defaults";
import { extractCampaignData } from "./campaign-data-utils";

export const useCampaignDataContext = (): CampaignDataProps => {
  const [campaignData, setCampaignData] = useState<CampaignData>(
    getParsedItem(CAMPAIGN_DATA_STORAGE_KEY) ?? DEFAULT_CAMPAIGN_DATA,
  );

  return {
    campaignData,
    setCampaignData: (campaignData) => {
      setCampaignData(campaignData);
      setParsedItem(CAMPAIGN_DATA_STORAGE_KEY, campaignData);
    },
    extractCampaignData,
  };
};

export const initialState: CampaignDataProps = {
  campaignData: DEFAULT_CAMPAIGN_DATA,
  extractCampaignData: () => DEFAULT_CAMPAIGN_DATA,
};
