import React, { createContext, useContext } from "react";

import { initialNavbarState } from "./navbar/consts";
import type { AppContextProps } from "./app-context.types";
import {
  initialState as initialCampaignData,
  useCampaignDataContext,
} from "./campaign-data-context";
import {
  initialState as initialGeoLocation,
  useGeoLocationContext,
} from "./geo-location";

const AppContext = createContext<AppContextProps>({
  campaignData: initialCampaignData,
  geoLocation: initialGeoLocation,
  navbar: initialNavbarState,
});

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const campaignDataContext = useCampaignDataContext();
  const geoLocationContext = useGeoLocationContext();
  const [selectedNavbarItem, setSelectedNavbarItem] = React.useState();

  const sharedState = {
    campaignData: campaignDataContext,
    geoLocation: geoLocationContext,
    navbar: {
      selectedNavbarItem,
      setSelectedNavbarItem,
    },
  };

  return (
    // @ts-expect-error: Wrong type
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
