import { Builder } from "@builder.io/sdk";

import { LIMITED_SCOPE_DIALOG_TRIGGER } from "@/builder/components-sections";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/Funnel/base/Dialog";
import { ArrowRight } from "@/components/icons/ArrowRight";
import { XIcon } from "@/components/icons/XIcon";
import { Text } from "@/components/v2/Text/Text";

import { LimitedScopeVideoContent } from "./LimitedScopeVideoContent";
import { sendOpenChangeEvent } from "./sendOpenChangeEvent";

export function LimitedScopeDialogTrigger() {
  return (
    <Dialog onOpenChange={sendOpenChangeEvent}>
      <DialogTrigger className="flex w-full items-center justify-between py-2">
        <Text variant="text-3">Learn about our pricing</Text>
        <ArrowRight height="24" width="24" />
      </DialogTrigger>
      <DialogContent className="max-w-2xl px-8 py-7">
        <DialogClose className="absolute right-4 top-5 w-fit rounded-full bg-green-20">
          <XIcon height="24" width="24" />
        </DialogClose>
        <LimitedScopeVideoContent headingVariant="h4" />
      </DialogContent>
    </Dialog>
  );
}

Builder.registerComponent(LimitedScopeDialogTrigger, {
  name: LIMITED_SCOPE_DIALOG_TRIGGER,
});
