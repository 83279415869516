import type { HeadingTag } from "@/components/v2/Heading/Heading";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";

export function LimitedScopeVideoContent({
  headingVariant,
}: {
  headingVariant: HeadingTag;
}) {
  return (
    <div className="mb-2 space-y-6">
      <div className="mt-9 space-y-4">
        <Heading variant={headingVariant} className="mt-6">
          Clear, personalized and upfront pricing
        </Heading>
        <Text variant="text-3">
          At Marble Law, we don’t charge you by the hour or ask for a retainer
          fee. We have fixed prices for different steps of your case, putting
          you in control. You hire your attorney for the services you need, when
          you need them.
        </Text>
      </div>
      <video
        height="auto"
        width="100%"
        autoPlay
        controls
        muted
        className="rounded-md border border-neutral-90"
      >
        <source
          src="https://cdn.builder.io/o/assets%2F15f74e88630d41b6b43ece1273087b6f%2F9307f56cabad4c1e9dfba05ca7d16406%2Fcompressed?apiKey=15f74e88630d41b6b43ece1273087b6f&token=9307f56cabad4c1e9dfba05ca7d16406&alt=media&optimized=true"
          type="video/mp4"
        ></source>
      </video>
    </div>
  );
}
