import { jsx as t, jsxs as u, Fragment as m } from "react/jsx-runtime";
import { forwardRef as p } from "react";
import { S as h } from "../../index-CCavb-1K.js";
import { c as x } from "../../index-BwYCDOHZ.js";
import { cn as i } from "../../utils/cn.js";
import { focusRing as v } from "../../utils/focus-ring.js";
const y = x(
  [
    v,
    "relative inline-flex items-center justify-center whitespace-nowrap disabled:pointer-events-none"
  ],
  {
    variants: {
      variant: {
        primary: "bg-action-bg-primary-default text-action-content-primary-default hover:bg-action-bg-primary-hover active:bg-action-bg-primary-pressed disabled:bg-action-bg-primary-disabled disabled:text-action-content-primary-disabled",
        danger: "bg-action-bg-danger-default text-action-content-danger-default hover:bg-action-bg-danger-hover active:bg-action-bg-danger-pressed disabled:bg-action-bg-danger-disabled disabled:text-action-content-danger-disabled",
        outline: "border border-action-stroke-outline-default bg-action-bg-outline-default text-action-content-outline-default hover:bg-action-bg-outline-hover active:bg-action-bg-outline-pressed disabled:border-action-stroke-outline-disabled disabled:bg-action-bg-outline-disabled disabled:text-action-content-outline-disabled",
        extra: "bg-action-bg-extra-default text-action-content-extra-default hover:bg-action-bg-extra-hover active:bg-action-bg-extra-pressed disabled:bg-action-bg-extra-disabled disabled:text-action-content-extra-disabled",
        ghost: "bg-action-bg-ghost-default text-action-content-ghost-default hover:bg-action-bg-ghost-hover active:bg-action-bg-ghost-pressed disabled:bg-action-bg-ghost-disabled disabled:text-action-content-ghost-disabled"
      },
      size: {
        sm: "h-s-5 rounded-sm px-[calc(((theme(height[s-5])-theme(fontSize[t2])*0.7)/2)*1.3)] text-t2",
        md: "h-s-6 rounded-sm px-[calc(((theme(height[s-6])-theme(fontSize[t2])*0.7)/2)*1.3)] text-t2",
        lg: "h-s-7 rounded-md px-[calc(((theme(height[s-7])-theme(fontSize[t1])*0.7)/2)*1.3)] text-t1"
      },
      fullWidth: {
        true: "w-full"
      },
      isLoading: {
        true: ""
      }
    },
    compoundVariants: [
      {
        isLoading: !0,
        variant: "primary",
        className: "disabled:bg-action-bg-primary-default disabled:text-action-content-primary-default"
      },
      {
        isLoading: !0,
        variant: "danger",
        className: "disabled:bg-action-bg-danger-default disabled:text-action-content-danger-default"
      },
      {
        isLoading: !0,
        variant: "outline",
        className: "disabled:border-action-stroke-outline-default disabled:bg-action-bg-outline-default disabled:text-action-content-outline-default"
      },
      {
        isLoading: !0,
        variant: "extra",
        className: "disabled:bg-action-bg-extra-default disabled:text-action-content-extra-default"
      },
      {
        isLoading: !0,
        variant: "ghost",
        className: "disabled:bg-action-bg-ghost-default disabled:text-action-content-ghost-default"
      }
    ]
  }
);
function N() {
  return /* @__PURE__ */ t("span", { className: "absolute size-sp-2 animate-dot-flashing rounded-full bg-current animation-delay-300 before:absolute before:-left-sp-3 before:top-[0] before:inline-block before:size-sp-2 before:animate-dot-flashing before:rounded-full before:bg-current before:content-[''] before:animation-delay-0 after:absolute after:left-sp-3 after:top-[0] after:inline-block after:size-sp-2 after:animate-dot-flashing after:rounded-full after:bg-current after:delay-700 after:content-[''] after:animation-delay-700" });
}
const z = p(
  ({
    asChild: a = !1,
    children: n,
    className: o,
    disabled: r,
    fullWidth: d = !1,
    isLoading: e = !1,
    size: s = "md",
    variant: l = "primary",
    ...b
  }, c) => {
    const g = a ? h : "button", f = r || e;
    return /* @__PURE__ */ t(
      g,
      {
        className: i(
          y({
            variant: l,
            size: s,
            fullWidth: d,
            className: o,
            isLoading: e
          })
        ),
        disabled: f,
        "aria-busy": e,
        ref: c,
        ...b,
        children: e && !a ? /* @__PURE__ */ u(m, { children: [
          /* @__PURE__ */ t("span", { className: i({ invisible: e }), children: n }),
          e && /* @__PURE__ */ t(N, {})
        ] }) : n
      }
    );
  }
);
z.displayName = "Button";
export {
  z as Button
};
