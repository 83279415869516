// keep common immigration flow parts in this file

import { i18n } from "@/services/locale/i18n";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import { Interlude } from "../../Interlude";
import { calculateLeadScore } from "../../visitor-checks/calculateLeadScore";
import {
  getVisitorStatusCode,
  isVisitorStatusCodeAllowLss,
} from "../../visitor-checks/getVisitorStatusCode";
import { DELAY_MS, stepsNames } from "../config.const";
import type { FunnelData, Screens } from "../config.types";

export const immigrationEligibleForLssScreens: Screens = {
  "currently-represented": {
    next: async () => "deportation-or-removal",
  },
  "deportation-or-removal": {
    next: async () => "criminal-history",
  },
  "criminal-history": {
    next: async ({ answers }) => {
      const { criminalHistory } = answers;
      // if criminalHistory === YES we continue regular flow and not lss
      return criminalHistory === "YES" ? "more-info" : "additional-info";
    },
  },
  "additional-info": {
    next: async ({ answers }) => {
      const statusCodePromise = getVisitorStatusCode({
        answers,
        currentStep: stepsNames["additional-info"],
      });

      const statusCode = await promiseWithMinimumDelay(
        statusCodePromise,
        DELAY_MS,
      );

      return isVisitorStatusCodeAllowLss(statusCode)
        ? "book-lss"
        : "out-of-scope";
    },
    sideEffects: ["updateVisitorStatusCode"],
    renderInterlude: () => (
      <Interlude heading={i18n.t("FUNNEL_INTERLUDE_REVIEWING_YOUR_ANSWERS")} />
    ),
  },
};

export const immigrationBookLssScreens: Screens = {
  // Direct LSS steps
  "book-lss": { next: async () => "contact-info-dlss" },
  "contact-info-dlss": {
    next: async ({ answers }) => {
      if (!answers.funnelActionId) return "intake-confirmation";
      return "lss-checkout";
    },
    sanitize: true,
    sideEffects: ["createLead"],
  },
  "lss-checkout": {
    next: async ({ meta }) => {
      const { checkoutStatus } = meta;
      return checkoutStatus;
    },
  },
  "slot-is-taken": {
    next: async () => "book-lss",
  },
  "payment-declined": {
    next: async () => "lss-checkout",
  },
  "lss-confirmation": {
    next: async () => undefined,
  },
};

export const intakeConfirmationScreens: Screens = {
  "more-info": {
    next: async ({ answers }) => {
      calculateLeadScore(answers, stepsNames["more-info"]);
      return "create-meeting";
    },
  },
  "create-meeting": {
    next: async () => "contact-info",
  },
  "contact-info": {
    next: async () => "intake-confirmation",
    sanitize: true,
    sideEffects: ["createLead"],
    renderInterlude: () => (
      <Interlude heading={i18n.t("FUNNEL_INTERLUDE_CONFIRMING_YOUR_CALL")} />
    ),
  },
  "intake-confirmation": {
    next: async () => undefined,
  },
};

export const isGreenCardLssFlow = ({
  answers,
  experiments,
}: Omit<FunnelData, "meta">): boolean => {
  const { subPractice, serviceType, sponsorType } = answers || {};
  const { abTestIsQualifiedForLssImmigration } = experiments || {};

  const isNoSponsorFlow =
    serviceType === "New green card application" &&
    sponsorType === "I don’t have a sponsor";

  const isSomethingElseFlow =
    subPractice === "green-card" && serviceType === "Something else";

  return (
    abTestIsQualifiedForLssImmigration &&
    !isNoSponsorFlow &&
    !isSomethingElseFlow
  );
};
