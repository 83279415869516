import { useEffect, useState } from "react";
import {
  SplitClient,
  SplitFactoryProvider,
} from "@splitsoftware/splitio-react";

import { analytics } from "@flare/analytics";

const DUMMY_SPLIT_KEY = "anonymous";

export const SplitProvider = ({ children }: { children: React.ReactNode }) => {
  const [anonymousId, setAnonymousId] = useState<string | null | undefined>();

  useEffect(() => {
    async function getAnonymousId() {
      const aId = await analytics.getAnonymousId();
      setAnonymousId(aId);
    }

    getAnonymousId();
  }, []);

  const splitConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_KEY as string,
      key: DUMMY_SPLIT_KEY,
      trafficType: "anonymous",
    },
  };

  if (anonymousId) {
    splitConfig.core.key = anonymousId;
  }

  return (
    <SplitFactoryProvider config={splitConfig}>
      <SplitClient splitKey={splitConfig.core.key}>{children}</SplitClient>
    </SplitFactoryProvider>
  );
};
