import type { LazyExoticComponent } from "react";
import { lazy } from "react";

import type { StepProps } from "../steps/step.types";

export function lazyLoadStep(
  stepFileName: string,
): LazyExoticComponent<(props: StepProps) => JSX.Element> {
  return lazy(() => import(`../steps/${stepFileName}`));
}
