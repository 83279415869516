// Should move to the backend in - https://helloflare.atlassian.net/browse/F20-748
export const fipsMap: Record<string, Record<string, string>> = {
  SC: {
    "abbeville county": "45001",
    "aiken county": "45003",
    "allendale county": "45005",
    "anderson county": "45007",
    "bamberg county": "45009",
    "barnwell county": "45011",
    "beaufort county": "45013",
    "berkeley county": "45015",
    "calhoun county": "45017",
    "charleston county": "45019",
    "cherokee county": "45021",
    "chester county": "45023",
    "chesterfield county": "45025",
    "clarendon county": "45027",
    "colleton county": "45029",
    "darlington county": "45031",
    "dillon county": "45033",
    "dorchester county": "45035",
    "edgefield county": "45037",
    "fairfield county": "45039",
    "florence county": "45041",
    "georgetown county": "45043",
    "greenville county": "45045",
    "greenwood county": "45047",
    "hampton county": "45049",
    "horry county": "45051",
    "jasper county": "45053",
    "kershaw county": "45055",
    "lancaster county": "45057",
    "laurens county": "45059",
    "lee county": "45061",
    "lexington county": "45063",
    "marion county": "45067",
    "marlboro county": "45069",
    "mccormick county": "45065",
    "newberry county": "45071",
    "oconee county": "45073",
    "orangeburg county": "45075",
    "pickens county": "45077",
    "richland county": "45079",
    "saluda county": "45081",
    "spartanburg county": "45083",
    "sumter county": "45085",
    "union county": "45087",
    "williamsburg county": "45089",
    "york county": "45091",
  },
  VA: {
    "accomack county": "51001",
    "albemarle county": "51003",
    "alexandria city": "51510",
    "alleghany county": "51005",
    "amelia county": "51007",
    "amherst county": "51009",
    "appomattox county": "51011",
    "arlington county": "51013",
    "augusta county": "51015",
    "bath county": "51017",
    "bedford county": "51019",
    "bland county": "51021",
    "botetourt county": "51023",
    "bristol city": "51520",
    "brunswick county": "51025",
    "buchanan county": "51027",
    "buckingham county": "51029",
    "buena vista city": "51530",
    "campbell county": "51031",
    "caroline county": "51033",
    "carroll county": "51035",
    "charles county": "51036",
    "charlotte county": "51037",
    "charlottesville city": "51540",
    "chesapeake city": "51550",
    "chesterfield county": "51041",
    "clarke county": "51043",
    "colonial heights city": "51570",
    "covington city": "51580",
    "craig county": "51045",
    "culpeper county": "51047",
    "cumberland county": "51049",
    "danville city": "51590",
    "dickenson county": "51051",
    "dinwiddie county": "51053",
    "emporia city": "51595",
    "essex county": "51057",
    "fairfax city": "51600",
    "fairfax county": "51059",
    "falls church city": "51610",
    "fauquier county": "51061",
    "floyd county": "51063",
    "fluvanna county": "51065",
    "franklin city": "51620",
    "franklin county": "51067",
    "frederick county": "51069",
    "fredericksburg city": "51630",
    "galax city": "51640",
    "giles county": "51071",
    "gloucester county": "51073",
    "goochland county": "51075",
    "grayson county": "51077",
    "greene county": "51079",
    "greensville county": "51081",
    "halifax county": "51083",
    "hampton city": "51650",
    "hanover county": "51085",
    "harrisonburg city": "51660",
    "henrico county": "51087",
    "henry county": "51089",
    "highland county": "51091",
    "hopewell city": "51670",
    "isle of wight county": "51093",
    "james county": "51095",
    "king and queen county": "51097",
    "king george county": "51099",
    "king william county": "51101",
    "lancaster county": "51103",
    "lee county": "51105",
    "lexington city": "51678",
    "loudoun county": "51107",
    "louisa county": "51109",
    "lunenburg county": "51111",
    "lynchburg city": "51680",
    "madison county": "51113",
    "manassas city": "51683",
    "manassas park city": "51685",
    "martinsville city": "51690",
    "mathews county": "51115",
    "mecklenburg county": "51117",
    "middlesex county": "51119",
    "montgomery county": "51121",
    "nelson county": "51125",
    "new kent county": "51127",
    "newport news city": "51700",
    "norfolk city": "51710",
    "northampton county": "51131",
    "northumberland county": "51133",
    "norton city": "51720",
    "nottoway county": "51135",
    "orange county": "51137",
    "page county": "51139",
    "patrick county": "51141",
    "petersburg city": "51730",
    "pittsylvania county": "51143",
    "poquoson city": "51735",
    "portsmouth city": "51740",
    "powhatan county": "51145",
    "prince edward county": "51147",
    "prince george county": "51149",
    "prince william county": "51153",
    "pulaski county": "51155",
    "radford city": "51750",
    "rappahannock county": "51157",
    "richmond city": "51760",
    "richmond county": "51159",
    "roanoke city": "51770",
    "roanoke county": "51161",
    "rockbridge county": "51163",
    "rockingham county": "51165",
    "russell county": "51167",
    "salem city": "51775",
    "scott county": "51169",
    "shenandoah county": "51171",
    "smyth county": "51173",
    "southampton county": "51175",
    "spotsylvania county": "51177",
    "stafford county": "51179",
    "staunton city": "51790",
    "suffolk city": "51800",
    "surry county": "51181",
    "sussex county": "51183",
    "tazewell county": "51185",
    "virginia beach city": "51810",
    "warren county": "51187",
    "washington county": "51191",
    "waynesboro city": "51820",
    "westmoreland county": "51193",
    "williamsburg city": "51830",
    "winchester city": "51840",
    "wise county": "51195",
    "wythe county": "51197",
    "york county": "51199",
  },
  KY: {
    "adair county": "21001",
    "allen county": "21003",
    "anderson county": "21005",
    "ballard county": "21007",
    "barren county": "21009",
    "bath county": "21011",
    "bell county": "21013",
    "boone county": "21015",
    "bourbon county": "21017",
    "boyd county": "21019",
    "boyle county": "21021",
    "bracken county": "21023",
    "breathitt county": "21025",
    "breckinridge county": "21027",
    "bullitt county": "21029",
    "butler county": "21031",
    "caldwell county": "21033",
    "calloway county": "21035",
    "campbell county": "21037",
    "carlisle county": "21039",
    "carroll county": "21041",
    "carter county": "21043",
    "casey county": "21045",
    "christian county": "21047",
    "clark county": "21049",
    "clay county": "21051",
    "clinton county": "21053",
    "crittenden county": "21055",
    "cumberland county": "21057",
    "daviess county": "21059",
    "edmonson county": "21061",
    "elliott county": "21063",
    "estill county": "21065",
    "fayette county": "21067",
    "fleming county": "21069",
    "floyd county": "21071",
    "franklin county": "21073",
    "fulton county": "21075",
    "gallatin county": "21077",
    "garrard county": "21079",
    "grant county": "21081",
    "graves county": "21083",
    "grayson county": "21085",
    "green county": "21087",
    "greenup county": "21089",
    "hancock county": "21091",
    "hardin county": "21093",
    "harlan county": "21095",
    "harrison county": "21097",
    "hart county": "21099",
    "henderson county": "21101",
    "henry county": "21103",
    "hickman county": "21105",
    "hopkins county": "21107",
    "jackson county": "21109",
    "jefferson county": "21111",
    "jessamine county": "21113",
    "johnson county": "21115",
    "kenton county": "21117",
    "knott county": "21119",
    "knox county": "21121",
    "larue county": "21123",
    "laurel county": "21125",
    "lawrence county": "21127",
    "lee county": "21129",
    "leslie county": "21131",
    "letcher county": "21133",
    "lewis county": "21135",
    "lincoln county": "21137",
    "livingston county": "21139",
    "logan county": "21141",
    "lyon county": "21143",
    "madison county": "21151",
    "magoffin county": "21153",
    "marion county": "21155",
    "marshall county": "21157",
    "martin county": "21159",
    "mason county": "21161",
    "mccracken county": "21145",
    "mccreary county": "21147",
    "mclean county": "21149",
    "meade county": "21163",
    "menifee county": "21165",
    "mercer county": "21167",
    "metcalfe county": "21169",
    "monroe county": "21171",
    "montgomery county": "21173",
    "morgan county": "21175",
    "muhlenberg county": "21177",
    "nelson county": "21179",
    "nicholas county": "21181",
    "ohio county": "21183",
    "oldham county": "21185",
    "owen county": "21187",
    "owsley county": "21189",
    "pendleton county": "21191",
    "perry county": "21193",
    "pike county": "21195",
    "powell county": "21197",
    "pulaski county": "21199",
    "robertson county": "21201",
    "rockcastle county": "21203",
    "rowan county": "21205",
    "russell county": "21207",
    "scott county": "21209",
    "shelby county": "21211",
    "simpson county": "21213",
    "spencer county": "21215",
    "taylor county": "21217",
    "todd county": "21219",
    "trigg county": "21221",
    "trimble county": "21223",
    "union county": "21225",
    "warren county": "21227",
    "washington county": "21229",
    "wayne county": "21231",
    "webster county": "21233",
    "whitley county": "21235",
    "wolfe county": "21237",
    "woodford county": "21239",
  },
  WA: {
    "adams county": "53001",
    "asotin county": "53003",
    "benton county": "53005",
    "chelan county": "53007",
    "clallam county": "53009",
    "clark county": "53011",
    "columbia county": "53013",
    "cowlitz county": "53015",
    "douglas county": "53017",
    "ferry county": "53019",
    "franklin county": "53021",
    "garfield county": "53023",
    "grant county": "53025",
    "grays harbor county": "53027",
    "island county": "53029",
    "jefferson county": "53031",
    "king county": "53033",
    "kitsap county": "53035",
    "kittitas county": "53037",
    "klickitat county": "53039",
    "lewis county": "53041",
    "lincoln county": "53043",
    "mason county": "53045",
    "okanogan county": "53047",
    "pacific county": "53049",
    "pend oreille county": "53051",
    "pierce county": "53053",
    "san juan county": "53055",
    "skagit county": "53057",
    "skamania county": "53059",
    "snohomish county": "53061",
    "spokane county": "53063",
    "stevens county": "53065",
    "thurston county": "53067",
    "wahkiakum county": "53069",
    "walla walla county": "53071",
    "whatcom county": "53073",
    "whitman county": "53075",
    "yakima county": "53077",
  },
  PA: {
    "adams county": "42001",
    "allegheny county": "42003",
    "armstrong county": "42005",
    "beaver county": "42007",
    "bedford county": "42009",
    "berks county": "42011",
    "blair county": "42013",
    "bradford county": "42015",
    "bucks county": "42017",
    "butler county": "42019",
    "cambria county": "42021",
    "cameron county": "42023",
    "carbon county": "42025",
    "centre county": "42027",
    "chester county": "42029",
    "clarion county": "42031",
    "clearfield county": "42033",
    "clinton county": "42035",
    "columbia county": "42037",
    "crawford county": "42039",
    "cumberland county": "42041",
    "dauphin county": "42043",
    "delaware county": "42045",
    "elk county": "42047",
    "erie county": "42049",
    "fayette county": "42051",
    "forest county": "42053",
    "franklin county": "42055",
    "fulton county": "42057",
    "greene county": "42059",
    "huntingdon county": "42061",
    "indiana county": "42063",
    "jefferson county": "42065",
    "juniata county": "42067",
    "lackawanna county": "42069",
    "lancaster county": "42071",
    "lawrence county": "42073",
    "lebanon county": "42075",
    "lehigh county": "42077",
    "luzerne county": "42079",
    "lycoming county": "42081",
    "mckean county": "42083",
    "mercer county": "42085",
    "mifflin county": "42087",
    "monroe county": "42089",
    "montgomery county": "42091",
    "montour county": "42093",
    "northampton county": "42095",
    "northumberland county": "42097",
    "perry county": "42099",
    "philadelphia county": "42101",
    "pike county": "42103",
    "potter county": "42105",
    "schuylkill county": "42107",
    "snyder county": "42109",
    "somerset county": "42111",
    "sullivan county": "42113",
    "susquehanna county": "42115",
    "tioga county": "42117",
    "union county": "42119",
    "venango county": "42121",
    "warren county": "42123",
    "washington county": "42125",
    "wayne county": "42127",
    "westmoreland county": "42129",
    "wyoming county": "42131",
    "york county": "42133",
  },
  IL: {
    "adams county": "17001",
    "alexander county": "17003",
    "bond county": "17005",
    "boone county": "17007",
    "brown county": "17009",
    "bureau county": "17011",
    "calhoun county": "17013",
    "carroll county": "17015",
    "cass county": "17017",
    "champaign county": "17019",
    "christian county": "17021",
    "clark county": "17023",
    "clay county": "17025",
    "clinton county": "17027",
    "coles county": "17029",
    "cook county": "17031",
    "crawford county": "17033",
    "cumberland county": "17035",
    "dekalb county": "17037",
    "dewitt county": "17039",
    "douglas county": "17041",
    "dupage county": "17043",
    "edgar county": "17045",
    "edwards county": "17047",
    "effingham county": "17049",
    "fayette county": "17051",
    "ford county": "17053",
    "franklin county": "17055",
    "fulton county": "17057",
    "gallatin county": "17059",
    "greene county": "17061",
    "grundy county": "17063",
    "hamilton county": "17065",
    "hancock county": "17067",
    "hardin county": "17069",
    "henderson county": "17071",
    "henry county": "17073",
    "iroquois county": "17075",
    "jackson county": "17077",
    "jasper county": "17079",
    "jefferson county": "17081",
    "jersey county": "17083",
    "jo daviess county": "17085",
    "johnson county": "17087",
    "kane county": "17089",
    "kankakee county": "17091",
    "kendall county": "17093",
    "knox county": "17095",
    "lake county": "17097",
    "lasalle county": "17099",
    "lawrence county": "17101",
    "lee county": "17103",
    "livingston county": "17105",
    "logan county": "17107",
    "macon county": "17115",
    "macoupin county": "17117",
    "madison county": "17119",
    "marion county": "17121",
    "marshall county": "17123",
    "mason county": "17125",
    "massac county": "17127",
    "mcdonough county": "17109",
    "mchenry county": "17111",
    "mclean county": "17113",
    "menard county": "17129",
    "mercer county": "17131",
    "monroe county": "17133",
    "montgomery county": "17135",
    "morgan county": "17137",
    "moultrie county": "17139",
    "ogle county": "17141",
    "peoria county": "17143",
    "perry county": "17145",
    "piatt county": "17147",
    "pike county": "17149",
    "pope county": "17151",
    "pulaski county": "17153",
    "putnam county": "17155",
    "randolph county": "17157",
    "richland county": "17159",
    "rock island county": "17161",
    "saline county": "17165",
    "sangamon county": "17167",
    "schuyler county": "17169",
    "scott county": "17171",
    "shelby county": "17173",
    "st.clair county": "17163",
    "stark county": "17175",
    "stephenson county": "17177",
    "tazewell county": "17179",
    "union county": "17181",
    "vermilion county": "17183",
    "wabash county": "17185",
    "warren county": "17187",
    "washington county": "17189",
    "wayne county": "17191",
    "white county": "17193",
    "whiteside county": "17195",
    "will county": "17197",
    "williamson county": "17199",
    "winnebago county": "17201",
    "woodford county": "17203",
  },
  OH: {
    "adams county": "39001",
    "allen county": "39003",
    "ashland county": "39005",
    "ashtabula county": "39007",
    "athens county": "39009",
    "auglaize county": "39011",
    "belmont county": "39013",
    "brown county": "39015",
    "butler county": "39017",
    "carroll county": "39019",
    "champaign county": "39021",
    "clark county": "39023",
    "clermont county": "39025",
    "clinton county": "39027",
    "columbiana county": "39029",
    "coshocton county": "39031",
    "crawford county": "39033",
    "cuyahoga county": "39035",
    "darke county": "39037",
    "defiance county": "39039",
    "delaware county": "39041",
    "erie county": "39043",
    "fairfield county": "39045",
    "fayette county": "39047",
    "franklin county": "39049",
    "fulton county": "39051",
    "gallia county": "39053",
    "geauga county": "39055",
    "greene county": "39057",
    "guernsey county": "39059",
    "hamilton county": "39061",
    "hancock county": "39063",
    "hardin county": "39065",
    "harrison county": "39067",
    "henry county": "39069",
    "highland county": "39071",
    "hocking county": "39073",
    "holmes county": "39075",
    "huron county": "39077",
    "jackson county": "39079",
    "jefferson county": "39081",
    "knox county": "39083",
    "lake county": "39085",
    "lawrence county": "39087",
    "licking county": "39089",
    "logan county": "39091",
    "lorain county": "39093",
    "lucas county": "39095",
    "madison county": "39097",
    "mahoning county": "39099",
    "marion county": "39101",
    "medina county": "39103",
    "meigs county": "39105",
    "mercer county": "39107",
    "miami county": "39109",
    "monroe county": "39111",
    "montgomery county": "39113",
    "morgan county": "39115",
    "morrow county": "39117",
    "muskingum county": "39119",
    "noble county": "39121",
    "ottawa county": "39123",
    "paulding county": "39125",
    "perry county": "39127",
    "pickaway county": "39129",
    "pike county": "39131",
    "portage county": "39133",
    "preble county": "39135",
    "putnam county": "39137",
    "richland county": "39139",
    "ross county": "39141",
    "sandusky county": "39143",
    "scioto county": "39145",
    "seneca county": "39147",
    "shelby county": "39149",
    "stark county": "39151",
    "summit county": "39153",
    "trumbull county": "39155",
    "tuscarawas county": "39157",
    "union county": "39159",
    "van wert county": "39161",
    "vinton county": "39163",
    "warren county": "39165",
    "washington county": "39167",
    "wayne county": "39169",
    "williams county": "39171",
    "wood county": "39173",
    "wyandot county": "39175",
  },
  MS: {
    "adams county": "28001",
    "alcorn county": "28003",
    "amite county": "28005",
    "attala county": "28007",
    "benton county": "28009",
    "bolivar county": "28011",
    "calhoun county": "28013",
    "carroll county": "28015",
    "chickasaw county": "28017",
    "choctaw county": "28019",
    "claiborne county": "28021",
    "clarke county": "28023",
    "clay county": "28025",
    "coahoma county": "28027",
    "copiah county": "28029",
    "covington county": "28031",
    "desoto county": "28033",
    "forrest county": "28035",
    "franklin county": "28037",
    "george county": "28039",
    "greene county": "28041",
    "grenada county": "28043",
    "hancock county": "28045",
    "harrison county": "28047",
    "hinds county": "28049",
    "holmes county": "28051",
    "humphreys county": "28053",
    "issaquena county": "28055",
    "itawamba county": "28057",
    "jackson county": "28059",
    "jasper county": "28061",
    "jefferson county": "28063",
    "jefferson davis county": "28065",
    "jones county": "28067",
    "kemper county": "28069",
    "lafayette county": "28071",
    "lamar county": "28073",
    "lauderdale county": "28075",
    "lawrence county": "28077",
    "leake county": "28079",
    "lee county": "28081",
    "leflore county": "28083",
    "lincoln county": "28085",
    "lowndes county": "28087",
    "madison county": "28089",
    "marion county": "28091",
    "marshall county": "28093",
    "monroe county": "28095",
    "montgomery county": "28097",
    "neshoba county": "28099",
    "newton county": "28101",
    "noxubee county": "28103",
    "oktibbeha county": "28105",
    "panola county": "28107",
    "pearl river county": "28109",
    "perry county": "28111",
    "pike county": "28113",
    "pontotoc county": "28115",
    "prentiss county": "28117",
    "quitman county": "28119",
    "rankin county": "28121",
    "scott county": "28123",
    "sharkey county": "28125",
    "simpson county": "28127",
    "smith county": "28129",
    "stone county": "28131",
    "sunflower county": "28133",
    "tallahatchie county": "28135",
    "tate county": "28137",
    "tippah county": "28139",
    "tishomingo county": "28141",
    "tunica county": "28143",
    "union county": "28145",
    "walthall county": "28147",
    "warren county": "28149",
    "washington county": "28151",
    "wayne county": "28153",
    "webster county": "28155",
    "wilkinson county": "28157",
    "winston county": "28159",
    "yalobusha county": "28161",
    "yazoo county": "28163",
  },
  CO: {
    "adams county": "08001",
    "alamosa county": "08003",
    "arapahoe county": "08005",
    "archuleta county": "08007",
    "baca county": "08009",
    "bent county": "08011",
    "boulder county": "08013",
    "broomfield county": "08014",
    "chaffee county": "08015",
    "cheyenne county": "08017",
    "clear creek county": "08019",
    "conejos county": "08021",
    "costilla county": "08023",
    "crowley county": "08025",
    "custer county": "08027",
    "delta county": "08029",
    "denver county": "08031",
    "dolores county": "08033",
    "douglas county": "08035",
    "eagle county": "08037",
    "el paso county": "08041",
    "elbert county": "08039",
    "fremont county": "08043",
    "garfield county": "08045",
    "gilpin county": "08047",
    "grand county": "08049",
    "gunnison county": "08051",
    "hinsdale county": "08053",
    "huerfano county": "08055",
    "jackson county": "08057",
    "jefferson county": "08059",
    "kiowa county": "08061",
    "kit carson county": "08063",
    "la plata county": "08067",
    "lake county": "08065",
    "larimer county": "08069",
    "las animas county": "08071",
    "lincoln county": "08073",
    "logan county": "08075",
    "mesa county": "08077",
    "mineral county": "08079",
    "moffat county": "08081",
    "montezuma county": "08083",
    "montrose county": "08085",
    "morgan county": "08087",
    "otero county": "08089",
    "ouray county": "08091",
    "park county": "08093",
    "phillips county": "08095",
    "pitkin county": "08097",
    "prowers county": "08099",
    "pueblo county": "08101",
    "rio blanco county": "08103",
    "rio grande county": "08105",
    "routt county": "08107",
    "saguache county": "08109",
    "san juan county": "08111",
    "san miguel county": "08113",
    "sedgwick county": "08115",
    "summit county": "08117",
    "teller county": "08119",
    "washington county": "08121",
    "weld county": "08123",
    "yuma county": "08125",
  },
  IN: {
    "adams county": "18001",
    "allen county": "18003",
    "bartholomew county": "18005",
    "benton county": "18007",
    "blackford county": "18009",
    "boone county": "18011",
    "brown county": "18013",
    "carroll county": "18015",
    "cass county": "18017",
    "clark county": "18019",
    "clay county": "18021",
    "clinton county": "18023",
    "crawford county": "18025",
    "daviess county": "18027",
    "dearborn county": "18029",
    "decatur county": "18031",
    "dekalb county": "18033",
    "delaware county": "18035",
    "dubois county": "18037",
    "elkhart county": "18039",
    "fayette county": "18041",
    "floyd county": "18043",
    "fountain county": "18045",
    "franklin county": "18047",
    "fulton county": "18049",
    "gibson county": "18051",
    "grant county": "18053",
    "greene county": "18055",
    "hamilton county": "18057",
    "hancock county": "18059",
    "harrison county": "18061",
    "hendricks county": "18063",
    "henry county": "18065",
    "howard county": "18067",
    "huntington county": "18069",
    "jackson county": "18071",
    "jasper county": "18073",
    "jay county": "18075",
    "jefferson county": "18077",
    "jennings county": "18079",
    "johnson county": "18081",
    "knox county": "18083",
    "kosciusko county": "18085",
    "lagrange county": "18087",
    "lake county": "18089",
    "laporte county": "18091",
    "lawrence county": "18093",
    "madison county": "18095",
    "marion county": "18097",
    "marshall county": "18099",
    "martin county": "18101",
    "miami county": "18103",
    "monroe county": "18105",
    "montgomery county": "18107",
    "morgan county": "18109",
    "newton county": "18111",
    "noble county": "18113",
    "ohio county": "18115",
    "orange county": "18117",
    "owen county": "18119",
    "parke county": "18121",
    "perry county": "18123",
    "pike county": "18125",
    "porter county": "18127",
    "posey county": "18129",
    "pulaski county": "18131",
    "putnam county": "18133",
    "randolph county": "18135",
    "ripley county": "18137",
    "rush county": "18139",
    "scott county": "18143",
    "shelby county": "18145",
    "spencer county": "18147",
    "st.joseph county": "18141",
    "starke county": "18149",
    "steuben county": "18151",
    "sullivan county": "18153",
    "switzerland county": "18155",
    "tippecanoe county": "18157",
    "tipton county": "18159",
    "union county": "18161",
    "vanderburgh county": "18163",
    "vermillion county": "18165",
    "vigo county": "18167",
    "wabash county": "18169",
    "warren county": "18171",
    "warrick county": "18173",
    "washington county": "18175",
    "wayne county": "18177",
    "wells county": "18179",
    "white county": "18181",
    "whitley county": "18183",
  },
  WI: {
    "adams county": "55001",
    "ashland county": "55003",
    "barron county": "55005",
    "bayfield county": "55007",
    "brown county": "55009",
    "buffalo county": "55011",
    "burnett county": "55013",
    "calumet county": "55015",
    "chippewa county": "55017",
    "clark county": "55019",
    "columbia county": "55021",
    "crawford county": "55023",
    "dane county": "55025",
    "dodge county": "55027",
    "door county": "55029",
    "douglas county": "55031",
    "dunn county": "55033",
    "eau claire county": "55035",
    "florence county": "55037",
    "fond du lac county": "55039",
    "forest county": "55041",
    "grant county": "55043",
    "green county": "55045",
    "green lake county": "55047",
    "iowa county": "55049",
    "iron county": "55051",
    "jackson county": "55053",
    "jefferson county": "55055",
    "juneau county": "55057",
    "kenosha county": "55059",
    "kewaunee county": "55061",
    "la crosse county": "55063",
    "lafayette county": "55065",
    "langlade county": "55067",
    "lincoln county": "55069",
    "manitowoc county": "55071",
    "marathon county": "55073",
    "marinette county": "55075",
    "marquette county": "55077",
    "menominee county": "55078",
    "milwaukee county": "55079",
    "monroe county": "55081",
    "oconto county": "55083",
    "oneida county": "55085",
    "outagamie county": "55087",
    "ozaukee county": "55089",
    "pepin county": "55091",
    "pierce county": "55093",
    "polk county": "55095",
    "portage county": "55097",
    "price county": "55099",
    "racine county": "55101",
    "richland county": "55103",
    "rock county": "55105",
    "rusk county": "55107",
    "sauk county": "55111",
    "sawyer county": "55113",
    "shawano county": "55115",
    "sheboygan county": "55117",
    "st.croix county": "55109",
    "taylor county": "55119",
    "trempealeau county": "55121",
    "vernon county": "55123",
    "vilas county": "55125",
    "walworth county": "55127",
    "washburn county": "55129",
    "washington county": "55131",
    "waukesha county": "55133",
    "waupaca county": "55135",
    "waushara county": "55137",
    "winnebago county": "55139",
    "wood county": "55141",
  },
  MN: {
    "aitkin county": "27001",
    "anoka county": "27003",
    "becker county": "27005",
    "beltrami county": "27007",
    "benton county": "27009",
    "big stone county": "27011",
    "blue earth county": "27013",
    "brown county": "27015",
    "carlton county": "27017",
    "carver county": "27019",
    "cass county": "27021",
    "chippewa county": "27023",
    "chisago county": "27025",
    "clay county": "27027",
    "clearwater county": "27029",
    "cook county": "27031",
    "cottonwood county": "27033",
    "crow wing county": "27035",
    "dakota county": "27037",
    "dodge county": "27039",
    "douglas county": "27041",
    "faribault county": "27043",
    "fillmore county": "27045",
    "freeborn county": "27047",
    "goodhue county": "27049",
    "grant county": "27051",
    "hennepin county": "27053",
    "houston county": "27055",
    "hubbard county": "27057",
    "isanti county": "27059",
    "itasca county": "27061",
    "jackson county": "27063",
    "kanabec county": "27065",
    "kandiyohi county": "27067",
    "kittson county": "27069",
    "koochiching county": "27071",
    "lac qui parle county": "27073",
    "lake county": "27075",
    "lake of the woods county": "27077",
    "le sueur county": "27079",
    "lincoln county": "27081",
    "lyon county": "27083",
    "mahnomen county": "27087",
    "marshall county": "27089",
    "martin county": "27091",
    "mcleod county": "27085",
    "meeker county": "27093",
    "mille lacs county": "27095",
    "morrison county": "27097",
    "mower county": "27099",
    "murray county": "27101",
    "nicollet county": "27103",
    "nobles county": "27105",
    "norman county": "27107",
    "olmsted county": "27109",
    "otter tail county": "27111",
    "pennington county": "27113",
    "pine county": "27115",
    "pipestone county": "27117",
    "polk county": "27119",
    "pope county": "27121",
    "ramsey county": "27123",
    "red lake county": "27125",
    "redwood county": "27127",
    "renville county": "27129",
    "rice county": "27131",
    "rock county": "27133",
    "roseau county": "27135",
    "scott county": "27139",
    "sherburne county": "27141",
    "sibley county": "27143",
    "st.louis county": "27137",
    "stearns county": "27145",
    "steele county": "27147",
    "stevens county": "27149",
    "swift county": "27151",
    "todd county": "27153",
    "traverse county": "27155",
    "wabasha county": "27157",
    "wadena county": "27159",
    "waseca county": "27161",
    "washington county": "27163",
    "watonwan county": "27165",
    "wilkin county": "27167",
    "winona county": "27169",
    "wright county": "27171",
    "yellow medicine county": "27173",
  },
  FL: {
    "alachua county": "12001",
    "baker county": "12003",
    "bay county": "12005",
    "bradford county": "12007",
    "brevard county": "12009",
    "broward county": "12011",
    "calhoun county": "12013",
    "charlotte county": "12015",
    "citrus county": "12017",
    "clay county": "12019",
    "collier county": "12021",
    "columbia county": "12023",
    "desoto county": "12027",
    "dixie county": "12029",
    "duval county": "12031",
    "escambia county": "12033",
    "flagler county": "12035",
    "franklin county": "12037",
    "gadsden county": "12039",
    "gilchrist county": "12041",
    "glades county": "12043",
    "gulf county": "12045",
    "hamilton county": "12047",
    "hardee county": "12049",
    "hendry county": "12051",
    "hernando county": "12053",
    "highlands county": "12055",
    "hillsborough county": "12057",
    "holmes county": "12059",
    "indian river county": "12061",
    "jackson county": "12063",
    "jefferson county": "12065",
    "lafayette county": "12067",
    "lake county": "12069",
    "lee county": "12071",
    "leon county": "12073",
    "levy county": "12075",
    "liberty county": "12077",
    "madison county": "12079",
    "manatee county": "12081",
    "marion county": "12083",
    "martin county": "12085",
    "miami-dade county": "12086",
    "monroe county": "12087",
    "nassau county": "12089",
    "okaloosa county": "12091",
    "okeechobee county": "12093",
    "orange county": "12095",
    "osceola county": "12097",
    "palm beach county": "12099",
    "pasco county": "12101",
    "pinellas county": "12103",
    "polk county": "12105",
    "putnam county": "12107",
    "santa rosa county": "12113",
    "sarasota county": "12115",
    "seminole county": "12117",
    "st.johns county": "12109",
    "st.lucie county": "12111",
    "sumter county": "12119",
    "suwannee county": "12121",
    "taylor county": "12123",
    "union county": "12125",
    "volusia county": "12127",
    "wakulla county": "12129",
    "walton county": "12131",
    "washington county": "12133",
  },
  NC: {
    "alamance county": "37001",
    "alexander county": "37003",
    "alleghany county": "37005",
    "anson county": "37007",
    "ashe county": "37009",
    "avery county": "37011",
    "beaufort county": "37013",
    "bertie county": "37015",
    "bladen county": "37017",
    "brunswick county": "37019",
    "buncombe county": "37021",
    "burke county": "37023",
    "cabarrus county": "37025",
    "caldwell county": "37027",
    "camden county": "37029",
    "carteret county": "37031",
    "caswell county": "37033",
    "catawba county": "37035",
    "chatham county": "37037",
    "cherokee county": "37039",
    "chowan county": "37041",
    "clay county": "37043",
    "cleveland county": "37045",
    "columbus county": "37047",
    "craven county": "37049",
    "cumberland county": "37051",
    "currituck county": "37053",
    "dare county": "37055",
    "davidson county": "37057",
    "davie county": "37059",
    "duplin county": "37061",
    "durham county": "37063",
    "edgecombe county": "37065",
    "forsyth county": "37067",
    "franklin county": "37069",
    "gaston county": "37071",
    "gates county": "37073",
    "graham county": "37075",
    "granville county": "37077",
    "greene county": "37079",
    "guilford county": "37081",
    "halifax county": "37083",
    "harnett county": "37085",
    "haywood county": "37087",
    "henderson county": "37089",
    "hertford county": "37091",
    "hoke county": "37093",
    "hyde county": "37095",
    "iredell county": "37097",
    "jackson county": "37099",
    "johnston county": "37101",
    "jones county": "37103",
    "lee county": "37105",
    "lenoir county": "37107",
    "lincoln county": "37109",
    "macon county": "37113",
    "madison county": "37115",
    "martin county": "37117",
    "mcdowell county": "37111",
    "mecklenburg county": "37119",
    "mitchell county": "37121",
    "montgomery county": "37123",
    "moore county": "37125",
    "nash county": "37127",
    "new hanover county": "37129",
    "northampton county": "37131",
    "onslow county": "37133",
    "orange county": "37135",
    "pamlico county": "37137",
    "pasquotank county": "37139",
    "pender county": "37141",
    "perquimans county": "37143",
    "person county": "37145",
    "pitt county": "37147",
    "polk county": "37149",
    "randolph county": "37151",
    "richmond county": "37153",
    "robeson county": "37155",
    "rockingham county": "37157",
    "rowan county": "37159",
    "rutherford county": "37161",
    "sampson county": "37163",
    "scotland county": "37165",
    "stanly county": "37167",
    "stokes county": "37169",
    "surry county": "37171",
    "swain county": "37173",
    "transylvania county": "37175",
    "tyrrell county": "37177",
    "union county": "37179",
    "vance county": "37181",
    "wake county": "37183",
    "warren county": "37185",
    "washington county": "37187",
    "watauga county": "37189",
    "wayne county": "37191",
    "wilkes county": "37193",
    "wilson county": "37195",
    "yadkin county": "37197",
    "yancey county": "37199",
  },
  CA: {
    "alameda county": "06001",
    "alpine county": "06003",
    "amador county": "06005",
    "butte county": "06007",
    "calaveras county": "06009",
    "colusa county": "06011",
    "contra costa county": "06013",
    "del norte county": "06015",
    "el dorado county": "06017",
    "fresno county": "06019",
    "glenn county": "06021",
    "humboldt county": "06023",
    "imperial county": "06025",
    "inyo county": "06027",
    "kern county": "06029",
    "kings county": "06031",
    "lake county": "06033",
    "lassen county": "06035",
    "los angeles county": "06037",
    "madera county": "06039",
    "marin county": "06041",
    "mariposa county": "06043",
    "mendocino county": "06045",
    "merced county": "06047",
    "modoc county": "06049",
    "mono county": "06051",
    "monterey county": "06053",
    "napa county": "06055",
    "nevada county": "06057",
    "orange county": "06059",
    "placer county": "06061",
    "plumas county": "06063",
    "riverside county": "06065",
    "sacramento county": "06067",
    "san benito county": "06069",
    "san bernardino county": "06071",
    "san diego county": "06073",
    "san francisco county": "06075",
    "san joaquin county": "06077",
    "san luis obispo county": "06079",
    "san mateo county": "06081",
    "santa barbara county": "06083",
    "santa clara county": "06085",
    "santa cruz county": "06087",
    "shasta county": "06089",
    "sierra county": "06091",
    "siskiyou county": "06093",
    "solano county": "06095",
    "sonoma county": "06097",
    "stanislaus county": "06099",
    "sutter county": "06101",
    "tehama county": "06103",
    "trinity county": "06105",
    "tulare county": "06107",
    "tuolumne county": "06109",
    "ventura county": "06111",
    "yolo county": "06113",
    "yuba county": "06115",
  },
  NY: {
    "albany county": "36001",
    "allegany county": "36003",
    "bronx county": "36005",
    "broome county": "36007",
    "cattaraugus county": "36009",
    "cayuga county": "36011",
    "chautauqua county": "36013",
    "chemung county": "36015",
    "chenango county": "36017",
    "clinton county": "36019",
    "columbia county": "36021",
    "cortland county": "36023",
    "delaware county": "36025",
    "dutchess county": "36027",
    "erie county": "36029",
    "essex county": "36031",
    "franklin county": "36033",
    "fulton county": "36035",
    "genesee county": "36037",
    "greene county": "36039",
    "hamilton county": "36041",
    "herkimer county": "36043",
    "jefferson county": "36045",
    "kings county": "36047",
    "lewis county": "36049",
    "livingston county": "36051",
    "madison county": "36053",
    "monroe county": "36055",
    "montgomery county": "36057",
    "nassau county": "36059",
    "new york county": "36061",
    "niagara county": "36063",
    "oneida county": "36065",
    "onondaga county": "36067",
    "ontario county": "36069",
    "orange county": "36071",
    "orleans county": "36073",
    "oswego county": "36075",
    "otsego county": "36077",
    "putnam county": "36079",
    "queens county": "36081",
    "rensselaer county": "36083",
    "richmond county": "36085",
    "rockland county": "36087",
    "saratoga county": "36091",
    "schenectady county": "36093",
    "schoharie county": "36095",
    "schuyler county": "36097",
    "seneca county": "36099",
    "st.lawrence county": "36089",
    "steuben county": "36101",
    "suffolk county": "36103",
    "sullivan county": "36105",
    "tioga county": "36107",
    "tompkins county": "36109",
    "ulster county": "36111",
    "warren county": "36113",
    "washington county": "36115",
    "wayne county": "36117",
    "westchester county": "36119",
    "wyoming county": "36121",
    "yates county": "36123",
  },
  MI: {
    "alcona county": "26001",
    "alger county": "26003",
    "allegan county": "26005",
    "alpena county": "26007",
    "antrim county": "26009",
    "arenac county": "26011",
    "baraga county": "26013",
    "barry county": "26015",
    "bay county": "26017",
    "benzie county": "26019",
    "berrien county": "26021",
    "branch county": "26023",
    "calhoun county": "26025",
    "cass county": "26027",
    "charlevoix county": "26029",
    "cheboygan county": "26031",
    "chippewa county": "26033",
    "clare county": "26035",
    "clinton county": "26037",
    "crawford county": "26039",
    "delta county": "26041",
    "dickinson county": "26043",
    "eaton county": "26045",
    "emmet county": "26047",
    "genesee county": "26049",
    "gladwin county": "26051",
    "gogebic county": "26053",
    "grand traverse county": "26055",
    "gratiot county": "26057",
    "hillsdale county": "26059",
    "houghton county": "26061",
    "huron county": "26063",
    "ingham county": "26065",
    "ionia county": "26067",
    "iosco county": "26069",
    "iron county": "26071",
    "isabella county": "26073",
    "jackson county": "26075",
    "kalamazoo county": "26077",
    "kalkaska county": "26079",
    "kent county": "26081",
    "keweenaw county": "26083",
    "lake county": "26085",
    "lapeer county": "26087",
    "leelanau county": "26089",
    "lenawee county": "26091",
    "livingston county": "26093",
    "luce county": "26095",
    "mackinac county": "26097",
    "macomb county": "26099",
    "manistee county": "26101",
    "marquette county": "26103",
    "mason county": "26105",
    "mecosta county": "26107",
    "menominee county": "26109",
    "midland county": "26111",
    "missaukee county": "26113",
    "monroe county": "26115",
    "montcalm county": "26117",
    "montmorency county": "26119",
    "muskegon county": "26121",
    "newaygo county": "26123",
    "oakland county": "26125",
    "oceana county": "26127",
    "ogemaw county": "26129",
    "ontonagon county": "26131",
    "osceola county": "26133",
    "oscoda county": "26135",
    "otsego county": "26137",
    "ottawa county": "26139",
    "presque isle county": "26141",
    "roscommon county": "26143",
    "saginaw county": "26145",
    "sanilac county": "26151",
    "schoolcraft county": "26153",
    "shiawassee county": "26155",
    "st.clair county": "26147",
    "st.joseph county": "26149",
    "tuscola county": "26157",
    "van buren county": "26159",
    "washtenaw county": "26161",
    "wayne county": "26163",
    "wexford county": "26165",
  },
  MD: {
    "allegany county": "24001",
    "anne arundel county": "24003",
    "baltimore city": "24510",
    "baltimore county": "24005",
    "calvert county": "24009",
    "caroline county": "24011",
    "carroll county": "24013",
    "cecil county": "24015",
    "charles county": "24017",
    "dorchester county": "24019",
    "frederick county": "24021",
    "garrett county": "24023",
    "harford county": "24025",
    "howard county": "24027",
    "kent county": "24029",
    "montgomery county": "24031",
    "prince george's county": "24033",
    "queen anne's county": "24035",
    "somerset county": "24039",
    "st.mary's county": "24037",
    "talbot county": "24041",
    "washington county": "24043",
    "wicomico county": "24045",
    "worcester county": "24047",
  },
  TX: {
    "anderson county": "48001",
    "andrews county": "48003",
    "angelina county": "48005",
    "aransas county": "48007",
    "archer county": "48009",
    "armstrong county": "48011",
    "atascosa county": "48013",
    "austin county": "48015",
    "bailey county": "48017",
    "bandera county": "48019",
    "bastrop county": "48021",
    "baylor county": "48023",
    "bee county": "48025",
    "bell county": "48027",
    "bexar county": "48029",
    "blanco county": "48031",
    "borden county": "48033",
    "bosque county": "48035",
    "bowie county": "48037",
    "brazoria county": "48039",
    "brazos county": "48041",
    "brewster county": "48043",
    "briscoe county": "48045",
    "brooks county": "48047",
    "brown county": "48049",
    "burleson county": "48051",
    "burnet county": "48053",
    "caldwell county": "48055",
    "calhoun county": "48057",
    "callahan county": "48059",
    "cameron county": "48061",
    "camp county": "48063",
    "carson county": "48065",
    "cass county": "48067",
    "castro county": "48069",
    "chambers county": "48071",
    "cherokee county": "48073",
    "childress county": "48075",
    "clay county": "48077",
    "cochran county": "48079",
    "coke county": "48081",
    "coleman county": "48083",
    "collin county": "48085",
    "collingsworth county": "48087",
    "colorado county": "48089",
    "comal county": "48091",
    "comanche county": "48093",
    "concho county": "48095",
    "cooke county": "48097",
    "coryell county": "48099",
    "cottle county": "48101",
    "crane county": "48103",
    "crockett county": "48105",
    "crosby county": "48107",
    "culberson county": "48109",
    "dallam county": "48111",
    "dallas county": "48113",
    "dawson county": "48115",
    "deaf smith county": "48117",
    "delta county": "48119",
    "denton county": "48121",
    "dewitt county": "48123",
    "dickens county": "48125",
    "dimmit county": "48127",
    "donley county": "48129",
    "duval county": "48131",
    "eastland county": "48133",
    "ector county": "48135",
    "edwards county": "48137",
    "el paso county": "48141",
    "ellis county": "48139",
    "erath county": "48143",
    "falls county": "48145",
    "fannin county": "48147",
    "fayette county": "48149",
    "fisher county": "48151",
    "floyd county": "48153",
    "foard county": "48155",
    "fort bend county": "48157",
    "franklin county": "48159",
    "freestone county": "48161",
    "frio county": "48163",
    "gaines county": "48165",
    "galveston county": "48167",
    "garza county": "48169",
    "gillespie county": "48171",
    "glasscock county": "48173",
    "goliad county": "48175",
    "gonzales county": "48177",
    "gray county": "48179",
    "grayson county": "48181",
    "gregg county": "48183",
    "grimes county": "48185",
    "guadalupe county": "48187",
    "hale county": "48189",
    "hall county": "48191",
    "hamilton county": "48193",
    "hansford county": "48195",
    "hardeman county": "48197",
    "hardin county": "48199",
    "harris county": "48201",
    "harrison county": "48203",
    "hartley county": "48205",
    "haskell county": "48207",
    "hays county": "48209",
    "hemphill county": "48211",
    "henderson county": "48213",
    "hidalgo county": "48215",
    "hill county": "48217",
    "hockley county": "48219",
    "hood county": "48221",
    "hopkins county": "48223",
    "houston county": "48225",
    "howard county": "48227",
    "hudspeth county": "48229",
    "hunt county": "48231",
    "hutchinson county": "48233",
    "irion county": "48235",
    "jack county": "48237",
    "jackson county": "48239",
    "jasper county": "48241",
    "jeff davis county": "48243",
    "jefferson county": "48245",
    "jim hogg county": "48247",
    "jim wells county": "48249",
    "johnson county": "48251",
    "jones county": "48253",
    "karnes county": "48255",
    "kaufman county": "48257",
    "kendall county": "48259",
    "kenedy county": "48261",
    "kent county": "48263",
    "kerr county": "48265",
    "kimble county": "48267",
    "king county": "48269",
    "kinney county": "48271",
    "kleberg county": "48273",
    "knox county": "48275",
    "la salle county": "48283",
    "lamar county": "48277",
    "lamb county": "48279",
    "lampasas county": "48281",
    "lavaca county": "48285",
    "lee county": "48287",
    "leon county": "48289",
    "liberty county": "48291",
    "limestone county": "48293",
    "lipscomb county": "48295",
    "live oak county": "48297",
    "llano county": "48299",
    "loving county": "48301",
    "lubbock county": "48303",
    "lynn county": "48305",
    "madison county": "48313",
    "marion county": "48315",
    "martin county": "48317",
    "mason county": "48319",
    "matagorda county": "48321",
    "maverick county": "48323",
    "mcculloch county": "48307",
    "mclennan county": "48309",
    "mcmullen county": "48311",
    "medina county": "48325",
    "menard county": "48327",
    "midland county": "48329",
    "milam county": "48331",
    "mills county": "48333",
    "mitchell county": "48335",
    "montague county": "48337",
    "montgomery county": "48339",
    "moore county": "48341",
    "morris county": "48343",
    "motley county": "48345",
    "nacogdoches county": "48347",
    "navarro county": "48349",
    "newton county": "48351",
    "nolan county": "48353",
    "nueces county": "48355",
    "ochiltree county": "48357",
    "oldham county": "48359",
    "orange county": "48361",
    "palo pinto county": "48363",
    "panola county": "48365",
    "parker county": "48367",
    "parmer county": "48369",
    "pecos county": "48371",
    "polk county": "48373",
    "potter county": "48375",
    "presidio county": "48377",
    "rains county": "48379",
    "randall county": "48381",
    "reagan county": "48383",
    "real county": "48385",
    "red river county": "48387",
    "reeves county": "48389",
    "refugio county": "48391",
    "roberts county": "48393",
    "robertson county": "48395",
    "rockwall county": "48397",
    "runnels county": "48399",
    "rusk county": "48401",
    "sabine county": "48403",
    "san augustine county": "48405",
    "san jacinto county": "48407",
    "san patricio county": "48409",
    "san saba county": "48411",
    "schleicher county": "48413",
    "scurry county": "48415",
    "shackelford county": "48417",
    "shelby county": "48419",
    "sherman county": "48421",
    "smith county": "48423",
    "somervell county": "48425",
    "starr county": "48427",
    "stephens county": "48429",
    "sterling county": "48431",
    "stonewall county": "48433",
    "sutton county": "48435",
    "swisher county": "48437",
    "tarrant county": "48439",
    "taylor county": "48441",
    "terrell county": "48443",
    "terry county": "48445",
    "throckmorton county": "48447",
    "titus county": "48449",
    "tom green county": "48451",
    "travis county": "48453",
    "trinity county": "48455",
    "tyler county": "48457",
    "upshur county": "48459",
    "upton county": "48461",
    "uvalde county": "48463",
    "val verde county": "48465",
    "van zandt county": "48467",
    "victoria county": "48469",
    "walker county": "48471",
    "waller county": "48473",
    "ward county": "48475",
    "washington county": "48477",
    "webb county": "48479",
    "wharton county": "48481",
    "wheeler county": "48483",
    "wichita county": "48485",
    "wilbarger county": "48487",
    "willacy county": "48489",
    "williamson county": "48491",
    "wilson county": "48493",
    "winkler county": "48495",
    "wise county": "48497",
    "wood county": "48499",
    "yoakum county": "48501",
    "young county": "48503",
    "zapata county": "48505",
    "zavala county": "48507",
  },
  TN: {
    "anderson county": "47001",
    "bedford county": "47003",
    "benton county": "47005",
    "bledsoe county": "47007",
    "blount county": "47009",
    "bradley county": "47011",
    "campbell county": "47013",
    "cannon county": "47015",
    "carroll county": "47017",
    "carter county": "47019",
    "cheatham county": "47021",
    "chester county": "47023",
    "claiborne county": "47025",
    "clay county": "47027",
    "cocke county": "47029",
    "coffee county": "47031",
    "crockett county": "47033",
    "cumberland county": "47035",
    "davidson county": "47037",
    "decatur county": "47039",
    "dekalb county": "47041",
    "dickson county": "47043",
    "dyer county": "47045",
    "fayette county": "47047",
    "fentress county": "47049",
    "franklin county": "47051",
    "gibson county": "47053",
    "giles county": "47055",
    "grainger county": "47057",
    "greene county": "47059",
    "grundy county": "47061",
    "hamblen county": "47063",
    "hamilton county": "47065",
    "hancock county": "47067",
    "hardeman county": "47069",
    "hardin county": "47071",
    "hawkins county": "47073",
    "haywood county": "47075",
    "henderson county": "47077",
    "henry county": "47079",
    "hickman county": "47081",
    "houston county": "47083",
    "humphreys county": "47085",
    "jackson county": "47087",
    "jefferson county": "47089",
    "johnson county": "47091",
    "knox county": "47093",
    "lake county": "47095",
    "lauderdale county": "47097",
    "lawrence county": "47099",
    "lewis county": "47101",
    "lincoln county": "47103",
    "loudon county": "47105",
    "macon county": "47111",
    "madison county": "47113",
    "marion county": "47115",
    "marshall county": "47117",
    "maury county": "47119",
    "mcminn county": "47107",
    "mcnairy county": "47109",
    "meigs county": "47121",
    "monroe county": "47123",
    "montgomery county": "47125",
    "moore county": "47127",
    "morgan county": "47129",
    "obion county": "47131",
    "overton county": "47133",
    "perry county": "47135",
    "pickett county": "47137",
    "polk county": "47139",
    "putnam county": "47141",
    "rhea county": "47143",
    "roane county": "47145",
    "robertson county": "47147",
    "rutherford county": "47149",
    "scott county": "47151",
    "sequatchie county": "47153",
    "sevier county": "47155",
    "shelby county": "47157",
    "smith county": "47159",
    "stewart county": "47161",
    "sullivan county": "47163",
    "sumner county": "47165",
    "tipton county": "47167",
    "trousdale county": "47169",
    "unicoi county": "47171",
    "union county": "47173",
    "van buren county": "47175",
    "warren county": "47177",
    "washington county": "47179",
    "wayne county": "47181",
    "weakley county": "47183",
    "white county": "47185",
    "williamson county": "47187",
    "wilson county": "47189",
  },
  AZ: {
    "apache county": "04001",
    "cochise county": "04003",
    "coconino county": "04005",
    "gila county": "04007",
    "graham county": "04009",
    "greenlee county": "04011",
    "la paz county": "04012",
    "maricopa county": "04013",
    "mohave county": "04015",
    "navajo county": "04017",
    "pima county": "04019",
    "pinal county": "04021",
    "santa cruz county": "04023",
    "yavapai county": "04025",
    "yuma county": "04027",
  },
  GA: {
    "appling county": "13001",
    "atkinson county": "13003",
    "bacon county": "13005",
    "baker county": "13007",
    "baldwin county": "13009",
    "banks county": "13011",
    "barrow county": "13013",
    "bartow county": "13015",
    "ben hill county": "13017",
    "berrien county": "13019",
    "bibb county": "13021",
    "bleckley county": "13023",
    "brantley county": "13025",
    "brooks county": "13027",
    "bryan county": "13029",
    "bulloch county": "13031",
    "burke county": "13033",
    "butts county": "13035",
    "calhoun county": "13037",
    "camden county": "13039",
    "candler county": "13043",
    "carroll county": "13045",
    "catoosa county": "13047",
    "charlton county": "13049",
    "chatham county": "13051",
    "chattahoochee county": "13053",
    "chattooga county": "13055",
    "cherokee county": "13057",
    "clarke county": "13059",
    "clay county": "13061",
    "clayton county": "13063",
    "clinch county": "13065",
    "cobb county": "13067",
    "coffee county": "13069",
    "colquitt county": "13071",
    "columbia county": "13073",
    "cook county": "13075",
    "coweta county": "13077",
    "crawford county": "13079",
    "crisp county": "13081",
    "dade county": "13083",
    "dawson county": "13085",
    "decatur county": "13087",
    "dekalb county": "13089",
    "dodge county": "13091",
    "dooly county": "13093",
    "dougherty county": "13095",
    "douglas county": "13097",
    "early county": "13099",
    "echols county": "13101",
    "effingham county": "13103",
    "elbert county": "13105",
    "emanuel county": "13107",
    "evans county": "13109",
    "fannin county": "13111",
    "fayette county": "13113",
    "floyd county": "13115",
    "forsyth county": "13117",
    "franklin county": "13119",
    "fulton county": "13121",
    "gilmer county": "13123",
    "glascock county": "13125",
    "glynn county": "13127",
    "gordon county": "13129",
    "grady county": "13131",
    "greene county": "13133",
    "gwinnett county": "13135",
    "habersham county": "13137",
    "hall county": "13139",
    "hancock county": "13141",
    "haralson county": "13143",
    "harris county": "13145",
    "hart county": "13147",
    "heard county": "13149",
    "henry county": "13151",
    "houston county": "13153",
    "irwin county": "13155",
    "jackson county": "13157",
    "jasper county": "13159",
    "jeff davis county": "13161",
    "jefferson county": "13163",
    "jenkins county": "13165",
    "johnson county": "13167",
    "jones county": "13169",
    "lamar county": "13171",
    "lanier county": "13173",
    "laurens county": "13175",
    "lee county": "13177",
    "liberty county": "13179",
    "lincoln county": "13181",
    "long county": "13183",
    "lowndes county": "13185",
    "lumpkin county": "13187",
    "macon county": "13193",
    "madison county": "13195",
    "marion county": "13197",
    "mcduffie county": "13189",
    "mcintosh county": "13191",
    "meriwether county": "13199",
    "miller county": "13201",
    "mitchell county": "13205",
    "monroe county": "13207",
    "montgomery county": "13209",
    "morgan county": "13211",
    "murray county": "13213",
    "muscogee county": "13215",
    "newton county": "13217",
    "oconee county": "13219",
    "oglethorpe county": "13221",
    "paulding county": "13223",
    "peach county": "13225",
    "pickens county": "13227",
    "pierce county": "13229",
    "pike county": "13231",
    "polk county": "13233",
    "pulaski county": "13235",
    "putnam county": "13237",
    "quitman county": "13239",
    "rabun county": "13241",
    "randolph county": "13243",
    "richmond county": "13245",
    "rockdale county": "13247",
    "schley county": "13249",
    "screven county": "13251",
    "seminole county": "13253",
    "spalding county": "13255",
    "stephens county": "13257",
    "stewart county": "13259",
    "sumter county": "13261",
    "talbot county": "13263",
    "taliaferro county": "13265",
    "tattnall county": "13267",
    "taylor county": "13269",
    "telfair county": "13271",
    "terrell county": "13273",
    "thomas county": "13275",
    "tift county": "13277",
    "toombs county": "13279",
    "towns county": "13281",
    "treutlen county": "13283",
    "troup county": "13285",
    "turner county": "13287",
    "twiggs county": "13289",
    "union county": "13291",
    "upson county": "13293",
    "walker county": "13295",
    "walton county": "13297",
    "ware county": "13299",
    "warren county": "13301",
    "washington county": "13303",
    "wayne county": "13305",
    "webster county": "13307",
    "wheeler county": "13309",
    "white county": "13311",
    "whitfield county": "13313",
    "wilcox county": "13315",
    "wilkes county": "13317",
    "wilkinson county": "13319",
    "worth county": "13321",
  },
  NJ: {
    "atlantic county": "34001",
    "bergen county": "34003",
    "burlington county": "34005",
    "camden county": "34007",
    "cape may county": "34009",
    "cumberland county": "34011",
    "essex county": "34013",
    "gloucester county": "34015",
    "hudson county": "34017",
    "hunterdon county": "34019",
    "mercer county": "34021",
    "middlesex county": "34023",
    "monmouth county": "34025",
    "morris county": "34027",
    "ocean county": "34029",
    "passaic county": "34031",
    "salem county": "34033",
    "somerset county": "34035",
    "sussex county": "34037",
    "union county": "34039",
    "warren county": "34041",
  },
  MA: {
    "barnstable county": "25001",
    "berkshire county": "25003",
    "bristol county": "25005",
    "dukes county": "25007",
    "essex county": "25009",
    "franklin county": "25011",
    "hampden county": "25013",
    "hampshire county": "25015",
    "middlesex county": "25017",
    "nantucket county": "25019",
    "norfolk county": "25021",
    "plymouth county": "25023",
    "suffolk county": "25025",
    "worcester county": "25027",
  },
};
