import Link from "next/link";

import { useTranslation } from "@flare/i18n";

import { ShieldIcon } from "@/components/icons/ShieldIcon";
import { Skeleton } from "@/components/Skeleton";
import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";

import { Label } from "../base/Label";
import { StickyActions } from "../base/StickyActions";

function FieldSkeleton({ label }: { label: string }) {
  return (
    <div className="flex w-full flex-col gap-2">
      <Label className="opacity-20">{label}</Label>
      <Skeleton className="h-14" />
    </div>
  );
}

export function FormSkeleton() {
  const { t } = useTranslation();
  const { funnelAnswers } = useFunnelAnswers();
  const { eventStartTime } = funnelAnswers;

  return (
    <div className="flex flex-col gap-7">
      <Heading variant="h4" className="text-center font-normal opacity-20">
        {t("FUNNEL_CHECKOUT_FORM_TITLE")}
      </Heading>
      <Skeleton className="h-40" />
      <div className="flex flex-col gap-5">
        <Heading variant="h5" className="font-sans font-medium opacity-20">
          {t("FUNNEL_CHECKOUT_FORM_PAYMENT_TITLE")}
        </Heading>
        <FieldSkeleton label={t("FUNNEL_CHECKOUT_FORM_NAME_LABEL")} />
        <FieldSkeleton label={t("FUNNEL_CHECKOUT_FORM_CARD_NUMBER_LABEL")} />
        <div className="flex flex-col gap-5 md:flex-row md:justify-between md:gap-3">
          <FieldSkeleton
            label={t("FUNNEL_CHECKOUT_FORM_EXPIRATION_DATE_LABEL")}
          />
          <FieldSkeleton label={t("FUNNEL_CHECKOUT_FORM_CVC_LABEL")} />
        </div>
        <FieldSkeleton label={t("FUNNEL_CHECKOUT_FORM_ZIP_CODE_LABEL")} />
      </div>
      <div className="flex items-center gap-2 opacity-20">
        <ShieldIcon height={18} width={16} />
        <Text className="font-medium" variant="text-6">
          {t("FUNNEL_CHECKOUT_FORM_SSL_ENCRYPTED")}
        </Text>
      </div>
      <Text variant="text-6" className="text-center text-blue-40">
        {t("FUNNEL_CHECKOUT_FORM_AUTHORIZATION_TEXT_1")}{" "}
        <Link target="_blank" href="/privacy" className="text-green-100">
          {t("PRIVACY_POLICY")} &{" "}
        </Link>
        <Link target="_blank" href="/terms-of-use" className="text-green-100">
          {t("TERMS_OF_USE")}.{" "}
        </Link>
        {t("FUNNEL_CHECKOUT_FORM_AUTHORIZATION_TEXT_2")}
      </Text>
      {!eventStartTime && (
        // Show intake banner skeleton only if an intake call has not already been scheduled
        <Skeleton className="align-center flex h-[218px]  md:h-[220px]" />
      )}
      <StickyActions className="flex flex-col items-center justify-center gap-4 md:mb-[-32px] md:w-screen md:self-center">
        <Skeleton className="h-14 w-full max-w-[350px]" />
      </StickyActions>
    </div>
  );
}
