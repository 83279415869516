import React from "react";
import { useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/sdk";
import { ArrowRight } from "@marbletech/core";

import { FUNNEL_NEXT_BUTTON_WITH_DISABLED } from "@/builder/components-sections";

import type { ButtonProps } from "./base/Button";
import { Button } from "./base/Button";

export type NextButtonProps = Pick<ButtonProps, "children" | "fullWidth"> & {
  withIcon?: boolean;
};

export const NextButtonWithDisabled = ({
  children,
  withIcon,
  fullWidth,
}: NextButtonProps) => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  const disabled = !isValid || isSubmitting;

  return (
    <Button type="submit" fullWidth={fullWidth} disabled={disabled}>
      {children}
      {withIcon && <ArrowRight />}
    </Button>
  );
};

Builder.registerComponent(NextButtonWithDisabled, {
  name: FUNNEL_NEXT_BUTTON_WITH_DISABLED,
  inputs: [
    {
      name: "children",
      friendlyName: "Button text",
      type: "string",
      defaultValue: "Click me",
      helperText: "Text displayed on the button",
    },
    {
      name: "withIcon",
      type: "boolean",
      defaultValue: false,
      helperText: "Add an arrow right icon to the button",
    },
    {
      name: "fullWidth",
      type: "boolean",
      defaultValue: false,
      helperText: "Set the button to be full width",
    },
  ],
});
