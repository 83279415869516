export function ChevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94 6.523a.7.7 0 0 0-.006 1.061l5.023 4.59a.234.234 0 0 1-.002.353l-5.016 4.496a.7.7 0 0 0-.005 1.061.891.891 0 0 0 1.171.005l5.016-4.496c.759-.68.764-1.788.013-2.475l-5.023-4.59a.891.891 0 0 0-1.172-.005Z"
      ></path>
    </svg>
  );
}
