import * as Sentry from "@sentry/nextjs";
import { add, endOfDay } from "date-fns";

import { analytics } from "@flare/analytics";

import { getCompetencies } from "@/components/Funnel/utils/get-competencies";
import { getFips } from "@/components/Funnel/utils/get-fips";
import type { GetAvailableSlotsRequest } from "@/edge/contract";
import {
  type BookitCreateRequestResponse,
  makeGetAvailableSlotsRequest,
  normalizePracticeAreaName,
  objectToQuery,
  type PracticeArea,
} from "@/edge/contract";
import { jsonRequest } from "@/lib/network/json-request";
import { safeAsync } from "@/lib/safe";
import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";
import { FUNNEL_DATA_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData } from "@/services/storage/session-storage";
import { requestTimeout } from "@/utils/request-timeout";

const MAX_MS_BOOKIT_RESPONSE = 5000;
const MAX_DAYS_TO_LSS_MEETING = 10;
const MIN_LSS_SLOTS = 1;

export async function checkAttorneyAvailability() {
  const funnelAnswers =
    (getData(FUNNEL_DATA_SESSION_STORAGE_KEY) as FunnelAnswers) || {};
  const { practice, subPractice, county, stateCode, serviceType, sponsorType } =
    funnelAnswers;

  const practiceArea: PracticeArea = normalizePracticeAreaName(practice);

  const competencies = getCompetencies({
    practice: practiceArea,
    subPractice,
    serviceType,
    sponsorType,
  });

  const fips = getFips({
    county,
    stateCode,
  });

  const now = new Date();

  const availableSlotsPayload: GetAvailableSlotsRequest =
    makeGetAvailableSlotsRequest({
      practiceArea,
      competencies,
      minSlots: MIN_LSS_SLOTS,
      from: now,
      to: endOfDay(add(now, { days: MAX_DAYS_TO_LSS_MEETING })),
      stateCode,
      fips,
    });

  // todo: merge logic with calculateAvailability
  const [error, result] = await safeAsync(() =>
    Promise.race([
      jsonRequest<BookitCreateRequestResponse>(
        `/api/vinny/bookee/availability/LSS?${objectToQuery(makeGetAvailableSlotsRequest(availableSlotsPayload))}`,
        {
          method: "GET",
        },
      ),
      requestTimeout(MAX_MS_BOOKIT_RESPONSE),
    ]),
  );

  if (error) {
    Sentry.captureException(error, {
      extra: {
        message: `error fetching lss slots for practiceArea: ${practice?.toString().toUpperCase()}, stateCode: ${stateCode}`,
      },
    });

    analytics.track(BiEventsNames.WebFunnelError, {
      error_type: "check_attorney_availability_error",
      error_message: error instanceof Error ? error?.message : "unknown error",
    });
    // If there is an error, we will assume we have attorney availability
    return true;
  }
  analytics.track(BiEventsNames.WebCcAvailabilityCheck, {
    has_slots: result,
  });

  return result;
}
