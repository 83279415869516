import React from "react";
import { CheckIcon } from "@marbletech/core";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "@flare/ui";

import { Hint } from "./Hint";
import { Label } from "./Label";

export type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  hasError?: boolean;
  hint?: React.ReactNode;
  label: React.ReactNode;
};

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, hint, label, name, hasError = false, ...restProps }, ref) => {
  const hintId = hint ? `${name}-hint` : undefined;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-4">
        <CheckboxPrimitive.Root
          className={cn(
            "flex size-4 shrink-0 items-center justify-center rounded-[3px] border border-green-100 bg-white hover:bg-green-20",
            {
              "ring-2 ring-inset ring-orange-100 focus-visible:ring-orange-100 border-none":
                hasError,
            },
            className,
          )}
          id={name}
          aria-describedby={hintId}
          aria-invalid={hasError}
          name={name}
          ref={ref}
          {...restProps}
        >
          <CheckboxPrimitive.Indicator className="pb-[3px] text-green-100">
            <CheckIcon size="sm" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && <Label htmlFor={name}>{label}</Label>}
      </div>
      {hint && (
        <Hint id={hintId} error={hasError}>
          {hint}
        </Hint>
      )}
    </div>
  );
});

Checkbox.displayName = "Checkbox";
