// keep common family flow parts in this file

import type { FunnelData } from "../config.types";

export const isFamilyDirectToLssFlow = ({
  answers,
  experiments,
}: Omit<FunnelData, "meta">): boolean => {
  const { leadIntent } = answers || {};
  const { abTestHighIntent, abTestLowIntent } = experiments || {};

  return (
    (abTestHighIntent && leadIntent === "high") ||
    (abTestLowIntent && leadIntent === "low")
  );
};
