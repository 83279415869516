import type { Operation } from "@apollo/client";
import type { NetworkError } from "@apollo/client/errors";
import { captureException, setContext } from "@sentry/nextjs";
import type { GraphQLFormattedError } from "graphql";
import { GraphQLError } from "graphql";

/**
 * Report GraphQL errors to Sentry
 *
 * @see
 * [Apollo GraphQL Errors](https://www.apollographql.com/docs/react/data/error-handling/#graphql-errors)
 */
export function reportGraphQLError({
  graphQLErrors,
  networkError,
  operation,
}: {
  graphQLErrors: ReadonlyArray<GraphQLFormattedError>;
  networkError?: NetworkError;
  operation: Operation;
}) {
  if (networkError) {
    const statusCode =
      typeof networkError === "object" && "status" in networkError
        ? networkError.status
        : undefined;

    // The networkError object contains some more useful information
    setContext("Network error", {
      statusCode,
      message: networkError?.message,
      cause: networkError?.cause,
    });
  }

  const {
    query,
    variables,
    operationName,
    extensions: operationExtensions,
  } = operation;

  graphQLErrors.forEach(({ extensions, message, path }) => {
    const { code, exception } = extensions || {};
    const statusCode =
      typeof exception === "object" &&
      exception !== null &&
      "status" in exception
        ? exception.status
        : undefined;

    setContext("GraphQL error", {
      Query: query,
      Variables: variables,
      "Operation name": operationName,
      "Operation Extensions": operationExtensions,
      "Query path": path?.join(" > "),
      "Error code": code,
      "Error Message": message,
      "Status code": statusCode,
    });

    captureException(new GraphQLError(message));
  });
}

/**
 * Report network errors to Sentry
 *
 * @see
 * [Apollo error link options](https://www.apollographql.com/docs/react/api/link/apollo-link-error/#networkerror)
 */
export function reportNetworkError({
  networkError,
  operation,
}: {
  networkError: NetworkError;
  operation: Operation;
}) {
  const {
    query,
    variables,
    operationName,
    extensions: operationExtensions,
  } = operation;

  setContext("Network error", {
    Query: query,
    Variables: variables,
    "Operation name": operationName,
    "Operation Extensions": operationExtensions,
    message: networkError?.message,
    cause: networkError?.cause,
  });

  captureException(networkError);
}
