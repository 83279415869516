import { Builder } from "@builder.io/sdk";
import { ChevronLeft } from "@marbletech/core";
import { useRouter } from "next/router";

import { useTranslation } from "@flare/i18n";

import { FUNNEL_BACK_BUTTON } from "@/builder/components-sections";

import { Button } from "./base/Button";

export const BackButton = () => {
  const router = useRouter();
  const { t } = useTranslation();

  function handleBackClick() {
    router.back();
  }

  return (
    <Button type="button" variant="base" onClick={handleBackClick}>
      <ChevronLeft />
      {t("FUNNEL_BACK_BUTTON")}
    </Button>
  );
};

Builder.registerComponent(BackButton, {
  name: FUNNEL_BACK_BUTTON,
});
