import * as r from "react";
import { jsx as h } from "react/jsx-runtime";
function P(e, t = []) {
  let c = [];
  function i(f, n) {
    const o = r.createContext(n), s = c.length;
    c = [...c, n];
    function p(x) {
      const { scope: u, children: C, ...m } = x, S = (u == null ? void 0 : u[e][s]) || o, v = r.useMemo(() => m, Object.values(m));
      return /* @__PURE__ */ h(S.Provider, { value: v, children: C });
    }
    function d(x, u) {
      const C = (u == null ? void 0 : u[e][s]) || o, m = r.useContext(C);
      if (m) return m;
      if (n !== void 0) return n;
      throw new Error(`\`${x}\` must be used within \`${f}\``);
    }
    return p.displayName = f + "Provider", [p, d];
  }
  const a = () => {
    const f = c.map((n) => r.createContext(n));
    return function(o) {
      const s = (o == null ? void 0 : o[e]) || f;
      return r.useMemo(
        () => ({ [`__scope${e}`]: { ...o, [e]: s } }),
        [o, s]
      );
    };
  };
  return a.scopeName = e, [i, l(a, ...t)];
}
function l(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const c = () => {
    const i = e.map((a) => ({
      useScope: a(),
      scopeName: a.scopeName
    }));
    return function(f) {
      const n = i.reduce((o, { useScope: s, scopeName: p }) => {
        const x = s(f)[`__scope${p}`];
        return { ...o, ...x };
      }, {});
      return r.useMemo(() => ({ [`__scope${t.scopeName}`]: n }), [n]);
    };
  };
  return c.scopeName = t.scopeName, c;
}
function $(e) {
  const t = r.useRef(e);
  return r.useEffect(() => {
    t.current = e;
  }), r.useMemo(() => (...c) => {
    var i;
    return (i = t.current) == null ? void 0 : i.call(t, ...c);
  }, []);
}
var E = globalThis != null && globalThis.document ? r.useLayoutEffect : () => {
};
export {
  $ as a,
  P as c,
  E as u
};
