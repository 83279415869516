export function ShieldIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" color="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35 1.0125C8.55 0.4125 7.45 0.4125 6.65 1.0125L6.46306 1.1527C5.50177 1.87367 4.38132 2.35311 3.19606 2.55066L2.3801 2.68665C1.29518 2.86747 0.5 3.80615 0.5 4.90604V9C0.5 11.7918 1.96478 14.3789 4.35872 15.8152L6.97133 17.3828C7.6045 17.7627 8.3955 17.7627 9.02867 17.3828L11.6413 15.8152C14.0352 14.3789 15.5 11.7918 15.5 9V4.90604C15.5 3.80615 14.7048 2.86747 13.6199 2.68665L12.8039 2.55066C11.6187 2.35311 10.4982 1.87367 9.53694 1.1527L9.35 1.0125ZM9.53694 1.1527L8.63694 2.3527C9.79049 3.21787 11.135 3.7932 12.5573 4.03025L13.3733 4.16624C13.7349 4.22651 14 4.53941 14 4.90604V9C14 11.2649 12.8117 13.3637 10.8695 14.529L8.25692 16.0966C8.09878 16.1914 7.90122 16.1914 7.74308 16.0966L5.13046 14.529C3.18833 13.3637 2 11.2649 2 9V4.90604C2 4.53941 2.26506 4.22651 2.6267 4.16624L3.44265 4.03025C4.86497 3.7932 6.20951 3.21787 7.36306 2.3527L7.55 2.2125C7.81667 2.0125 8.18333 2.0125 8.45 2.2125L8.63694 2.3527L9.53694 1.1527Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5931 6.89371C11.8023 7.08532 11.8023 7.39598 11.5931 7.58759L8.06499 10.8189C7.43737 11.3937 6.41978 11.3937 5.79215 10.8189L4.40691 9.55017C4.1977 9.35856 4.1977 9.0479 4.40691 8.85629C4.61612 8.66468 4.95531 8.66468 5.16452 8.85629L6.54976 10.125C6.75897 10.3166 7.09817 10.3166 7.30738 10.125L10.8355 6.89371C11.0447 6.7021 11.3839 6.7021 11.5931 6.89371Z"
      />
    </svg>
  );
}
