import { useEffect, useState } from "react";
import { BookitProvider } from "@marbletech/bookit-core";
import { captureException, captureMessage } from "@sentry/nextjs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { useSessionStorage } from "usehooks-ts";

import { analytics } from "@flare/analytics";

import type { RegisterStripeCustomerInput } from "@/generated/graphql.old_backend";
import { useRegisterStripeCustomerMutation } from "@/generated/graphql.old_backend";
import { useAsyncEffect } from "@/hooks/useAsyncEffect";
import { useBookitRequest } from "@/modules/v2/funnel/hooks/useBookitRequest";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { useLeadId } from "@/modules/v2/funnel/hooks/useLeadId";
import type { StepHandlers } from "@/modules/v2/funnel/steps/step.types";
import { BiEventsNames } from "@/services/analytics/event-names";

import { CheckoutForm } from "./CheckoutForm";
import { FormSkeleton } from "./FormSkeleton";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || "");

const bookitBaseUrl = process.env.NEXT_PUBLIC_BOOKIT_BASE_URL || "";

export function LssCheckout({ handlers }: { handlers: StepHandlers }) {
  const { query } = useRouter();
  const [clientSecret, setClientSecret] = useState("");
  const { funnelAnswers } = useFunnelAnswers();
  const { isLoading, isError, data: leadId, error } = useLeadId();
  const { firstName, lastName, email, phone } = funnelAnswers || {};

  const practice = query.practice as string;
  const step = query.step as string;

  const [registerStripeCustomerMutation] = useRegisterStripeCustomerMutation();
  const { createBookitRequest } = useBookitRequest();
  const [bookitRequestId] = useSessionStorage<string | null>(
    "bookitRequestId",
    null,
  );

  useAsyncEffect(async () => {
    if (isLoading) return;

    if (isError) {
      analytics.track(BiEventsNames.WebFunnelError, {
        current_step_key: step,
        error_type: "error_in_fetching_lead_id",
        practice,
        error_message: error?.message,
      });
      captureMessage("Error in fetching lead id", {
        extra: { step: "lss-checkout" },
      });
      return;
    }

    try {
      const payload: RegisterStripeCustomerInput = {
        customerDetails: {
          name: `${firstName} ${lastName}`,
          email,
          phone,
        },
        description: { practiceArea: practice },
        metadata: {
          deal_id: leadId!,
        },
      };

      const { data: stripeCustomerData } = await registerStripeCustomerMutation(
        {
          variables: { input: payload },
        },
      );

      const clientSecret =
        stripeCustomerData?.registerStripeCustomer?.clientSecret;

      if (!clientSecret) {
        analytics.track(BiEventsNames.WebFunnelError, {
          current_step_key: step,
          error_type: "register_stripe_customer",
          practice,
        });
        throw new Error(`Failed to register Stripe customer`);
      }

      setClientSecret(clientSecret);
    } catch (error) {
      captureException(error, {
        extra: {
          action: "fetchLeadIdAndRegisterCustomer",
        },
      });
    }
  }, [
    isLoading,
    email,
    leadId,
    firstName,
    lastName,
    phone,
    practice,
    step,
    registerStripeCustomerMutation,
  ]);

  useEffect(() => {
    if (isLoading || !leadId || bookitRequestId) return;
    createBookitRequest(leadId);
    // createBookitRequest should be on the dependency array but it is not memoized and causing infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, leadId, bookitRequestId]);

  const options = { clientSecret };

  return !isLoading && leadId && clientSecret && bookitRequestId ? (
    <Elements stripe={stripePromise} options={options}>
      <BookitProvider baseUrl={bookitBaseUrl} meetingId={bookitRequestId}>
        <CheckoutForm
          clientSecret={clientSecret}
          leadId={leadId}
          handlers={handlers}
        />
      </BookitProvider>
    </Elements>
  ) : (
    <FormSkeleton />
  );
}
