import * as React from "react";

import { cn } from "@flare/ui";

import { Hint } from "./Hint";
import { Label } from "./Label";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  hint?: string;
  label?: string;
  name: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, hint, label, name, ...restProps }, ref) => {
    const hasError = Boolean(error);
    const hintId = hint ? `${name}-hint` : undefined;

    return (
      <div className="flex w-full flex-col gap-2">
        {label && <Label htmlFor={name}>{label}</Label>}
        <input
          aria-describedby={hintId}
          aria-invalid={hasError}
          name={name}
          id={name}
          className={cn(
            "flex w-full rounded-lg bg-grey-50 p-4 text-base transition-colors placeholder:text-blue-40 focus-visible:bg-green-20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-green-100 disabled:bg-blue-20",
            {
              "ring-1 ring-orange-100 focus-visible:ring-orange-100": hasError,
            },
            className,
          )}
          ref={ref}
          {...restProps}
        />
        {hint && (
          <Hint id={hintId} error={hasError}>
            {hint}
          </Hint>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
