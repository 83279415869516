import type { BuilderStore } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";

import { FUNNEL_SKIP_BUTTON } from "@/builder/components-sections";

import type { ButtonProps } from "./base/Button";
import { Button } from "./base/Button";

type SkipButtonProps = Pick<ButtonProps, "children"> & {
  builderState: BuilderStore;
  className?: string;
  variant?: "base" | "textPrimary" | "primary" | "outline";
};

type onClickType = Pick<ButtonProps, "onClick">["onClick"];

/**
 * Button to skip the current step
 *
 * `onSkip` - function to call when the button is clicked, passed via Builder.io context
 */
export const SkipButton = ({
  children,
  builderState,
  variant = "base",
  className,
}: SkipButtonProps) => {
  const onSkip: onClickType = builderState.state?.onSkip;

  return (
    <Button
      onClick={onSkip}
      variant={variant}
      type="button"
      className={className}
    >
      {children}
    </Button>
  );
};

Builder.registerComponent(SkipButton, {
  name: FUNNEL_SKIP_BUTTON,
  inputs: [
    {
      name: "children",
      friendlyName: "Button text",
      type: "string",
      defaultValue: "Click me",
      helperText: "Text displayed on the button",
    },
    {
      name: "variant",
      friendlyName: "variant",
      defaultValue: "base",
      type: "string",
      enum: ["base", "textPrimary"],
    },
  ],
});
