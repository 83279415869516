import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent,
} from "@stripe/stripe-js";

import { cn } from "@flare/ui";

import { Hint } from "../base/Hint";
import { Label } from "../base/Label";

type StripeEvent =
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardNumberElementChangeEvent;

type StripeComponentProps = {
  component: React.ElementType;
  label: string;
  name:
    | StripeCardCvcElementChangeEvent["elementType"]
    | StripeCardExpiryElementChangeEvent["elementType"]
    | StripeCardNumberElementChangeEvent["elementType"];
  onChange?: (e: StripeEvent) => void;
  placeholder?: string;
};

type StripeError = Pick<StripeElementChangeEvent, "error">["error"];

const stripeElementStyles = {
  base: {
    color: "#32325d",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    fontWeight: "400",
    backgroundColor: "transparent",
    iconColor: "#2C816E",
    "::placeholder": {
      color: "#9e9fa8",
    },
  },
  invalid: {
    color: "#32325d",
  },
};

export const StripeComponent = ({
  component: Component,
  name,
  label,
  placeholder,
  onChange,
}: StripeComponentProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const [stripeError, setStripeError] = useState<StripeError>();

  const formHasError = Boolean(errors?.[name] || stripeError);

  const options = {
    ...(name === "cardNumber" && {
      showIcon: true,
      disableLink: true,
    }),
    style: stripeElementStyles,
    placeholder,
  };

  return (
    <Controller
      rules={{ required: true }}
      shouldUnregister
      render={({ field }) => (
        <div className="flex w-full flex-col gap-2" key={name}>
          <Label>{label}</Label>
          <div
            className={cn(
              "transition-color w-full rounded-lg bg-grey-50 p-4 text-base disabled:bg-blue-20",
              {
                "bg-green-20 outline-none ring-1 ring-green-100": isFocused,
              },
              {
                "ring-1 ring-orange-100 outline-none": formHasError,
              },
            )}
          >
            <Component
              options={options}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e: StripeEvent) => {
                //e.error either have a value or be 'undefined' in the e object
                setStripeError(e.error);
                field.onChange(e.complete);
                onChange?.(e);
              }}
            />
          </div>
          {formHasError && (
            <Hint id={`${name}-error`} error={formHasError}>
              {stripeError?.message || "This field is required"}
            </Hint>
          )}
        </div>
      )}
      name={name}
      control={control}
    />
  );
};
