import { useController } from "react-hook-form";
import { Builder } from "@builder.io/sdk";
import { boolean } from "yup";

import { FUNNEL_CHECKBOX } from "@/builder/components-sections";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";

import { Text } from "../v2/Text/Text";

import { Checkbox as BaseCheckbox } from "./base/Checkbox";

export type CheckboxProps = {
  customErrorMessage?: string;
  hint?: string;
  label?: string | React.ReactNode;
  name: string;
  required: boolean;
};

export const Checkbox = ({
  name,
  required,
  hint,
  label,
  customErrorMessage,
}: CheckboxProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      validate: async (value: boolean) => {
        if (!required) return;

        try {
          await boolean()
            .oneOf([true], customErrorMessage)
            .validate(Boolean(value));
        } catch (error) {
          // @ts-expect-error: Wrong type
          return error.message;
        }
      },
    },
  });

  const hasError = Boolean(error);
  const innerHint = hasError ? error?.message : hint;

  return (
    <BaseCheckbox
      {...field}
      hasError={hasError}
      hint={innerHint}
      label={
        typeof label === "string" ? (
          <Text
            variant="text-6"
            as="span"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : (
          <Text variant="text-6" as="span">
            {label}
          </Text>
        )
      }
      checked={field.value}
      onCheckedChange={field.onChange}
    />
  );
};

Builder.registerComponent(Checkbox, {
  name: FUNNEL_CHECKBOX,
  inputs: [
    {
      name: "name",
      type: "string",
      required: true,
      helperText: "Should be written in camelCase",
      enum: Object.values(funnelInputsNameMap),
    },
    {
      name: "label",
      type: "richText",
      defaultValue: "Label",
    },
    {
      name: "hint",
      type: "string",
      helperText: "Field hint",
    },
    {
      name: "required",
      type: "boolean",
      defaultValue: false,
      helperText: "Is this field required?",
    },
    {
      name: "customErrorMessage",
      type: "string",
      helperText: "Error message to display if validation fails",
    },
  ],
});
