import { useEffect, useState } from "react";
import { isNumber } from "lodash";

import { getData, setData } from "@/services/storage/session-storage";

import {
  getParsedItem,
  setParsedItem,
  USER_SESSION_DATA_LOCAL_STORAGE_KEY,
  USER_SESSION_DATA_SESSION_STORAGE_KEY,
} from "../../storage";

import type { UserSessionData } from "./user-session.types";

export const useUserSession = () => {
  const [sessionData, setSessionData] = useState<UserSessionData>({
    visitsCount: 0,
    sessionStartTime: new Date(),
  });

  useEffect(() => {
    let sessionStartTime = getData(USER_SESSION_DATA_SESSION_STORAGE_KEY)
      ?.sessionStartTime as Date;

    let { visitsCount } =
      getParsedItem(USER_SESSION_DATA_LOCAL_STORAGE_KEY) ?? {};

    if (!sessionStartTime) {
      // This means it's a new session, so increment the visitsCount
      visitsCount = isNumber(visitsCount) ? visitsCount + 1 : 0;

      setParsedItem(USER_SESSION_DATA_LOCAL_STORAGE_KEY, {
        visitsCount,
      });

      sessionStartTime = new Date();
      setData(USER_SESSION_DATA_SESSION_STORAGE_KEY, { sessionStartTime });
    } else {
      // If sessionStartTime exists, use the existing visitsCount
      visitsCount = isNumber(visitsCount) ? visitsCount : 0;
    }

    setSessionData({
      visitsCount,
      sessionStartTime: sessionStartTime ? sessionStartTime : new Date(),
    });
  }, []);

  return sessionData;
};

export const initialUserSessionData = {
  visitsCount: 0,
  sessionStartTime: new Date(),
};
