export declare type EResult<T> = [Error | null, T | null];
export function Success<TRet>(obj: TRet): EResult<TRet> {
  return [null, obj];
}
export function Failure<TRet>(error: Error): EResult<TRet> {
  return [error, null];
}
export function safe<TRet>(
  fn: () => TRet,
  cleanup?: () => void,
): EResult<TRet> {
  try {
    return [null, fn()];
  } catch (error) {
    return [error as Error, null];
  } finally {
    cleanup?.();
  }
}
export async function safeAsync<TRet>(
  fn: () => Promise<TRet>,
  cleanup?: () => void,
): Promise<EResult<TRet>> {
  try {
    return [null, await fn()];
  } catch (error) {
    return [error as Error, null];
  } finally {
    cleanup?.();
  }
}
