import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@flare/ui";

const buttonVariants = cva(
  "inline-flex h-14 min-w-[160px] items-center justify-center gap-1 whitespace-nowrap rounded-lg px-6 py-5 text-base font-normal  focus-visible:outline-none focus-visible:ring-2 disabled:pointer-events-none",
  {
    variants: {
      fullWidth: {
        true: "w-full",
      },
      variant: {
        base: "h-auto min-w-fit p-0 text-blue-100 disabled:text-blue-40",
        textPrimary:
          "h-auto min-w-fit p-0 text-green-100 disabled:text-blue-40",
        primary:
          "bg-green-100 text-white-80 disabled:bg-blue-20 disabled:text-blue-40",
        outline:
          "border border-green-100 text-green-100 disabled:border-blue-20 disabled:text-blue-40",
      },
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & { asChild?: boolean };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = "primary",
      fullWidth = false,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        className={cn(buttonVariants({ variant, className, fullWidth }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";
