import { jsx as o } from "react/jsx-runtime";
import * as f from "react";
import { forwardRef as l } from "react";
import { P as s } from "../../index-u2zzFwMH.js";
import { cn as n } from "../../utils/cn.js";
var d = "Label", i = f.forwardRef((a, e) => /* @__PURE__ */ o(
  s.label,
  {
    ...a,
    ref: e,
    onMouseDown: (t) => {
      var r;
      t.target.closest("button, input, select, textarea") || ((r = a.onMouseDown) == null || r.call(a, t), !t.defaultPrevented && t.detail > 1 && t.preventDefault());
    }
  }
));
i.displayName = d;
var m = i;
const u = l(({ className: a, ...e }, t) => /* @__PURE__ */ o(
  m,
  {
    ref: t,
    className: n("text-t2", a),
    ...e
  }
));
u.displayName = m.displayName;
export {
  u as Label
};
