import * as e from "react";
import { u as a } from "./index-adpA2O3F.js";
var s = e.useId || (() => {
}), i = 0;
function n(r) {
  const [t, o] = e.useState(s());
  return a(() => {
    o((u) => u ?? String(i++));
  }, [r]), t ? `radix-${t}` : "";
}
export {
  n as u
};
