import { jsx as e } from "react/jsx-runtime";
import { S as l } from "../../index-CCavb-1K.js";
import { c as o } from "../../index-BwYCDOHZ.js";
import { cn as d } from "../../utils/cn.js";
const h = o("font-serif antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end"
    },
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      "primary-inverted": "text-text-primary-inverted",
      danger: "text-text-danger",
      success: "text-text-success"
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6"
    },
    textWrap: {
      balance: "text-balance",
      pretty: "text-pretty"
    },
    variant: {
      h1: "text-h1",
      h2: "pb-[1px] pt-[3px] text-h2",
      h3: "pb-[3px] pt-[1px] font-sans text-h3",
      h4: "font-sans text-h4"
    }
  }
}), v = ({
  alignment: a = "start",
  as: r,
  children: n,
  className: s,
  color: i = "primary",
  textWrap: p,
  maxLines: x,
  variant: t = "h1",
  ...c
}) => {
  const m = r ?? t ?? "h1";
  return /* @__PURE__ */ e(
    l,
    {
      className: d(
        h({
          alignment: a,
          color: i,
          maxLines: x,
          textWrap: p,
          variant: t,
          className: s
        })
      ),
      ...c,
      children: /* @__PURE__ */ e(m, { children: n })
    }
  );
};
export {
  v as Heading
};
