import { calculateLeadScore } from "../../visitor-checks/calculateLeadScore";
import { checkCmnEligibility } from "../../visitor-checks/checkCmnEligibility";
import { stepsNames } from "../config.const";
import type { SubPractices } from "../config.types";

export const familyElse: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async ({ answers }) => {
        const { serviceType } = answers;

        switch (serviceType?.toLowerCase()) {
          case "none of the above":
            return "zip-code";

          case "child protective services involvement":
            return "child-protective-services";

          default:
            return "out-of-scope";
        }
      },
    },
    "zip-code": {
      next: async ({ answers }) => {
        const { skip, state } = answers;

        if (skip) {
          return "state-selection";
        }

        if (state === "unsupported") {
          return "unsupported";
        }

        return "location-confirmation";
      },
    },
    "state-selection": {
      next: async ({ answers }) => {
        const { state } = answers;

        if (state === "unsupported") {
          return "unsupported";
        }

        return "location-confirmation";
      },
    },
    unsupported: {
      next: async () => "unsupported-confirmed",
      sideEffects: ["rejectCustomer"],
      sanitize: true,
    },
    "unsupported-confirmed": {
      next: async () => "unsupported-confirmed",
    },
    "location-confirmation": {
      next: async () => "case-urgency",
    },
    "case-urgency": {
      next: async () => "more-info",
    },
    "more-info": {
      next: async ({ answers, experiments }) => {
        const { abTestCmnMidIntent } = experiments;
        const { intent: leadIntent, score } = calculateLeadScore(
          answers,
          stepsNames["more-info"],
        );

        if (abTestCmnMidIntent) {
          checkCmnEligibility({ leadIntent, leadScore: score });
        }

        return "create-meeting";
      },
    },
    "create-meeting": {
      next: async ({ answers }) =>
        answers?.eventStartTime === "call_me_now"
          ? "contact-info-call-now"
          : "contact-info",
    },
    "contact-info": {
      next: async () => "intake-confirmation",
      sideEffects: ["createLead"],
      sanitize: true,
    },

    "contact-info-call-now": {
      next: async () => "intake-confirmation-call-now",
      sideEffects: ["createLead"],
      sanitize: true,
    },

    "intake-confirmation": {
      next: async () => undefined,
    },

    "intake-confirmation-call-now": {
      next: async () => undefined,
    },

    "child-protective-services": {
      next: async ({ answers }) => {
        const { childProtectiveServices } = answers;
        return childProtectiveServices?.toLowerCase() === "yes"
          ? "out-of-scope"
          : "zip-code";
      },
    },
    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
