import { useEffect } from "react";
import { isBrowser } from "@marbletech/utils";
import * as Sentry from "@sentry/nextjs";

import { analytics } from "@flare/analytics";

import { BiEventsNames } from "./event-names";

type Fullstory = {
  anonymize(): void;
  consent(consent: boolean): void;
  event(eventName: string, eventProperties: object): void;
  getCurrentSessionURL(now?: boolean): string;
  identify(uid: string, userVars?: object): void;
  log(msg: string): void;
  log(level: string, msg: string): void;
  restart(): void;
  setUserVars(userVars: object): void;
  shutdown(): void;
};

declare global {
  interface Window {
    FS: Fullstory;
  }
}

export const fullstoryReadyHandler = (retry = 10) => {
  if (!isBrowser()) {
    return;
  }
  if (retry === 0) {
    return;
  }
  if (!window?.FS || !window?.FS?.getCurrentSessionURL?.(true)) {
    setTimeout(() => {
      fullstoryReadyHandler(retry - 1);
    }, 300);
    return;
  }

  const fullstorySession = window?.FS?.getCurrentSessionURL?.(true);
  if (!fullstorySession) {
    return;
  }

  analytics.track(BiEventsNames.OnlineFunnelFullstoryRecordingStarted, {
    session: fullstorySession,
  });

  Sentry.setContext("Recording", {
    fullstory: fullstorySession,
  });
};

export const FullstoryContainer = () => {
  useEffect(() => {
    analytics.ready(fullstoryReadyHandler);
  }, []);

  return null;
};
