export const competenciesMap: Record<
  string,
  Record<string, string[] | Record<string, string[]>>
> = {
  family: {
    divorce: ["Divorce"],
    "child-support": ["Support"],
    "child-custody": ["Custody"],
  },
  immigration: {
    "green-card": {
      "new-green-card-application/spouse": [
        "Family: Complete Green Card Process",
      ],
      "new-green-card-application/parent": [
        "Family: Complete Green Card Process",
      ],
      "new-green-card-application/child": [
        "Family: Complete Green Card Process",
      ],
      "new-green-card-application/employer": ["Employment: EB Visa"],

      "green-card-renewal": ["Renewals/Extensions"],
      "removal-of-conditions": ["Family: Removal of Conditions"],
    },
    citizenship: {
      "i’m-a-green-card-holder": ["Naturalization (N-400)"],
      "my-parent-or-grandparent-is-a-u.s.-citizen": ["Citizenship (N-600)"],
    },
    "fiance-visa": {
      "i’m-engaged-to-a-u.s.-citizen": ["K-1 Visa", "K-2 Visa"],
      "i’m-in-a-relationship-with-a-u.s.-citizen": ["K-1 Visa", "K-2 Visa"],
    },
  },
};
