import type { ButtonProps } from "@/components/Funnel/base/Button";
import { Button } from "@/components/Funnel/base/Button";

type SkipButtonProps = Pick<ButtonProps, "children"> & {
  className?: string;
  onSkip: () => Promise<void>;
  variant?: "base" | "textPrimary" | "primary" | "outline";
};

/**
 * Button to skip the current step
 *
 * `onSkip` - function to call when the button is clicked, passed to the consuming step component via [step] page in handlers prop.
 */
export const SkipButton = ({
  children,
  variant = "base",
  onSkip,
  className,
}: SkipButtonProps) => {
  return (
    <Button
      onClick={onSkip}
      variant={variant}
      type="button"
      className={className}
    >
      {children}
    </Button>
  );
};
