import { useEffect } from "react";
import { PageLoadingLottie } from "@marbletech/core";
import * as Dialog from "@radix-ui/react-dialog";

import { Heading } from "@/components/v2/Heading/Heading";
import { Text } from "@/components/v2/Text/Text";

type InterludeProps = {
  heading: string;
  subHeading?: string;
};

export function Interlude({ heading, subHeading }: InterludeProps) {
  useEffect(() => {
    // Scroll to the top of the page to ensure not sticky top nav is visible and completing overlay to 100% height.
    window.scrollTo(0, 0);
  }, []);

  return (
    <Dialog.Root open>
      <Dialog.Portal container={document.getElementById("appContainer")}>
        <Dialog.Overlay />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed bottom-0 left-0 z-10 flex h-[calc(100%-65px)] w-screen flex-col items-center justify-center gap-4 bg-white-80 focus-within:outline-none  md:h-[calc(100%-73px)]">
          <PageLoadingLottie />
          <div className="flex flex-col items-center justify-center gap-1">
            <Heading variant="h4">{heading}</Heading>
            {subHeading && <Text variant="text-3">{subHeading}</Text>}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
