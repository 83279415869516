import { i18n } from "@/services/locale/i18n";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import { Interlude } from "../../Interlude";
import { calculateLssQualification } from "../../visitor-checks/calculateLssQualification";
import { DELAY_MS } from "../config.const";
import type { RenderInterludeArgs, SubPractices } from "../config.types";

import {
  immigrationBookLssScreens,
  immigrationEligibleForLssScreens,
  intakeConfirmationScreens,
  isGreenCardLssFlow,
} from "./common-immigration";

export const immigrationGreenCard: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async ({ answers, experiments }) => {
        const { serviceType } = answers;
        const { abTestImmigrationEligibility } = experiments;

        if (abTestImmigrationEligibility) {
          if (serviceType === "New green card application") {
            return "sponsor-type";
          }

          if (
            ["Removal of conditions", "Green card renewal"].includes(
              serviceType!,
            )
          ) {
            return "green-card-status";
          }

          return "case-urgency";
        }

        return "applicant-type";
      },
    },
    "green-card-status": {
      next: async ({ answers }) => {
        const { greenCardStatus } = answers;
        if (greenCardStatus === "I have a green card and live in the U.S.") {
          return "green-card-type";
        }
        return "out-of-scope";
      },
    },
    "green-card-type": {
      next: async () => "case-urgency",
    },
    "sponsor-type": {
      next: async ({ answers }) => {
        const { sponsorType } = answers;
        if (sponsorType === "employer") return "job-offer";
        if (sponsorType === "I don’t have a sponsor") return "advanced-degree";
        return "sponsor-status";
      },
    },
    "sponsor-status": {
      next: async ({ answers }) => {
        const { sponsorType, sponsorStatus } = answers;
        if (sponsorStatus === "U.S Citizen") {
          if (sponsorType === "child") return "child-age";
          return "case-urgency";
        }
        if (sponsorStatus === "Green card holder") {
          if (sponsorType === "spouse") return "case-urgency";
          if (sponsorType === "parent") return "child-age";
          return "out-of-scope";
        }
        return "out-of-scope";
      },
    },
    "child-age": {
      next: async ({ answers }) => {
        const { sponsorType, isChildUnderTwentyOne } = answers;
        if (sponsorType === "parent") {
          if (isChildUnderTwentyOne === "Yes") return "case-urgency";
          return "child-marital-status";
        }
        if (sponsorType === "child" && isChildUnderTwentyOne === "No") {
          return "case-urgency";
        }
        return "out-of-scope";
      },
    },
    "child-marital-status": {
      next: async ({ answers }) => {
        const { isChildMarried } = answers;
        if (isChildMarried === "No") {
          return "case-urgency";
        }
        return "out-of-scope";
      },
    },
    "job-offer": {
      next: async ({ answers }) => {
        const { jobOffer } = answers;
        if (jobOffer === "No") return "out-of-scope";
        return "case-urgency";
      },
    },
    "advanced-degree": {
      next: async ({ answers }) => {
        const { advancedDegree } = answers;
        if (advancedDegree === "No") return "exceptional-ability";
        return "case-urgency";
      },
    },
    "exceptional-ability": {
      next: async ({ answers }) => {
        const { exceptionalAbility } = answers;
        if (exceptionalAbility === "No") return "humanitarian-status";
        return "case-urgency";
      },
    },
    "humanitarian-status": {
      next: async ({ answers }) => {
        const { humanitarianStatus } = answers;
        if (humanitarianStatus === "No") return "out-of-scope";
        return "case-urgency";
      },
    },
    "applicant-type": {
      next: async () => "case-urgency",
    },
    "case-urgency": {
      next: async ({ answers, experiments }) => {
        const { serviceType } = answers;
        const { abTestImmigrationEligibility } = experiments;

        if (isGreenCardLssFlow({ answers, experiments })) {
          const isQualifiedForLss = await promiseWithMinimumDelay(
            calculateLssQualification(),
            DELAY_MS,
          );

          if (!isQualifiedForLss) {
            return "more-info";
          }

          const hasSponsorInfoStep = [
            "New green card application",
            "Removal of conditions",
          ].includes(serviceType!);

          return hasSponsorInfoStep ? "sponsor-info" : "currently-represented";
        }

        return abTestImmigrationEligibility ? "more-info" : "funding-source";
      },
      renderInterlude: ({ answers, experiments }: RenderInterludeArgs) => {
        return isGreenCardLssFlow({ answers, experiments }) ? (
          <Interlude
            heading={i18n.t("FUNNEL_INTERLUDE_JUST_A_FEW_MORE_MOMENTS")}
          />
        ) : null;
      },
    },
    "sponsor-info": {
      next: async () => "currently-represented",
    },

    ...immigrationEligibleForLssScreens,

    "funding-source": {
      next: async () => "beneficiary-region",
    },
    "beneficiary-region": {
      next: async () => "more-info",
    },

    ...intakeConfirmationScreens,
    ...immigrationBookLssScreens,

    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
