export function CalendarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 3c-.621 0-1.125.504-1.125 1.125V4.5H6a3 3 0 0 0-3 3v9.75a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-.244a.75.75 0 0 0-1.5 0v.244a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V10.5h15v3.3a.75.75 0 0 0 1.5 0V7.5a3 3 0 0 0-3-3h-.75v-.375a1.125 1.125 0 0 0-2.25 0V4.5H9v-.375C9 3.504 8.496 3 7.875 3Zm7.189 3H8.936a1.125 1.125 0 0 1-2.122 0H6a1.5 1.5 0 0 0-1.5 1.5V9h15V7.5A1.5 1.5 0 0 0 18 6h-.814a1.125 1.125 0 0 1-2.122 0Z"
      />
    </svg>
  );
}
