import Balancer from "react-wrap-balancer";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@flare/ui";

export type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5";
type HeadingHProps = {
  as?: HeadingTag;
} & React.ComponentPropsWithRef<"h1">;

const headingVariants = cva("font-serif font-light antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end",
    },
    color: {
      blue: "text-blue-100",
      beige: "text-neutral-100",
      green: "text-green-100",
      white: "text-white-80",
      orange: "text-orange-100",
    },
    variant: {
      h1: "text-[40px] leading-[48px] md:text-[88px] md:leading-[96px]",
      h2: "text-[32px] leading-10 md:text-[56px] md:leading-[64px]",
      h3: "text-[32px] leading-10 md:text-5xl md:leading-[60px]",
      h4: "text-[22px] font-normal leading-8 md:text-[32px] md:leading-10",
      h5: "text-lg font-normal leading-[26px] md:text-2xl md:leading-8",
    },
  },
});

type HeadingVariants = VariantProps<typeof headingVariants>;

export type HeadingProps = HeadingHProps &
  HeadingVariants & {
    balanceText?: boolean;
  };

export const Heading = ({
  alignment = "start",
  as,
  balanceText,
  children,
  className,
  color = "blue",
  variant = "h1",
  ...restProps
}: HeadingProps) => {
  const Tag = as ?? variant;

  return (
    <Slot
      className={cn(headingVariants({ alignment, color, variant, className }))}
      {...restProps}
    >
      {/* @ts-expect-error: Types are wrong */}
      <Tag>{balanceText ? <Balancer>{children}</Balancer> : children}</Tag>
    </Slot>
  );
};
