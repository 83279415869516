export function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      color="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M6.97 15.97a.75.75 0 1 0 1.06 1.06L12 13.06l3.97 3.97a.75.75 0 1 0 1.06-1.06L13.06 12l3.97-3.97a.75.75 0 0 0-1.06-1.06L12 10.94 8.03 6.97a.75.75 0 0 0-1.06 1.06L10.94 12l-3.97 3.97Z"
      ></path>
    </svg>
  );
}
