import * as i from "react";
import C from "react";
import { u as $, c as w } from "./index-CdzV-7sB.js";
import { c as G, a as J } from "./index-adpA2O3F.js";
import { u as h, S as O } from "./index-CCavb-1K.js";
import { jsx as m } from "react/jsx-runtime";
import { u as Q } from "./index-pkJZZ5xF.js";
import { P as L } from "./index-u2zzFwMH.js";
function W(e) {
  const o = e + "CollectionProvider", [a, n] = G(o), [A, v] = a(
    o,
    { collectionRef: { current: null }, itemMap: /* @__PURE__ */ new Map() }
  ), b = (f) => {
    const { scope: t, children: s } = f, d = C.useRef(null), r = C.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ m(A, { scope: t, itemMap: r, collectionRef: d, children: s });
  };
  b.displayName = o;
  const g = e + "CollectionSlot", p = C.forwardRef(
    (f, t) => {
      const { scope: s, children: d } = f, r = v(g, s), c = h(t, r.collectionRef);
      return /* @__PURE__ */ m(O, { ref: c, children: d });
    }
  );
  p.displayName = g;
  const u = e + "CollectionItemSlot", F = "data-radix-collection-item", S = C.forwardRef(
    (f, t) => {
      const { scope: s, children: d, ...r } = f, c = C.useRef(null), E = h(t, c), T = v(u, s);
      return C.useEffect(() => (T.itemMap.set(c, { ref: c, ...r }), () => void T.itemMap.delete(c))), /* @__PURE__ */ m(O, { [F]: "", ref: E, children: d });
    }
  );
  S.displayName = u;
  function I(f) {
    const t = v(e + "CollectionConsumer", f);
    return C.useCallback(() => {
      const d = t.collectionRef.current;
      if (!d) return [];
      const r = Array.from(d.querySelectorAll(`[${F}]`));
      return Array.from(t.itemMap.values()).sort(
        (T, _) => r.indexOf(T.ref.current) - r.indexOf(_.ref.current)
      );
    }, [t.collectionRef, t.itemMap]);
  }
  return [
    { Provider: b, Slot: p, ItemSlot: S },
    I,
    n
  ];
}
var X = i.createContext(void 0);
function Z(e) {
  const o = i.useContext(X);
  return e || o || "ltr";
}
var M = "rovingFocusGroup.onEntryFocus", ee = { bubbles: !1, cancelable: !0 }, x = "RovingFocusGroup", [N, K, te] = W(x), [oe, Ce] = G(
  x,
  [te]
), [re, ne] = oe(x), k = i.forwardRef(
  (e, o) => /* @__PURE__ */ m(N.Provider, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ m(N.Slot, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ m(ce, { ...e, ref: o }) }) })
);
k.displayName = x;
var ce = i.forwardRef((e, o) => {
  const {
    __scopeRovingFocusGroup: a,
    orientation: n,
    loop: A = !1,
    dir: v,
    currentTabStopId: b,
    defaultCurrentTabStopId: g,
    onCurrentTabStopIdChange: p,
    onEntryFocus: u,
    preventScrollOnEntryFocus: F = !1,
    ...S
  } = e, I = i.useRef(null), f = h(o, I), t = Z(v), [s = null, d] = $({
    prop: b,
    defaultProp: g,
    onChange: p
  }), [r, c] = i.useState(!1), E = J(u), T = K(a), _ = i.useRef(!1), [Y, P] = i.useState(0);
  return i.useEffect(() => {
    const l = I.current;
    if (l)
      return l.addEventListener(M, E), () => l.removeEventListener(M, E);
  }, [E]), /* @__PURE__ */ m(
    re,
    {
      scope: a,
      orientation: n,
      dir: t,
      loop: A,
      currentTabStopId: s,
      onItemFocus: i.useCallback(
        (l) => d(l),
        [d]
      ),
      onItemShiftTab: i.useCallback(() => c(!0), []),
      onFocusableItemAdd: i.useCallback(
        () => P((l) => l + 1),
        []
      ),
      onFocusableItemRemove: i.useCallback(
        () => P((l) => l - 1),
        []
      ),
      children: /* @__PURE__ */ m(
        L.div,
        {
          tabIndex: r || Y === 0 ? -1 : 0,
          "data-orientation": n,
          ...S,
          ref: f,
          style: { outline: "none", ...e.style },
          onMouseDown: w(e.onMouseDown, () => {
            _.current = !0;
          }),
          onFocus: w(e.onFocus, (l) => {
            const H = !_.current;
            if (l.target === l.currentTarget && H && !r) {
              const D = new CustomEvent(M, ee);
              if (l.currentTarget.dispatchEvent(D), !D.defaultPrevented) {
                const y = T().filter((R) => R.focusable), j = y.find((R) => R.active), q = y.find((R) => R.id === s), z = [j, q, ...y].filter(
                  Boolean
                ).map((R) => R.ref.current);
                V(z, F);
              }
            }
            _.current = !1;
          }),
          onBlur: w(e.onBlur, () => c(!1))
        }
      )
    }
  );
}), U = "RovingFocusGroupItem", B = i.forwardRef(
  (e, o) => {
    const {
      __scopeRovingFocusGroup: a,
      focusable: n = !0,
      active: A = !1,
      tabStopId: v,
      ...b
    } = e, g = Q(), p = v || g, u = ne(U, a), F = u.currentTabStopId === p, S = K(a), { onFocusableItemAdd: I, onFocusableItemRemove: f } = u;
    return i.useEffect(() => {
      if (n)
        return I(), () => f();
    }, [n, I, f]), /* @__PURE__ */ m(
      N.ItemSlot,
      {
        scope: a,
        id: p,
        focusable: n,
        active: A,
        children: /* @__PURE__ */ m(
          L.span,
          {
            tabIndex: F ? 0 : -1,
            "data-orientation": u.orientation,
            ...b,
            ref: o,
            onMouseDown: w(e.onMouseDown, (t) => {
              n ? u.onItemFocus(p) : t.preventDefault();
            }),
            onFocus: w(e.onFocus, () => u.onItemFocus(p)),
            onKeyDown: w(e.onKeyDown, (t) => {
              if (t.key === "Tab" && t.shiftKey) {
                u.onItemShiftTab();
                return;
              }
              if (t.target !== t.currentTarget) return;
              const s = ae(t, u.orientation, u.dir);
              if (s !== void 0) {
                if (t.metaKey || t.ctrlKey || t.altKey || t.shiftKey) return;
                t.preventDefault();
                let r = S().filter((c) => c.focusable).map((c) => c.ref.current);
                if (s === "last") r.reverse();
                else if (s === "prev" || s === "next") {
                  s === "prev" && r.reverse();
                  const c = r.indexOf(t.currentTarget);
                  r = u.loop ? ue(r, c + 1) : r.slice(c + 1);
                }
                setTimeout(() => V(r));
              }
            })
          }
        )
      }
    );
  }
);
B.displayName = U;
var se = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function ie(e, o) {
  return o !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function ae(e, o, a) {
  const n = ie(e.key, a);
  if (!(o === "vertical" && ["ArrowLeft", "ArrowRight"].includes(n)) && !(o === "horizontal" && ["ArrowUp", "ArrowDown"].includes(n)))
    return se[n];
}
function V(e, o = !1) {
  const a = document.activeElement;
  for (const n of e)
    if (n === a || (n.focus({ preventScroll: o }), document.activeElement !== a)) return;
}
function ue(e, o) {
  return e.map((a, n) => e[(o + n) % e.length]);
}
var be = k, ge = B;
export {
  ge as I,
  be as R,
  Ce as c,
  Z as u
};
