import { useFormContext } from "react-hook-form";

import { cn } from "@flare/ui";

import { Button } from "@/components/Funnel/base/Button";
import { StickyActions } from "@/components/Funnel/base/StickyActions";

export type ActionButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    className?: string;
  };

export function ActionButton({
  children,
  className,
  ...restProps
}: ActionButtonProps) {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <StickyActions className="flex flex-col items-center justify-center gap-4 md:w-screen md:self-center">
      <Button
        type="submit"
        className={cn("w-full max-w-[450px] md:max-w-[350px]", className)}
        disabled={isSubmitting}
        {...restProps}
      >
        {children}
      </Button>
    </StickyActions>
  );
}
