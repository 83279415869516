import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";

import { cn } from "@flare/ui";

export type LabelProps = React.ComponentPropsWithRef<
  typeof LabelPrimitive.Root
>;

export const Label = ({ className, ...restProps }: LabelProps) => {
  return (
    <LabelPrimitive.Root
      className={cn("text-xs font-normal", className)}
      {...restProps}
    />
  );
};
