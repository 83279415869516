// keep common family & immigrations flow parts in this file

import { i18n } from "@/services/locale/i18n";

export const interludeContent = {
  default: {
    heading: i18n.t("FUNNEL_INTERLUDE_REVIEWING_YOUR_ANSWERS"),
    subHeading: i18n.t("FUNNEL_INTERLUDE_JUST_A_FEW_MORE_MOMENTS"),
  },
  directToLss: {
    heading: i18n.t("FUNNEL_INTERLUDE_FINDING_THE_RIGHT_ATTORNEY"),
    subHeading: i18n.t("FUNNEL_INTERLUDE_MATCHING_BASED_ON_YOUR_ANSWERS"),
  },
};
