import type { HTMLInputTypeAttribute } from "react";
import { useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/sdk";
import { isEmail, isPhoneNumber } from "class-validator";
import type { StringSchema } from "yup";
import { string } from "yup";

import { FUNNEL_TEXT_FIELD } from "@/builder/components-sections";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";
import { isQualifiedInternalEmail } from "@/modules/v2/funnel/visitor-checks/checkQualifiedInternalEmail";
import { i18n } from "@/services/locale/i18n";

import type { InputProps } from "./base/Input";
import { Input } from "./base/Input";
import { REQUIRED_VALIDATION_MESSAGE } from "./funnel.consts";

type TextFieldProps = InputProps & {
  contextFields?: string;
  validationErrorMessage?: string;
};

type TypeToValidationSchema = Partial<
  Record<HTMLInputTypeAttribute, StringSchema>
>;

type HandleValidation = {
  context?: { [id: string]: string };
  errorMessage?: string;
  required?: boolean;
  type: HTMLInputTypeAttribute | "tel-US" | "zipCode";
  value: string;
};

async function handleValidation({
  value,
  type,
  required = false,
  errorMessage,
  context,
}: HandleValidation): Promise<string | undefined> {
  const typeToValidationSchema = {
    // TODO: Remove zipCode type after moving to stripe PaymentElement
    zipCode: required
      ? string()
          .trim()
          .matches(/^\d{5}$/, errorMessage)
          .required(errorMessage || REQUIRED_VALIDATION_MESSAGE)
      : string()
          .trim()
          .matches(/^\d{5}$/, errorMessage),
    text: required
      ? string()
          .trim()
          .required(errorMessage || REQUIRED_VALIDATION_MESSAGE)
      : string().trim(),
    email: string()
      .trim()
      .test({
        message: i18n.t(
          "FUNNEL_INPUT_TEXT_FIELD_DEFAULT_EMAIL_VALIDATION_ERROR",
        ),
        test: (value) => {
          // The test method triggers the validation error by returning false
          return value ? isEmail(value) : !required;
        },
      }),
    "tel-US": string()
      .trim()
      .test({
        message: i18n.t("FUNNEL_INPUT_TEXT_FIELD_DEFAULT_TEL_VALIDATION_ERROR"),
        test: (value, context) => {
          // The test method triggers the validation error by returning false
          if (!value) return !required;
          const allowNonUs = isQualifiedInternalEmail(
            context.options.context?.email,
          );
          const hasInvalidCharacters = /[^\d\s()+-]/.test(value);
          return (
            isPhoneNumber(value, allowNonUs ? undefined : "US") &&
            !hasInvalidCharacters
          );
        },
      }),
    tel: required
      ? string()
          .trim()
          .matches(/^[\d\s()+-]+$/, errorMessage)
          .required()
      : string()
          .trim()
          .matches(/^[\d\s()+-]+$/, errorMessage),
  } satisfies TypeToValidationSchema;

  // @ts-expect-error: implicit any
  const schema = typeToValidationSchema[type];

  try {
    await schema.validate(value, { context });
  } catch (error) {
    return error instanceof Error ? error.message : "Invalid value";
  }
}

export const TextField = ({
  name,
  required,
  hint,
  label,
  type = "text",
  validationErrorMessage,
  contextFields,
  onChange,
  ...restProps
}: TextFieldProps) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const hasError = Boolean(errors?.[name]);
  const innerHint = hasError ? errors?.[name]?.message : hint;
  const context = contextFields
    ?.split(",")
    .reduce((prev, key) => Object.assign(prev, { [key]: watch(key) }), {});
  const { onChange: registerOnChange, ...restRegister } = register(name, {
    validate: (value: string) =>
      handleValidation({
        context,
        required,
        type,
        value,
        errorMessage: validationErrorMessage,
      }),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    registerOnChange(event);
    onChange?.(event);
  };

  return (
    <Input
      error={hasError}
      hint={innerHint as string}
      label={label}
      onChange={handleChange}
      {...restRegister}
      {...restProps}
    />
  );
};

Builder.registerComponent(TextField, {
  name: FUNNEL_TEXT_FIELD,
  inputs: [
    {
      name: "name",
      type: "string",
      required: true,
      helperText: "Should be written in camelCase",
      enum: Object.values(funnelInputsNameMap),
    },
    {
      name: "label",
      type: "string",
      required: true,
      helperText: "Field label",
    },
    {
      name: "type",
      type: "string",
      enum: ["text", "email", "tel", "tel-US"],
      defaultValue: "text",
      helperText: "Field type, each type has different validation rules",
    },
    {
      name: "placeholder",
      type: "string",
      defaultValue: "Enter your text here",
      helperText: "Placeholder text displayed inside the field",
    },
    {
      name: "hint",
      type: "string",
      helperText: "Hint text displayed below the field",
    },
    {
      name: "required",
      type: "boolean",
      defaultValue: false,
      helperText: "Is this field required?",
    },
    {
      name: "validationErrorMessage",
      type: "string",
      helperText: "Error message to display when validation fails",
    },
    {
      name: "contextFields",
      type: "string",
      helperText: "Adjacent fields to pass to validator (comma separated)",
    },
  ],
});
