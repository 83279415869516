import * as React from "react";

import type { ServicesContainerProps } from "./analytics/analytics.types";
import { AppInitService } from "./analytics/app-init-service";
import { FullstoryContainer } from "./analytics/fullstory";

export const ServicesContainer = ({ pageProps }: ServicesContainerProps) => {
  return (
    <>
      <AppInitService pageProps={pageProps} />
      <FullstoryContainer />
    </>
  );
};
