export const DEFAULT_CAMPAIGN_DATA = {
  source: null,
  campaignId: null,
  adgroupId: null,
  keyword: null,
  content: null,
  medium: null,
  marbleCategory: null,
  gclid: null,
  fbclid: null,
  ttclid: null,
  msclkid: null,
  referrer: null,
  affiliateId: null,
  matchLocation: null,
  matchType: null,
  searchNetwork: null,
  mcidtype: null,
};

export const SEARCH_ENGINE_LIST = [
  "google",
  "baidu",
  "bing",
  "yahoo",
  "duckduckgo",
  "yandex",
  "naver",
  "ecosia.org",
  "so.com",
  "sogou",
  "daum",
  "jobs_on_facebook",
  "go.mail.ru",
  "qwant.com",
  "ask",
  "aol",
  "seznam",
  "yippy",
  "amazon",
  "openverse",
  "gibiru",
  "searchencrypt",
  "searx",
  "startpage",
  "swisscows",
  "ecosia",
  "gigablast",
  "lycos",
  "mojeek",
  "neeva",
  "qmamu",
  "wolframalpha",
  "webcrawler",
  "metager",
  "qwant",
  "you",
  "oscobo",
  "infinity Search",
  "YEP",
  "Anoox",
];
