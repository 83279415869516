import { useController, useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/sdk";
import { format, formatISO, parseISO, startOfDay } from "date-fns";

import { cn } from "@flare/ui";

import { FUNNEL_DATE_PICKER } from "@/builder/components-sections";
import { Button } from "@/components/Funnel/base/Button";
import { Calendar } from "@/components/Funnel/base/Calendar";
import { Label } from "@/components/Funnel/base/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Funnel/base/Popover";
import { CalendarIcon } from "@/components/icons/CalendarIcon";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";

import { Text } from "../v2/Text/Text";

import { Hint } from "./base/Hint";

type DatePickerProps = {
  label: string;
  name: string;
  placeholder: string;
  required?: boolean;
  validationErrorMessage: string;
};

export function DatePicker({
  label,
  name,
  placeholder,
  required,
  validationErrorMessage,
}: DatePickerProps) {
  const {
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name,
    rules: { required },
  });

  const handleSelectDate = (date: Date | undefined) => {
    date
      ? field.onChange(formatISO(date, { representation: "date" }))
      : field.onChange(undefined);
  };

  const currentDate = startOfDay(new Date());
  const hasError = Boolean(errors?.[name]);
  const selectedDate = field.value ? parseISO(field.value) : undefined;

  return (
    <Popover>
      <div className="flex flex-col gap-2">
        <Label htmlFor={`${name}-btn`}>{label}</Label>
        <PopoverTrigger asChild>
          <Button
            variant="base"
            fullWidth
            id={`${name}-btn`}
            className={cn(
              "justify-start gap-2 rounded-lg bg-grey-50 p-4 text-base transition-colors placeholder:text-blue-40 disabled:bg-blue-20 data-[state=open]:bg-green-20 data-[state=open]:ring-1 data-[state=open]:ring-green-100",
              {
                "ring-1 ring-orange-100 focus-visible:ring-orange-100":
                  hasError,
              },
            )}
          >
            <CalendarIcon className="size-6" />
            {field.value ? (
              <Text variant="text-4">{format(selectedDate!, "PPP")}</Text>
            ) : (
              <Text variant="text-4" className="text-blue-40">
                {placeholder}
              </Text>
            )}
          </Button>
        </PopoverTrigger>
        {hasError && (
          <Hint id={`${name}-error`} error={hasError}>
            {validationErrorMessage}
          </Hint>
        )}
      </div>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={selectedDate}
          onSelect={handleSelectDate}
          disabled={{ before: currentDate }}
          defaultMonth={selectedDate || currentDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

Builder.registerComponent(DatePicker, {
  name: FUNNEL_DATE_PICKER,
  inputs: [
    {
      name: "name",
      type: "string",
      required: true,
      helperText: "Should be written in camelCase",
      enum: Object.values(funnelInputsNameMap),
    },
    {
      name: "label",
      type: "string",
      required: true,
      helperText: "Field label",
    },
    {
      name: "required",
      type: "boolean",
      defaultValue: false,
      helperText: "Is this field required?",
    },
    {
      name: "placeholder",
      type: "string",
      defaultValue: "Pick a date",
      helperText: "Placeholder text displayed inside the field",
    },
    {
      name: "validationErrorMessage",
      type: "string",
      defaultValue: "Select a date",
      helperText: "Error message to display when validation fails",
    },
  ],
});
