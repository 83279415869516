import { isBrowser } from "@/utils/isBrowser";

export type StorageData = Record<string, unknown>;

/**
 * Sets data in the browser's `sessionStorage` under the specified key.
 * If `sessionStorage` is not available (e.g., in a server-side environment), no action is taken.
 * Merges the provided data with existing data if present under the same key.
 *
 * @param {string} key - The key under which to store the data in `sessionStorage`.
 * @param {StorageData} data - The data to be stored.
 */
export function setData(key: string, data: StorageData) {
  if (!isBrowser()) return;

  try {
    const existingData = getData(key);

    const mergedData = { ...existingData, ...data };

    const serializedData = JSON.stringify(mergedData);

    window.sessionStorage.setItem(key, serializedData);
    window.dispatchEvent(new StorageEvent("session-storage", { key }));
  } catch (error) {
    console.error("Error storing data in sessionStorage:", error);
  }
}

/**
 * Retrieves data from the browser's `sessionStorage` based on the specified key.
 * If `sessionStorage` is not available (e.g., in a server-side environment), returns null.
 *
 * @param {string} key - The key under which the data is stored in `sessionStorage`.
 */
export function getData(key: string) {
  if (!isBrowser()) return null;

  try {
    const serializedData = sessionStorage.getItem(key);

    if (serializedData === null) {
      return null;
    }

    const parsedData: StorageData = JSON.parse(serializedData);

    return parsedData;
  } catch (error) {
    console.error("Error retrieving data from sessionStorage:", error);
  }

  return null;
}
