import { familyAlimony } from "./alimony";
import { familyChildCustody } from "./child-custody";
import { familyChildSupport } from "./child-support";
import { familyDivorce } from "./divorce";
import { familyElse } from "./else";
import { familyReschedule } from "./reschedule";

export const familyFlow = {
  divorce: familyDivorce,
  alimony: familyAlimony,
  "child-custody": familyChildCustody,
  "child-support": familyChildSupport,
  else: familyElse,
  reschedule: familyReschedule,
};
