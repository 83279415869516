import { Builder } from "@builder.io/sdk";

import { LIMITED_SCOPE_DRAWER_TRIGGER } from "@/builder/components-sections";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/Funnel/base/Drawer";
import { ArrowRight } from "@/components/icons/ArrowRight";
import { XIcon } from "@/components/icons/XIcon";
import { Text } from "@/components/v2/Text/Text";

import { LimitedScopeVideoContent } from "./LimitedScopeVideoContent";
import { sendOpenChangeEvent } from "./sendOpenChangeEvent";

export function LimitedScopeDrawerTrigger() {
  return (
    <Drawer onOpenChange={sendOpenChangeEvent}>
      <DrawerTrigger className="flex w-full items-center justify-between py-2">
        <Text variant="text-3">Learn about our pricing</Text>
        <ArrowRight height="24" width="24" />
      </DrawerTrigger>
      <DrawerContent className="py-9">
        <DrawerClose className="absolute right-4 top-5 w-fit rounded-full bg-green-20">
          <XIcon height="24" width="24" />
        </DrawerClose>
        <LimitedScopeVideoContent headingVariant="h3" />
      </DrawerContent>
    </Drawer>
  );
}

Builder.registerComponent(LimitedScopeDrawerTrigger, {
  name: LIMITED_SCOPE_DRAWER_TRIGGER,
});
