const OFFICE_TIMEZONE = "America/Phoenix";
export const OPEN_HOUR = 6;
export const CLOSE_HOUR = 15;

export function checkIntakeOfficeAvailability() {
  const now = new Date();
  const officeDateString = now.toLocaleString("en-US", {
    timeZone: OFFICE_TIMEZONE,
    hour12: false,
  });
  const officeHour = new Date(officeDateString).getHours();
  return officeHour >= OPEN_HOUR && officeHour < CLOSE_HOUR;
}
