var Oe = Object.defineProperty;
var Ne = (s, e, t) => e in s ? Oe(s, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[e] = t;
var ne = (s, e, t) => Ne(s, typeof e != "symbol" ? e + "" : e, t);
import { format as Pe, formatRelative as Ce } from "date-fns";
import { createContext as Re, useContext as ke, useState as $e, useRef as pe, useEffect as X, useCallback as Fe, useMemo as Ee, createElement as je } from "react";
const Ie = {
  type: "logger",
  log(s) {
    this.output("log", s);
  },
  warn(s) {
    this.output("warn", s);
  },
  error(s) {
    this.output("error", s);
  },
  output(s, e) {
    console && console[s] && console[s].apply(console, e);
  }
};
class z {
  constructor(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    this.init(e, t);
  }
  init(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    this.prefix = t.prefix || "i18next:", this.logger = e || Ie, this.options = t, this.debug = t.debug;
  }
  log() {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
      t[n] = arguments[n];
    return this.forward(t, "log", "", !0);
  }
  warn() {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
      t[n] = arguments[n];
    return this.forward(t, "warn", "", !0);
  }
  error() {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
      t[n] = arguments[n];
    return this.forward(t, "error", "");
  }
  deprecate() {
    for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
      t[n] = arguments[n];
    return this.forward(t, "warn", "WARNING DEPRECATED: ", !0);
  }
  forward(e, t, n, r) {
    return r && !this.debug ? null : (typeof e[0] == "string" && (e[0] = `${n}${this.prefix} ${e[0]}`), this.logger[t](e));
  }
  create(e) {
    return new z(this.logger, {
      prefix: `${this.prefix}:${e}:`,
      ...this.options
    });
  }
  clone(e) {
    return e = e || this.options, e.prefix = e.prefix || this.prefix, new z(this.logger, e);
  }
}
var P = new z();
class Q {
  constructor() {
    this.observers = {};
  }
  on(e, t) {
    return e.split(" ").forEach((n) => {
      this.observers[n] || (this.observers[n] = /* @__PURE__ */ new Map());
      const r = this.observers[n].get(t) || 0;
      this.observers[n].set(t, r + 1);
    }), this;
  }
  off(e, t) {
    if (this.observers[e]) {
      if (!t) {
        delete this.observers[e];
        return;
      }
      this.observers[e].delete(t);
    }
  }
  emit(e) {
    for (var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++)
      n[r - 1] = arguments[r];
    this.observers[e] && Array.from(this.observers[e].entries()).forEach((a) => {
      let [o, u] = a;
      for (let l = 0; l < u; l++)
        o(...n);
    }), this.observers["*"] && Array.from(this.observers["*"].entries()).forEach((a) => {
      let [o, u] = a;
      for (let l = 0; l < u; l++)
        o.apply(o, [e, ...n]);
    });
  }
}
function M() {
  let s, e;
  const t = new Promise((n, r) => {
    s = n, e = r;
  });
  return t.resolve = s, t.reject = e, t;
}
function se(s) {
  return s == null ? "" : "" + s;
}
function Me(s, e, t) {
  s.forEach((n) => {
    e[n] && (t[n] = e[n]);
  });
}
const Ae = /###/g;
function D(s, e, t) {
  function n(o) {
    return o && o.indexOf("###") > -1 ? o.replace(Ae, ".") : o;
  }
  function r() {
    return !s || typeof s == "string";
  }
  const i = typeof e != "string" ? e : e.split(".");
  let a = 0;
  for (; a < i.length - 1; ) {
    if (r()) return {};
    const o = n(i[a]);
    !s[o] && t && (s[o] = new t()), Object.prototype.hasOwnProperty.call(s, o) ? s = s[o] : s = {}, ++a;
  }
  return r() ? {} : {
    obj: s,
    k: n(i[a])
  };
}
function re(s, e, t) {
  const {
    obj: n,
    k: r
  } = D(s, e, Object);
  if (n !== void 0 || e.length === 1) {
    n[r] = t;
    return;
  }
  let i = e[e.length - 1], a = e.slice(0, e.length - 1), o = D(s, a, Object);
  for (; o.obj === void 0 && a.length; )
    i = `${a[a.length - 1]}.${i}`, a = a.slice(0, a.length - 1), o = D(s, a, Object), o && o.obj && typeof o.obj[`${o.k}.${i}`] < "u" && (o.obj = void 0);
  o.obj[`${o.k}.${i}`] = t;
}
function Te(s, e, t, n) {
  const {
    obj: r,
    k: i
  } = D(s, e, Object);
  r[i] = r[i] || [], r[i].push(t);
}
function H(s, e) {
  const {
    obj: t,
    k: n
  } = D(s, e);
  if (t)
    return t[n];
}
function De(s, e, t) {
  const n = H(s, t);
  return n !== void 0 ? n : H(e, t);
}
function me(s, e, t) {
  for (const n in e)
    n !== "__proto__" && n !== "constructor" && (n in s ? typeof s[n] == "string" || s[n] instanceof String || typeof e[n] == "string" || e[n] instanceof String ? t && (s[n] = e[n]) : me(s[n], e[n], t) : s[n] = e[n]);
  return s;
}
function k(s) {
  return s.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
var Ve = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;"
};
function Ue(s) {
  return typeof s == "string" ? s.replace(/[&<>"'\/]/g, (e) => Ve[e]) : s;
}
class Ke {
  constructor(e) {
    this.capacity = e, this.regExpMap = /* @__PURE__ */ new Map(), this.regExpQueue = [];
  }
  getRegExp(e) {
    const t = this.regExpMap.get(e);
    if (t !== void 0)
      return t;
    const n = new RegExp(e);
    return this.regExpQueue.length === this.capacity && this.regExpMap.delete(this.regExpQueue.shift()), this.regExpMap.set(e, n), this.regExpQueue.push(e), n;
  }
}
const We = [" ", ",", "?", "!", ";"], ze = new Ke(20);
function He(s, e, t) {
  e = e || "", t = t || "";
  const n = We.filter((a) => e.indexOf(a) < 0 && t.indexOf(a) < 0);
  if (n.length === 0) return !0;
  const r = ze.getRegExp(`(${n.map((a) => a === "?" ? "\\?" : a).join("|")})`);
  let i = !r.test(s);
  if (!i) {
    const a = s.indexOf(t);
    a > 0 && !r.test(s.substring(0, a)) && (i = !0);
  }
  return i;
}
function G(s, e) {
  let t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : ".";
  if (!s) return;
  if (s[e]) return s[e];
  const n = e.split(t);
  let r = s;
  for (let i = 0; i < n.length; ) {
    if (!r || typeof r != "object")
      return;
    let a, o = "";
    for (let u = i; u < n.length; ++u)
      if (u !== i && (o += t), o += n[u], a = r[o], a !== void 0) {
        if (["string", "number", "boolean"].indexOf(typeof a) > -1 && u < n.length - 1)
          continue;
        i += u - i + 1;
        break;
      }
    r = a;
  }
  return r;
}
function J(s) {
  return s && s.indexOf("_") > 0 ? s.replace("_", "-") : s;
}
class ie extends Q {
  constructor(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
      ns: ["translation"],
      defaultNS: "translation"
    };
    super(), this.data = e || {}, this.options = t, this.options.keySeparator === void 0 && (this.options.keySeparator = "."), this.options.ignoreJSONStructure === void 0 && (this.options.ignoreJSONStructure = !0);
  }
  addNamespaces(e) {
    this.options.ns.indexOf(e) < 0 && this.options.ns.push(e);
  }
  removeNamespaces(e) {
    const t = this.options.ns.indexOf(e);
    t > -1 && this.options.ns.splice(t, 1);
  }
  getResource(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    const i = r.keySeparator !== void 0 ? r.keySeparator : this.options.keySeparator, a = r.ignoreJSONStructure !== void 0 ? r.ignoreJSONStructure : this.options.ignoreJSONStructure;
    let o;
    e.indexOf(".") > -1 ? o = e.split(".") : (o = [e, t], n && (Array.isArray(n) ? o.push(...n) : typeof n == "string" && i ? o.push(...n.split(i)) : o.push(n)));
    const u = H(this.data, o);
    return !u && !t && !n && e.indexOf(".") > -1 && (e = o[0], t = o[1], n = o.slice(2).join(".")), u || !a || typeof n != "string" ? u : G(this.data && this.data[e] && this.data[e][t], n, i);
  }
  addResource(e, t, n, r) {
    let i = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : {
      silent: !1
    };
    const a = i.keySeparator !== void 0 ? i.keySeparator : this.options.keySeparator;
    let o = [e, t];
    n && (o = o.concat(a ? n.split(a) : n)), e.indexOf(".") > -1 && (o = e.split("."), r = t, t = o[1]), this.addNamespaces(t), re(this.data, o, r), i.silent || this.emit("added", e, t, n, r);
  }
  addResources(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {
      silent: !1
    };
    for (const i in n)
      (typeof n[i] == "string" || Array.isArray(n[i])) && this.addResource(e, t, i, n[i], {
        silent: !0
      });
    r.silent || this.emit("added", e, t, n);
  }
  addResourceBundle(e, t, n, r, i) {
    let a = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : {
      silent: !1,
      skipCopy: !1
    }, o = [e, t];
    e.indexOf(".") > -1 && (o = e.split("."), r = n, n = t, t = o[1]), this.addNamespaces(t);
    let u = H(this.data, o) || {};
    a.skipCopy || (n = JSON.parse(JSON.stringify(n))), r ? me(u, n, i) : u = {
      ...u,
      ...n
    }, re(this.data, o, u), a.silent || this.emit("added", e, t, n);
  }
  removeResourceBundle(e, t) {
    this.hasResourceBundle(e, t) && delete this.data[e][t], this.removeNamespaces(t), this.emit("removed", e, t);
  }
  hasResourceBundle(e, t) {
    return this.getResource(e, t) !== void 0;
  }
  getResourceBundle(e, t) {
    return t || (t = this.options.defaultNS), this.options.compatibilityAPI === "v1" ? {
      ...this.getResource(e, t)
    } : this.getResource(e, t);
  }
  getDataByLanguage(e) {
    return this.data[e];
  }
  hasLanguageSomeTranslations(e) {
    const t = this.getDataByLanguage(e);
    return !!(t && Object.keys(t) || []).find((r) => t[r] && Object.keys(t[r]).length > 0);
  }
  toJSON() {
    return this.data;
  }
}
var be = {
  processors: {},
  addPostProcessor(s) {
    this.processors[s.name] = s;
  },
  handle(s, e, t, n, r) {
    return s.forEach((i) => {
      this.processors[i] && (e = this.processors[i].process(e, t, n, r));
    }), e;
  }
};
const ae = {};
class B extends Q {
  constructor(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    super(), Me(["resourceStore", "languageUtils", "pluralResolver", "interpolator", "backendConnector", "i18nFormat", "utils"], e, this), this.options = t, this.options.keySeparator === void 0 && (this.options.keySeparator = "."), this.logger = P.create("translator");
  }
  changeLanguage(e) {
    e && (this.language = e);
  }
  exists(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
      interpolation: {}
    };
    if (e == null)
      return !1;
    const n = this.resolve(e, t);
    return n && n.res !== void 0;
  }
  extractFromKey(e, t) {
    let n = t.nsSeparator !== void 0 ? t.nsSeparator : this.options.nsSeparator;
    n === void 0 && (n = ":");
    const r = t.keySeparator !== void 0 ? t.keySeparator : this.options.keySeparator;
    let i = t.ns || this.options.defaultNS || [];
    const a = n && e.indexOf(n) > -1, o = !this.options.userDefinedKeySeparator && !t.keySeparator && !this.options.userDefinedNsSeparator && !t.nsSeparator && !He(e, n, r);
    if (a && !o) {
      const u = e.match(this.interpolator.nestingRegexp);
      if (u && u.length > 0)
        return {
          key: e,
          namespaces: i
        };
      const l = e.split(n);
      (n !== r || n === r && this.options.ns.indexOf(l[0]) > -1) && (i = l.shift()), e = l.join(r);
    }
    return typeof i == "string" && (i = [i]), {
      key: e,
      namespaces: i
    };
  }
  translate(e, t, n) {
    if (typeof t != "object" && this.options.overloadTranslationOptionHandler && (t = this.options.overloadTranslationOptionHandler(arguments)), typeof t == "object" && (t = {
      ...t
    }), t || (t = {}), e == null) return "";
    Array.isArray(e) || (e = [String(e)]);
    const r = t.returnDetails !== void 0 ? t.returnDetails : this.options.returnDetails, i = t.keySeparator !== void 0 ? t.keySeparator : this.options.keySeparator, {
      key: a,
      namespaces: o
    } = this.extractFromKey(e[e.length - 1], t), u = o[o.length - 1], l = t.lng || this.language, d = t.appendNamespaceToCIMode || this.options.appendNamespaceToCIMode;
    if (l && l.toLowerCase() === "cimode") {
      if (d) {
        const p = t.nsSeparator || this.options.nsSeparator;
        return r ? {
          res: `${u}${p}${a}`,
          usedKey: a,
          exactUsedKey: a,
          usedLng: l,
          usedNS: u,
          usedParams: this.getUsedParamsDetails(t)
        } : `${u}${p}${a}`;
      }
      return r ? {
        res: a,
        usedKey: a,
        exactUsedKey: a,
        usedLng: l,
        usedNS: u,
        usedParams: this.getUsedParamsDetails(t)
      } : a;
    }
    const c = this.resolve(e, t);
    let f = c && c.res;
    const g = c && c.usedKey || a, h = c && c.exactUsedKey || a, b = Object.prototype.toString.apply(f), m = ["[object Number]", "[object Function]", "[object RegExp]"], w = t.joinArrays !== void 0 ? t.joinArrays : this.options.joinArrays, y = !this.i18nFormat || this.i18nFormat.handleAsObject;
    if (y && f && (typeof f != "string" && typeof f != "boolean" && typeof f != "number") && m.indexOf(b) < 0 && !(typeof w == "string" && Array.isArray(f))) {
      if (!t.returnObjects && !this.options.returnObjects) {
        this.options.returnedObjectHandler || this.logger.warn("accessing an object - but returnObjects options is not enabled!");
        const p = this.options.returnedObjectHandler ? this.options.returnedObjectHandler(g, f, {
          ...t,
          ns: o
        }) : `key '${a} (${this.language})' returned an object instead of string.`;
        return r ? (c.res = p, c.usedParams = this.getUsedParamsDetails(t), c) : p;
      }
      if (i) {
        const p = Array.isArray(f), x = p ? [] : {}, S = p ? h : g;
        for (const v in f)
          if (Object.prototype.hasOwnProperty.call(f, v)) {
            const U = `${S}${i}${v}`;
            x[v] = this.translate(U, {
              ...t,
              joinArrays: !1,
              ns: o
            }), x[v] === U && (x[v] = f[v]);
          }
        f = x;
      }
    } else if (y && typeof w == "string" && Array.isArray(f))
      f = f.join(w), f && (f = this.extendTranslation(f, e, t, n));
    else {
      let p = !1, x = !1;
      const S = t.count !== void 0 && typeof t.count != "string", v = B.hasDefaultValue(t), U = S ? this.pluralResolver.getSuffix(l, t.count, t) : "", we = t.ordinal && S ? this.pluralResolver.getSuffix(l, t.count, {
        ordinal: !1
      }) : "", _ = S && !t.ordinal && t.count === 0 && this.pluralResolver.shouldUseIntlApi(), F = _ && t[`defaultValue${this.options.pluralSeparator}zero`] || t[`defaultValue${U}`] || t[`defaultValue${we}`] || t.defaultValue;
      !this.isValidLookup(f) && v && (p = !0, f = F), this.isValidLookup(f) || (x = !0, f = a);
      const Le = (t.missingKeyNoValueFallbackToKey || this.options.missingKeyNoValueFallbackToKey) && x ? void 0 : f, E = v && F !== f && this.options.updateMissing;
      if (x || p || E) {
        if (this.logger.log(E ? "updateKey" : "missingKey", l, u, a, E ? F : f), i) {
          const O = this.resolve(a, {
            ...t,
            keySeparator: !1
          });
          O && O.res && this.logger.warn("Seems the loaded translations were in flat JSON format instead of nested. Either set keySeparator: false on init or make sure your translations are published in nested format.");
        }
        let j = [];
        const K = this.languageUtils.getFallbackCodes(this.options.fallbackLng, t.lng || this.language);
        if (this.options.saveMissingTo === "fallback" && K && K[0])
          for (let O = 0; O < K.length; O++)
            j.push(K[O]);
        else this.options.saveMissingTo === "all" ? j = this.languageUtils.toResolveHierarchy(t.lng || this.language) : j.push(t.lng || this.language);
        const ee = (O, C, I) => {
          const te = v && I !== f ? I : Le;
          this.options.missingKeyHandler ? this.options.missingKeyHandler(O, u, C, te, E, t) : this.backendConnector && this.backendConnector.saveMissing && this.backendConnector.saveMissing(O, u, C, te, E, t), this.emit("missingKey", O, u, C, f);
        };
        this.options.saveMissing && (this.options.saveMissingPlurals && S ? j.forEach((O) => {
          const C = this.pluralResolver.getSuffixes(O, t);
          _ && t[`defaultValue${this.options.pluralSeparator}zero`] && C.indexOf(`${this.options.pluralSeparator}zero`) < 0 && C.push(`${this.options.pluralSeparator}zero`), C.forEach((I) => {
            ee([O], a + I, t[`defaultValue${I}`] || F);
          });
        }) : ee(j, a, F));
      }
      f = this.extendTranslation(f, e, t, c, n), x && f === a && this.options.appendNamespaceToMissingKey && (f = `${u}:${a}`), (x || p) && this.options.parseMissingKeyHandler && (this.options.compatibilityAPI !== "v1" ? f = this.options.parseMissingKeyHandler(this.options.appendNamespaceToMissingKey ? `${u}:${a}` : a, p ? f : void 0) : f = this.options.parseMissingKeyHandler(f));
    }
    return r ? (c.res = f, c.usedParams = this.getUsedParamsDetails(t), c) : f;
  }
  extendTranslation(e, t, n, r, i) {
    var a = this;
    if (this.i18nFormat && this.i18nFormat.parse)
      e = this.i18nFormat.parse(e, {
        ...this.options.interpolation.defaultVariables,
        ...n
      }, n.lng || this.language || r.usedLng, r.usedNS, r.usedKey, {
        resolved: r
      });
    else if (!n.skipInterpolation) {
      n.interpolation && this.interpolator.init({
        ...n,
        interpolation: {
          ...this.options.interpolation,
          ...n.interpolation
        }
      });
      const l = typeof e == "string" && (n && n.interpolation && n.interpolation.skipOnVariables !== void 0 ? n.interpolation.skipOnVariables : this.options.interpolation.skipOnVariables);
      let d;
      if (l) {
        const f = e.match(this.interpolator.nestingRegexp);
        d = f && f.length;
      }
      let c = n.replace && typeof n.replace != "string" ? n.replace : n;
      if (this.options.interpolation.defaultVariables && (c = {
        ...this.options.interpolation.defaultVariables,
        ...c
      }), e = this.interpolator.interpolate(e, c, n.lng || this.language, n), l) {
        const f = e.match(this.interpolator.nestingRegexp), g = f && f.length;
        d < g && (n.nest = !1);
      }
      !n.lng && this.options.compatibilityAPI !== "v1" && r && r.res && (n.lng = r.usedLng), n.nest !== !1 && (e = this.interpolator.nest(e, function() {
        for (var f = arguments.length, g = new Array(f), h = 0; h < f; h++)
          g[h] = arguments[h];
        return i && i[0] === g[0] && !n.context ? (a.logger.warn(`It seems you are nesting recursively key: ${g[0]} in key: ${t[0]}`), null) : a.translate(...g, t);
      }, n)), n.interpolation && this.interpolator.reset();
    }
    const o = n.postProcess || this.options.postProcess, u = typeof o == "string" ? [o] : o;
    return e != null && u && u.length && n.applyPostProcessor !== !1 && (e = be.handle(u, e, t, this.options && this.options.postProcessPassResolved ? {
      i18nResolved: {
        ...r,
        usedParams: this.getUsedParamsDetails(n)
      },
      ...n
    } : n, this)), e;
  }
  resolve(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n, r, i, a, o;
    return typeof e == "string" && (e = [e]), e.forEach((u) => {
      if (this.isValidLookup(n)) return;
      const l = this.extractFromKey(u, t), d = l.key;
      r = d;
      let c = l.namespaces;
      this.options.fallbackNS && (c = c.concat(this.options.fallbackNS));
      const f = t.count !== void 0 && typeof t.count != "string", g = f && !t.ordinal && t.count === 0 && this.pluralResolver.shouldUseIntlApi(), h = t.context !== void 0 && (typeof t.context == "string" || typeof t.context == "number") && t.context !== "", b = t.lngs ? t.lngs : this.languageUtils.toResolveHierarchy(t.lng || this.language, t.fallbackLng);
      c.forEach((m) => {
        this.isValidLookup(n) || (o = m, !ae[`${b[0]}-${m}`] && this.utils && this.utils.hasLoadedNamespace && !this.utils.hasLoadedNamespace(o) && (ae[`${b[0]}-${m}`] = !0, this.logger.warn(`key "${r}" for languages "${b.join(", ")}" won't get resolved as namespace "${o}" was not yet loaded`, "This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!")), b.forEach((w) => {
          if (this.isValidLookup(n)) return;
          a = w;
          const y = [d];
          if (this.i18nFormat && this.i18nFormat.addLookupKeys)
            this.i18nFormat.addLookupKeys(y, d, w, m, t);
          else {
            let p;
            f && (p = this.pluralResolver.getSuffix(w, t.count, t));
            const x = `${this.options.pluralSeparator}zero`, S = `${this.options.pluralSeparator}ordinal${this.options.pluralSeparator}`;
            if (f && (y.push(d + p), t.ordinal && p.indexOf(S) === 0 && y.push(d + p.replace(S, this.options.pluralSeparator)), g && y.push(d + x)), h) {
              const v = `${d}${this.options.contextSeparator}${t.context}`;
              y.push(v), f && (y.push(v + p), t.ordinal && p.indexOf(S) === 0 && y.push(v + p.replace(S, this.options.pluralSeparator)), g && y.push(v + x));
            }
          }
          let N;
          for (; N = y.pop(); )
            this.isValidLookup(n) || (i = N, n = this.getResource(w, m, N, t));
        }));
      });
    }), {
      res: n,
      usedKey: r,
      exactUsedKey: i,
      usedLng: a,
      usedNS: o
    };
  }
  isValidLookup(e) {
    return e !== void 0 && !(!this.options.returnNull && e === null) && !(!this.options.returnEmptyString && e === "");
  }
  getResource(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    return this.i18nFormat && this.i18nFormat.getResource ? this.i18nFormat.getResource(e, t, n, r) : this.resourceStore.getResource(e, t, n, r);
  }
  getUsedParamsDetails() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const t = ["defaultValue", "ordinal", "context", "replace", "lng", "lngs", "fallbackLng", "ns", "keySeparator", "nsSeparator", "returnObjects", "returnDetails", "joinArrays", "postProcess", "interpolation"], n = e.replace && typeof e.replace != "string";
    let r = n ? e.replace : e;
    if (n && typeof e.count < "u" && (r.count = e.count), this.options.interpolation.defaultVariables && (r = {
      ...this.options.interpolation.defaultVariables,
      ...r
    }), !n) {
      r = {
        ...r
      };
      for (const i of t)
        delete r[i];
    }
    return r;
  }
  static hasDefaultValue(e) {
    const t = "defaultValue";
    for (const n in e)
      if (Object.prototype.hasOwnProperty.call(e, n) && t === n.substring(0, t.length) && e[n] !== void 0)
        return !0;
    return !1;
  }
}
function Y(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
class oe {
  constructor(e) {
    this.options = e, this.supportedLngs = this.options.supportedLngs || !1, this.logger = P.create("languageUtils");
  }
  getScriptPartFromCode(e) {
    if (e = J(e), !e || e.indexOf("-") < 0) return null;
    const t = e.split("-");
    return t.length === 2 || (t.pop(), t[t.length - 1].toLowerCase() === "x") ? null : this.formatLanguageCode(t.join("-"));
  }
  getLanguagePartFromCode(e) {
    if (e = J(e), !e || e.indexOf("-") < 0) return e;
    const t = e.split("-");
    return this.formatLanguageCode(t[0]);
  }
  formatLanguageCode(e) {
    if (typeof e == "string" && e.indexOf("-") > -1) {
      const t = ["hans", "hant", "latn", "cyrl", "cans", "mong", "arab"];
      let n = e.split("-");
      return this.options.lowerCaseLng ? n = n.map((r) => r.toLowerCase()) : n.length === 2 ? (n[0] = n[0].toLowerCase(), n[1] = n[1].toUpperCase(), t.indexOf(n[1].toLowerCase()) > -1 && (n[1] = Y(n[1].toLowerCase()))) : n.length === 3 && (n[0] = n[0].toLowerCase(), n[1].length === 2 && (n[1] = n[1].toUpperCase()), n[0] !== "sgn" && n[2].length === 2 && (n[2] = n[2].toUpperCase()), t.indexOf(n[1].toLowerCase()) > -1 && (n[1] = Y(n[1].toLowerCase())), t.indexOf(n[2].toLowerCase()) > -1 && (n[2] = Y(n[2].toLowerCase()))), n.join("-");
    }
    return this.options.cleanCode || this.options.lowerCaseLng ? e.toLowerCase() : e;
  }
  isSupportedCode(e) {
    return (this.options.load === "languageOnly" || this.options.nonExplicitSupportedLngs) && (e = this.getLanguagePartFromCode(e)), !this.supportedLngs || !this.supportedLngs.length || this.supportedLngs.indexOf(e) > -1;
  }
  getBestMatchFromCodes(e) {
    if (!e) return null;
    let t;
    return e.forEach((n) => {
      if (t) return;
      const r = this.formatLanguageCode(n);
      (!this.options.supportedLngs || this.isSupportedCode(r)) && (t = r);
    }), !t && this.options.supportedLngs && e.forEach((n) => {
      if (t) return;
      const r = this.getLanguagePartFromCode(n);
      if (this.isSupportedCode(r)) return t = r;
      t = this.options.supportedLngs.find((i) => {
        if (i === r) return i;
        if (!(i.indexOf("-") < 0 && r.indexOf("-") < 0) && (i.indexOf("-") > 0 && r.indexOf("-") < 0 && i.substring(0, i.indexOf("-")) === r || i.indexOf(r) === 0 && r.length > 1))
          return i;
      });
    }), t || (t = this.getFallbackCodes(this.options.fallbackLng)[0]), t;
  }
  getFallbackCodes(e, t) {
    if (!e) return [];
    if (typeof e == "function" && (e = e(t)), typeof e == "string" && (e = [e]), Array.isArray(e)) return e;
    if (!t) return e.default || [];
    let n = e[t];
    return n || (n = e[this.getScriptPartFromCode(t)]), n || (n = e[this.formatLanguageCode(t)]), n || (n = e[this.getLanguagePartFromCode(t)]), n || (n = e.default), n || [];
  }
  toResolveHierarchy(e, t) {
    const n = this.getFallbackCodes(t || this.options.fallbackLng || [], e), r = [], i = (a) => {
      a && (this.isSupportedCode(a) ? r.push(a) : this.logger.warn(`rejecting language code not found in supportedLngs: ${a}`));
    };
    return typeof e == "string" && (e.indexOf("-") > -1 || e.indexOf("_") > -1) ? (this.options.load !== "languageOnly" && i(this.formatLanguageCode(e)), this.options.load !== "languageOnly" && this.options.load !== "currentOnly" && i(this.getScriptPartFromCode(e)), this.options.load !== "currentOnly" && i(this.getLanguagePartFromCode(e))) : typeof e == "string" && i(this.formatLanguageCode(e)), n.forEach((a) => {
      r.indexOf(a) < 0 && i(this.formatLanguageCode(a));
    }), r;
  }
}
let Je = [{
  lngs: ["ach", "ak", "am", "arn", "br", "fil", "gun", "ln", "mfe", "mg", "mi", "oc", "pt", "pt-BR", "tg", "tl", "ti", "tr", "uz", "wa"],
  nr: [1, 2],
  fc: 1
}, {
  lngs: ["af", "an", "ast", "az", "bg", "bn", "ca", "da", "de", "dev", "el", "en", "eo", "es", "et", "eu", "fi", "fo", "fur", "fy", "gl", "gu", "ha", "hi", "hu", "hy", "ia", "it", "kk", "kn", "ku", "lb", "mai", "ml", "mn", "mr", "nah", "nap", "nb", "ne", "nl", "nn", "no", "nso", "pa", "pap", "pms", "ps", "pt-PT", "rm", "sco", "se", "si", "so", "son", "sq", "sv", "sw", "ta", "te", "tk", "ur", "yo"],
  nr: [1, 2],
  fc: 2
}, {
  lngs: ["ay", "bo", "cgg", "fa", "ht", "id", "ja", "jbo", "ka", "km", "ko", "ky", "lo", "ms", "sah", "su", "th", "tt", "ug", "vi", "wo", "zh"],
  nr: [1],
  fc: 3
}, {
  lngs: ["be", "bs", "cnr", "dz", "hr", "ru", "sr", "uk"],
  nr: [1, 2, 5],
  fc: 4
}, {
  lngs: ["ar"],
  nr: [0, 1, 2, 3, 11, 100],
  fc: 5
}, {
  lngs: ["cs", "sk"],
  nr: [1, 2, 5],
  fc: 6
}, {
  lngs: ["csb", "pl"],
  nr: [1, 2, 5],
  fc: 7
}, {
  lngs: ["cy"],
  nr: [1, 2, 3, 8],
  fc: 8
}, {
  lngs: ["fr"],
  nr: [1, 2],
  fc: 9
}, {
  lngs: ["ga"],
  nr: [1, 2, 3, 7, 11],
  fc: 10
}, {
  lngs: ["gd"],
  nr: [1, 2, 3, 20],
  fc: 11
}, {
  lngs: ["is"],
  nr: [1, 2],
  fc: 12
}, {
  lngs: ["jv"],
  nr: [0, 1],
  fc: 13
}, {
  lngs: ["kw"],
  nr: [1, 2, 3, 4],
  fc: 14
}, {
  lngs: ["lt"],
  nr: [1, 2, 10],
  fc: 15
}, {
  lngs: ["lv"],
  nr: [1, 2, 0],
  fc: 16
}, {
  lngs: ["mk"],
  nr: [1, 2],
  fc: 17
}, {
  lngs: ["mnk"],
  nr: [0, 1, 2],
  fc: 18
}, {
  lngs: ["mt"],
  nr: [1, 2, 11, 20],
  fc: 19
}, {
  lngs: ["or"],
  nr: [2, 1],
  fc: 2
}, {
  lngs: ["ro"],
  nr: [1, 2, 20],
  fc: 20
}, {
  lngs: ["sl"],
  nr: [5, 1, 2, 3],
  fc: 21
}, {
  lngs: ["he", "iw"],
  nr: [1, 2, 20, 21],
  fc: 22
}], Be = {
  1: function(s) {
    return +(s > 1);
  },
  2: function(s) {
    return +(s != 1);
  },
  3: function(s) {
    return 0;
  },
  4: function(s) {
    return s % 10 == 1 && s % 100 != 11 ? 0 : s % 10 >= 2 && s % 10 <= 4 && (s % 100 < 10 || s % 100 >= 20) ? 1 : 2;
  },
  5: function(s) {
    return s == 0 ? 0 : s == 1 ? 1 : s == 2 ? 2 : s % 100 >= 3 && s % 100 <= 10 ? 3 : s % 100 >= 11 ? 4 : 5;
  },
  6: function(s) {
    return s == 1 ? 0 : s >= 2 && s <= 4 ? 1 : 2;
  },
  7: function(s) {
    return s == 1 ? 0 : s % 10 >= 2 && s % 10 <= 4 && (s % 100 < 10 || s % 100 >= 20) ? 1 : 2;
  },
  8: function(s) {
    return s == 1 ? 0 : s == 2 ? 1 : s != 8 && s != 11 ? 2 : 3;
  },
  9: function(s) {
    return +(s >= 2);
  },
  10: function(s) {
    return s == 1 ? 0 : s == 2 ? 1 : s < 7 ? 2 : s < 11 ? 3 : 4;
  },
  11: function(s) {
    return s == 1 || s == 11 ? 0 : s == 2 || s == 12 ? 1 : s > 2 && s < 20 ? 2 : 3;
  },
  12: function(s) {
    return +(s % 10 != 1 || s % 100 == 11);
  },
  13: function(s) {
    return +(s !== 0);
  },
  14: function(s) {
    return s == 1 ? 0 : s == 2 ? 1 : s == 3 ? 2 : 3;
  },
  15: function(s) {
    return s % 10 == 1 && s % 100 != 11 ? 0 : s % 10 >= 2 && (s % 100 < 10 || s % 100 >= 20) ? 1 : 2;
  },
  16: function(s) {
    return s % 10 == 1 && s % 100 != 11 ? 0 : s !== 0 ? 1 : 2;
  },
  17: function(s) {
    return s == 1 || s % 10 == 1 && s % 100 != 11 ? 0 : 1;
  },
  18: function(s) {
    return s == 0 ? 0 : s == 1 ? 1 : 2;
  },
  19: function(s) {
    return s == 1 ? 0 : s == 0 || s % 100 > 1 && s % 100 < 11 ? 1 : s % 100 > 10 && s % 100 < 20 ? 2 : 3;
  },
  20: function(s) {
    return s == 1 ? 0 : s == 0 || s % 100 > 0 && s % 100 < 20 ? 1 : 2;
  },
  21: function(s) {
    return s % 100 == 1 ? 1 : s % 100 == 2 ? 2 : s % 100 == 3 || s % 100 == 4 ? 3 : 0;
  },
  22: function(s) {
    return s == 1 ? 0 : s == 2 ? 1 : (s < 0 || s > 10) && s % 10 == 0 ? 2 : 3;
  }
};
const Qe = ["v1", "v2", "v3"], Ye = ["v4"], ue = {
  zero: 0,
  one: 1,
  two: 2,
  few: 3,
  many: 4,
  other: 5
};
function qe() {
  const s = {};
  return Je.forEach((e) => {
    e.lngs.forEach((t) => {
      s[t] = {
        numbers: e.nr,
        plurals: Be[e.fc]
      };
    });
  }), s;
}
class Xe {
  constructor(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    this.languageUtils = e, this.options = t, this.logger = P.create("pluralResolver"), (!this.options.compatibilityJSON || Ye.includes(this.options.compatibilityJSON)) && (typeof Intl > "u" || !Intl.PluralRules) && (this.options.compatibilityJSON = "v3", this.logger.error("Your environment seems not to be Intl API compatible, use an Intl.PluralRules polyfill. Will fallback to the compatibilityJSON v3 format handling.")), this.rules = qe();
  }
  addRule(e, t) {
    this.rules[e] = t;
  }
  getRule(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (this.shouldUseIntlApi())
      try {
        return new Intl.PluralRules(J(e === "dev" ? "en" : e), {
          type: t.ordinal ? "ordinal" : "cardinal"
        });
      } catch {
        return;
      }
    return this.rules[e] || this.rules[this.languageUtils.getLanguagePartFromCode(e)];
  }
  needsPlural(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const n = this.getRule(e, t);
    return this.shouldUseIntlApi() ? n && n.resolvedOptions().pluralCategories.length > 1 : n && n.numbers.length > 1;
  }
  getPluralFormsOfKey(e, t) {
    let n = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    return this.getSuffixes(e, n).map((r) => `${t}${r}`);
  }
  getSuffixes(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const n = this.getRule(e, t);
    return n ? this.shouldUseIntlApi() ? n.resolvedOptions().pluralCategories.sort((r, i) => ue[r] - ue[i]).map((r) => `${this.options.prepend}${t.ordinal ? `ordinal${this.options.prepend}` : ""}${r}`) : n.numbers.map((r) => this.getSuffix(e, r, t)) : [];
  }
  getSuffix(e, t) {
    let n = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const r = this.getRule(e, n);
    return r ? this.shouldUseIntlApi() ? `${this.options.prepend}${n.ordinal ? `ordinal${this.options.prepend}` : ""}${r.select(t)}` : this.getSuffixRetroCompatible(r, t) : (this.logger.warn(`no plural rule found for: ${e}`), "");
  }
  getSuffixRetroCompatible(e, t) {
    const n = e.noAbs ? e.plurals(t) : e.plurals(Math.abs(t));
    let r = e.numbers[n];
    this.options.simplifyPluralSuffix && e.numbers.length === 2 && e.numbers[0] === 1 && (r === 2 ? r = "plural" : r === 1 && (r = ""));
    const i = () => this.options.prepend && r.toString() ? this.options.prepend + r.toString() : r.toString();
    return this.options.compatibilityJSON === "v1" ? r === 1 ? "" : typeof r == "number" ? `_plural_${r.toString()}` : i() : this.options.compatibilityJSON === "v2" || this.options.simplifyPluralSuffix && e.numbers.length === 2 && e.numbers[0] === 1 ? i() : this.options.prepend && n.toString() ? this.options.prepend + n.toString() : n.toString();
  }
  shouldUseIntlApi() {
    return !Qe.includes(this.options.compatibilityJSON);
  }
}
function le(s, e, t) {
  let n = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : ".", r = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : !0, i = De(s, e, t);
  return !i && r && typeof t == "string" && (i = G(s, t, n), i === void 0 && (i = G(e, t, n))), i;
}
class Ge {
  constructor() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    this.logger = P.create("interpolator"), this.options = e, this.format = e.interpolation && e.interpolation.format || ((t) => t), this.init(e);
  }
  init() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    e.interpolation || (e.interpolation = {
      escapeValue: !0
    });
    const {
      escape: t,
      escapeValue: n,
      useRawValueToEscape: r,
      prefix: i,
      prefixEscaped: a,
      suffix: o,
      suffixEscaped: u,
      formatSeparator: l,
      unescapeSuffix: d,
      unescapePrefix: c,
      nestingPrefix: f,
      nestingPrefixEscaped: g,
      nestingSuffix: h,
      nestingSuffixEscaped: b,
      nestingOptionsSeparator: m,
      maxReplaces: w,
      alwaysFormat: y
    } = e.interpolation;
    this.escape = t !== void 0 ? t : Ue, this.escapeValue = n !== void 0 ? n : !0, this.useRawValueToEscape = r !== void 0 ? r : !1, this.prefix = i ? k(i) : a || "{{", this.suffix = o ? k(o) : u || "}}", this.formatSeparator = l || ",", this.unescapePrefix = d ? "" : c || "-", this.unescapeSuffix = this.unescapePrefix ? "" : d || "", this.nestingPrefix = f ? k(f) : g || k("$t("), this.nestingSuffix = h ? k(h) : b || k(")"), this.nestingOptionsSeparator = m || ",", this.maxReplaces = w || 1e3, this.alwaysFormat = y !== void 0 ? y : !1, this.resetRegExp();
  }
  reset() {
    this.options && this.init(this.options);
  }
  resetRegExp() {
    const e = (t, n) => t && t.source === n ? (t.lastIndex = 0, t) : new RegExp(n, "g");
    this.regexp = e(this.regexp, `${this.prefix}(.+?)${this.suffix}`), this.regexpUnescape = e(this.regexpUnescape, `${this.prefix}${this.unescapePrefix}(.+?)${this.unescapeSuffix}${this.suffix}`), this.nestingRegexp = e(this.nestingRegexp, `${this.nestingPrefix}(.+?)${this.nestingSuffix}`);
  }
  interpolate(e, t, n, r) {
    let i, a, o;
    const u = this.options && this.options.interpolation && this.options.interpolation.defaultVariables || {};
    function l(h) {
      return h.replace(/\$/g, "$$$$");
    }
    const d = (h) => {
      if (h.indexOf(this.formatSeparator) < 0) {
        const y = le(t, u, h, this.options.keySeparator, this.options.ignoreJSONStructure);
        return this.alwaysFormat ? this.format(y, void 0, n, {
          ...r,
          ...t,
          interpolationkey: h
        }) : y;
      }
      const b = h.split(this.formatSeparator), m = b.shift().trim(), w = b.join(this.formatSeparator).trim();
      return this.format(le(t, u, m, this.options.keySeparator, this.options.ignoreJSONStructure), w, n, {
        ...r,
        ...t,
        interpolationkey: m
      });
    };
    this.resetRegExp();
    const c = r && r.missingInterpolationHandler || this.options.missingInterpolationHandler, f = r && r.interpolation && r.interpolation.skipOnVariables !== void 0 ? r.interpolation.skipOnVariables : this.options.interpolation.skipOnVariables;
    return [{
      regex: this.regexpUnescape,
      safeValue: (h) => l(h)
    }, {
      regex: this.regexp,
      safeValue: (h) => this.escapeValue ? l(this.escape(h)) : l(h)
    }].forEach((h) => {
      for (o = 0; i = h.regex.exec(e); ) {
        const b = i[1].trim();
        if (a = d(b), a === void 0)
          if (typeof c == "function") {
            const w = c(e, i, r);
            a = typeof w == "string" ? w : "";
          } else if (r && Object.prototype.hasOwnProperty.call(r, b))
            a = "";
          else if (f) {
            a = i[0];
            continue;
          } else
            this.logger.warn(`missed to pass in variable ${b} for interpolating ${e}`), a = "";
        else typeof a != "string" && !this.useRawValueToEscape && (a = se(a));
        const m = h.safeValue(a);
        if (e = e.replace(i[0], m), f ? (h.regex.lastIndex += a.length, h.regex.lastIndex -= i[0].length) : h.regex.lastIndex = 0, o++, o >= this.maxReplaces)
          break;
      }
    }), e;
  }
  nest(e, t) {
    let n = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, r, i, a;
    function o(u, l) {
      const d = this.nestingOptionsSeparator;
      if (u.indexOf(d) < 0) return u;
      const c = u.split(new RegExp(`${d}[ ]*{`));
      let f = `{${c[1]}`;
      u = c[0], f = this.interpolate(f, a);
      const g = f.match(/'/g), h = f.match(/"/g);
      (g && g.length % 2 === 0 && !h || h.length % 2 !== 0) && (f = f.replace(/'/g, '"'));
      try {
        a = JSON.parse(f), l && (a = {
          ...l,
          ...a
        });
      } catch (b) {
        return this.logger.warn(`failed parsing options string in nesting for key ${u}`, b), `${u}${d}${f}`;
      }
      return a.defaultValue && a.defaultValue.indexOf(this.prefix) > -1 && delete a.defaultValue, u;
    }
    for (; r = this.nestingRegexp.exec(e); ) {
      let u = [];
      a = {
        ...n
      }, a = a.replace && typeof a.replace != "string" ? a.replace : a, a.applyPostProcessor = !1, delete a.defaultValue;
      let l = !1;
      if (r[0].indexOf(this.formatSeparator) !== -1 && !/{.*}/.test(r[1])) {
        const d = r[1].split(this.formatSeparator).map((c) => c.trim());
        r[1] = d.shift(), u = d, l = !0;
      }
      if (i = t(o.call(this, r[1].trim(), a), a), i && r[0] === e && typeof i != "string") return i;
      typeof i != "string" && (i = se(i)), i || (this.logger.warn(`missed to resolve ${r[1]} for nesting ${e}`), i = ""), l && (i = u.reduce((d, c) => this.format(d, c, n.lng, {
        ...n,
        interpolationkey: r[1].trim()
      }), i.trim())), e = e.replace(r[0], i), this.regexp.lastIndex = 0;
    }
    return e;
  }
}
function Ze(s) {
  let e = s.toLowerCase().trim();
  const t = {};
  if (s.indexOf("(") > -1) {
    const n = s.split("(");
    e = n[0].toLowerCase().trim();
    const r = n[1].substring(0, n[1].length - 1);
    e === "currency" && r.indexOf(":") < 0 ? t.currency || (t.currency = r.trim()) : e === "relativetime" && r.indexOf(":") < 0 ? t.range || (t.range = r.trim()) : r.split(";").forEach((a) => {
      if (a) {
        const [o, ...u] = a.split(":"), l = u.join(":").trim().replace(/^'+|'+$/g, ""), d = o.trim();
        t[d] || (t[d] = l), l === "false" && (t[d] = !1), l === "true" && (t[d] = !0), isNaN(l) || (t[d] = parseInt(l, 10));
      }
    });
  }
  return {
    formatName: e,
    formatOptions: t
  };
}
function $(s) {
  const e = {};
  return function(n, r, i) {
    const a = r + JSON.stringify(i);
    let o = e[a];
    return o || (o = s(J(r), i), e[a] = o), o(n);
  };
}
class _e {
  constructor() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    this.logger = P.create("formatter"), this.options = e, this.formats = {
      number: $((t, n) => {
        const r = new Intl.NumberFormat(t, {
          ...n
        });
        return (i) => r.format(i);
      }),
      currency: $((t, n) => {
        const r = new Intl.NumberFormat(t, {
          ...n,
          style: "currency"
        });
        return (i) => r.format(i);
      }),
      datetime: $((t, n) => {
        const r = new Intl.DateTimeFormat(t, {
          ...n
        });
        return (i) => r.format(i);
      }),
      relativetime: $((t, n) => {
        const r = new Intl.RelativeTimeFormat(t, {
          ...n
        });
        return (i) => r.format(i, n.range || "day");
      }),
      list: $((t, n) => {
        const r = new Intl.ListFormat(t, {
          ...n
        });
        return (i) => r.format(i);
      })
    }, this.init(e);
  }
  init(e) {
    const n = (arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
      interpolation: {}
    }).interpolation;
    this.formatSeparator = n.formatSeparator ? n.formatSeparator : n.formatSeparator || ",";
  }
  add(e, t) {
    this.formats[e.toLowerCase().trim()] = t;
  }
  addCached(e, t) {
    this.formats[e.toLowerCase().trim()] = $(t);
  }
  format(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    return t.split(this.formatSeparator).reduce((o, u) => {
      const {
        formatName: l,
        formatOptions: d
      } = Ze(u);
      if (this.formats[l]) {
        let c = o;
        try {
          const f = r && r.formatParams && r.formatParams[r.interpolationkey] || {}, g = f.locale || f.lng || r.locale || r.lng || n;
          c = this.formats[l](o, g, {
            ...d,
            ...r,
            ...f
          });
        } catch (f) {
          this.logger.warn(f);
        }
        return c;
      } else
        this.logger.warn(`there was no format function for ${l}`);
      return o;
    }, e);
  }
}
function et(s, e) {
  s.pending[e] !== void 0 && (delete s.pending[e], s.pendingCount--);
}
class tt extends Q {
  constructor(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    super(), this.backend = e, this.store = t, this.services = n, this.languageUtils = n.languageUtils, this.options = r, this.logger = P.create("backendConnector"), this.waitingReads = [], this.maxParallelReads = r.maxParallelReads || 10, this.readingCalls = 0, this.maxRetries = r.maxRetries >= 0 ? r.maxRetries : 5, this.retryTimeout = r.retryTimeout >= 1 ? r.retryTimeout : 350, this.state = {}, this.queue = [], this.backend && this.backend.init && this.backend.init(n, r.backend, r);
  }
  queueLoad(e, t, n, r) {
    const i = {}, a = {}, o = {}, u = {};
    return e.forEach((l) => {
      let d = !0;
      t.forEach((c) => {
        const f = `${l}|${c}`;
        !n.reload && this.store.hasResourceBundle(l, c) ? this.state[f] = 2 : this.state[f] < 0 || (this.state[f] === 1 ? a[f] === void 0 && (a[f] = !0) : (this.state[f] = 1, d = !1, a[f] === void 0 && (a[f] = !0), i[f] === void 0 && (i[f] = !0), u[c] === void 0 && (u[c] = !0)));
      }), d || (o[l] = !0);
    }), (Object.keys(i).length || Object.keys(a).length) && this.queue.push({
      pending: a,
      pendingCount: Object.keys(a).length,
      loaded: {},
      errors: [],
      callback: r
    }), {
      toLoad: Object.keys(i),
      pending: Object.keys(a),
      toLoadLanguages: Object.keys(o),
      toLoadNamespaces: Object.keys(u)
    };
  }
  loaded(e, t, n) {
    const r = e.split("|"), i = r[0], a = r[1];
    t && this.emit("failedLoading", i, a, t), n && this.store.addResourceBundle(i, a, n, void 0, void 0, {
      skipCopy: !0
    }), this.state[e] = t ? -1 : 2;
    const o = {};
    this.queue.forEach((u) => {
      Te(u.loaded, [i], a), et(u, e), t && u.errors.push(t), u.pendingCount === 0 && !u.done && (Object.keys(u.loaded).forEach((l) => {
        o[l] || (o[l] = {});
        const d = u.loaded[l];
        d.length && d.forEach((c) => {
          o[l][c] === void 0 && (o[l][c] = !0);
        });
      }), u.done = !0, u.errors.length ? u.callback(u.errors) : u.callback());
    }), this.emit("loaded", o), this.queue = this.queue.filter((u) => !u.done);
  }
  read(e, t, n) {
    let r = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 0, i = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : this.retryTimeout, a = arguments.length > 5 ? arguments[5] : void 0;
    if (!e.length) return a(null, {});
    if (this.readingCalls >= this.maxParallelReads) {
      this.waitingReads.push({
        lng: e,
        ns: t,
        fcName: n,
        tried: r,
        wait: i,
        callback: a
      });
      return;
    }
    this.readingCalls++;
    const o = (l, d) => {
      if (this.readingCalls--, this.waitingReads.length > 0) {
        const c = this.waitingReads.shift();
        this.read(c.lng, c.ns, c.fcName, c.tried, c.wait, c.callback);
      }
      if (l && d && r < this.maxRetries) {
        setTimeout(() => {
          this.read.call(this, e, t, n, r + 1, i * 2, a);
        }, i);
        return;
      }
      a(l, d);
    }, u = this.backend[n].bind(this.backend);
    if (u.length === 2) {
      try {
        const l = u(e, t);
        l && typeof l.then == "function" ? l.then((d) => o(null, d)).catch(o) : o(null, l);
      } catch (l) {
        o(l);
      }
      return;
    }
    return u(e, t, o);
  }
  prepareLoading(e, t) {
    let n = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, r = arguments.length > 3 ? arguments[3] : void 0;
    if (!this.backend)
      return this.logger.warn("No backend was added via i18next.use. Will not load resources."), r && r();
    typeof e == "string" && (e = this.languageUtils.toResolveHierarchy(e)), typeof t == "string" && (t = [t]);
    const i = this.queueLoad(e, t, n, r);
    if (!i.toLoad.length)
      return i.pending.length || r(), null;
    i.toLoad.forEach((a) => {
      this.loadOne(a);
    });
  }
  load(e, t, n) {
    this.prepareLoading(e, t, {}, n);
  }
  reload(e, t, n) {
    this.prepareLoading(e, t, {
      reload: !0
    }, n);
  }
  loadOne(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
    const n = e.split("|"), r = n[0], i = n[1];
    this.read(r, i, "read", void 0, void 0, (a, o) => {
      a && this.logger.warn(`${t}loading namespace ${i} for language ${r} failed`, a), !a && o && this.logger.log(`${t}loaded namespace ${i} for language ${r}`, o), this.loaded(e, a, o);
    });
  }
  saveMissing(e, t, n, r, i) {
    let a = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : {}, o = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : () => {
    };
    if (this.services.utils && this.services.utils.hasLoadedNamespace && !this.services.utils.hasLoadedNamespace(t)) {
      this.logger.warn(`did not save key "${n}" as the namespace "${t}" was not yet loaded`, "This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!");
      return;
    }
    if (!(n == null || n === "")) {
      if (this.backend && this.backend.create) {
        const u = {
          ...a,
          isUpdate: i
        }, l = this.backend.create.bind(this.backend);
        if (l.length < 6)
          try {
            let d;
            l.length === 5 ? d = l(e, t, n, r, u) : d = l(e, t, n, r), d && typeof d.then == "function" ? d.then((c) => o(null, c)).catch(o) : o(null, d);
          } catch (d) {
            o(d);
          }
        else
          l(e, t, n, r, o, u);
      }
      !e || !e[0] || this.store.addResource(e[0], t, n, r);
    }
  }
}
function fe() {
  return {
    debug: !1,
    initImmediate: !0,
    ns: ["translation"],
    defaultNS: ["translation"],
    fallbackLng: ["dev"],
    fallbackNS: !1,
    supportedLngs: !1,
    nonExplicitSupportedLngs: !1,
    load: "all",
    preload: !1,
    simplifyPluralSuffix: !0,
    keySeparator: ".",
    nsSeparator: ":",
    pluralSeparator: "_",
    contextSeparator: "_",
    partialBundledLanguages: !1,
    saveMissing: !1,
    updateMissing: !1,
    saveMissingTo: "fallback",
    saveMissingPlurals: !0,
    missingKeyHandler: !1,
    missingInterpolationHandler: !1,
    postProcess: !1,
    postProcessPassResolved: !1,
    returnNull: !1,
    returnEmptyString: !0,
    returnObjects: !1,
    joinArrays: !1,
    returnedObjectHandler: !1,
    parseMissingKeyHandler: !1,
    appendNamespaceToMissingKey: !1,
    appendNamespaceToCIMode: !1,
    overloadTranslationOptionHandler: function(e) {
      let t = {};
      if (typeof e[1] == "object" && (t = e[1]), typeof e[1] == "string" && (t.defaultValue = e[1]), typeof e[2] == "string" && (t.tDescription = e[2]), typeof e[2] == "object" || typeof e[3] == "object") {
        const n = e[3] || e[2];
        Object.keys(n).forEach((r) => {
          t[r] = n[r];
        });
      }
      return t;
    },
    interpolation: {
      escapeValue: !0,
      format: (s) => s,
      prefix: "{{",
      suffix: "}}",
      formatSeparator: ",",
      unescapePrefix: "-",
      nestingPrefix: "$t(",
      nestingSuffix: ")",
      nestingOptionsSeparator: ",",
      maxReplaces: 1e3,
      skipOnVariables: !0
    }
  };
}
function de(s) {
  return typeof s.ns == "string" && (s.ns = [s.ns]), typeof s.fallbackLng == "string" && (s.fallbackLng = [s.fallbackLng]), typeof s.fallbackNS == "string" && (s.fallbackNS = [s.fallbackNS]), s.supportedLngs && s.supportedLngs.indexOf("cimode") < 0 && (s.supportedLngs = s.supportedLngs.concat(["cimode"])), s;
}
function W() {
}
function nt(s) {
  Object.getOwnPropertyNames(Object.getPrototypeOf(s)).forEach((t) => {
    typeof s[t] == "function" && (s[t] = s[t].bind(s));
  });
}
class V extends Q {
  constructor() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = arguments.length > 1 ? arguments[1] : void 0;
    if (super(), this.options = de(e), this.services = {}, this.logger = P, this.modules = {
      external: []
    }, nt(this), t && !this.isInitialized && !e.isClone) {
      if (!this.options.initImmediate)
        return this.init(e, t), this;
      setTimeout(() => {
        this.init(e, t);
      }, 0);
    }
  }
  init() {
    var e = this;
    let t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, n = arguments.length > 1 ? arguments[1] : void 0;
    this.isInitializing = !0, typeof t == "function" && (n = t, t = {}), !t.defaultNS && t.defaultNS !== !1 && t.ns && (typeof t.ns == "string" ? t.defaultNS = t.ns : t.ns.indexOf("translation") < 0 && (t.defaultNS = t.ns[0]));
    const r = fe();
    this.options = {
      ...r,
      ...this.options,
      ...de(t)
    }, this.options.compatibilityAPI !== "v1" && (this.options.interpolation = {
      ...r.interpolation,
      ...this.options.interpolation
    }), t.keySeparator !== void 0 && (this.options.userDefinedKeySeparator = t.keySeparator), t.nsSeparator !== void 0 && (this.options.userDefinedNsSeparator = t.nsSeparator);
    function i(d) {
      return d ? typeof d == "function" ? new d() : d : null;
    }
    if (!this.options.isClone) {
      this.modules.logger ? P.init(i(this.modules.logger), this.options) : P.init(null, this.options);
      let d;
      this.modules.formatter ? d = this.modules.formatter : typeof Intl < "u" && (d = _e);
      const c = new oe(this.options);
      this.store = new ie(this.options.resources, this.options);
      const f = this.services;
      f.logger = P, f.resourceStore = this.store, f.languageUtils = c, f.pluralResolver = new Xe(c, {
        prepend: this.options.pluralSeparator,
        compatibilityJSON: this.options.compatibilityJSON,
        simplifyPluralSuffix: this.options.simplifyPluralSuffix
      }), d && (!this.options.interpolation.format || this.options.interpolation.format === r.interpolation.format) && (f.formatter = i(d), f.formatter.init(f, this.options), this.options.interpolation.format = f.formatter.format.bind(f.formatter)), f.interpolator = new Ge(this.options), f.utils = {
        hasLoadedNamespace: this.hasLoadedNamespace.bind(this)
      }, f.backendConnector = new tt(i(this.modules.backend), f.resourceStore, f, this.options), f.backendConnector.on("*", function(g) {
        for (var h = arguments.length, b = new Array(h > 1 ? h - 1 : 0), m = 1; m < h; m++)
          b[m - 1] = arguments[m];
        e.emit(g, ...b);
      }), this.modules.languageDetector && (f.languageDetector = i(this.modules.languageDetector), f.languageDetector.init && f.languageDetector.init(f, this.options.detection, this.options)), this.modules.i18nFormat && (f.i18nFormat = i(this.modules.i18nFormat), f.i18nFormat.init && f.i18nFormat.init(this)), this.translator = new B(this.services, this.options), this.translator.on("*", function(g) {
        for (var h = arguments.length, b = new Array(h > 1 ? h - 1 : 0), m = 1; m < h; m++)
          b[m - 1] = arguments[m];
        e.emit(g, ...b);
      }), this.modules.external.forEach((g) => {
        g.init && g.init(this);
      });
    }
    if (this.format = this.options.interpolation.format, n || (n = W), this.options.fallbackLng && !this.services.languageDetector && !this.options.lng) {
      const d = this.services.languageUtils.getFallbackCodes(this.options.fallbackLng);
      d.length > 0 && d[0] !== "dev" && (this.options.lng = d[0]);
    }
    !this.services.languageDetector && !this.options.lng && this.logger.warn("init: no languageDetector is used and no lng is defined"), ["getResource", "hasResourceBundle", "getResourceBundle", "getDataByLanguage"].forEach((d) => {
      this[d] = function() {
        return e.store[d](...arguments);
      };
    }), ["addResource", "addResources", "addResourceBundle", "removeResourceBundle"].forEach((d) => {
      this[d] = function() {
        return e.store[d](...arguments), e;
      };
    });
    const u = M(), l = () => {
      const d = (c, f) => {
        this.isInitializing = !1, this.isInitialized && !this.initializedStoreOnce && this.logger.warn("init: i18next is already initialized. You should call init just once!"), this.isInitialized = !0, this.options.isClone || this.logger.log("initialized", this.options), this.emit("initialized", this.options), u.resolve(f), n(c, f);
      };
      if (this.languages && this.options.compatibilityAPI !== "v1" && !this.isInitialized) return d(null, this.t.bind(this));
      this.changeLanguage(this.options.lng, d);
    };
    return this.options.resources || !this.options.initImmediate ? l() : setTimeout(l, 0), u;
  }
  loadResources(e) {
    let n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : W;
    const r = typeof e == "string" ? e : this.language;
    if (typeof e == "function" && (n = e), !this.options.resources || this.options.partialBundledLanguages) {
      if (r && r.toLowerCase() === "cimode" && (!this.options.preload || this.options.preload.length === 0)) return n();
      const i = [], a = (o) => {
        if (!o || o === "cimode") return;
        this.services.languageUtils.toResolveHierarchy(o).forEach((l) => {
          l !== "cimode" && i.indexOf(l) < 0 && i.push(l);
        });
      };
      r ? a(r) : this.services.languageUtils.getFallbackCodes(this.options.fallbackLng).forEach((u) => a(u)), this.options.preload && this.options.preload.forEach((o) => a(o)), this.services.backendConnector.load(i, this.options.ns, (o) => {
        !o && !this.resolvedLanguage && this.language && this.setResolvedLanguage(this.language), n(o);
      });
    } else
      n(null);
  }
  reloadResources(e, t, n) {
    const r = M();
    return e || (e = this.languages), t || (t = this.options.ns), n || (n = W), this.services.backendConnector.reload(e, t, (i) => {
      r.resolve(), n(i);
    }), r;
  }
  use(e) {
    if (!e) throw new Error("You are passing an undefined module! Please check the object you are passing to i18next.use()");
    if (!e.type) throw new Error("You are passing a wrong module! Please check the object you are passing to i18next.use()");
    return e.type === "backend" && (this.modules.backend = e), (e.type === "logger" || e.log && e.warn && e.error) && (this.modules.logger = e), e.type === "languageDetector" && (this.modules.languageDetector = e), e.type === "i18nFormat" && (this.modules.i18nFormat = e), e.type === "postProcessor" && be.addPostProcessor(e), e.type === "formatter" && (this.modules.formatter = e), e.type === "3rdParty" && this.modules.external.push(e), this;
  }
  setResolvedLanguage(e) {
    if (!(!e || !this.languages) && !(["cimode", "dev"].indexOf(e) > -1))
      for (let t = 0; t < this.languages.length; t++) {
        const n = this.languages[t];
        if (!(["cimode", "dev"].indexOf(n) > -1) && this.store.hasLanguageSomeTranslations(n)) {
          this.resolvedLanguage = n;
          break;
        }
      }
  }
  changeLanguage(e, t) {
    var n = this;
    this.isLanguageChangingTo = e;
    const r = M();
    this.emit("languageChanging", e);
    const i = (u) => {
      this.language = u, this.languages = this.services.languageUtils.toResolveHierarchy(u), this.resolvedLanguage = void 0, this.setResolvedLanguage(u);
    }, a = (u, l) => {
      l ? (i(l), this.translator.changeLanguage(l), this.isLanguageChangingTo = void 0, this.emit("languageChanged", l), this.logger.log("languageChanged", l)) : this.isLanguageChangingTo = void 0, r.resolve(function() {
        return n.t(...arguments);
      }), t && t(u, function() {
        return n.t(...arguments);
      });
    }, o = (u) => {
      !e && !u && this.services.languageDetector && (u = []);
      const l = typeof u == "string" ? u : this.services.languageUtils.getBestMatchFromCodes(u);
      l && (this.language || i(l), this.translator.language || this.translator.changeLanguage(l), this.services.languageDetector && this.services.languageDetector.cacheUserLanguage && this.services.languageDetector.cacheUserLanguage(l)), this.loadResources(l, (d) => {
        a(d, l);
      });
    };
    return !e && this.services.languageDetector && !this.services.languageDetector.async ? o(this.services.languageDetector.detect()) : !e && this.services.languageDetector && this.services.languageDetector.async ? this.services.languageDetector.detect.length === 0 ? this.services.languageDetector.detect().then(o) : this.services.languageDetector.detect(o) : o(e), r;
  }
  getFixedT(e, t, n) {
    var r = this;
    const i = function(a, o) {
      let u;
      if (typeof o != "object") {
        for (var l = arguments.length, d = new Array(l > 2 ? l - 2 : 0), c = 2; c < l; c++)
          d[c - 2] = arguments[c];
        u = r.options.overloadTranslationOptionHandler([a, o].concat(d));
      } else
        u = {
          ...o
        };
      u.lng = u.lng || i.lng, u.lngs = u.lngs || i.lngs, u.ns = u.ns || i.ns, u.keyPrefix = u.keyPrefix || n || i.keyPrefix;
      const f = r.options.keySeparator || ".";
      let g;
      return u.keyPrefix && Array.isArray(a) ? g = a.map((h) => `${u.keyPrefix}${f}${h}`) : g = u.keyPrefix ? `${u.keyPrefix}${f}${a}` : a, r.t(g, u);
    };
    return typeof e == "string" ? i.lng = e : i.lngs = e, i.ns = t, i.keyPrefix = n, i;
  }
  t() {
    return this.translator && this.translator.translate(...arguments);
  }
  exists() {
    return this.translator && this.translator.exists(...arguments);
  }
  setDefaultNamespace(e) {
    this.options.defaultNS = e;
  }
  hasLoadedNamespace(e) {
    let t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (!this.isInitialized)
      return this.logger.warn("hasLoadedNamespace: i18next was not initialized", this.languages), !1;
    if (!this.languages || !this.languages.length)
      return this.logger.warn("hasLoadedNamespace: i18n.languages were undefined or empty", this.languages), !1;
    const n = t.lng || this.resolvedLanguage || this.languages[0], r = this.options ? this.options.fallbackLng : !1, i = this.languages[this.languages.length - 1];
    if (n.toLowerCase() === "cimode") return !0;
    const a = (o, u) => {
      const l = this.services.backendConnector.state[`${o}|${u}`];
      return l === -1 || l === 2;
    };
    if (t.precheck) {
      const o = t.precheck(this, a);
      if (o !== void 0) return o;
    }
    return !!(this.hasResourceBundle(n, e) || !this.services.backendConnector.backend || this.options.resources && !this.options.partialBundledLanguages || a(n, e) && (!r || a(i, e)));
  }
  loadNamespaces(e, t) {
    const n = M();
    return this.options.ns ? (typeof e == "string" && (e = [e]), e.forEach((r) => {
      this.options.ns.indexOf(r) < 0 && this.options.ns.push(r);
    }), this.loadResources((r) => {
      n.resolve(), t && t(r);
    }), n) : (t && t(), Promise.resolve());
  }
  loadLanguages(e, t) {
    const n = M();
    typeof e == "string" && (e = [e]);
    const r = this.options.preload || [], i = e.filter((a) => r.indexOf(a) < 0 && this.services.languageUtils.isSupportedCode(a));
    return i.length ? (this.options.preload = r.concat(i), this.loadResources((a) => {
      n.resolve(), t && t(a);
    }), n) : (t && t(), Promise.resolve());
  }
  dir(e) {
    if (e || (e = this.resolvedLanguage || (this.languages && this.languages.length > 0 ? this.languages[0] : this.language)), !e) return "rtl";
    const t = ["ar", "shu", "sqr", "ssh", "xaa", "yhd", "yud", "aao", "abh", "abv", "acm", "acq", "acw", "acx", "acy", "adf", "ads", "aeb", "aec", "afb", "ajp", "apc", "apd", "arb", "arq", "ars", "ary", "arz", "auz", "avl", "ayh", "ayl", "ayn", "ayp", "bbz", "pga", "he", "iw", "ps", "pbt", "pbu", "pst", "prp", "prd", "ug", "ur", "ydd", "yds", "yih", "ji", "yi", "hbo", "men", "xmn", "fa", "jpr", "peo", "pes", "prs", "dv", "sam", "ckb"], n = this.services && this.services.languageUtils || new oe(fe());
    return t.indexOf(n.getLanguagePartFromCode(e)) > -1 || e.toLowerCase().indexOf("-arab") > 1 ? "rtl" : "ltr";
  }
  static createInstance() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = arguments.length > 1 ? arguments[1] : void 0;
    return new V(e, t);
  }
  cloneInstance() {
    let e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : W;
    const n = e.forkResourceStore;
    n && delete e.forkResourceStore;
    const r = {
      ...this.options,
      ...e,
      isClone: !0
    }, i = new V(r);
    return (e.debug !== void 0 || e.prefix !== void 0) && (i.logger = i.logger.clone(e)), ["store", "services", "language"].forEach((o) => {
      i[o] = this[o];
    }), i.services = {
      ...this.services
    }, i.services.utils = {
      hasLoadedNamespace: i.hasLoadedNamespace.bind(i)
    }, n && (i.store = new ie(this.store.data, r), i.services.resourceStore = i.store), i.translator = new B(i.services, r), i.translator.on("*", function(o) {
      for (var u = arguments.length, l = new Array(u > 1 ? u - 1 : 0), d = 1; d < u; d++)
        l[d - 1] = arguments[d];
      i.emit(o, ...l);
    }), i.init(r, t), i.translator.options = r, i.translator.backendConnector.services.utils = {
      hasLoadedNamespace: i.hasLoadedNamespace.bind(i)
    }, i;
  }
  toJSON() {
    return {
      options: this.options,
      store: this.store,
      language: this.language,
      languages: this.languages,
      resolvedLanguage: this.resolvedLanguage
    };
  }
}
const L = V.createInstance();
L.createInstance = V.createInstance;
L.createInstance;
L.dir;
L.init;
L.loadResources;
L.reloadResources;
L.use;
L.changeLanguage;
L.getFixedT;
L.t;
L.exists;
L.setDefaultNamespace;
L.hasLoadedNamespace;
L.loadNamespaces;
L.loadLanguages;
function q(s) {
  return function() {
    var e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = e.width ? String(e.width) : s.defaultWidth, n = s.formats[t] || s.formats[s.defaultWidth];
    return n;
  };
}
function A(s) {
  return function(e, t) {
    var n = t != null && t.context ? String(t.context) : "standalone", r;
    if (n === "formatting" && s.formattingValues) {
      var i = s.defaultFormattingWidth || s.defaultWidth, a = t != null && t.width ? String(t.width) : i;
      r = s.formattingValues[a] || s.formattingValues[i];
    } else {
      var o = s.defaultWidth, u = t != null && t.width ? String(t.width) : s.defaultWidth;
      r = s.values[u] || s.values[o];
    }
    var l = s.argumentCallback ? s.argumentCallback(e) : e;
    return r[l];
  };
}
function T(s) {
  return function(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = t.width, r = n && s.matchPatterns[n] || s.matchPatterns[s.defaultMatchWidth], i = e.match(r);
    if (!i)
      return null;
    var a = i[0], o = n && s.parsePatterns[n] || s.parsePatterns[s.defaultParseWidth], u = Array.isArray(o) ? rt(o, function(c) {
      return c.test(a);
    }) : st(o, function(c) {
      return c.test(a);
    }), l;
    l = s.valueCallback ? s.valueCallback(u) : u, l = t.valueCallback ? t.valueCallback(l) : l;
    var d = e.slice(a.length);
    return {
      value: l,
      rest: d
    };
  };
}
function st(s, e) {
  for (var t in s)
    if (s.hasOwnProperty(t) && e(s[t]))
      return t;
}
function rt(s, e) {
  for (var t = 0; t < s.length; t++)
    if (e(s[t]))
      return t;
}
function it(s) {
  return function(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = e.match(s.matchPattern);
    if (!n) return null;
    var r = n[0], i = e.match(s.parsePattern);
    if (!i) return null;
    var a = s.valueCallback ? s.valueCallback(i[0]) : i[0];
    a = t.valueCallback ? t.valueCallback(a) : a;
    var o = e.slice(r.length);
    return {
      value: a,
      rest: o
    };
  };
}
var at = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, ot = function(e, t, n) {
  var r, i = at[e];
  return typeof i == "string" ? r = i : t === 1 ? r = i.one : r = i.other.replace("{{count}}", t.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + r : r + " ago" : r;
}, ut = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, lt = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, ft = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, dt = {
  date: q({
    formats: ut,
    defaultWidth: "full"
  }),
  time: q({
    formats: lt,
    defaultWidth: "full"
  }),
  dateTime: q({
    formats: ft,
    defaultWidth: "full"
  })
}, ct = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, ht = function(e, t, n, r) {
  return ct[e];
}, gt = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, pt = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, mt = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}, bt = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}, yt = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, vt = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, xt = function(e, t) {
  var n = Number(e), r = n % 100;
  if (r > 20 || r < 10)
    switch (r % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, St = {
  ordinalNumber: xt,
  era: A({
    values: gt,
    defaultWidth: "wide"
  }),
  quarter: A({
    values: pt,
    defaultWidth: "wide",
    argumentCallback: function(e) {
      return e - 1;
    }
  }),
  month: A({
    values: mt,
    defaultWidth: "wide"
  }),
  day: A({
    values: bt,
    defaultWidth: "wide"
  }),
  dayPeriod: A({
    values: yt,
    defaultWidth: "wide",
    formattingValues: vt,
    defaultFormattingWidth: "wide"
  })
}, wt = /^(\d+)(th|st|nd|rd)?/i, Lt = /\d+/i, Ot = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, Nt = {
  any: [/^b/i, /^(a|c)/i]
}, Pt = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, Ct = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Rt = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, kt = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
}, $t = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Ft = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Et = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, jt = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, It = {
  ordinalNumber: it({
    matchPattern: wt,
    parsePattern: Lt,
    valueCallback: function(e) {
      return parseInt(e, 10);
    }
  }),
  era: T({
    matchPatterns: Ot,
    defaultMatchWidth: "wide",
    parsePatterns: Nt,
    defaultParseWidth: "any"
  }),
  quarter: T({
    matchPatterns: Pt,
    defaultMatchWidth: "wide",
    parsePatterns: Ct,
    defaultParseWidth: "any",
    valueCallback: function(e) {
      return e + 1;
    }
  }),
  month: T({
    matchPatterns: Rt,
    defaultMatchWidth: "wide",
    parsePatterns: kt,
    defaultParseWidth: "any"
  }),
  day: T({
    matchPatterns: $t,
    defaultMatchWidth: "wide",
    parsePatterns: Ft,
    defaultParseWidth: "any"
  }),
  dayPeriod: T({
    matchPatterns: Et,
    defaultMatchWidth: "any",
    parsePatterns: jt,
    defaultParseWidth: "any"
  })
}, Mt = {
  code: "en-US",
  formatDistance: ot,
  formatLong: dt,
  formatRelative: ht,
  localize: St,
  match: It,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
const ye = {
  "en-US": Mt
};
function At(s, e) {
  const { formatKey: t } = e, n = ye[s ?? "en-US"];
  return (r) => Pe(r, t, { locale: n });
}
function Tt(s, e) {
  const t = (e == null ? void 0 : e.baseDate) ?? /* @__PURE__ */ new Date();
  return (n) => {
    const r = ye[s ?? "en-US"];
    return Ce(n, t, { locale: r });
  };
}
const Dt = [
  {
    name: "dateFormatter",
    formatter: At
  },
  {
    name: "relativeDateFormatter",
    formatter: Tt
  }
];
function Vt() {
  if (console && console.warn) {
    for (var s = arguments.length, e = new Array(s), t = 0; t < s; t++)
      e[t] = arguments[t];
    R(e[0]) && (e[0] = `react-i18next:: ${e[0]}`), console.warn(...e);
  }
}
const ce = {};
function Z() {
  for (var s = arguments.length, e = new Array(s), t = 0; t < s; t++)
    e[t] = arguments[t];
  R(e[0]) && ce[e[0]] || (R(e[0]) && (ce[e[0]] = /* @__PURE__ */ new Date()), Vt(...e));
}
const ve = (s, e) => () => {
  if (s.isInitialized)
    e();
  else {
    const t = () => {
      setTimeout(() => {
        s.off("initialized", t);
      }, 0), e();
    };
    s.on("initialized", t);
  }
}, he = (s, e, t) => {
  s.loadNamespaces(e, ve(s, t));
}, ge = (s, e, t, n) => {
  R(t) && (t = [t]), t.forEach((r) => {
    s.options.ns.indexOf(r) < 0 && s.options.ns.push(r);
  }), s.loadLanguages(e, ve(s, n));
}, Ut = function(s, e) {
  let t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  const n = e.languages[0], r = e.options ? e.options.fallbackLng : !1, i = e.languages[e.languages.length - 1];
  if (n.toLowerCase() === "cimode") return !0;
  const a = (o, u) => {
    const l = e.services.backendConnector.state[`${o}|${u}`];
    return l === -1 || l === 2;
  };
  return t.bindI18n && t.bindI18n.indexOf("languageChanging") > -1 && e.services.backendConnector.backend && e.isLanguageChangingTo && !a(e.isLanguageChangingTo, s) ? !1 : !!(e.hasResourceBundle(n, s) || !e.services.backendConnector.backend || e.options.resources && !e.options.partialBundledLanguages || a(n, s) && (!r || a(i, s)));
}, Kt = function(s, e) {
  let t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  return !e.languages || !e.languages.length ? (Z("i18n.languages were undefined or empty", e.languages), !0) : e.options.ignoreJSONStructure !== void 0 ? e.hasLoadedNamespace(s, {
    lng: t.lng,
    precheck: (r, i) => {
      if (t.bindI18n && t.bindI18n.indexOf("languageChanging") > -1 && r.services.backendConnector.backend && r.isLanguageChangingTo && !i(r.isLanguageChangingTo, s)) return !1;
    }
  }) : Ut(s, e, t);
}, R = (s) => typeof s == "string", Wt = (s) => typeof s == "object" && s !== null, zt = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34|nbsp|#160|copy|#169|reg|#174|hellip|#8230|#x2F|#47);/g, Ht = {
  "&amp;": "&",
  "&#38;": "&",
  "&lt;": "<",
  "&#60;": "<",
  "&gt;": ">",
  "&#62;": ">",
  "&apos;": "'",
  "&#39;": "'",
  "&quot;": '"',
  "&#34;": '"',
  "&nbsp;": " ",
  "&#160;": " ",
  "&copy;": "©",
  "&#169;": "©",
  "&reg;": "®",
  "&#174;": "®",
  "&hellip;": "…",
  "&#8230;": "…",
  "&#x2F;": "/",
  "&#47;": "/"
}, Jt = (s) => Ht[s], Bt = (s) => s.replace(zt, Jt);
let Qt = {
  bindI18n: "languageChanged",
  bindI18nStore: "",
  transEmptyNodeValue: "",
  transSupportBasicHtmlNodes: !0,
  transWrapTextNodes: "",
  transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
  useSuspense: !0,
  unescape: Bt
};
const Yt = () => Qt;
let qt;
const Xt = () => qt, xe = Re();
class Gt {
  constructor() {
    ne(this, "getUsedNamespaces", () => Object.keys(this.usedNamespaces));
    this.usedNamespaces = {};
  }
  addUsedNamespaces(e) {
    e.forEach((t) => {
      this.usedNamespaces[t] || (this.usedNamespaces[t] = !0);
    });
  }
}
const Zt = (s, e) => {
  const t = pe();
  return X(() => {
    t.current = s;
  }, [s, e]), t.current;
}, Se = (s, e, t, n) => s.getFixedT(e, t, n), _t = (s, e, t, n) => Fe(Se(s, e, t, n), [s, e, t, n]), an = function(s) {
  let e = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
  const {
    i18n: t
  } = e, {
    i18n: n,
    defaultNS: r
  } = ke(xe) || {}, i = t || n || Xt();
  if (i && !i.reportNamespaces && (i.reportNamespaces = new Gt()), !i) {
    Z("You will need to pass in an i18next instance by using initReactI18next");
    const p = (S, v) => R(v) ? v : Wt(v) && R(v.defaultValue) ? v.defaultValue : Array.isArray(S) ? S[S.length - 1] : S, x = [p, {}, !1];
    return x.t = p, x.i18n = {}, x.ready = !1, x;
  }
  i.options.react && i.options.react.wait !== void 0 && Z("It seems you are still using the old wait option, you may migrate to the new useSuspense behaviour.");
  const a = {
    ...Yt(),
    ...i.options.react,
    ...e
  }, {
    useSuspense: o,
    keyPrefix: u
  } = a;
  let l = s || r || i.options && i.options.defaultNS;
  l = R(l) ? [l] : l || ["translation"], i.reportNamespaces.addUsedNamespaces && i.reportNamespaces.addUsedNamespaces(l);
  const d = (i.isInitialized || i.initializedStoreOnce) && l.every((p) => Kt(p, i, a)), c = _t(i, e.lng || null, a.nsMode === "fallback" ? l : l[0], u), f = () => c, g = () => Se(i, e.lng || null, a.nsMode === "fallback" ? l : l[0], u), [h, b] = $e(f);
  let m = l.join();
  e.lng && (m = `${e.lng}${m}`);
  const w = Zt(m), y = pe(!0);
  X(() => {
    const {
      bindI18n: p,
      bindI18nStore: x
    } = a;
    y.current = !0, !d && !o && (e.lng ? ge(i, e.lng, l, () => {
      y.current && b(g);
    }) : he(i, l, () => {
      y.current && b(g);
    })), d && w && w !== m && y.current && b(g);
    const S = () => {
      y.current && b(g);
    };
    return p && i && i.on(p, S), x && i && i.store.on(x, S), () => {
      y.current = !1, p && i && p.split(" ").forEach((v) => i.off(v, S)), x && i && x.split(" ").forEach((v) => i.store.off(v, S));
    };
  }, [i, m]), X(() => {
    y.current && d && b(f);
  }, [i, u, d]);
  const N = [h, i, d];
  if (N.t = h, N.i18n = i, N.ready = d, d || !d && !o) return N;
  throw new Promise((p) => {
    e.lng ? ge(i, e.lng, l, () => p()) : he(i, l, () => p());
  });
};
function on(s) {
  let {
    i18n: e,
    defaultNS: t,
    children: n
  } = s;
  const r = Ee(() => ({
    i18n: e,
    defaultNS: t
  }), [e, t]);
  return je(xe.Provider, {
    value: r
  }, n);
}
const en = {
  fallbackLng: ["en-US"],
  // Fallback to English if a translation is missing
  lng: "en-US",
  // Set the default language
  defaultNS: "translation",
  debug: !1
};
function un(s) {
  const e = {
    ...en,
    ...s
  };
  return L.init(e), Dt.forEach(({ name: t, formatter: n }) => {
    var r;
    (r = L.services.formatter) == null || r.addCached(t, n);
  }), L;
}
export {
  on as I18nextProvider,
  un as initI18n,
  an as useTranslation
};
