import { fipsMap } from "@/consts/fips";

export function getFips({
  stateCode,
  county,
}: {
  county?: string;
  stateCode?: string;
}) {
  return (
    stateCode &&
    county &&
    fipsMap[stateCode.toUpperCase()]?.[county.toLowerCase()]
  );
}
