import type { FunnelMeta } from "@/modules/v2/funnel/config";
import { FUNNEL_META_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData, setData } from "@/services/storage/session-storage";

/**
 * This hook is used to get and set the funnel meta in the session storage
 */

export function useFunnelMeta() {
  function setFunnelMeta(data: FunnelMeta) {
    setData(FUNNEL_META_SESSION_STORAGE_KEY, data);
  }

  function getFunnelMeta() {
    const funnelMeta =
      (getData(FUNNEL_META_SESSION_STORAGE_KEY) as FunnelMeta) || {};

    return funnelMeta;
  }

  return { getFunnelMeta, setFunnelMeta };
}
