import { i18n } from "@/services/locale/i18n";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import { Interlude } from "../../Interlude";
import { calculateLssQualification } from "../../visitor-checks/calculateLssQualification";
import { DELAY_MS } from "../config.const";
import type { SubPractices } from "../config.types";

import {
  immigrationBookLssScreens,
  immigrationEligibleForLssScreens,
  intakeConfirmationScreens,
} from "./common-immigration";

export const immigrationFianceVisa: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async ({ answers, experiments }) => {
        const { abTestImmigrationEligibility } = experiments;
        const { fianceRelationshipStatus } = answers;

        if (abTestImmigrationEligibility) {
          if (fianceRelationshipStatus === "Something else") {
            return "out-of-scope";
          }

          return "partner-interaction";
        }

        return "applicant-type";
      },
    },
    "applicant-type": {
      next: async () => "case-urgency",
    },
    "partner-interaction": {
      next: async () => "case-urgency",
    },
    "case-urgency": {
      next: async ({ experiments }) => {
        const {
          abTestImmigrationEligibility,
          abTestIsQualifiedForLssImmigration,
        } = experiments;

        if (abTestIsQualifiedForLssImmigration) {
          const isQualifiedForLss = await promiseWithMinimumDelay(
            calculateLssQualification(),
            DELAY_MS,
          );

          if (!isQualifiedForLss) {
            return "more-info";
          }

          return "sponsor-info";
        }

        return abTestImmigrationEligibility ? "more-info" : "funding-source";
      },
      renderInterlude: ({ experiments }) => {
        const { abTestIsQualifiedForLssImmigration } = experiments;
        return abTestIsQualifiedForLssImmigration ? (
          <Interlude
            heading={i18n.t("FUNNEL_INTERLUDE_JUST_A_FEW_MORE_MOMENTS")}
          />
        ) : null;
      },
    },
    "sponsor-info": {
      next: async () => "currently-represented",
    },

    ...immigrationEligibleForLssScreens,

    "funding-source": {
      next: async () => "beneficiary-region",
    },
    "beneficiary-region": {
      next: async () => "more-info",
    },

    ...intakeConfirmationScreens,
    ...immigrationBookLssScreens,

    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
