export type SeoComponent = {
  canonicalHref?: string;
  description?: string;
  image?: { filename?: string };
  noIndex?: boolean;
  title?: string;
};

export const DEFAULT_SEO: SeoComponent = {
  title: "Marble Law Firm",
  description: "",
  image: {
    filename: "",
  },
  noIndex: false,
  canonicalHref: "",
};
