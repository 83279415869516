import { cn } from "@flare/ui";

export type HintProps = React.HTMLAttributes<HTMLDivElement> & {
  error?: boolean;
};

export const Hint = ({ error, children, ...restProps }: HintProps) => {
  return (
    <div
      className={cn("text-xs font-normal", {
        "text-orange-100": error,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};
