import * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "@flare/ui";

import { ChevronLeft } from "@/components/icons/ChevronLeft";
import { ChevronRight } from "@/components/icons/ChevronRight";

type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("rounded-lg bg-white-80 p-2 font-sans", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center my-3",
        nav_button: "bg-transparent size-6 p-0 opacity-50 hover:opacity-100",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex gap-1 mb-1",
        head_cell: "rounded-md w-9 font-light text-[0.8rem] opacity-80",
        row: "flex gap-1 w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative focus-within:relative focus-within:z-20",
        day: "size-9 md:hover:bg-green-20 md:hover:rounded-full p-0 font-normal aria-selected:opacity-100 font-sans",
        day_selected:
          "bg-green-100 text-white-80 rounded-full hover:!bg-green-100 hover:!text-white-80 focus:bg-green-100 focus:text-white-80",
        day_outside: "text-blue-40 pointer-events-none",
        day_disabled: "text-blue-40 pointer-events-none",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft {...props} />,
        IconRight: ({ ...props }) => <ChevronRight {...props} />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
