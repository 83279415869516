import type { FunnelConfig } from "./config.types";
import { familyFlow } from "./family";
import { immigrationFlow } from "./immigration";

export * from "./config.const";
export * from "./config.types";

export const funnelConfig: FunnelConfig = {
  family: familyFlow,
  immigration: immigrationFlow,
};
