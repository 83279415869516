// Temporay hard coded beacuse breakpoints from marbletech/core dont match website
const breakpoints = {
  xs: 360,
  sm: 600,
  md: 960,
  lg: 1140,
  xl: 1920,
};

export default breakpoints;
