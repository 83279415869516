/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  JSON: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  Time: { input: any; output: any; }
  URL: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccountForAch = {
  __typename?: 'AccountForAch';
  accountId: Scalars['String']['output'];
  current?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  accountNumber?: Maybe<Scalars['String']['output']>;
  amountAvailable: Scalars['Int']['output'];
  amountUsed: Scalars['Int']['output'];
  lender: Scalars['String']['output'];
};

export type AccountsForAchInput = {
  externalId: Scalars['String']['input'];
};

export type AchData = PaymentMethod & {
  __typename?: 'AchData';
  bankName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export enum ActionType {
  Idle = 'IDLE',
  Navigation = 'NAVIGATION'
}

export type ActivateLoanInput = {
  accountId: Scalars['String']['input'];
  loanId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type AddCaseEventInput = {
  arbitraryLocation?: InputMaybe<Scalars['String']['input']>;
  caseId: Scalars['ID']['input'];
  eventSubtype?: InputMaybe<CaseEventSubtype>;
  eventType: CaseEventType;
  externalId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<CaseEventLocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  occursAt: Scalars['DateTime']['input'];
  occursAtTime?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<CaseEventSource>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  virtualLocation?: InputMaybe<Scalars['URL']['input']>;
};

export type AddCaseTaskInput = {
  caseId: Scalars['String']['input'];
  caseStage: StageEnum;
  dueDate: Scalars['Date']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  ownerType: CaseTaskOwnerType;
  status: CaseTaskStatusInput;
  title: Scalars['String']['input'];
};

export type AddDocumentClassificationPayload = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  documentClassificationName: Scalars['String']['input'];
  newCategoryName?: InputMaybe<Scalars['String']['input']>;
};

export type AddPaymentInput = {
  amountDue?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  setupIntentId: Scalars['String']['input'];
  tryChargeLss?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddRequiredDocumentPayload = {
  classificationId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  legalServiceExternalId?: InputMaybe<Scalars['String']['input']>;
};

export type Addendum = PayoutItem & {
  __typename?: 'Addendum';
  amount: Scalars['Float']['output'];
  amountTotal: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  numOfPayments: Scalars['Int']['output'];
  opportunityExternalId: Scalars['String']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type Adjustment = PayoutItem & {
  __typename?: 'Adjustment';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type Admin = {
  __typename?: 'Admin';
  alternativeEmails?: Maybe<Array<Maybe<Scalars['EmailAddress']['output']>>>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  name: Name;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  unreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
};


export type AdminNotificationsArgs = {
  filter: CustomerNotificationInput;
  orderBy?: InputMaybe<NotificationsOrderByInput>;
};

export enum AdminOnboardingStatus {
  Converted = 'CONVERTED',
  Pending = 'PENDING'
}

export type AffirmProviderEventInput = {
  accountFirstName?: InputMaybe<Scalars['String']['input']>;
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  agentPhone?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  checkoutId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  requestedAmount?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type: AffirmType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum AffirmType {
  Cancel = 'cancel',
  CheckoutCreated = 'checkout_created'
}

export enum App {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export enum AppClients {
  MyMarbleApp = 'myMarbleApp'
}

export type AppConfigurations = {
  __typename?: 'AppConfigurations';
  client?: Maybe<Scalars['String']['output']>;
  configurations?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  platform: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AppInstallation = {
  __typename?: 'AppInstallation';
  appBundleId: Scalars['String']['output'];
  appVersion: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deviceBrand: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  deviceModel: Scalars['String']['output'];
  deviceOS: Scalars['String']['output'];
  deviceOSVersion: Scalars['String']['output'];
  deviceType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notificationsAllowed?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type AppInstallationInput = {
  appBundleId: Scalars['String']['input'];
  appVersion: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  deviceBrand: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  deviceModel: Scalars['String']['input'];
  deviceOS: Scalars['String']['input'];
  deviceOSVersion: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Attorney = {
  __typename?: 'Attorney';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  attorneyType?: Maybe<AttorneyType>;
  barLicenseExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  barLicenseId?: Maybe<Scalars['String']['output']>;
  barNumber?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cafNumber?: Maybe<Scalars['String']['output']>;
  calendlyEmail?: Maybe<Scalars['EmailAddress']['output']>;
  calendlyLink?: Maybe<Scalars['String']['output']>;
  case?: Maybe<Case>;
  caseCapacity: Scalars['Int']['output'];
  cases?: Maybe<Array<Maybe<Case>>>;
  casesOffLimits?: Maybe<Array<Maybe<CasesOffLimits>>>;
  casesOffLimitsOther?: Maybe<Scalars['String']['output']>;
  conservatorship: Scalars['Boolean']['output'];
  contestedDivorce: Scalars['Boolean']['output'];
  contractLink?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  earning?: Maybe<Earning>;
  earnings: Array<Maybe<Earning>>;
  education?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['EmailAddress']['output'];
  familyCourtNY?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Gender>;
  guardianship: Scalars['Boolean']['output'];
  handlesCases: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['URL']['output']>;
  inHouse: Scalars['Boolean']['output'];
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  juvenileCrime: Scalars['Boolean']['output'];
  languages: Array<Language>;
  licensingJurisdiction?: Maybe<Scalars['String']['output']>;
  litigation: Scalars['Boolean']['output'];
  locations?: Maybe<Scalars['JSON']['output']>;
  lssCase?: Maybe<Case>;
  lssCases?: Maybe<Array<Maybe<Case>>>;
  lssCasesByView?: Maybe<Array<Maybe<Case>>>;
  marbleId: Scalars['String']['output'];
  matrimonialCourtNY?: Maybe<Scalars['Boolean']['output']>;
  name: Name;
  numberOfActiveCases?: Maybe<Scalars['Int']['output']>;
  numberOfAssociates?: Maybe<Scalars['Int']['output']>;
  numberOfLegalSecretaries?: Maybe<Scalars['Int']['output']>;
  numberOfParalegals?: Maybe<Scalars['Int']['output']>;
  pendingEarnings?: Maybe<Earning>;
  performsLSS: Scalars['Boolean']['output'];
  phones: Phones;
  practiceAreas: Array<PracticeArea>;
  prenup: Scalars['Boolean']['output'];
  ptin?: Maybe<Scalars['String']['output']>;
  salesforceId: Scalars['String']['output'];
  shortDeadline: Scalars['Boolean']['output'];
  toggleHandlesCasesAt?: Maybe<Scalars['Date']['output']>;
  togglePerformsLSSAt?: Maybe<Scalars['Date']['output']>;
  totalEarnings: Scalars['Float']['output'];
};


export type AttorneyCaseArgs = {
  id: Scalars['ID']['input'];
};


export type AttorneyEarningArgs = {
  billingDate: Scalars['Date']['input'];
};


export type AttorneyLssCaseArgs = {
  id: Scalars['ID']['input'];
};


export type AttorneyLssCasesByViewArgs = {
  input: LssCasesByViewInput;
};

export enum AttorneyType {
  CaseAttorney = 'CASE_ATTORNEY',
  LegalPartner = 'LEGAL_PARTNER',
  LssAttorney = 'LSS_ATTORNEY',
  ManagingAttorney = 'MANAGING_ATTORNEY'
}

export type AuthorizeTransactionPayload = {
  checkoutId: Scalars['String']['input'];
};

export type Autopay = {
  __typename?: 'Autopay';
  agreementDocumentId: Scalars['String']['output'];
  paymentInstrumentId: Scalars['String']['output'];
};

export type BatchCreatePreAuthLinkResponse = {
  __typename?: 'BatchCreatePreAuthLinkResponse';
  customer?: Maybe<Customer>;
  link?: Maybe<Scalars['String']['output']>;
};

export type BatchDeletePactsafeContractsInput = {
  apiKey: Scalars['String']['input'];
  contractIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type BillingCycle = {
  everyTwoWeeks?: InputMaybe<EveryTwoWeeks>;
  monthly?: InputMaybe<Scalars['Int']['input']>;
  singleTerm?: InputMaybe<Scalars['String']['input']>;
  twiceMonthly?: InputMaybe<TwiceMonthly>;
  type: PaymentFrequency;
  weekly?: InputMaybe<Scalars['Int']['input']>;
};

export type BillingCycleOutput = {
  __typename?: 'BillingCycleOutput';
  everyTwoWeeks?: Maybe<EveryTwoWeeksOutput>;
  monthly?: Maybe<Scalars['Int']['output']>;
  singleTerm?: Maybe<Scalars['String']['output']>;
  twiceMonthly?: Maybe<TwiceMonthlyOutput>;
  type: PaymentFrequency;
  weekly?: Maybe<Scalars['Int']['output']>;
};

export type Bonus = PayoutItem & {
  __typename?: 'Bonus';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type BraintreeAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type BraintreeCurrencyAmount = {
  currency?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BrazeCustomerInput = {
  properties: BrazeProperties;
  userId: Scalars['String']['input'];
};

export type BrazeMigrationInput = {
  pageSize: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type BrazeMigrationResponse = {
  __typename?: 'BrazeMigrationResponse';
  count: Scalars['Int']['output'];
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export enum BrazeNotification {
  BrazeCutomerLogin = 'BRAZE_CUTOMER_LOGIN',
  BrazeDiyWelcomeEvent = 'BRAZE_DIY_WELCOME_EVENT',
  BrazeLpNewCaseNotification = 'BRAZE_LP_NEW_CASE_NOTIFICATION',
  BrazePactsafeCustomerSignatureRequest = 'BRAZE_PACTSAFE_CUSTOMER_SIGNATURE_REQUEST',
  BrazePactsafeCustomerSigned = 'BRAZE_PACTSAFE_CUSTOMER_SIGNED',
  BrazePactsafeLpSignatureRequest = 'BRAZE_PACTSAFE_LP_SIGNATURE_REQUEST',
  BrazePactsafeLpSigned = 'BRAZE_PACTSAFE_LP_SIGNED',
  BrazePactsafeMarbleSignatureRequest = 'BRAZE_PACTSAFE_MARBLE_SIGNATURE_REQUEST',
  BrazePactsafeMarbleSigned = 'BRAZE_PACTSAFE_MARBLE_SIGNED',
  BrazePaymentReminder = 'BRAZE_PAYMENT_REMINDER',
  BrazeVerificationCode = 'BRAZE_VERIFICATION_CODE',
  CaseUpdate = 'CASE_UPDATE',
  CheddarFlareRicWelcome = 'CHEDDAR_FLARE_RIC_WELCOME',
  ClosedWonEvent = 'CLOSED_WON_EVENT',
  CourtDateCreated = 'COURT_DATE_CREATED',
  CourtDateReminder = 'COURT_DATE_REMINDER',
  LssMeetingDoneEvent = 'LSS_MEETING_DONE_EVENT',
  MeetYourLegalTeam = 'MEET_YOUR_LEGAL_TEAM',
  OnboardingReminder = 'ONBOARDING_REMINDER'
}

export type BrazeProperties = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export enum CalculatedRefundErrorCode {
  NoCaseFound = 'NoCaseFound',
  NoServiceClosureFound = 'NoServiceClosureFound',
  NoServicesForCaseFound = 'NoServicesForCaseFound'
}

export type CalendarAvailability = {
  __typename?: 'CalendarAvailability';
  eventType: Scalars['String']['output'];
  freeSlotsToday?: Maybe<Scalars['Int']['output']>;
  hoursThreshold?: Maybe<Scalars['Int']['output']>;
  isWithinThreshold: Scalars['Boolean']['output'];
  nextSpot: Scalars['String']['output'];
  timeLeft: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CalendlyEvent = {
  eventType: Scalars['String']['input'];
  hoursThreshold?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CallLog = {
  __typename?: 'CallLog';
  caseId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: CallLogCreatedBy;
  /** @deprecated Use utcDate instead */
  date?: Maybe<Scalars['Date']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notes: Scalars['String']['output'];
  outcome: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
  recordingUrl?: Maybe<Scalars['String']['output']>;
  rep: Scalars['String']['output'];
  /** @deprecated Use utcDate instead */
  time?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  touchpoint: TouchpointOptions;
  type: Scalars['String']['output'];
  utcDate: Scalars['DateTime']['output'];
};

export type CallLogCreatedBy = {
  __typename?: 'CallLogCreatedBy';
  adminId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CallLogInput = {
  caseId: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  outcome: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  recordingUrl?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  touchpoint: TouchpointOptions;
  type: Scalars['String']['input'];
  utcDate: Scalars['DateTime']['input'];
};

export enum CallLogSource {
  Os = 'OS'
}

export type CancelAutopayRequest = {
  paymentPlanId: Scalars['String']['input'];
};

export type CancelEventInput = {
  orderId: Scalars['String']['input'];
};

export type CancelLoanApplicationInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type CancelPaymentOnHoldInput = {
  paymentId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CancelScheduledEventInput = {
  eventId: Scalars['String']['input'];
  options?: InputMaybe<CancelScheduledEventsOptions>;
};

export type CancelScheduledEventsInput = {
  eventIds: Array<Scalars['String']['input']>;
  options?: InputMaybe<CancelScheduledEventsOptions>;
};

export type CancelScheduledEventsOptions = {
  cancelReason?: InputMaybe<Scalars['String']['input']>;
};

export type CancelScheduledEventsResponse = {
  __typename?: 'CancelScheduledEventsResponse';
  cancelledEvents?: Maybe<Array<Maybe<CancelledEvent>>>;
};

export type CancelledEvent = {
  __typename?: 'CancelledEvent';
  cancelled: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
};

export type CapturePaymentOnHoldInput = {
  paymentId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CaptureTransactionResponse = {
  __typename?: 'CaptureTransactionResponse';
  type?: Maybe<Scalars['String']['output']>;
};

export type Card = PaymentMethod & {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  funding: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  last4: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Case = {
  __typename?: 'Case';
  additionalExternalIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attorneyFee?: Maybe<Scalars['Float']['output']>;
  callLogs?: Maybe<Array<Maybe<CallLog>>>;
  /** @deprecated Not In use anymore, please use caseManagerInfo or caseManagerId instead */
  caseManager?: Maybe<Scalars['String']['output']>;
  caseManagerCalendlyLink?: Maybe<Scalars['String']['output']>;
  caseManagerId?: Maybe<Scalars['String']['output']>;
  caseManagerInfo?: Maybe<Admin>;
  /** @deprecated Not In use anymore, please use caseParalegal or caseParalegalId instead */
  caseParalegal?: Maybe<Scalars['String']['output']>;
  caseParalegalId?: Maybe<Scalars['String']['output']>;
  caseParalegalInfo?: Maybe<Admin>;
  caseReviewMeetingTime?: Maybe<Scalars['String']['output']>;
  changeLogs?: Maybe<Array<Maybe<ChangeLog>>>;
  clientPrice?: Maybe<Scalars['Float']['output']>;
  closeDate?: Maybe<Scalars['Date']['output']>;
  courtInfo?: Maybe<CourtInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  criticalDates?: Maybe<Array<CaseOfferCriticalDate>>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']['output']>;
  dispatchAgent?: Maybe<Admin>;
  documents: Array<Maybe<CaseDocument>>;
  engagementLetterSignUrl?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Maybe<CaseEvent>>>;
  externalCreatedAt?: Maybe<Scalars['Date']['output']>;
  externalId: Scalars['String']['output'];
  formFields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  intakeMeeting?: Maybe<IntakeMeeting>;
  isAttorneyCompletedStage: Scalars['Boolean']['output'];
  isIntroductionCallStage?: Maybe<Scalars['Boolean']['output']>;
  isLssAttorneyCompletedSummary?: Maybe<Scalars['Boolean']['output']>;
  isLssRejectedPcNoShow?: Maybe<Scalars['Boolean']['output']>;
  isUrgentDispatch?: Maybe<Scalars['Boolean']['output']>;
  lastCustomerContact?: Maybe<Scalars['Date']['output']>;
  latestLssSummary?: Maybe<LssSummary>;
  legalCircleMeetingTime?: Maybe<Scalars['String']['output']>;
  legalProductIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** @deprecated Replaced by legalServices field */
  legalProducts?: Maybe<Array<Maybe<CaseLegalProduct>>>;
  legalServices?: Maybe<Array<Maybe<CaseLegalService>>>;
  location?: Maybe<CaseLocation>;
  lssAttorney?: Maybe<Attorney>;
  lssAttorneyId?: Maybe<Scalars['String']['output']>;
  lssSummaries?: Maybe<Array<LssSummary>>;
  milestone?: Maybe<CaseMilestone>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Maybe<CaseNote>>>;
  opposingParty: OpposingParty;
  optionalServices?: Maybe<Array<Maybe<CaseService>>>;
  practiceArea?: Maybe<PracticeArea>;
  progress?: Maybe<Scalars['Int']['output']>;
  requiredDocuments: Array<Maybe<RequiredDocument>>;
  requiredDocumentsData?: Maybe<CaseRequiredDocumentsData>;
  responsibleAttorney?: Maybe<Attorney>;
  responsibleAttorneyId?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<Stage>;
  stageTasksInfo?: Maybe<CaseTasksInfo>;
  status?: Maybe<CaseStatus>;
  tasks?: Maybe<Array<Maybe<CaseTask>>>;
  type?: Maybe<CaseType>;
  updates?: Maybe<Array<Maybe<CaseUpdate>>>;
  whatIsNext?: Maybe<WhatIsNext>;
};


export type CaseEventsArgs = {
  filter?: InputMaybe<SpecificCaseEventsFilterInput>;
};

export type CaseCostTicketData = {
  __typename?: 'CaseCostTicketData';
  amount?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  typeOfFee?: Maybe<TypeOfFee>;
};

export type CaseDocument = ICaseDocument & {
  __typename?: 'CaseDocument';
  case?: Maybe<Case>;
  category: CaseDocumentCategory;
  classification?: Maybe<DocumentClassification>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  sharingOptions?: Maybe<CaseDocumentSharingOptions>;
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
};

export enum CaseDocumentCategory {
  AutoFilled = 'AUTO_FILLED',
  Classified = 'CLASSIFIED',
  EngagementLetter = 'ENGAGEMENT_LETTER',
  Evidence = 'EVIDENCE',
  IntakeForm = 'INTAKE_FORM',
  LegalDoc = 'LEGAL_DOC',
  LpPack = 'LP_PACK'
}

export type CaseDocumentFilterInput = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<CaseDocumentCategory>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  status?: InputMaybe<CaseDocumentStatus>;
};

export type CaseDocumentForm = ICaseDocument & {
  __typename?: 'CaseDocumentForm';
  case?: Maybe<Case>;
  classification?: Maybe<DocumentClassification>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  fields?: Maybe<Array<Maybe<CaseDocumentFormField>>>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sharingOptions?: Maybe<CaseDocumentSharingOptions>;
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
  template: FormTemplate;
  templateId?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
};

export type CaseDocumentFormField = IFormTemplateField & {
  __typename?: 'CaseDocumentFormField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CaseDocumentFormFieldsUpdateInput = {
  name: Scalars['String']['input'];
  type?: InputMaybe<FormTemplateFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CaseDocumentInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<CaseDocumentStatus>;
};

export type CaseDocumentSharingOptions = {
  __typename?: 'CaseDocumentSharingOptions';
  attorney: Scalars['Boolean']['output'];
  client: Scalars['Boolean']['output'];
};

export enum CaseDocumentSource {
  Customer = 'CUSTOMER',
  Os = 'OS'
}

export enum CaseDocumentStatus {
  Archived = 'ARCHIVED',
  New = 'NEW',
  ReadyForCase = 'READY_FOR_CASE',
  ReadyToSign = 'READY_TO_SIGN',
  RecycleBin = 'RECYCLE_BIN',
  Restored = 'RESTORED',
  UploadedToClio = 'UPLOADED_TO_CLIO',
  WaitForAResponseClient = 'WAIT_FOR_A_RESPONSE_CLIENT',
  WaitForAResponseLp = 'WAIT_FOR_A_RESPONSE_LP',
  WaitForAResponsePl = 'WAIT_FOR_A_RESPONSE_PL'
}

export enum CaseDocumentType {
  File = 'FILE',
  Form = 'FORM'
}

export type CaseEvent = {
  __typename?: 'CaseEvent';
  arbitraryLocation?: Maybe<Scalars['String']['output']>;
  case?: Maybe<Case>;
  caseId: Scalars['ID']['output'];
  clioId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<CaseEventCreatedBy>;
  eventSubtype?: Maybe<CaseEventSubtype>;
  eventType: CaseEventType;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  location?: Maybe<CaseEventLocation>;
  notes?: Maybe<Scalars['String']['output']>;
  occursAt: Scalars['Date']['output'];
  occursAtTime?: Maybe<Scalars['Time']['output']>;
  source: CaseEventSource;
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  virtualLocation?: Maybe<Scalars['URL']['output']>;
};

export type CaseEventCreatedBy = {
  __typename?: 'CaseEventCreatedBy';
  adminId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CaseEventLocation = {
  __typename?: 'CaseEventLocation';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  fullAddress: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type CaseEventLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fullAddress: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export enum CaseEventSource {
  Clio = 'CLIO',
  Marble = 'MARBLE',
  Salesforce = 'SALESFORCE'
}

export enum CaseEventSubtype {
  Arraignment = 'ARRAIGNMENT',
  BenchWarrantRecall = 'BENCH_WARRANT_RECALL',
  CaseStatusConf = 'CASE_STATUS_CONF',
  DiscoveryHearing = 'DISCOVERY_HEARING',
  Hearing = 'HEARING',
  Mediation = 'MEDIATION',
  Other = 'OTHER',
  Plea = 'PLEA',
  PleaseNegotiation = 'PLEASE_NEGOTIATION',
  Prelim = 'PRELIM',
  PrePrelim = 'PRE_PRELIM',
  PreTrial = 'PRE_TRIAL',
  Proof = 'PROOF',
  SecondArraignment = 'SECOND_ARRAIGNMENT',
  Sentencing = 'SENTENCING',
  SettlementConf = 'SETTLEMENT_CONF',
  Trial = 'TRIAL',
  TrialSettingConf = 'TRIAL_SETTING_CONF',
  WarrantRecall = 'WARRANT_RECALL',
  WithClient = 'WITH_CLIENT',
  WithOpposingCounsel = 'WITH_OPPOSING_COUNSEL',
  WithOpposingParty = 'WITH_OPPOSING_PARTY'
}

export enum CaseEventType {
  /** @deprecated Not in use */
  Action = 'ACTION',
  CourtDate = 'COURT_DATE',
  Deadline = 'DEADLINE',
  Meeting = 'MEETING',
  /** @deprecated Not in use */
  Other = 'OTHER',
  /** @deprecated Not in use */
  Petitioner = 'PETITIONER',
  /** @deprecated Not in use */
  Response = 'RESPONSE'
}

export type CaseEventsFilterInput = {
  caseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<CaseEventType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CaseFilters = {
  __typename?: 'CaseFilters';
  legalServicesFilters?: Maybe<Array<Maybe<LegalServiceSection>>>;
  lpFilters?: Maybe<Array<Maybe<FilterVal>>>;
  managersFilters?: Maybe<Array<Maybe<FilterVal>>>;
  milestoneFilters?: Maybe<Array<Maybe<CaseMilestone>>>;
  paralegalsFilters?: Maybe<Array<Maybe<FilterVal>>>;
  scopeFilters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CaseLegalProduct = {
  __typename?: 'CaseLegalProduct';
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type CaseLegalService = {
  __typename?: 'CaseLegalService';
  createdAt?: Maybe<Scalars['Date']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downgrade?: Maybe<Scalars['Boolean']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  legalService?: Maybe<LegalService>;
  legalServiceExternalId: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type CaseLocation = {
  __typename?: 'CaseLocation';
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CaseMeeting = {
  __typename?: 'CaseMeeting';
  body?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CaseMilestone {
  CaseDelivery = 'CASE_DELIVERY',
  IntroductionCall = 'INTRODUCTION_CALL',
  NextScope = 'NEXT_SCOPE',
  Onboarding = 'ONBOARDING',
  ScopeCompletion = 'SCOPE_COMPLETION'
}

export type CaseNote = {
  __typename?: 'CaseNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CaseNoteInput = {
  caseId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CaseOfferCriticalDate = {
  __typename?: 'CaseOfferCriticalDate';
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: CaseOfferCriticalDateType;
};

export enum CaseOfferCriticalDateType {
  Action = 'ACTION',
  CourtDate = 'COURT_DATE',
  CriticalDate = 'CRITICAL_DATE',
  Deadline = 'DEADLINE',
  Meeting = 'MEETING',
  Other = 'OTHER',
  Petitioner = 'PETITIONER',
  Response = 'RESPONSE',
  ResponseDue = 'RESPONSE_DUE'
}

export type CaseRequiredDocumentsData = {
  __typename?: 'CaseRequiredDocumentsData';
  ignoredIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  requiredIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type CaseService = {
  __typename?: 'CaseService';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CaseStatus {
  AwaitingReview = 'AWAITING_REVIEW',
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Closed = 'CLOSED',
  Dispatch = 'DISPATCH',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN'
}

export type CaseSubentityFailedObject = {
  __typename?: 'CaseSubentityFailedObject';
  error: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CaseSubentityMigratedObject = {
  __typename?: 'CaseSubentityMigratedObject';
  flareId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CaseSubentityMigrationResponse = {
  __typename?: 'CaseSubentityMigrationResponse';
  failed: Array<Maybe<CaseSubentityFailedObject>>;
  migrated: Array<Maybe<CaseSubentityMigratedObject>>;
  skipped: Array<Maybe<Scalars['String']['output']>>;
  total: Scalars['Int']['output'];
};

export type CaseTask = {
  __typename?: 'CaseTask';
  caseId: Scalars['String']['output'];
  caseStage: StageEnum;
  dueDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerType: CaseTaskOwnerType;
  status: CaseTaskStatus;
  title: Scalars['String']['output'];
};

export enum CaseTaskOwnerType {
  CaseManager = 'CASE_MANAGER',
  Client = 'CLIENT',
  LegalPartner = 'LEGAL_PARTNER',
  Paralegal = 'PARALEGAL'
}

export type CaseTaskStatus = {
  __typename?: 'CaseTaskStatus';
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: CaseTaskStatusValue;
};

export type CaseTaskStatusInput = {
  value: CaseTaskStatusValue;
};

export enum CaseTaskStatusValue {
  Completed = 'COMPLETED',
  ToDo = 'TO_DO'
}

export type CaseTasksInfo = {
  __typename?: 'CaseTasksInfo';
  completed: Scalars['Int']['output'];
  currentTask?: Maybe<CaseTask>;
  total: Scalars['Int']['output'];
};

export enum CaseType {
  ExistingBusiness = 'EXISTING_BUSINESS',
  NewBusiness = 'NEW_BUSINESS'
}

export type CaseUpdate = {
  __typename?: 'CaseUpdate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isUrgent?: Maybe<Scalars['Boolean']['output']>;
  occursAt?: Maybe<Scalars['DateTime']['output']>;
  sharingOptions?: Maybe<CaseUpdateSharingOptions>;
  source: CaseUpdateSources;
  title?: Maybe<Scalars['String']['output']>;
  userRead?: Maybe<Scalars['DateTime']['output']>;
};

export type CaseUpdateInput = {
  caseId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  sharingOptions?: InputMaybe<CaseUpdateSharingOptionsInput>;
  source: CaseUpdateSources;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title: Scalars['String']['input'];
};

export type CaseUpdateInputWithTags = {
  caseId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  sharingOptions?: InputMaybe<CaseUpdateSharingOptionsInput>;
  source: CaseUpdateSources;
  tagTitles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CaseUpdateSharingOptions = {
  __typename?: 'CaseUpdateSharingOptions';
  attorney: Scalars['Boolean']['output'];
  client: Scalars['Boolean']['output'];
};

export type CaseUpdateSharingOptionsInput = {
  attorney: Scalars['Boolean']['input'];
  client: Scalars['Boolean']['input'];
};

export enum CaseUpdateSources {
  Cem = 'CEM',
  CemPayments = 'CEM_PAYMENTS',
  Clio = 'CLIO',
  Lp = 'LP',
  Salesforce = 'SALESFORCE'
}

export type CasesFilterInput = {
  flareCaseId?: InputMaybe<Scalars['String']['input']>;
  hasNewDocs?: InputMaybe<Scalars['Boolean']['input']>;
  legalServices?: InputMaybe<Array<InputMaybe<LegalServiceInput>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  managers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  milestones?: InputMaybe<Array<InputMaybe<CaseMilestone>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paralegals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  status?: InputMaybe<CaseStatus>;
};

export enum CasesOffLimits {
  CasesWithDrugs = 'CASES_WITH_DRUGS',
  CasesWithViolence = 'CASES_WITH_VIOLENCE',
  Divorce = 'DIVORCE',
  RestrainingOrders = 'RESTRAINING_ORDERS'
}

export type CasesResults = {
  __typename?: 'CasesResults';
  cases?: Maybe<Array<Maybe<Case>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type ChangeLog = {
  __typename?: 'ChangeLog';
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['ID']['output'];
  entityType: ChangeLogEntityTypes;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<Admin>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum ChangeLogEntityTypes {
  Case = 'CASE'
}

export type CheckCalendarsAvailabilityResponse = {
  __typename?: 'CheckCalendarsAvailabilityResponse';
  failed: Array<Maybe<Scalars['String']['output']>>;
  succeeded: Array<Maybe<CalendarAvailability>>;
};

export type Clawback = PayoutItem & {
  __typename?: 'Clawback';
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type ClosedCase = {
  __typename?: 'ClosedCase';
  driveCreationTime?: Maybe<Scalars['Date']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
};

export type CodeVerificationForEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  userType: UserType;
};

export type CodeVerificationInput = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  userType: UserType;
};

export type CodeVerificationResponse = {
  __typename?: 'CodeVerificationResponse';
  attempts?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CollectOverdueBalanceInput = {
  accountHash: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type ContactUsInput = {
  email: Scalars['EmailAddress']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['PhoneNumber']['input'];
  request?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
};

export enum ContractType {
  MarbleAgreement = 'MARBLE_AGREEMENT',
  PaymentAgreement = 'PAYMENT_AGREEMENT'
}

export type ConvertPendingToPaidInput = {
  all: Scalars['Boolean']['input'];
  externalAttorneyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CourtInfo = {
  __typename?: 'CourtInfo';
  caseNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CoveredCarePrequalData = {
  __typename?: 'CoveredCarePrequalData';
  applicationStatus?: Maybe<Scalars['String']['output']>;
  incompletePrequal?: Maybe<Scalars['Boolean']['output']>;
  offers?: Maybe<Array<Maybe<CoveredCarePrequalOffer>>>;
  startUrl?: Maybe<Scalars['String']['output']>;
  takenLoanAmount?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CoveredCarePrequalLink = {
  __typename?: 'CoveredCarePrequalLink';
  url: Scalars['String']['output'];
};

export type CoveredCarePrequalOffer = {
  __typename?: 'CoveredCarePrequalOffer';
  LoanGradeDescription?: Maybe<Scalars['String']['output']>;
  downPayment: Scalars['Float']['output'];
  downPaymentMinPct: Scalars['Float']['output'];
  downPaymentResult?: Maybe<Scalars['String']['output']>;
  estMonthlyPaymentAmt: Scalars['Float']['output'];
  expireDate: Scalars['String']['output'];
  loanAmount: Scalars['Float']['output'];
  loanInterest: Scalars['Float']['output'];
  marketingMessage?: Maybe<Scalars['String']['output']>;
  matrixGUID: Scalars['String']['output'];
  maxDownPaymentAmt: Scalars['Float']['output'];
  minDownPaymentAmt: Scalars['Float']['output'];
  minPaymentAmt: Scalars['Float']['output'];
  offerGUID: Scalars['String']['output'];
  offerOrder: Scalars['Int']['output'];
  startURL: Scalars['String']['output'];
  term: Scalars['Int']['output'];
};

export type CoveredCarePrequalResult = {
  __typename?: 'CoveredCarePrequalResult';
  status: Scalars['Boolean']['output'];
};

export type CreateAchInput = {
  accountId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
};

export type CreateAdminInput = {
  alternativeEmails?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  email: Scalars['EmailAddress']['input'];
  name: NameInput;
  password: Scalars['String']['input'];
  repassword: Scalars['String']['input'];
  teams?: InputMaybe<Array<InputMaybe<Team>>>;
};

export type CreateAttorneyCodeVerificationResponse = {
  __typename?: 'CreateAttorneyCodeVerificationResponse';
  countdown: Scalars['BigInt']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateAttorneyInput = {
  address?: InputMaybe<AddressInput>;
  attorneyType?: InputMaybe<AttorneyType>;
  barLicenseExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  barLicenseId?: InputMaybe<Scalars['String']['input']>;
  barNumber?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  cafNumber?: InputMaybe<Scalars['String']['input']>;
  calendlyEmail: Scalars['EmailAddress']['input'];
  calendlyLink?: InputMaybe<Scalars['String']['input']>;
  caseCapacity?: InputMaybe<Scalars['Int']['input']>;
  casesOffLimits?: InputMaybe<Array<InputMaybe<CasesOffLimits>>>;
  casesOffLimitsOther?: InputMaybe<Scalars['String']['input']>;
  conservatorship?: InputMaybe<Scalars['Boolean']['input']>;
  contestedDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  contractLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['EmailAddress']['input'];
  familyCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  guardianship?: InputMaybe<Scalars['Boolean']['input']>;
  handlesCases: Scalars['Boolean']['input'];
  inHouse: Scalars['Boolean']['input'];
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  juvenileCrime?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Language>;
  licensingJurisdiction?: InputMaybe<Scalars['String']['input']>;
  litigation?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Scalars['JSON']['input']>;
  marbleId?: InputMaybe<Scalars['String']['input']>;
  matrimonialCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  name: NameInput;
  numberOfActiveCases?: InputMaybe<Scalars['Int']['input']>;
  numberOfAssociates?: InputMaybe<Scalars['Int']['input']>;
  numberOfLegalSecretaries?: InputMaybe<Scalars['Int']['input']>;
  numberOfParalegals?: InputMaybe<Scalars['Int']['input']>;
  performsLSS: Scalars['Boolean']['input'];
  phones: PhonesInput;
  practiceAreas: Array<PracticeArea>;
  prenup?: InputMaybe<Scalars['Boolean']['input']>;
  ptin?: InputMaybe<Scalars['String']['input']>;
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  shortDeadline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCaseDocumentFormInput = {
  caseId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type CreateCodeVerificationResponse = {
  __typename?: 'CreateCodeVerificationResponse';
  countdown: Scalars['BigInt']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateCustomerInput = {
  email: Scalars['EmailAddress']['input'];
  name: NameInput;
  password: Scalars['String']['input'];
  repassword: Scalars['String']['input'];
};

export type CreateDownPaymentPlanComponentInput = {
  amount: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};

export type CreateFilingFeePaymentPlanComponentInput = {
  amount: Scalars['String']['input'];
  caseId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type CreateLegalProductInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLinkTokenInput = {
  externalId: Scalars['String']['input'];
};

export type CreatePayInFullPaymentPlanComponentInput = {
  amount: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type CreatePaymentOnHoldInput = {
  amountInDollars: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type CreatePaymentPlanComponentInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  achPayment?: InputMaybe<Scalars['Boolean']['input']>;
  amountInDollars: Scalars['String']['input'];
  autopayAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  billingCycle?: InputMaybe<BillingCycle>;
  billingDate: Scalars['Date']['input'];
  downPayments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  financeCompany?: InputMaybe<Scalars['String']['input']>;
  installments?: InputMaybe<Scalars['Int']['input']>;
  last4DigitsForReporting?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentPlanProvider: PaymentPlanProvider;
  reportButDoNotCharge?: InputMaybe<Scalars['Boolean']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  stripePaymentIntentId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type: PaymentPlanSetupPaymentPlanType;
  updateSalesforce?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePayoutItemBulkInput = {
  payouts: Array<CreatePayoutItemInput>;
};

export type CreatePayoutItemBulkResponse = {
  __typename?: 'CreatePayoutItemBulkResponse';
  responses: Array<CreatePayoutItemResponse>;
};

export type CreatePayoutItemError = {
  __typename?: 'CreatePayoutItemError';
  message: Scalars['String']['output'];
};

export type CreatePayoutItemInput = {
  amount: Scalars['Float']['input'];
  amountTotal?: InputMaybe<Scalars['Float']['input']>;
  billingDate: Scalars['Date']['input'];
  externalAttorneyId?: InputMaybe<Scalars['String']['input']>;
  externalCaseId?: InputMaybe<Scalars['String']['input']>;
  paymentNumber: Scalars['Int']['input'];
  pending?: InputMaybe<Scalars['Boolean']['input']>;
  type: PayoutItemType;
};

export type CreatePayoutItemResponse = {
  __typename?: 'CreatePayoutItemResponse';
  data?: Maybe<PayoutItem>;
  errors?: Maybe<Array<CreatePayoutItemError>>;
};

export type CreateRejectedCustomerInput = {
  customerRejectionType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  practiceArea?: InputMaybe<PracticeArea>;
};

export type CreateShortLivedTokenOutput = {
  __typename?: 'CreateShortLivedTokenOutput';
  token?: Maybe<Scalars['String']['output']>;
};

export type CreateThirdPartyUserInput = {
  address: UwAddressInput;
  dateOfBirth: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobileNumber: Scalars['String']['input'];
  payingForOpportunityId: Scalars['String']['input'];
  payingForOtherByExternalId: Scalars['String']['input'];
};

export type CreateWebLeadInput = {
  AdditionalQuestion__c?: InputMaybe<Scalars['String']['input']>;
  Concurrence__c?: InputMaybe<Scalars['Boolean']['input']>;
  County__c?: InputMaybe<Scalars['String']['input']>;
  Current_Situation__c?: InputMaybe<Scalars['String']['input']>;
  Email__c?: InputMaybe<Scalars['String']['input']>;
  First_name__c?: InputMaybe<Scalars['String']['input']>;
  Funnel_Parameters__c?: InputMaybe<Scalars['String']['input']>;
  Last_name__c?: InputMaybe<Scalars['String']['input']>;
  Phone__c?: InputMaybe<Scalars['String']['input']>;
  Postal_code__c?: InputMaybe<Scalars['String']['input']>;
  Practice__c?: InputMaybe<Scalars['String']['input']>;
  State__c?: InputMaybe<Scalars['String']['input']>;
  Test_Group__c?: InputMaybe<Scalars['String']['input']>;
  Test_Name__c?: InputMaybe<Scalars['String']['input']>;
  WhatMatterRelated__c?: InputMaybe<Scalars['String']['input']>;
  affilate_id__c?: InputMaybe<Scalars['String']['input']>;
  anon_id__c?: InputMaybe<Scalars['String']['input']>;
  fbclid__c?: InputMaybe<Scalars['String']['input']>;
  gclid__c?: InputMaybe<Scalars['String']['input']>;
  match_location__c?: InputMaybe<Scalars['String']['input']>;
  match_type__c?: InputMaybe<Scalars['String']['input']>;
  ttclid__c?: InputMaybe<Scalars['String']['input']>;
  url_refferal__c?: InputMaybe<Scalars['String']['input']>;
  utm_campaign__c?: InputMaybe<Scalars['String']['input']>;
  utm_content__c?: InputMaybe<Scalars['String']['input']>;
  utm_medium__c?: InputMaybe<Scalars['String']['input']>;
  utm_search_network__c?: InputMaybe<Scalars['String']['input']>;
  utm_source__c?: InputMaybe<Scalars['String']['input']>;
  utm_sub_campaign__c?: InputMaybe<Scalars['String']['input']>;
  utm_term__c?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWisetackLoanLinkInput = {
  purchaseId: Scalars['String']['input'];
  transactionAmount: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type CreditCardPaymentInput = {
  cardNumber: Scalars['String']['input'];
  cvc: Scalars['String']['input'];
  expirationDate: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCheckAddressInput = {
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export enum CreditCheckErrorCode {
  StateCodeLength = 'STATE_CODE_LENGTH',
  Unknown = 'UNKNOWN',
  ZipCodeLength = 'ZIP_CODE_LENGTH'
}

export type CreditFinancingOffered = {
  cartAmountImmutable?: InputMaybe<Scalars['Boolean']['input']>;
  monthlyPayment?: InputMaybe<BraintreeCurrencyAmount>;
  payerAcceptance?: InputMaybe<Scalars['Boolean']['input']>;
  term?: InputMaybe<Scalars['Float']['input']>;
  totalCost?: InputMaybe<BraintreeCurrencyAmount>;
  totalInterest?: InputMaybe<BraintreeCurrencyAmount>;
};

export type Customer = {
  __typename?: 'Customer';
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerState?: Maybe<CustomerState>;
  diyFunnelData?: Maybe<DiyFunnelData>;
  email?: Maybe<Scalars['String']['output']>;
  externalCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  formData?: Maybe<FormData>;
  id: Scalars['ID']['output'];
  isEligibleForFillingFees?: Maybe<Scalars['Boolean']['output']>;
  marbleId?: Maybe<Scalars['String']['output']>;
  name: Name;
  payment?: Maybe<Payment>;
  paymentAccount?: Maybe<PaymentAccount>;
  phone?: Maybe<Scalars['String']['output']>;
  stripeData?: Maybe<StripeData>;
  totalBookings?: Maybe<Scalars['Int']['output']>;
  voucher?: Maybe<VoucherDiscount>;
};

export type CustomerDetails = {
  address?: InputMaybe<PaymentCustomerAddress>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFinancingSummary = {
  __typename?: 'CustomerFinancingSummary';
  amountToFinance: Scalars['Int']['output'];
  downPaymentsPaidAmount: Scalars['Int']['output'];
  paidLssAmount: Scalars['Int']['output'];
  thirdPartyFinancedAmount: Scalars['Int']['output'];
  totalBalance: Scalars['Int']['output'];
  totalDiscount: Scalars['Int']['output'];
  totalPaid: Scalars['Int']['output'];
};

export type CustomerInput = {
  email: Scalars['String']['input'];
  name: NameInput;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerLeadInput = {
  email: Scalars['String']['input'];
  hashVersion?: InputMaybe<Scalars['String']['input']>;
  marbleUserId?: InputMaybe<Scalars['String']['input']>;
  name: NameInput;
  phone: Scalars['String']['input'];
};

export enum CustomerLoginType {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type CustomerNotificationInput = {
  excludeArrived?: InputMaybe<Scalars['Boolean']['input']>;
  excludeOpened?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSent?: InputMaybe<Scalars['Boolean']['input']>;
  excludeViewed?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerState = {
  __typename?: 'CustomerState';
  blockAllCommunications?: Maybe<Scalars['Boolean']['output']>;
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  firstEmailSent?: Maybe<Scalars['Boolean']['output']>;
  postBookingShown?: Maybe<Scalars['Boolean']['output']>;
  submittedForms?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerStripeData = {
  __typename?: 'CustomerStripeData';
  stripeCustomerId: Scalars['String']['output'];
  stripeCustomerSecretKey: Scalars['String']['output'];
};

export type CustomerUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<NameInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteDownPaymentInput = {
  componentId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type DeletePactsafeContractInput = {
  apiKey: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type DeleteThirdPartyPaymentInput = {
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type Details = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  creditFinancingOffered?: InputMaybe<CreditFinancingOffered>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<BraintreeAddress>;
};

export type DevVerificationCodeInput = {
  phone: Scalars['String']['input'];
  userType: UserType;
};

export type DiyFunnelData = {
  __typename?: 'DiyFunnelData';
  createdAt?: Maybe<Scalars['Date']['output']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isAfterOnboarding?: Maybe<Scalars['Boolean']['output']>;
  isAlreadyViewed?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<DiyFunnelLocation>;
  selectedService?: Maybe<Scalars['String']['output']>;
  stepsData?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DiyFunnelLocation = {
  __typename?: 'DiyFunnelLocation';
  county?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type DocumentClassification = {
  __typename?: 'DocumentClassification';
  category?: Maybe<DocumentClassificationCategory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentClassificationCategory = {
  __typename?: 'DocumentClassificationCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DocumentClassificationsWithCategory = {
  __typename?: 'DocumentClassificationsWithCategory';
  category: DocumentClassificationCategory;
  classifications?: Maybe<Array<Maybe<DocumentClassification>>>;
  topClassifications?: Maybe<Array<Maybe<DocumentClassification>>>;
};

export type DownPayment = {
  __typename?: 'DownPayment';
  percentage: Scalars['Float']['output'];
};

export type DownPaymentsPaidAmount = {
  __typename?: 'DownPaymentsPaidAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export type DriveDocument = {
  __typename?: 'DriveDocument';
  documentId: Scalars['ID']['output'];
  driveCreationTime?: Maybe<Scalars['Date']['output']>;
  driveId?: Maybe<Scalars['String']['output']>;
};

export type ElOverrides = {
  lpAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  marbleAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  redispatchAttorneySFId?: InputMaybe<Scalars['String']['input']>;
  redispatchPayoutAmount?: InputMaybe<Scalars['Float']['input']>;
  redispatchServiceIds?: InputMaybe<Scalars['String']['input']>;
};

export enum ElTenant {
  Kindred = 'KINDRED',
  Marble = 'MARBLE'
}

export enum ElType {
  AdditionalServicesEl = 'ADDITIONAL_SERVICES_EL',
  El = 'EL',
  Pa = 'PA',
  RedispatchEl = 'REDISPATCH_EL',
  TaxPoa = 'TAX_POA',
  X3PartyPayor = 'X3_PARTY_PAYOR'
}

export type Earning = {
  __typename?: 'Earning';
  amount: Scalars['Float']['output'];
  billingDate: Scalars['Date']['output'];
  caseCount: Scalars['Int']['output'];
  payoutItems: Array<PayoutItem>;
};

export type EditRecurringPaymentInput = {
  accountId: Scalars['String']['input'];
  amountInDollars: Scalars['String']['input'];
  billingDate: Scalars['Date']['input'];
  installments: Scalars['Int']['input'];
  newOpportunityId: Scalars['String']['input'];
  oldComponentId: Scalars['String']['input'];
  oldOpportunityId: Scalars['String']['input'];
  oldSubscriptionId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type EditThirdPartyPaymentInput = {
  amountInDollars: Scalars['String']['input'];
  billingDate: Scalars['Date']['input'];
  financeCompany?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type EnableAutopayRequest = {
  paymentMethodId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
};

export type EndRecurringPaymentInput = {
  componentId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  paymentPlanProvider?: InputMaybe<Scalars['String']['input']>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export enum Environments {
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export type EveryTwoWeeks = {
  date?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
};

export type EveryTwoWeeksOutput = {
  __typename?: 'EveryTwoWeeksOutput';
  date?: Maybe<Scalars['String']['output']>;
  day: Scalars['Int']['output'];
};

export type ExchangePublicTokenInput = {
  externalId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Float']['output']>;
  paymentDate?: Maybe<Scalars['String']['output']>;
};

export type FetchTransactionDataForCustomerInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type FilterVal = {
  __typename?: 'FilterVal';
  id: Scalars['String']['output'];
  name: Name;
};

export type FinancingOption = {
  __typename?: 'FinancingOption';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<DownPayment>;
  financingTermsOptions?: Maybe<Array<FinancingTerms>>;
  installment?: Maybe<Scalars['Float']['output']>;
  provider: FinancingOptionProvider;
  status: Scalars['String']['output'];
};

export enum FinancingOptionProvider {
  Affirm = 'AFFIRM',
  Covered = 'COVERED',
  Genesis = 'GENESIS',
  InHouse = 'IN_HOUSE',
  InHouseViola = 'IN_HOUSE_VIOLA',
  Klarna = 'KLARNA',
  None = 'NONE',
  Paypal = 'PAYPAL',
  Wisetack = 'WISETACK'
}

export type FinancingTerms = {
  __typename?: 'FinancingTerms';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<DownPayment>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installment?: Maybe<Scalars['Float']['output']>;
  interestRate?: Maybe<Scalars['Float']['output']>;
};

export enum FinancingType {
  Pay = 'PAY',
  Ric = 'RIC'
}

export type Form = {
  __typename?: 'Form';
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<FormField>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type FormData = {
  __typename?: 'FormData';
  formData?: Maybe<Scalars['JSON']['output']>;
};

export type FormDataInput = {
  formData?: InputMaybe<Scalars['JSON']['input']>;
};

export type FormField = {
  __typename?: 'FormField';
  name?: Maybe<Scalars['String']['output']>;
};

export type FormFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FormInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<FormFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  county?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<FormTemplateField>>>;
  id: Scalars['ID']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<PracticeArea>;
  state?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FormTemplateField = IFormTemplateField & {
  __typename?: 'FormTemplateField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FormTemplateFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type?: InputMaybe<FormTemplateFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FormTemplateFieldType {
  Btn = 'Btn',
  Sig = 'Sig',
  Tx = 'Tx'
}

export type FormTemplatesFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  practices?: InputMaybe<Array<InputMaybe<PracticeArea>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FreeTaxGeneratorPayload = {
  address: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  irsFormName: Scalars['String']['input'];
  reasoning?: InputMaybe<Scalars['String']['input']>;
  taxPeriod: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenerateElInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  elType?: InputMaybe<Array<InputMaybe<ElType>>>;
  opportunityId: Scalars['String']['input'];
  overrides?: InputMaybe<ElOverrides>;
  payInFull?: InputMaybe<Scalars['Boolean']['input']>;
  selfServe?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<ElTenant>;
};

export type GenerateLeadInput = {
  category: Scalars['String']['input'];
  customer: CustomerLeadInput;
  entryUrl: Scalars['String']['input'];
  funnelQuestions: Scalars['JSON']['input'];
  meetingDetails?: InputMaybe<MeetingDetailsLeadInput>;
  practiceArea: PracticeArea;
  sessionPageType?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  utm?: InputMaybe<UtmInput>;
};

export type GenerateLoanApplicationLinkInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  transactionPurpose: Scalars['String']['input'];
};

export type GeneratePaymentAgreementResponse = {
  __typename?: 'GeneratePaymentAgreementResponse';
  contractURL: Scalars['String']['output'];
};

export type GeneratePrequalLinkInput = {
  accountId: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  transactionPurpose: Scalars['String']['input'];
};

export enum GenesisCaptureFundsError {
  InsufficientDownpayment = 'INSUFFICIENT_DOWNPAYMENT'
}

export type GenesisLoanAccount = {
  __typename?: 'GenesisLoanAccount';
  accountNumber: Scalars['String']['output'];
  availableCredit: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type GenesisLoanApplication = {
  __typename?: 'GenesisLoanApplication';
  approvedAmount: Scalars['Int']['output'];
  bank: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type GenesisLoanTerms = {
  __typename?: 'GenesisLoanTerms';
  base64Html: Scalars['String']['output'];
};

export type GenesisOffer = {
  __typename?: 'GenesisOffer';
  downPaymentPercentage: Scalars['Float']['output'];
};

export type GenesisPrequalApplication = {
  __typename?: 'GenesisPrequalApplication';
  approvedAmount: Scalars['Int']['output'];
  bank: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  offer?: Maybe<GenesisOffer>;
  requestedAmount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type GenesisStartPrequalInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature?: InputMaybe<Scalars['String']['input']>;
};

export type GetActiveContractsInput = {
  contractType: ElType;
  opportunityId: Scalars['String']['input'];
  tenant: ElTenant;
};

export type GetActiveContractsResponse = {
  __typename?: 'GetActiveContractsResponse';
  contractType: ElType;
  customFields?: Maybe<Scalars['String']['output']>;
  signedByCustomer: Scalars['Boolean']['output'];
  signingId: Scalars['String']['output'];
  signingUrl: Scalars['String']['output'];
};

export type GetAppConfigurationsInput = {
  platform: PlatformConfigurationsType;
};

export type GetAppPrivateConfigurationsInput = {
  client: AppClients;
  platform: PlatformConfigurationsType;
};

export type GetAppPublicConfigurationsInput = {
  client: AppClients;
  platform: PlatformConfigurationsType;
};

export type GetCalendarsAvailabilityByUrlOptions = {
  hoursThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCalendarsAvailabilityInput = {
  events: Array<InputMaybe<CalendlyEvent>>;
};

export type GetCustomerRiskLevelInput = {
  userId: Scalars['String']['input'];
};

export type GetCustomerRiskLevelResult = {
  __typename?: 'GetCustomerRiskLevelResult';
  riskLevel: Scalars['String']['output'];
};

export type GetLegalServicesOptions = {
  filterByText?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  serviceType: LegalServiceType;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<LegalServicesSortOptions>;
};

export type GetLegalServicesResult = {
  __typename?: 'GetLegalServicesResult';
  legalServices: Array<Maybe<LegalService>>;
  total: Scalars['Int']['output'];
};

export type GetOnboardingProgressInput = {
  customerId: Scalars['String']['input'];
};

export type GetOpportunityThirdPartyPayerFinancingOptionsInput = {
  opportunityId: Scalars['String']['input'];
};

export type GetOrCreatePaymentPlanAccountInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  paymentPlanProvider?: InputMaybe<PaymentPlanProvider>;
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type GetOrCreatePaymentPlanAccountResponse = {
  __typename?: 'GetOrCreatePaymentPlanAccountResponse';
  accountId: Scalars['String']['output'];
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
};

export type GetPaginatedDiyUsersParams = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GetPaginatedDiyUsersResponse = {
  __typename?: 'GetPaginatedDiyUsersResponse';
  results?: Maybe<Array<Maybe<DiyFunnelData>>>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export type GetPaidDownPaymentsAmountInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
};

export type GetPaidLssAmountInput = {
  sfAccountId: Scalars['String']['input'];
};

export type GetPayoutItemsInput = {
  attorneyId?: InputMaybe<Scalars['String']['input']>;
  billingDate?: InputMaybe<Scalars['Date']['input']>;
  externalCaseId?: InputMaybe<Scalars['String']['input']>;
  pending?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetPendingSigningRequestDetailsInput = {
  contractTemplateName?: InputMaybe<Scalars['String']['input']>;
  contractType: ContractType;
  opportunityId: Scalars['String']['input'];
  tenant?: InputMaybe<ElTenant>;
};

export type GetRefundTicketByIdInput = {
  ticketId: Scalars['String']['input'];
};

export type GetRefundTicketByIdResponse = {
  __typename?: 'GetRefundTicketByIdResponse';
  accountId: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  additionalInfo?: Maybe<Scalars['String']['output']>;
  amountPaid: Scalars['Int']['output'];
  opportunityId: Scalars['ID']['output'];
  refundAmount: Scalars['Int']['output'];
  refundReason: RefundReason;
  services: Array<ServiceOnOpportunity>;
  status: Scalars['String']['output'];
  ticketId: Scalars['String']['output'];
};

export type GetRequiredDocumentsOptions = {
  legalServicesExternalIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GetSuggestedThirdPartyProviderInput = {
  accountId: Scalars['String']['input'];
  enabledCovered?: InputMaybe<Scalars['Boolean']['input']>;
  signature: Scalars['String']['input'];
};

export type GetThirdPartyFinancedAmountInput = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
};

export type GetUnderwritingFinancingInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserByExternalIdInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum GqlPrequalApplicationStatus {
  Approved = 'approved',
  Canceled = 'canceled',
  Created = 'created',
  Declined = 'declined',
  Expired = 'expired',
  Failed = 'failed',
  Incomplete = 'incomplete',
  NotStarted = 'not_started',
  Started = 'started'
}

export enum HashAlgorithm {
  Md5 = 'MD5',
  Sha256 = 'SHA256',
  Sha512 = 'SHA512'
}

export type HashOptions = {
  algorithm?: InputMaybe<HashAlgorithm>;
  useSalt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HashResponse = {
  __typename?: 'HashResponse';
  algorithm?: Maybe<HashAlgorithm>;
  hashed?: Maybe<Scalars['JSON']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type ICaseDocument = {
  case?: Maybe<Case>;
  classificationId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source?: Maybe<CaseDocumentSource>;
  status: CaseDocumentStatus;
};

export type IFormTemplateField = {
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<FormTemplateFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type IdentityVerificationInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type IdentityVerificationOutput = {
  __typename?: 'IdentityVerificationOutput';
  externalId: Scalars['String']['output'];
  idvRequestId: Scalars['String']['output'];
  status: IdentityVerificationStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export enum IdentityVerificationStatus {
  Active = 'active',
  AdditionalInformation = 'additional_information',
  Canceled = 'canceled',
  Expired = 'expired',
  Failed = 'failed',
  PendingReview = 'pending_review',
  Success = 'success'
}

export type ImpersonateByApiKeyInput = {
  adminEmail: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userType: UserType;
};

export type InHousePrequalApplication = {
  __typename?: 'InHousePrequalApplication';
  borrowerId?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<InHousePrequalApplicationMetadata>;
  offers?: Maybe<Array<Maybe<InHousePrequalApplicationOffer>>>;
};

export type InHousePrequalApplicationMetadata = {
  __typename?: 'InHousePrequalApplicationMetadata';
  minDpAmount?: Maybe<Scalars['Float']['output']>;
  minDpPercent?: Maybe<Scalars['Float']['output']>;
};

export type InHousePrequalApplicationOffer = {
  __typename?: 'InHousePrequalApplicationOffer';
  amount?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  downPayment?: Maybe<Scalars['Float']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  installments?: Maybe<Scalars['Float']['output']>;
  interestRate?: Maybe<Scalars['Float']['output']>;
  minDpPercent?: Maybe<Scalars['Float']['output']>;
};

export type Installment = PayoutItem & {
  __typename?: 'Installment';
  amount: Scalars['Float']['output'];
  amountTotal: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  case?: Maybe<Case>;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  numOfPayments: Scalars['Int']['output'];
  opportunityExternalId?: Maybe<Scalars['String']['output']>;
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export type IntakeMeeting = {
  __typename?: 'IntakeMeeting';
  notes?: Maybe<Scalars['String']['output']>;
  notesUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IntakeMeetingSlots = {
  __typename?: 'IntakeMeetingSlots';
  slotTimeSpan: Scalars['Int']['output'];
  slots: Array<Scalars['String']['output']>;
};

export type IntakeMeetingSlotsInput = {
  numberOfDays: Scalars['Int']['input'];
  practice: Scalars['String']['input'];
  rangeStart: Scalars['String']['input'];
};

export enum Language {
  English = 'ENGLISH',
  Spanish = 'SPANISH'
}

export type Lead = {
  __typename?: 'Lead';
  id: Scalars['String']['output'];
};

export type LegalProduct = {
  __typename?: 'LegalProduct';
  externalId?: Maybe<Scalars['String']['output']>;
  formIds: Array<Maybe<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type LegalService = {
  __typename?: 'LegalService';
  advancedBilling?: Maybe<Scalars['String']['output']>;
  billingPeriod?: Maybe<Scalars['String']['output']>;
  buyingPrice?: Maybe<Scalars['Int']['output']>;
  chargeScheduling?: Maybe<Scalars['String']['output']>;
  chargeType?: Maybe<Scalars['String']['output']>;
  commitment?: Maybe<Scalars['Int']['output']>;
  earlyTermination?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fromDate?: Maybe<Scalars['Date']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  indexValue?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  itemCategory?: Maybe<Scalars['String']['output']>;
  loanAmount?: Maybe<Scalars['Int']['output']>;
  loanApprovalDate?: Maybe<Scalars['Date']['output']>;
  minimumUnit?: Maybe<Scalars['Int']['output']>;
  pricePlanItem?: Maybe<Scalars['String']['output']>;
  pricingType?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  prorationType?: Maybe<Scalars['String']['output']>;
  quantityUnitOfMeasure?: Maybe<Scalars['String']['output']>;
  requiredDocuments: Array<Maybe<RequiredDocument>>;
  requiredDocumentsIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  serviceCategory?: Maybe<Scalars['String']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  serviceDescription?: Maybe<Scalars['String']['output']>;
  serviceDescriptionFormatted?: Maybe<Scalars['String']['output']>;
  serviceFamily?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceSKU?: Maybe<Scalars['String']['output']>;
  serviceType: LegalServiceType;
  status?: Maybe<Scalars['String']['output']>;
  subzeeId?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  timelineDescriptionLink?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['Date']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type LegalServiceInput = {
  serviceCategory?: InputMaybe<Scalars['String']['input']>;
  serviceFamily?: InputMaybe<Scalars['String']['input']>;
};

export type LegalServiceProvider = {
  __typename?: 'LegalServiceProvider';
  bio?: Maybe<Scalars['String']['output']>;
  legalArea?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LegalServiceSection = {
  __typename?: 'LegalServiceSection';
  serviceCategory?: Maybe<Scalars['String']['output']>;
  serviceFamily?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
};

export enum LegalServiceType {
  MarbleService = 'MARBLE_SERVICE',
  PaymentProduct = 'PAYMENT_PRODUCT'
}

export type LegalServicesInput = {
  filterByState: Scalars['String']['input'];
  practiceAreaFilter?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalServicesSortOptions {
  ServiceCode = 'SERVICE_CODE'
}

export enum LenderName {
  Affirm = 'affirm',
  Covered = 'covered',
  Genesis = 'genesis',
  Inhouse = 'inhouse',
  Klarna = 'klarna',
  Paypal = 'paypal',
  Wisetack = 'wisetack'
}

export type LenderOffer = {
  __typename?: 'LenderOffer';
  amount: Scalars['Float']['output'];
  downPayment: Scalars['Float']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  installments: Scalars['Float']['output'];
  interestRate: Scalars['Float']['output'];
};

export type LinkTokenCreateResponse = {
  __typename?: 'LinkTokenCreateResponse';
  linkToken: Scalars['String']['output'];
};

export enum LoanAmountStatus {
  Approved = 'approved',
  Partial = 'partial'
}

export type LoanProvider = {
  __typename?: 'LoanProvider';
  amount?: Maybe<Scalars['Float']['output']>;
  applicationProcessId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  lender: LenderName;
  lenderRefId?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type LoginByCognitoInput = {
  token: Scalars['String']['input'];
  userType: Scalars['String']['input'];
};

export type LoginByGoogleInput = {
  token: Scalars['String']['input'];
  userType: Scalars['String']['input'];
};

export type LoginByTokenResponse = {
  __typename?: 'LoginByTokenResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['JSON']['output']>;
};

export type LoginDemoUserByGoogleInput = {
  token: Scalars['String']['input'];
  userType: UserType;
};

export enum LssAppearance {
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum LssAttorneyCaseRejectionReason {
  ClientIsNotGoodFit = 'CLIENT_IS_NOT_GOOD_FIT',
  Distance = 'DISTANCE',
  KeyDateConflict = 'KEY_DATE_CONFLICT',
  Other = 'OTHER',
  OutOfCapacity = 'OUT_OF_CAPACITY',
  OutOfScope = 'OUT_OF_SCOPE'
}

export type LssAttorneyResponseInput = {
  attorneyAcceptedCase?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<LssAttorneyCaseRejectionReason>;
  rejectionReasonOther?: InputMaybe<Scalars['String']['input']>;
};

export type LssCancelAndRefundRequestInput = {
  amountPaid: Scalars['Int']['input'];
  calendlyEventId: Scalars['String']['input'];
  sfAccountId?: InputMaybe<Scalars['String']['input']>;
  sfLeadId?: InputMaybe<Scalars['String']['input']>;
  sfOpportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type LssCaseInfo = {
  __typename?: 'LssCaseInfo';
  appearance?: Maybe<LssAppearance>;
  county: Scalars['String']['output'];
  courtCaseId?: Maybe<Scalars['String']['output']>;
  filingOrResponding?: Maybe<LssFilingOrResponding>;
  opposingParty?: Maybe<Name>;
  practiceArea: PracticeArea;
  state: Scalars['String']['output'];
  urgent: Scalars['Boolean']['output'];
};

export type LssCaseInfoInput = {
  appearance?: InputMaybe<LssAppearance>;
  county: Scalars['String']['input'];
  courtCaseId?: InputMaybe<Scalars['String']['input']>;
  filingOrResponding?: InputMaybe<LssFilingOrResponding>;
  opposingParty?: InputMaybe<NameInput>;
  practiceArea: PracticeArea;
  state: Scalars['String']['input'];
  urgent: Scalars['Boolean']['input'];
};

export enum LssCaseView {
  ManagingAttorney = 'MANAGING_ATTORNEY'
}

export type LssCasesByViewInput = {
  filter?: InputMaybe<LssCasesFilterInput>;
  viewType: LssCaseView;
};

export type LssCasesFilterInput = {
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type LssClientDetails = {
  __typename?: 'LssClientDetails';
  birthday?: Maybe<Scalars['Date']['output']>;
  charges?: Maybe<Scalars['String']['output']>;
  gender: LssGender;
  name: Name;
  numberOfChildren?: Maybe<Scalars['Int']['output']>;
  priors?: Maybe<Scalars['String']['output']>;
};

export type LssClientDetailsInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  charges?: InputMaybe<Scalars['String']['input']>;
  gender: LssGender;
  name: NameInput;
  numberOfChildren?: InputMaybe<Scalars['Int']['input']>;
  priors?: InputMaybe<Scalars['String']['input']>;
};

export type LssCriticalDate = {
  __typename?: 'LssCriticalDate';
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: LssCriticalDateType;
};

export type LssCriticalDateInput = {
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  type: LssCriticalDateType;
};

export enum LssCriticalDateType {
  CourtDate = 'COURT_DATE',
  CriticalDate = 'CRITICAL_DATE',
  ResponseDue = 'RESPONSE_DUE'
}

export enum LssFilingOrResponding {
  Filing = 'FILING',
  Responding = 'RESPONDING'
}

export enum LssGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type LssRefundRequestInput = {
  amountPaid: Scalars['Int']['input'];
  sfAccountId?: InputMaybe<Scalars['String']['input']>;
  sfLeadId?: InputMaybe<Scalars['String']['input']>;
  sfOpportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type LssRejected = {
  __typename?: 'LssRejected';
  other?: Maybe<Scalars['String']['output']>;
  reason: LssRejectionReason;
};

export enum LssRejectionReason {
  AttorneyNotNeeded = 'ATTORNEY_NOT_NEEDED',
  ClientAlreadyConvicted = 'CLIENT_ALREADY_CONVICTED',
  ClientNotAGoodFit = 'CLIENT_NOT_A_GOOD_FIT',
  ClientRejected = 'CLIENT_REJECTED',
  ConflictOfInterest = 'CONFLICT_OF_INTEREST',
  CpsInvolved = 'CPS_INVOLVED',
  Other = 'OTHER',
  OutOfPracticeArea = 'OUT_OF_PRACTICE_AREA',
  OutOfState = 'OUT_OF_STATE',
  PcNoShow = 'PC_NO_SHOW',
  SignedSeveranceAgreement = 'SIGNED_SEVERANCE_AGREEMENT',
  WrongAssignment = 'WRONG_ASSIGNMENT'
}

export type LssRescheduled = {
  __typename?: 'LssRescheduled';
  reason?: Maybe<Scalars['String']['output']>;
};

export type LssSummary = {
  __typename?: 'LssSummary';
  additionalServices?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use lssAttorneyResponse - currently not exposed on LssSummary */
  attorneyAcceptedCase?: Maybe<Scalars['Boolean']['output']>;
  attorneyId: Scalars['ID']['output'];
  caseInfo?: Maybe<LssCaseInfo>;
  clientDetails?: Maybe<LssClientDetails>;
  clientGoals?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  criticalDates?: Maybe<Array<LssCriticalDate>>;
  executiveSummary?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use recommendedServices */
  externalServicesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  financialInfo?: Maybe<Scalars['String']['output']>;
  impressionOfSelectedClientValue?: Maybe<Scalars['String']['output']>;
  impressionsOfClients?: Maybe<Scalars['String']['output']>;
  meetingTime: Scalars['DateTime']['output'];
  recommendedServices?: Maybe<Array<RecommendedServiceDetails>>;
  rejected?: Maybe<LssRejected>;
  rescheduled?: Maybe<LssRescheduled>;
};

export type LssSummaryInput = {
  additionalServices?: InputMaybe<Scalars['String']['input']>;
  attorneyAcceptedCase?: InputMaybe<Scalars['Boolean']['input']>;
  caseId: Scalars['ID']['input'];
  caseInfo: LssCaseInfoInput;
  clientDetails: LssClientDetailsInput;
  clientGoals?: InputMaybe<Scalars['String']['input']>;
  criticalDates?: InputMaybe<Array<LssCriticalDateInput>>;
  executiveSummary: Scalars['String']['input'];
  externalServicesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  financialInfo?: InputMaybe<Scalars['String']['input']>;
  impressionOfSelectedClientValue?: InputMaybe<Scalars['String']['input']>;
  impressionsOfClients?: InputMaybe<Scalars['String']['input']>;
  lssAttorneyResponse?: InputMaybe<LssAttorneyResponseInput>;
  meetingTime: Scalars['DateTime']['input'];
  recommendedServices?: InputMaybe<Array<RecommendedService>>;
};

export type MakeLoanPaymentInput = {
  amountInCents: Scalars['Int']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
  paymentPlanId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type MakeLoanPaymentResponse = {
  __typename?: 'MakeLoanPaymentResponse';
  success: Scalars['Boolean']['output'];
};

export type ManagerCasesFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CaseStatus>;
};

export type MarkAllNotificationsAsReadResult = {
  __typename?: 'MarkAllNotificationsAsReadResult';
  identity?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MeetingBookingInput = {
  email: Scalars['String']['input'];
};

export type MeetingDetailsLeadInput = {
  assignTo: Scalars['String']['input'];
  isApproved: Scalars['Boolean']['input'];
  meetingEnd: Scalars['String']['input'];
  meetingName: Scalars['String']['input'];
  meetingStart: Scalars['String']['input'];
};

export type MigrateCaseDocumentsToVinnyResponse = {
  __typename?: 'MigrateCaseDocumentsToVinnyResponse';
  caseId: Scalars['String']['output'];
  failed: Array<Maybe<Scalars['String']['output']>>;
  flareCaseId: Scalars['String']['output'];
  migrated: Array<Maybe<Scalars['String']['output']>>;
  skipped: Array<Maybe<Scalars['String']['output']>>;
};

export type MigrateToVinnyParams = {
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
};

export type MigrateToVinnyPayload = {
  caseId?: InputMaybe<Scalars['String']['input']>;
  flareCaseId: Scalars['String']['input'];
  flareServiceId: Scalars['String']['input'];
  opportunityId?: InputMaybe<Scalars['String']['input']>;
};

export type MigrateToVinnyResponse = {
  __typename?: 'MigrateToVinnyResponse';
  caseEvents?: Maybe<CaseSubentityMigrationResponse>;
  caseId?: Maybe<Scalars['String']['output']>;
  caseUpdates?: Maybe<CaseSubentityMigrationResponse>;
  error?: Maybe<Scalars['String']['output']>;
  flareCaseId: Scalars['String']['output'];
  flareServiceId: Scalars['String']['output'];
  opportunityId?: Maybe<Scalars['String']['output']>;
};

export type MissedPayment = {
  __typename?: 'MissedPayment';
  amount: Scalars['Int']['output'];
  attempt?: Maybe<Scalars['Int']['output']>;
  billingCycle?: Maybe<Scalars['Boolean']['output']>;
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
  resultDescription?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateLoan: Scalars['Boolean']['output'];
  addAdminAlternativeEmail?: Maybe<Admin>;
  addCallLog?: Maybe<CallLog>;
  addCaseEvent?: Maybe<CaseEvent>;
  addCaseNote?: Maybe<CaseNote>;
  addCaseTask?: Maybe<UpsertCaseTaskResult>;
  addCaseUpdate?: Maybe<CaseUpdate>;
  addCaseUpdateWithTags?: Maybe<CaseUpdate>;
  addDocumentClassification?: Maybe<DocumentClassification>;
  addPayment: Scalars['Boolean']['output'];
  addPaymentMethodToCustomer?: Maybe<Scalars['Boolean']['output']>;
  addRequiredDocument?: Maybe<RequiredDocument>;
  addRequiredDocumentToCase?: Maybe<Scalars['Boolean']['output']>;
  archiveCallLog?: Maybe<Scalars['String']['output']>;
  archiveCaseDocument?: Maybe<CaseDocument>;
  archiveCaseEvent?: Maybe<CaseEvent>;
  archiveFormTemplate?: Maybe<Scalars['Boolean']['output']>;
  authorizeAndCaptureTransaction?: Maybe<CaptureTransactionResponse>;
  batchCreatePreAuthLink?: Maybe<Array<Maybe<BatchCreatePreAuthLinkResponse>>>;
  batchDeletePactsafeContract?: Maybe<Scalars['Boolean']['output']>;
  cancelAutopay?: Maybe<Scalars['Boolean']['output']>;
  cancelLoanApplication?: Maybe<Scalars['Boolean']['output']>;
  cancelPaymentOnHold: PaymentOnHold;
  cancelScheduledEvent?: Maybe<CancelledEvent>;
  cancelScheduledEvents?: Maybe<CancelScheduledEventsResponse>;
  capturePaymentOnHold: PaymentOnHold;
  codeVerification?: Maybe<CodeVerificationResponse>;
  codeVerificationForEmail?: Maybe<CodeVerificationResponse>;
  collectOverdueBalance: Scalars['Boolean']['output'];
  contactUs?: Maybe<Scalars['Boolean']['output']>;
  createAch: Scalars['Boolean']['output'];
  createAdmin?: Maybe<Admin>;
  createAttorney?: Maybe<Attorney>;
  createAttorneyCodeVerification?: Maybe<CreateAttorneyCodeVerificationResponse>;
  createCaseDocumentForm?: Maybe<CaseDocumentForm>;
  createCodeVerification?: Maybe<CreateCodeVerificationResponse>;
  createCodeVerificationWithEmail?: Maybe<CreateCodeVerificationResponse>;
  createCustomer?: Maybe<Customer>;
  createDownPaymentPlanComponent: Scalars['Boolean']['output'];
  createFilingFeePaymentPlanComponent: Scalars['Boolean']['output'];
  createForms?: Maybe<Array<Maybe<Form>>>;
  createIdentityVerification?: Maybe<IdentityVerificationOutput>;
  createLegalProduct?: Maybe<LegalProduct>;
  createLinkToken: LinkTokenCreateResponse;
  createMeetingBookingUrl?: Maybe<Scalars['Boolean']['output']>;
  createPayInFullPaymentPlanComponent: Scalars['Boolean']['output'];
  createPaymentOnHold: PaymentOnHold;
  createPaymentPlanComponent: Scalars['Boolean']['output'];
  createPayoutItemBulk?: Maybe<CreatePayoutItemBulkResponse>;
  createRejectedCustomer?: Maybe<RejectedCustomer>;
  createShortLivedToken?: Maybe<CreateShortLivedTokenOutput>;
  createWebLead: Lead;
  createWisetackLoanLink: WisetackLoanLink;
  createWisetackPrequalLink: WisetackPrequalLink;
  deleteCustomerPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  deleteDevVerificationCode?: Maybe<Scalars['Boolean']['output']>;
  deleteDownPayment: Scalars['Boolean']['output'];
  deletePactsafeContract?: Maybe<Scalars['Boolean']['output']>;
  deletePeachCustomerPaymentMethodByExternalId?: Maybe<Scalars['Boolean']['output']>;
  deleteThirdPartyPayment: Scalars['Boolean']['output'];
  deployPortal?: Maybe<Scalars['Boolean']['output']>;
  disableAllMarketingMessages?: Maybe<Scalars['Boolean']['output']>;
  editRecurringPayment: Scalars['Boolean']['output'];
  editThirdPartyPayment: Scalars['Boolean']['output'];
  enableAutopay?: Maybe<Scalars['Boolean']['output']>;
  endRecurringPayment: Scalars['Boolean']['output'];
  exchangePublicToken: Scalars['Boolean']['output'];
  fetchTransactionsDataForCustomer: Scalars['Boolean']['output'];
  generateEl?: Maybe<Scalars['Boolean']['output']>;
  generateLead?: Maybe<Scalars['Boolean']['output']>;
  generateLeadFromSMS?: Maybe<Scalars['URL']['output']>;
  generateLoanApplicationLink?: Maybe<Scalars['String']['output']>;
  generatePaymentAgreement: GeneratePaymentAgreementResponse;
  generatePowerOfAttorney?: Maybe<Scalars['Boolean']['output']>;
  generatePrequalLink?: Maybe<Scalars['Boolean']['output']>;
  genesisAcceptOffer: Scalars['String']['output'];
  genesisCaptureFunds: Scalars['Boolean']['output'];
  genesisStartPrequal: Scalars['String']['output'];
  getCustomerStripeData: CustomerStripeData;
  getOrCreatePaymentPlanAccount: GetOrCreatePaymentPlanAccountResponse;
  hash?: Maybe<HashResponse>;
  impersonateAdminLogin?: Maybe<Scalars['String']['output']>;
  impersonateByApiKey?: Maybe<Scalars['String']['output']>;
  initiateSMSConversation?: Maybe<Scalars['Boolean']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  loginAsCustomer?: Maybe<Scalars['URL']['output']>;
  loginByCognitoToken?: Maybe<Scalars['String']['output']>;
  loginByGoogleToken?: Maybe<Scalars['String']['output']>;
  loginByMail?: Maybe<Scalars['Boolean']['output']>;
  loginByPassword?: Maybe<Scalars['String']['output']>;
  loginByToken?: Maybe<LoginByTokenResponse>;
  loginDemoUserByGoogle?: Maybe<Scalars['String']['output']>;
  lssCancelAndRefundRequest: Scalars['Boolean']['output'];
  lssRefundRequest: Scalars['Boolean']['output'];
  makeLoanPayment: MakeLoanPaymentResponse;
  markAllNotificationsAsRead?: Maybe<MarkAllNotificationsAsReadResult>;
  migrateAttorneys?: Maybe<Scalars['Boolean']['output']>;
  migrateBrazeProfiles?: Maybe<BrazeMigrationResponse>;
  migrateCaseDocumentsToVinny?: Maybe<MigrateCaseDocumentsToVinnyResponse>;
  migrateOneAttorney?: Maybe<Scalars['Boolean']['output']>;
  migrateToDiy?: Maybe<Scalars['Boolean']['output']>;
  migrateToVinny?: Maybe<Array<Maybe<MigrateToVinnyResponse>>>;
  notificationOpened?: Maybe<Scalars['Boolean']['output']>;
  notificationsViewed?: Maybe<Scalars['Boolean']['output']>;
  patchRefundMetadata: RefundMetadata;
  payTotalOutstanding?: Maybe<PayTotalOutstandingResponse>;
  paypalCancelPrequalApplication: Scalars['Boolean']['output'];
  paypalCapture: Scalars['Boolean']['output'];
  paypalStartPrequalApplication: PrequalResponse;
  postAffirmEvent?: Maybe<Scalars['String']['output']>;
  postLssForm?: Maybe<Scalars['Boolean']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  refundLoan: RefundLoanOutput;
  refundLoanApplication?: Maybe<Scalars['Boolean']['output']>;
  refundTransactions?: Maybe<Array<RefundTransactionResult>>;
  registerDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  registerLpDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  registerStripeCustomer: RegisterStripeCustomerResponse;
  removeRequiredDocument?: Maybe<Scalars['String']['output']>;
  removeRequiredDocumentFromCase?: Maybe<Scalars['ID']['output']>;
  rescheduleLss?: Maybe<Scalars['Boolean']['output']>;
  rescheduleLssMeeting?: Maybe<LssSummary>;
  restoreCaseDocument?: Maybe<CaseDocument>;
  runCoveredCarePrequal?: Maybe<CoveredCarePrequalResult>;
  runCreditCheck?: Maybe<Scalars['Boolean']['output']>;
  runCreditCheckByUserId?: Maybe<Scalars['Boolean']['output']>;
  runCreditCheckManualUtility?: Maybe<Scalars['Boolean']['output']>;
  scheduleLssMeetingReminder?: Maybe<Scalars['Boolean']['output']>;
  sendAddServicesRequest?: Maybe<Scalars['Boolean']['output']>;
  sendAttorneyFeedback?: Maybe<Scalars['Boolean']['output']>;
  sendDocsToClio?: Maybe<Array<Maybe<UpdatedCaseDocumentStatus>>>;
  sendDocumentsPack?: Maybe<Scalars['Boolean']['output']>;
  sendLpNotification?: Maybe<Notification>;
  sendNotification?: Maybe<Notification>;
  sendPayoutMails?: Maybe<SendPayoutMailsResponse>;
  sendSMS?: Maybe<Scalars['Boolean']['output']>;
  setCaseDocumentFilename?: Maybe<Scalars['String']['output']>;
  setCaseUpdateStatus?: Maybe<Scalars['Boolean']['output']>;
  setCaseWithFlareId?: Maybe<Scalars['Boolean']['output']>;
  setDevVerificationCode?: Maybe<Scalars['String']['output']>;
  setNotificationsAllowed?: Maybe<Scalars['Boolean']['output']>;
  signupOrLogin?: Maybe<Scalars['String']['output']>;
  startLoanApplication: StartLoanApplicationOutput;
  submitCaseForm?: Maybe<Scalars['Boolean']['output']>;
  submitLssSummary?: Maybe<LssSummary>;
  submitRejectedLssSummary?: Maybe<LssSummary>;
  submitRequestForDpPolicyChange: SubmitRequestForDpPolicyChangeResult;
  syncCaseFromOpportunity?: Maybe<Scalars['Boolean']['output']>;
  syncClosedCaseInDrive?: Maybe<ClosedCase>;
  syncDeviceInfo?: Maybe<SyncDeviceResponse>;
  syncDocumentToDrive?: Maybe<DriveDocument>;
  syncUserToBraze?: Maybe<Scalars['Boolean']['output']>;
  tryStartCase: TryStartCaseResponse;
  underwritingCreateThirdPartyUser: UwUser;
  underwritingSetUser: UwUser;
  updateAttorney?: Maybe<Attorney>;
  updateCaseCourtInfo?: Maybe<Case>;
  updateCaseDocument?: Maybe<CaseDocument>;
  updateCaseDocumentClassification?: Maybe<CaseDocument>;
  updateCaseDocumentFormClassification?: Maybe<CaseDocumentForm>;
  updateCaseDocumentFormFields?: Maybe<CaseDocumentForm>;
  updateCaseDocumentFormStatus?: Maybe<UpdatedCaseDocumentStatus>;
  updateCaseEvent?: Maybe<CaseEvent>;
  updateCaseStage?: Maybe<Case>;
  updateCaseTask?: Maybe<UpsertCaseTaskResult>;
  updateCustomer?: Maybe<Customer>;
  updateCustomerDefaultPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  updateCustomerState?: Maybe<Scalars['Boolean']['output']>;
  updateDefaultPaymentMethod: Scalars['Boolean']['output'];
  updateDocumentSharingWithClient?: Maybe<CaseDocument>;
  updateFormTemplate?: Maybe<FormTemplate>;
  updateLPOnEl?: Maybe<Scalars['Boolean']['output']>;
  updateLastCustomerContact?: Maybe<Case>;
  updateLegalProduct?: Maybe<LegalProduct>;
  updateSFEvent: Scalars['Boolean']['output'];
  updateSFrefundTicket?: Maybe<GetRefundTicketByIdResponse>;
  updateUnderwritingUser: UwUser;
  updateWebLead: Lead;
  updateWhatIsNext?: Maybe<Case>;
  upsertDiyFunnelData?: Maybe<DiyFunnelData>;
  upsertFormData?: Maybe<FormData>;
};


export type MutationActivateLoanArgs = {
  input: ActivateLoanInput;
};


export type MutationAddAdminAlternativeEmailArgs = {
  alternativeEmails?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  email: Scalars['EmailAddress']['input'];
};


export type MutationAddCallLogArgs = {
  callLog?: InputMaybe<CallLogInput>;
};


export type MutationAddCaseEventArgs = {
  event?: InputMaybe<AddCaseEventInput>;
};


export type MutationAddCaseNoteArgs = {
  caseNote?: InputMaybe<CaseNoteInput>;
};


export type MutationAddCaseTaskArgs = {
  caseTask?: InputMaybe<AddCaseTaskInput>;
};


export type MutationAddCaseUpdateArgs = {
  caseUpdate?: InputMaybe<CaseUpdateInput>;
};


export type MutationAddCaseUpdateWithTagsArgs = {
  caseUpdate?: InputMaybe<CaseUpdateInputWithTags>;
};


export type MutationAddDocumentClassificationArgs = {
  payload: AddDocumentClassificationPayload;
};


export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
};


export type MutationAddPaymentMethodToCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddRequiredDocumentArgs = {
  payload: AddRequiredDocumentPayload;
};


export type MutationAddRequiredDocumentToCaseArgs = {
  caseId: Scalars['ID']['input'];
  requiredDocumentId: Scalars['ID']['input'];
};


export type MutationArchiveCallLogArgs = {
  callLogId: Scalars['String']['input'];
};


export type MutationArchiveCaseDocumentArgs = {
  caseDocumentId: Scalars['ID']['input'];
};


export type MutationArchiveCaseEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAuthorizeAndCaptureTransactionArgs = {
  input: AuthorizeTransactionPayload;
};


export type MutationBatchCreatePreAuthLinkArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationBatchDeletePactsafeContractArgs = {
  input: BatchDeletePactsafeContractsInput;
};


export type MutationCancelAutopayArgs = {
  input?: InputMaybe<CancelAutopayRequest>;
};


export type MutationCancelLoanApplicationArgs = {
  input: CancelLoanApplicationInput;
};


export type MutationCancelPaymentOnHoldArgs = {
  input: CancelPaymentOnHoldInput;
};


export type MutationCancelScheduledEventArgs = {
  input: CancelScheduledEventInput;
};


export type MutationCancelScheduledEventsArgs = {
  input: CancelScheduledEventsInput;
};


export type MutationCapturePaymentOnHoldArgs = {
  input: CapturePaymentOnHoldInput;
};


export type MutationCodeVerificationArgs = {
  input?: InputMaybe<CodeVerificationInput>;
};


export type MutationCodeVerificationForEmailArgs = {
  input?: InputMaybe<CodeVerificationForEmailInput>;
};


export type MutationCollectOverdueBalanceArgs = {
  input: CollectOverdueBalanceInput;
};


export type MutationContactUsArgs = {
  contactUs?: InputMaybe<ContactUsInput>;
};


export type MutationCreateAchArgs = {
  input: CreateAchInput;
};


export type MutationCreateAdminArgs = {
  admin?: InputMaybe<CreateAdminInput>;
};


export type MutationCreateAttorneyArgs = {
  attorney?: InputMaybe<CreateAttorneyInput>;
};


export type MutationCreateAttorneyCodeVerificationArgs = {
  phone: Scalars['PhoneNumber']['input'];
};


export type MutationCreateCaseDocumentFormArgs = {
  payload?: InputMaybe<CreateCaseDocumentFormInput>;
};


export type MutationCreateCodeVerificationArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCodeVerificationWithEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCustomerArgs = {
  customer?: InputMaybe<CreateCustomerInput>;
};


export type MutationCreateDownPaymentPlanComponentArgs = {
  input: CreateDownPaymentPlanComponentInput;
};


export type MutationCreateFilingFeePaymentPlanComponentArgs = {
  input: CreateFilingFeePaymentPlanComponentInput;
};


export type MutationCreateFormsArgs = {
  forms?: InputMaybe<Array<InputMaybe<FormInput>>>;
};


export type MutationCreateIdentityVerificationArgs = {
  input: IdentityVerificationInput;
};


export type MutationCreateLegalProductArgs = {
  legalProduct?: InputMaybe<CreateLegalProductInput>;
};


export type MutationCreateLinkTokenArgs = {
  input: CreateLinkTokenInput;
};


export type MutationCreateMeetingBookingUrlArgs = {
  input: MeetingBookingInput;
};


export type MutationCreatePayInFullPaymentPlanComponentArgs = {
  input: CreatePayInFullPaymentPlanComponentInput;
};


export type MutationCreatePaymentOnHoldArgs = {
  input: CreatePaymentOnHoldInput;
};


export type MutationCreatePaymentPlanComponentArgs = {
  input: CreatePaymentPlanComponentInput;
};


export type MutationCreatePayoutItemBulkArgs = {
  input: CreatePayoutItemBulkInput;
};


export type MutationCreateRejectedCustomerArgs = {
  rejectedCustomer: CreateRejectedCustomerInput;
};


export type MutationCreateWebLeadArgs = {
  input: CreateWebLeadInput;
};


export type MutationCreateWisetackLoanLinkArgs = {
  input: CreateWisetackLoanLinkInput;
};


export type MutationCreateWisetackPrequalLinkArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteCustomerPaymentMethodArgs = {
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteDevVerificationCodeArgs = {
  input?: InputMaybe<DevVerificationCodeInput>;
};


export type MutationDeleteDownPaymentArgs = {
  input: DeleteDownPaymentInput;
};


export type MutationDeletePactsafeContractArgs = {
  input: DeletePactsafeContractInput;
};


export type MutationDeletePeachCustomerPaymentMethodByExternalIdArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
  signature?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteThirdPartyPaymentArgs = {
  input: DeleteThirdPartyPaymentInput;
};


export type MutationDeployPortalArgs = {
  environment: Environments;
};


export type MutationDisableAllMarketingMessagesArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationEditRecurringPaymentArgs = {
  input: EditRecurringPaymentInput;
};


export type MutationEditThirdPartyPaymentArgs = {
  input: EditThirdPartyPaymentInput;
};


export type MutationEnableAutopayArgs = {
  input?: InputMaybe<EnableAutopayRequest>;
};


export type MutationEndRecurringPaymentArgs = {
  input: EndRecurringPaymentInput;
};


export type MutationExchangePublicTokenArgs = {
  input: ExchangePublicTokenInput;
};


export type MutationFetchTransactionsDataForCustomerArgs = {
  input: CreateLinkTokenInput;
};


export type MutationGenerateElArgs = {
  input: GenerateElInput;
};


export type MutationGenerateLeadArgs = {
  lead: GenerateLeadInput;
};


export type MutationGenerateLeadFromSmsArgs = {
  lead: GenerateLeadInput;
};


export type MutationGenerateLoanApplicationLinkArgs = {
  input: GenerateLoanApplicationLinkInput;
};


export type MutationGeneratePaymentAgreementArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type MutationGeneratePowerOfAttorneyArgs = {
  input: GenerateElInput;
};


export type MutationGeneratePrequalLinkArgs = {
  input: GeneratePrequalLinkInput;
};


export type MutationGenesisAcceptOfferArgs = {
  userId: Scalars['String']['input'];
};


export type MutationGenesisCaptureFundsArgs = {
  amount: Scalars['Int']['input'];
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationGenesisStartPrequalArgs = {
  input: GenesisStartPrequalInput;
};


export type MutationGetOrCreatePaymentPlanAccountArgs = {
  input: GetOrCreatePaymentPlanAccountInput;
};


export type MutationHashArgs = {
  hashOptions?: InputMaybe<HashOptions>;
  input: Scalars['JSON']['input'];
};


export type MutationImpersonateAdminLoginArgs = {
  email: Scalars['String']['input'];
};


export type MutationImpersonateByApiKeyArgs = {
  input: ImpersonateByApiKeyInput;
};


export type MutationInitiateSmsConversationArgs = {
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
};


export type MutationLoginArgs = {
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginAsCustomerArgs = {
  email: Scalars['EmailAddress']['input'];
  loginType?: InputMaybe<CustomerLoginType>;
};


export type MutationLoginByCognitoTokenArgs = {
  input: LoginByCognitoInput;
};


export type MutationLoginByGoogleTokenArgs = {
  input?: InputMaybe<LoginByGoogleInput>;
};


export type MutationLoginByMailArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginByPasswordArgs = {
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginByTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginDemoUserByGoogleArgs = {
  input?: InputMaybe<LoginDemoUserByGoogleInput>;
};


export type MutationLssCancelAndRefundRequestArgs = {
  input: LssCancelAndRefundRequestInput;
};


export type MutationLssRefundRequestArgs = {
  input: LssRefundRequestInput;
};


export type MutationMakeLoanPaymentArgs = {
  input: MakeLoanPaymentInput;
};


export type MutationMigrateBrazeProfilesArgs = {
  params?: InputMaybe<BrazeMigrationInput>;
};


export type MutationMigrateCaseDocumentsToVinnyArgs = {
  caseId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationMigrateOneAttorneyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMigrateToDiyArgs = {
  opportunityIds: Array<Scalars['String']['input']>;
};


export type MutationMigrateToVinnyArgs = {
  params?: InputMaybe<MigrateToVinnyParams>;
  payload: Array<InputMaybe<MigrateToVinnyPayload>>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationNotificationOpenedArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationNotificationsViewedArgs = {
  notificationIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationPatchRefundMetadataArgs = {
  accountId: Scalars['String']['input'];
  input: PatchRefundMetadataInput;
  signature: Scalars['String']['input'];
};


export type MutationPayTotalOutstandingArgs = {
  input?: InputMaybe<PayTotalOutstandingRequest>;
};


export type MutationPaypalCancelPrequalApplicationArgs = {
  input?: InputMaybe<CancelEventInput>;
};


export type MutationPaypalCaptureArgs = {
  checkout: PaypalCheckout;
};


export type MutationPaypalStartPrequalApplicationArgs = {
  input: PrequalInput;
};


export type MutationPostAffirmEventArgs = {
  input: AffirmProviderEventInput;
};


export type MutationPostLssFormArgs = {
  input: PostLssFormInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRefundLoanArgs = {
  input?: InputMaybe<RefundLoanInput>;
};


export type MutationRefundLoanApplicationArgs = {
  input: RefundLoanApplicationInput;
};


export type MutationRefundTransactionsArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  reason: TransactionRefundReason;
  signature: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
  transactions: Array<RefundTransaction>;
};


export type MutationRegisterDeviceTokenArgs = {
  app: App;
  platform: PlatformType;
  token: Scalars['String']['input'];
};


export type MutationRegisterLpDeviceTokenArgs = {
  platform: PlatformType;
  token: Scalars['String']['input'];
};


export type MutationRegisterStripeCustomerArgs = {
  input: RegisterStripeCustomerInput;
};


export type MutationRemoveRequiredDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveRequiredDocumentFromCaseArgs = {
  caseId: Scalars['ID']['input'];
  requiredDocumentId: Scalars['ID']['input'];
};


export type MutationRescheduleLssArgs = {
  input: RescheduleLssInput;
};


export type MutationRescheduleLssMeetingArgs = {
  input: RescheduleLssMeetingInput;
};


export type MutationRestoreCaseDocumentArgs = {
  caseDocumentId: Scalars['ID']['input'];
};


export type MutationRunCoveredCarePrequalArgs = {
  input?: InputMaybe<RunCoveredCarePrequalInput>;
};


export type MutationRunCreditCheckArgs = {
  input: RunCreditCheckInput;
};


export type MutationRunCreditCheckByUserIdArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRunCreditCheckManualUtilityArgs = {
  input?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationScheduleLssMeetingReminderArgs = {
  input: ScheduleLssMeetingReminderInput;
};


export type MutationSendAddServicesRequestArgs = {
  sendAddServicesRequest?: InputMaybe<SendAddServicesRequestInput>;
};


export type MutationSendAttorneyFeedbackArgs = {
  sendAttorneyFeedback?: InputMaybe<SendAttorneyFeedbackInput>;
};


export type MutationSendDocsToClioArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  docList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationSendDocumentsPackArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSendLpNotificationArgs = {
  pushNotificationInput: NotificationInput;
};


export type MutationSendNotificationArgs = {
  pushNotificationInput: NotificationInput;
};


export type MutationSendPayoutMailsArgs = {
  input?: InputMaybe<SendPayoutMailsInput>;
};


export type MutationSendSmsArgs = {
  SMSContent: Scalars['String']['input'];
  toNumbers: Array<InputMaybe<Scalars['PhoneNumber']['input']>>;
  userId: Scalars['String']['input'];
};


export type MutationSetCaseDocumentFilenameArgs = {
  caseDocumentType?: InputMaybe<CaseDocumentType>;
  filename: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationSetCaseUpdateStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetCaseWithFlareIdArgs = {
  caseId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetDevVerificationCodeArgs = {
  input?: InputMaybe<DevVerificationCodeInput>;
};


export type MutationSetNotificationsAllowedArgs = {
  setNotificationsAllowedInput: SetNotificationsAllowedInput;
};


export type MutationSignupOrLoginArgs = {
  apiKey: Scalars['String']['input'];
  customer: CustomerInput;
};


export type MutationStartLoanApplicationArgs = {
  input?: InputMaybe<StartLoanApplicationInput>;
};


export type MutationSubmitCaseFormArgs = {
  caseId: Scalars['ID']['input'];
  questionList?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationSubmitLssSummaryArgs = {
  input: LssSummaryInput;
};


export type MutationSubmitRejectedLssSummaryArgs = {
  input: RejectedLssSummaryInput;
};


export type MutationSubmitRequestForDpPolicyChangeArgs = {
  input: SubmitRequestForDpPolicyChangeInput;
};


export type MutationSyncCaseFromOpportunityArgs = {
  input: SyncCaseFromOpportunityInput;
};


export type MutationSyncClosedCaseInDriveArgs = {
  closedCase?: InputMaybe<SyncClosedCaseInput>;
};


export type MutationSyncDeviceInfoArgs = {
  appInstallationInput: AppInstallationInput;
};


export type MutationSyncDocumentToDriveArgs = {
  document?: InputMaybe<SyncDocumentToDriveInput>;
};


export type MutationSyncUserToBrazeArgs = {
  customer?: InputMaybe<BrazeCustomerInput>;
};


export type MutationTryStartCaseArgs = {
  input: TryStartCaseInput;
};


export type MutationUnderwritingCreateThirdPartyUserArgs = {
  input: CreateThirdPartyUserInput;
};


export type MutationUnderwritingSetUserArgs = {
  input: SetUserInput;
};


export type MutationUpdateAttorneyArgs = {
  attorney?: InputMaybe<UpdateAttorneyInput>;
};


export type MutationUpdateCaseCourtInfoArgs = {
  courtInfo?: InputMaybe<UpdateCaseCourtInfoInput>;
};


export type MutationUpdateCaseDocumentArgs = {
  caseDocument?: InputMaybe<CaseDocumentInput>;
};


export type MutationUpdateCaseDocumentClassificationArgs = {
  payload?: InputMaybe<UpdateCaseDocumentTypeInput>;
};


export type MutationUpdateCaseDocumentFormClassificationArgs = {
  payload?: InputMaybe<UpdateCaseDocumentTypeInput>;
};


export type MutationUpdateCaseDocumentFormFieldsArgs = {
  payload?: InputMaybe<UpdateCaseDocumentFormFieldsInput>;
};


export type MutationUpdateCaseDocumentFormStatusArgs = {
  payload?: InputMaybe<UpdateCaseDocumentFormStatusInput>;
};


export type MutationUpdateCaseEventArgs = {
  event?: InputMaybe<UpdateCaseEventInput>;
};


export type MutationUpdateCaseStageArgs = {
  caseId: Scalars['ID']['input'];
  newStage: StageEnum;
};


export type MutationUpdateCaseTaskArgs = {
  caseTaskUpdate?: InputMaybe<UpdateCaseTaskInput>;
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerUpdateInput;
};


export type MutationUpdateCustomerDefaultPaymentMethodArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCustomerStateArgs = {
  key: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateDefaultPaymentMethodArgs = {
  input: UpdateDefaultPaymentMethodInput;
};


export type MutationUpdateDocumentSharingWithClientArgs = {
  payload?: InputMaybe<UpdateDocumentSharingWithClientPayload>;
};


export type MutationUpdateFormTemplateArgs = {
  payload?: InputMaybe<UpdateFormTemplateInput>;
};


export type MutationUpdateLpOnElArgs = {
  input: GenerateElInput;
};


export type MutationUpdateLastCustomerContactArgs = {
  caseId: Scalars['ID']['input'];
};


export type MutationUpdateLegalProductArgs = {
  legalProductInput?: InputMaybe<UpdateLegalProductInput>;
};


export type MutationUpdateSfEventArgs = {
  input: SfUpdateEventInput;
};


export type MutationUpdateSFrefundTicketArgs = {
  input: UpdateSfRefundTicketInput;
};


export type MutationUpdateUnderwritingUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWebLeadArgs = {
  input: UpdateWebLeadInput;
};


export type MutationUpdateWhatIsNextArgs = {
  whatIsNext?: InputMaybe<UpdateWhatIsNextInput>;
};


export type MutationUpsertDiyFunnelDataArgs = {
  payload: UpsertDiyFunnelData;
};


export type MutationUpsertFormDataArgs = {
  formData?: InputMaybe<FormDataInput>;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  middle?: Maybe<Scalars['String']['output']>;
};

export type NameInput = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type NextPayment = {
  __typename?: 'NextPayment';
  amount: Scalars['Int']['output'];
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  additionalData?: Maybe<Scalars['JSON']['output']>;
  body: Scalars['String']['output'];
  category?: Maybe<NotificationCategory>;
  createdAt: Scalars['Date']['output'];
  data?: Maybe<NotificationData>;
  id: Scalars['ID']['output'];
  identity: Scalars['String']['output'];
  notificationStatus: NotificationStatus;
  notificationType: Scalars['String']['output'];
  sound: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum NotificationCategory {
  AttorneyCx = 'ATTORNEY_CX',
  CaseClosed = 'CASE_CLOSED',
  CaseUpdate = 'CASE_UPDATE',
  Documents = 'DOCUMENTS',
  Event = 'EVENT',
  Generic = 'GENERIC',
  NewCaseAdded = 'NEW_CASE_ADDED',
  Payments = 'PAYMENTS',
  Scheduling = 'SCHEDULING',
  UpcomingCourtDate_10D = 'UPCOMING_COURT_DATE_10D'
}

export type NotificationData = {
  __typename?: 'NotificationData';
  actionData?: Maybe<Scalars['JSON']['output']>;
  actionType?: Maybe<ActionType>;
};

export type NotificationDataInput = {
  actionData?: InputMaybe<Scalars['JSON']['input']>;
  actionType?: InputMaybe<ActionType>;
};

export type NotificationInput = {
  additionalData?: InputMaybe<Scalars['JSON']['input']>;
  body: Scalars['String']['input'];
  category?: InputMaybe<NotificationCategory>;
  data: NotificationDataInput;
  identity: Scalars['String']['input'];
  notificationType: NotificationType;
  sound?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum NotificationStatus {
  Arrived = 'ARRIVED',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Viewed = 'VIEWED'
}

export enum NotificationType {
  AppOnly = 'APP_ONLY',
  Push = 'PUSH'
}

export type NotificationsOrderByInput = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  engagementLetterSigned?: Maybe<Scalars['Boolean']['output']>;
  fee?: Maybe<Fee>;
  lssMeeting?: Maybe<ScheduledMeeting>;
};

export type OnboardingProgress = {
  __typename?: 'OnboardingProgress';
  step?: Maybe<OnboardingStepProgress>;
};

export enum OnboardingStepId {
  Ach = 'ACH',
  End = 'END',
  Fico = 'FICO',
  PaymentMethod = 'PAYMENT_METHOD'
}

export type OnboardingStepProgress = {
  __typename?: 'OnboardingStepProgress';
  stepId: OnboardingStepId;
};

export type OneTimePlan = PaymentPlanComponent & {
  __typename?: 'OneTimePlan';
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  paymentFrequency?: Maybe<PaymentFrequency>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
};

export type OpportunityLinkFields = {
  __typename?: 'OpportunityLinkFields';
  accountFirstName: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  agentId: Scalars['String']['output'];
  agentPhone?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
};

export type OpportunityLinkFieldsByCaseIdInput = {
  caseId: Scalars['String']['input'];
};

export type OpportunityLinkFieldsInput = {
  repeatId: Scalars['String']['input'];
};

export type OpportunityPaymentData = {
  __typename?: 'OpportunityPaymentData';
  action: Scalars['String']['output'];
  action_text: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  ccAuthorized: Scalars['Boolean']['output'];
  deal_id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  stripeAccount: Scalars['String']['output'];
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type OpportunityStage = {
  __typename?: 'OpportunityStage';
  stage: Scalars['String']['output'];
};

export type OpposingParty = {
  __typename?: 'OpposingParty';
  name: PartialName;
};

export type Owner = Admin | Attorney | Customer;

export type Page = {
  __typename?: 'Page';
  email: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PaidLssAmount = {
  __typename?: 'PaidLssAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export type PartialAttorney = {
  __typename?: 'PartialAttorney';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  adminOnboardingStatus: AdminOnboardingStatus;
  attorneyType?: Maybe<AttorneyType>;
  barNumber?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  cafNumber?: Maybe<Scalars['String']['output']>;
  calendlyLink?: Maybe<Scalars['String']['output']>;
  casesOffLimits?: Maybe<Array<Maybe<CasesOffLimits>>>;
  casesOffLimitsOther?: Maybe<Scalars['String']['output']>;
  conservatorship?: Maybe<Scalars['Boolean']['output']>;
  contestedDivorce?: Maybe<Scalars['Boolean']['output']>;
  contractLink?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Array<Scalars['String']['output']>>;
  email: Scalars['EmailAddress']['output'];
  familyCourtNY?: Maybe<Scalars['Boolean']['output']>;
  guardianship?: Maybe<Scalars['Boolean']['output']>;
  handlesCases?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['URL']['output']>;
  inHouse?: Maybe<Scalars['Boolean']['output']>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  juvenileCrime?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Language>>;
  licensingJurisdiction?: Maybe<Scalars['String']['output']>;
  litigation?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Scalars['JSON']['output']>;
  matrimonialCourtNY?: Maybe<Scalars['Boolean']['output']>;
  name: Name;
  performsLSS?: Maybe<Scalars['Boolean']['output']>;
  phones?: Maybe<Phones>;
  practiceAreas?: Maybe<Array<PracticeArea>>;
  prenup?: Maybe<Scalars['Boolean']['output']>;
  ptin?: Maybe<Scalars['String']['output']>;
  salesforceId: Scalars['String']['output'];
  shortDeadline?: Maybe<Scalars['Boolean']['output']>;
};

export type PartialName = {
  __typename?: 'PartialName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  middle?: Maybe<Scalars['String']['output']>;
};

export type PartialPaymentAccount = {
  __typename?: 'PartialPaymentAccount';
  nextPayment?: Maybe<NextPayment>;
  totalDebt?: Maybe<Scalars['Int']['output']>;
};

export type PastPayment = {
  __typename?: 'PastPayment';
  amount: Scalars['Int']['output'];
  chargeDescription?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  loanId?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  p_type?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentStatus>;
};

export type PastTransaction = {
  __typename?: 'PastTransaction';
  amount: Scalars['Float']['output'];
  dueDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  isRefundable: Scalars['Boolean']['output'];
  loanId?: Maybe<Scalars['String']['output']>;
  method?: Maybe<TransactionPaymentMethod>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  paymentProvider: TransactionPaymentProvider;
  provider: TransactionsProvider;
  refundAmount: Scalars['Float']['output'];
  status: TransactionsStatus;
  type: TransactionsType;
};

export type PatchRefundMetadataInput = {
  manualRefundAmount?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<TransactionRefundReason>;
  ticketId: Scalars['String']['input'];
};

export type PayTotalOutstandingRequest = {
  accountHash: Scalars['String']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type PayTotalOutstandingResponse = {
  __typename?: 'PayTotalOutstandingResponse';
  accountHash: Scalars['String']['output'];
  amountPaid: Scalars['Int']['output'];
  paymentMethodMask: Scalars['String']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
};

export type PayerFinancingOptions = {
  __typename?: 'PayerFinancingOptions';
  financingOptions?: Maybe<Array<FinancingOption>>;
  payer?: Maybe<UwUser>;
};

export type Payment = {
  __typename?: 'Payment';
  methods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  accountId?: Maybe<Scalars['String']['output']>;
  financingType?: Maybe<FinancingType>;
  hash: Scalars['String']['output'];
  missedPayments?: Maybe<Array<MissedPayment>>;
  nextPayment?: Maybe<NextPayment>;
  pastPayments?: Maybe<Array<PastPayment>>;
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
  paymentStatus?: Maybe<PaymentStatus>;
  plans?: Maybe<Array<PaymentPlan>>;
  totalAmount: Scalars['Int']['output'];
  totalDebt: Scalars['Int']['output'];
  totalOutstanding: Scalars['Int']['output'];
  totalOutstandingAttempted: Scalars['Int']['output'];
  totalPaid: Scalars['Int']['output'];
  unacknowledgedOutstandingBalancePayment?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentAgreementResponse = {
  __typename?: 'PaymentAgreementResponse';
  paSigned: Scalars['Boolean']['output'];
  paURL?: Maybe<Scalars['String']['output']>;
};

export type PaymentCustomerAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum PaymentFrequency {
  EveryTwoWeeks = 'everyTwoWeeks',
  Monthly = 'monthly',
  SingleTerm = 'singleTerm',
  TwiceMonthly = 'twiceMonthly',
  Weekly = 'weekly'
}

export type PaymentInput = {
  opportunityId: Scalars['String']['input'];
  requestAction: Scalars['String']['input'];
};

export type PaymentMethod = {
  id: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PaymentOnHold = {
  __typename?: 'PaymentOnHold';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  customerId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opportunityId: Scalars['String']['output'];
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  stripePaymentIntentId: Scalars['String']['output'];
  stripePaymentStatus: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  caseExternalId?: Maybe<Scalars['String']['output']>;
  components: Array<PaymentPlanComponent>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  startedAt: Scalars['Date']['output'];
  totalPrice: Scalars['Int']['output'];
};

export type PaymentPlanComponent = {
  amount: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paymentFrequency?: Maybe<PaymentFrequency>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
};

export type PaymentPlanConfig = {
  __typename?: 'PaymentPlanConfig';
  minDownpaymentRatio: Scalars['Float']['output'];
  thirdPartyLoanAllowed: Scalars['Boolean']['output'];
};

export type PaymentPlanData = {
  __typename?: 'PaymentPlanData';
  paymentPlans?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlan>>>;
};

export enum PaymentPlanItem {
  DownPayment = 'DOWN_PAYMENT',
  Lss = 'LSS',
  MonthlyPayment = 'MONTHLY_PAYMENT'
}

export enum PaymentPlanProvider {
  Peach = 'peach',
  Subzee = 'subzee'
}

export type PaymentPlanSetupAccountData = {
  __typename?: 'PaymentPlanSetupAccountData';
  accountHash?: Maybe<Scalars['String']['output']>;
  accountId?: Maybe<Scalars['String']['output']>;
  achData?: Maybe<AchData>;
  customerFirstName?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  financingType?: Maybe<FinancingType>;
  opportunities?: Maybe<Array<Maybe<PaymentPlanSetupOpportunity>>>;
  paymentMethods?: Maybe<Array<Maybe<Card>>>;
  paymentPlanProvider?: Maybe<PaymentPlanProvider>;
  paymentPlans?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlan>>>;
  payments?: Maybe<Array<Maybe<PastPayment>>>;
  paymentsOnHold?: Maybe<Array<PaymentOnHold>>;
  totalBalance: Scalars['Int']['output'];
  totalBookings: Scalars['Int']['output'];
  totalOutstanding: Scalars['Int']['output'];
  totalPaid?: Maybe<Scalars['Int']['output']>;
};

export type PaymentPlanSetupOpportunity = {
  __typename?: 'PaymentPlanSetupOpportunity';
  actualTotalCost?: Maybe<Scalars['String']['output']>;
  legalCaseStatus?: Maybe<Scalars['String']['output']>;
  opportunityAmount?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
  opportunityName: Scalars['String']['output'];
  opportunityOwner: Scalars['String']['output'];
  opportunityRecordType?: Maybe<Scalars['String']['output']>;
  servicesWithFilingFees: Scalars['Int']['output'];
  stageName: Scalars['String']['output'];
  thirdPartyFinanceAmount?: Maybe<Scalars['String']['output']>;
  thirdPartyFinanceApprovalDate?: Maybe<Scalars['String']['output']>;
};

export type PaymentPlanSetupPaymentPlan = {
  __typename?: 'PaymentPlanSetupPaymentPlan';
  components?: Maybe<Array<Maybe<PaymentPlanSetupPaymentPlanComponent>>>;
  opportunityId: Scalars['String']['output'];
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type PaymentPlanSetupPaymentPlanComponent = {
  __typename?: 'PaymentPlanSetupPaymentPlanComponent';
  amount: Scalars['Int']['output'];
  autopay?: Maybe<Autopay>;
  autopayAccepted?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<BillingCycleOutput>;
  description: Scalars['String']['output'];
  downPayments?: Maybe<Array<Scalars['String']['output']>>;
  endDate?: Maybe<Scalars['Date']['output']>;
  financeCompany?: Maybe<ThirdPartyProviderType>;
  id: Scalars['String']['output'];
  installments: Scalars['Int']['output'];
  overdueBalance?: Maybe<Scalars['Int']['output']>;
  paidBalance?: Maybe<Scalars['Int']['output']>;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  servicer?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Int']['output'];
  type: PaymentPlanSetupPaymentPlanType;
};

export enum PaymentPlanSetupPaymentPlanType {
  Credit = 'Credit',
  DownPayment = 'DownPayment',
  FilingFee = 'FilingFee',
  Lss = 'LSS',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  PayInFull = 'PayInFull',
  Refund = 'Refund',
  ThirdParty = 'ThirdParty'
}

export type PaymentPlansPaymentMethods = {
  __typename?: 'PaymentPlansPaymentMethods';
  paymentMethods?: Maybe<Array<Maybe<Card>>>;
};

export enum PaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PayoutItem = {
  amount: Scalars['Float']['output'];
  attorney?: Maybe<Attorney>;
  attorneyId: Scalars['String']['output'];
  billingDate: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  paymentNumber: Scalars['Int']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  type: PayoutItemType;
  updatedAt: Scalars['Date']['output'];
};

export enum PayoutItemType {
  Addendum = 'Addendum',
  Adjustment = 'Adjustment',
  Bonus = 'Bonus',
  Clawback = 'Clawback',
  Installment = 'Installment'
}

export type PaypalCheckout = {
  dealId: Scalars['String']['input'];
  details: Details;
  loanApplicationId: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type PaypalClientTokenResponse = {
  __typename?: 'PaypalClientTokenResponse';
  clientToken: Scalars['String']['output'];
};

export type PendingOnboardingAttorney = {
  __typename?: 'PendingOnboardingAttorney';
  attorney: PartialAttorney;
  id: Scalars['ID']['output'];
  isOnboarded: Scalars['Boolean']['output'];
};

export type Phones = {
  __typename?: 'Phones';
  external?: Maybe<Array<Maybe<Scalars['PhoneNumber']['output']>>>;
  fax?: Maybe<Array<Maybe<Scalars['PhoneNumber']['output']>>>;
  personal: Array<Maybe<Scalars['PhoneNumber']['output']>>;
};

export type PhonesInput = {
  external?: InputMaybe<Array<InputMaybe<Scalars['PhoneNumber']['input']>>>;
  fax?: InputMaybe<Array<InputMaybe<Scalars['PhoneNumber']['input']>>>;
  personal: Array<InputMaybe<Scalars['PhoneNumber']['input']>>;
};

export enum PlatformConfigurationsType {
  Mobile = 'mobile',
  Web = 'web'
}

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type PostLssFormInput = {
  clientServedDate?: InputMaybe<Scalars['Date']['input']>;
  deadLineToRespondDate?: InputMaybe<Scalars['Date']['input']>;
  lssNotes?: InputMaybe<Scalars['String']['input']>;
  lssOutcome: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  otherDate?: InputMaybe<Scalars['Date']['input']>;
  otherText?: InputMaybe<Scalars['String']['input']>;
  outOfScopeReason?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  scheduledHearingDate?: InputMaybe<Scalars['Date']['input']>;
  statueToLimitationDeadlineDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum PracticeArea {
  Bankruptcy = 'BANKRUPTCY',
  Criminal = 'CRIMINAL',
  Dui = 'DUI',
  Employment = 'EMPLOYMENT',
  EstatePlanning = 'ESTATE_PLANNING',
  Family = 'FAMILY',
  Immigration = 'IMMIGRATION',
  Tax = 'TAX',
  Unknown = 'UNKNOWN'
}

export type PrequalApplicationOutput = {
  __typename?: 'PrequalApplicationOutput';
  amount?: Maybe<Scalars['Float']['output']>;
  applicationProcessId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  lender: LenderName;
  lenderRefId?: Maybe<Scalars['String']['output']>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<GqlPrequalApplicationStatus>;
  updatedAt: Scalars['Date']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type PrequalInput = {
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  requestedAmount: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type PrequalResponse = {
  __typename?: 'PrequalResponse';
  loanApplicationId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  allAttorneys?: Maybe<Array<Maybe<Attorney>>>;
  checkAttonreysWithSlots?: Maybe<Scalars['JSON']['output']>;
  checkCalendarsAvailability?: Maybe<CheckCalendarsAvailabilityResponse>;
  genesisGetAccount?: Maybe<GenesisLoanAccount>;
  genesisGetLoanApplication?: Maybe<GenesisLoanApplication>;
  genesisGetLoanTerms?: Maybe<GenesisLoanTerms>;
  genesisGetPrequalApplication?: Maybe<GenesisPrequalApplication>;
  getAccountStatuses: Array<AccountStatus>;
  getAccountsForAch?: Maybe<Array<AccountForAch>>;
  getActiveCasesFilters?: Maybe<CaseFilters>;
  getActiveContracts: Array<Maybe<GetActiveContractsResponse>>;
  getAdmin?: Maybe<Admin>;
  getAdminNotifications?: Maybe<Array<Maybe<Notification>>>;
  getAdmins: Array<Maybe<Admin>>;
  getAdminsByTeams: Array<Admin>;
  getAllBillingDates: Array<Maybe<Scalars['Date']['output']>>;
  getAllFinancingOptions: Array<UserFinancingOptionOrNull>;
  getAppConfigurations?: Maybe<AppConfigurations>;
  getAppInstallationsInfo?: Maybe<AppInstallation>;
  getAppPrivateConfigurations?: Maybe<AppConfigurations>;
  getAppPublicConfigurations?: Maybe<AppConfigurations>;
  getAttorney?: Maybe<Attorney>;
  getAttorneys?: Maybe<Array<Maybe<Attorney>>>;
  getCalendarsAvailability?: Maybe<Array<Maybe<CalendarAvailability>>>;
  getCalendarsAvailabilityByUrl?: Maybe<Array<Maybe<CalendarAvailability>>>;
  getCase?: Maybe<Case>;
  getCaseByExternalId?: Maybe<Case>;
  getCaseCostTicketDataById?: Maybe<CaseCostTicketData>;
  getCaseDocument?: Maybe<CaseDocument>;
  getCaseDocumentFormById?: Maybe<CaseDocumentForm>;
  /** @deprecated Use getCase.documents instead */
  getCaseDocuments: Array<Maybe<CaseDocument>>;
  getCaseDocumentsByCaseId?: Maybe<Array<Maybe<CaseDocument>>>;
  getCaseDocumentsWithForms: Array<Maybe<ICaseDocument>>;
  getCaseEventById?: Maybe<CaseEvent>;
  getCaseEvents?: Maybe<Array<Maybe<CaseEvent>>>;
  getCases?: Maybe<CasesResults>;
  getChatHistory?: Maybe<Array<Maybe<Ticket>>>;
  getCoveredCarePrequalData?: Maybe<Array<CoveredCarePrequalData>>;
  getCoveredCarePrequalLink: CoveredCarePrequalLink;
  getCurrent?: Maybe<Customer>;
  getCurrentAdmin?: Maybe<Admin>;
  getCurrentAttorney?: Maybe<Attorney>;
  getCustomerCases?: Maybe<Array<Maybe<Case>>>;
  getCustomerFinancingSummary?: Maybe<CustomerFinancingSummary>;
  getCustomerNotifications?: Maybe<Array<Maybe<Notification>>>;
  getCustomerPaymentPlanData?: Maybe<PaymentPlanData>;
  getCustomerRiskLevel: GetCustomerRiskLevelResult;
  getCustomerUnreadNotificationsCount?: Maybe<Scalars['Int']['output']>;
  getDecryptedPhone?: Maybe<Scalars['String']['output']>;
  getDocument?: Maybe<Scalars['String']['output']>;
  getDocumentClassificationsWithCategories?: Maybe<Array<Maybe<DocumentClassificationsWithCategory>>>;
  getFormTemplate?: Maybe<FormTemplate>;
  getFormTemplates: Array<Maybe<FormTemplate>>;
  getForms?: Maybe<Array<Maybe<Form>>>;
  getFreeTaxFunnelPDF?: Maybe<Scalars['String']['output']>;
  getIdentityVerification?: Maybe<IdentityVerificationOutput>;
  getInHouseFinancingType?: Maybe<FinancingType>;
  getIntakeMeetingSlots: IntakeMeetingSlots;
  getIsReadyForCaseStatus: Scalars['Boolean']['output'];
  getLeadIdByFunnelActionId?: Maybe<Lead>;
  getLegalProducts?: Maybe<Array<Maybe<LegalProduct>>>;
  getLegalServices: GetLegalServicesResult;
  getLssAttorneyCases?: Maybe<Array<Maybe<Case>>>;
  getManagerCases?: Maybe<Array<Maybe<Case>>>;
  getNotification?: Maybe<Notification>;
  getOpportunityLinkFields: OpportunityLinkFields;
  getOpportunityLinkFieldsByCaseId: OpportunityLinkFields;
  getOpportunityPaymentData: OpportunityPaymentData;
  getOpportunityStage?: Maybe<OpportunityStage>;
  getOpportunityThirdPartyPayerFinancingOptions: Array<PayerFinancingOptions>;
  getPaginatedDiyUsers?: Maybe<GetPaginatedDiyUsersResponse>;
  getPaidDownPaymentAmount: DownPaymentsPaidAmount;
  getPaidLssAmount: PaidLssAmount;
  getPastTransactions?: Maybe<Array<PastTransaction>>;
  getPaymentAccount?: Maybe<PaymentAccount>;
  getPaymentAgreement: PaymentAgreementResponse;
  getPaymentPlanBalanceData?: Maybe<PartialPaymentAccount>;
  getPaymentPlanSetupAccountData?: Maybe<PaymentPlanSetupAccountData>;
  getPaymentsOnHold?: Maybe<Array<PaymentOnHold>>;
  getPayoutItems: Array<Maybe<PayoutItem>>;
  getPendingOnboardingAttorneys?: Maybe<Array<Maybe<PendingOnboardingAttorney>>>;
  getPendingSigningRequestDetails?: Maybe<SigningRequestDetails>;
  getPrequalApplications: Array<PrequalApplicationOutput>;
  getRefundMetadata: RefundMetadata;
  getRefundTicketById: GetRefundTicketByIdResponse;
  getRejectedCustomers?: Maybe<Array<Maybe<RejectedCustomer>>>;
  getRequiredDocuments?: Maybe<Array<Maybe<RequiredDocument>>>;
  getSFAttorneyEvents: Array<SfEvent>;
  getSFIntakeData?: Maybe<SfIntakeData>;
  getSFLegalServices?: Maybe<Array<Maybe<SfLegalService>>>;
  getSFServices: Array<SfService>;
  getSchedulingPageForUser?: Maybe<Array<Maybe<Page>>>;
  getServicesPaymentDataForOpportunity: ServicesPaymentDataForOpportunity;
  getSignDocumentLinks?: Maybe<SignDocumentLinks>;
  getSuggestedThirdPartyProvider: SuggestedThirdPartyProviderResponse;
  getThirdPartyFinancedAmount: ThirdPartyFinancedAmount;
  getTopDocumentClassifications?: Maybe<Array<Maybe<DocumentClassification>>>;
  getTransactionLoanAmountStatusByOpportunityId: WisetackLoanAmountStatus;
  getUserPastTransactions?: Maybe<Array<PastTransaction>>;
  getUserRoleName?: Maybe<UserRoleName>;
  inHouseGetPrequalApplication?: Maybe<InHousePrequalApplication>;
  isBitlyLoanApplicationSent?: Maybe<Scalars['Boolean']['output']>;
  isSelfCheckoutEligible?: Maybe<Scalars['Boolean']['output']>;
  loanProviderByExternalId: Array<LoanProvider>;
  onboardingProgress?: Maybe<OnboardingProgress>;
  paypalGenerateClientToken: PaypalClientTokenResponse;
  renderContract: RenderContractResponse;
  renderDraft: RenderContractResponse;
  underwritingFinancingOption?: Maybe<FinancingOption>;
  underwritingFinancingOptionV2?: Maybe<FinancingOption>;
  underwritingGetUserByExternalId?: Maybe<UwUser>;
  underwritingUser?: Maybe<UwUser>;
};


export type QueryAllAttorneysArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryCheckCalendarsAvailabilityArgs = {
  alertAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGenesisGetAccountArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetLoanApplicationArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetLoanTermsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGenesisGetPrequalApplicationArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetAccountStatusesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetAccountsForAchArgs = {
  input: AccountsForAchInput;
};


export type QueryGetActiveCasesFiltersArgs = {
  activeFilters?: InputMaybe<CasesFilterInput>;
};


export type QueryGetActiveContractsArgs = {
  input: GetActiveContractsInput;
};


export type QueryGetAdminArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetAdminNotificationsArgs = {
  filter: CustomerNotificationInput;
  orderBy?: InputMaybe<NotificationsOrderByInput>;
};


export type QueryGetAdminsByTeamsArgs = {
  teams: Array<InputMaybe<Team>>;
};


export type QueryGetAllFinancingOptionsArgs = {
  input: GetUnderwritingFinancingInput;
};


export type QueryGetAppConfigurationsArgs = {
  input: GetAppConfigurationsInput;
};


export type QueryGetAppInstallationsInfoArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetAppPrivateConfigurationsArgs = {
  input: GetAppPrivateConfigurationsInput;
};


export type QueryGetAppPublicConfigurationsArgs = {
  input: GetAppPublicConfigurationsInput;
};


export type QueryGetAttorneyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCalendarsAvailabilityArgs = {
  input: GetCalendarsAvailabilityInput;
};


export type QueryGetCalendarsAvailabilityByUrlArgs = {
  input: Array<InputMaybe<Scalars['String']['input']>>;
  options?: InputMaybe<GetCalendarsAvailabilityByUrlOptions>;
};


export type QueryGetCaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCaseByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryGetCaseCostTicketDataByIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetCaseDocumentArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryGetCaseDocumentFormByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetCaseDocumentsArgs = {
  filter: CaseDocumentFilterInput;
};


export type QueryGetCaseDocumentsByCaseIdArgs = {
  caseId: Scalars['ID']['input'];
};


export type QueryGetCaseDocumentsWithFormsArgs = {
  filter: CaseDocumentFilterInput;
};


export type QueryGetCaseEventByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCaseEventsArgs = {
  filter?: InputMaybe<CaseEventsFilterInput>;
};


export type QueryGetCasesArgs = {
  filter: CasesFilterInput;
};


export type QueryGetCoveredCarePrequalDataArgs = {
  input?: InputMaybe<SignedInput>;
};


export type QueryGetCoveredCarePrequalLinkArgs = {
  amountToFinance?: InputMaybe<Scalars['Int']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetCustomerFinancingSummaryArgs = {
  externalId: Scalars['ID']['input'];
  opportunityId: Scalars['ID']['input'];
};


export type QueryGetCustomerNotificationsArgs = {
  filter: CustomerNotificationInput;
};


export type QueryGetCustomerRiskLevelArgs = {
  input: GetCustomerRiskLevelInput;
};


export type QueryGetDecryptedPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type QueryGetFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetFormTemplatesArgs = {
  filter?: InputMaybe<FormTemplatesFilterInput>;
};


export type QueryGetFreeTaxFunnelPdfArgs = {
  isAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  payload: FreeTaxGeneratorPayload;
};


export type QueryGetIdentityVerificationArgs = {
  input: IdentityVerificationInput;
};


export type QueryGetIntakeMeetingSlotsArgs = {
  input: IntakeMeetingSlotsInput;
};


export type QueryGetIsReadyForCaseStatusArgs = {
  caseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetLeadIdByFunnelActionIdArgs = {
  funnelActionId: Scalars['String']['input'];
};


export type QueryGetLegalServicesArgs = {
  options: GetLegalServicesOptions;
};


export type QueryGetManagerCasesArgs = {
  filter: ManagerCasesFilterInput;
};


export type QueryGetNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOpportunityLinkFieldsArgs = {
  input: OpportunityLinkFieldsInput;
};


export type QueryGetOpportunityLinkFieldsByCaseIdArgs = {
  input: OpportunityLinkFieldsByCaseIdInput;
};


export type QueryGetOpportunityPaymentDataArgs = {
  input: PaymentInput;
};


export type QueryGetOpportunityStageArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetOpportunityThirdPartyPayerFinancingOptionsArgs = {
  input: GetOpportunityThirdPartyPayerFinancingOptionsInput;
};


export type QueryGetPaginatedDiyUsersArgs = {
  options?: InputMaybe<GetPaginatedDiyUsersParams>;
};


export type QueryGetPaidDownPaymentAmountArgs = {
  input: GetPaidDownPaymentsAmountInput;
};


export type QueryGetPaidLssAmountArgs = {
  input: GetPaidLssAmountInput;
};


export type QueryGetPastTransactionsArgs = {
  accountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPaymentAgreementArgs = {
  accountId: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentPlanSetupAccountDataArgs = {
  accountId: Scalars['String']['input'];
  agentId?: InputMaybe<Scalars['String']['input']>;
  signature: Scalars['String']['input'];
};


export type QueryGetPaymentsOnHoldArgs = {
  accountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};


export type QueryGetPayoutItemsArgs = {
  input?: InputMaybe<GetPayoutItemsInput>;
};


export type QueryGetPendingSigningRequestDetailsArgs = {
  input: GetPendingSigningRequestDetailsInput;
};


export type QueryGetPrequalApplicationsArgs = {
  lenderName?: InputMaybe<LenderName>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetRefundMetadataArgs = {
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
};


export type QueryGetRefundTicketByIdArgs = {
  input: GetRefundTicketByIdInput;
};


export type QueryGetRequiredDocumentsArgs = {
  options: GetRequiredDocumentsOptions;
};


export type QueryGetSfAttorneyEventsArgs = {
  attorneyId: Scalars['ID']['input'];
};


export type QueryGetSfIntakeDataArgs = {
  opportunityId: Scalars['ID']['input'];
};


export type QueryGetSfLegalServicesArgs = {
  input: LegalServicesInput;
};


export type QueryGetSfServicesArgs = {
  input: SfServicesInput;
};


export type QueryGetSchedulingPageForUserArgs = {
  input: Scalars['String']['input'];
};


export type QueryGetServicesPaymentDataForOpportunityArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetSignDocumentLinksArgs = {
  input?: InputMaybe<SignDocumentParameters>;
};


export type QueryGetSuggestedThirdPartyProviderArgs = {
  input: GetSuggestedThirdPartyProviderInput;
};


export type QueryGetThirdPartyFinancedAmountArgs = {
  input: GetThirdPartyFinancedAmountInput;
};


export type QueryGetTransactionLoanAmountStatusByOpportunityIdArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryGetUserPastTransactionsArgs = {
  opportunityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserRoleNameArgs = {
  salesforceId: Scalars['String']['input'];
};


export type QueryInHouseGetPrequalApplicationArgs = {
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryIsBitlyLoanApplicationSentArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryIsSelfCheckoutEligibleArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryLoanProviderByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryOnboardingProgressArgs = {
  input: GetOnboardingProgressInput;
};


export type QueryRenderContractArgs = {
  input: RenderContractInput;
};


export type QueryRenderDraftArgs = {
  input: RenderDraftInput;
};


export type QueryUnderwritingFinancingOptionArgs = {
  input: UnderwritingFinancingOptionInput;
};


export type QueryUnderwritingFinancingOptionV2Args = {
  input: UnderwritingFinancingOptionInput;
};


export type QueryUnderwritingGetUserByExternalIdArgs = {
  input: GetUserByExternalIdInput;
};


export type QueryUnderwritingUserArgs = {
  input: GetUserInput;
};

export type RecommendedService = {
  details?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type RecommendedServiceDetails = {
  __typename?: 'RecommendedServiceDetails';
  details?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
};

export type RecurringPlan = PaymentPlanComponent & {
  __typename?: 'RecurringPlan';
  amount: Scalars['Int']['output'];
  autopay?: Maybe<Autopay>;
  autopayAccepted?: Maybe<Scalars['Boolean']['output']>;
  billingCycle?: Maybe<BillingCycleOutput>;
  description: Scalars['String']['output'];
  downPayments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromDate: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  numOfPayments: Scalars['Int']['output'];
  overdueBalance?: Maybe<Scalars['Int']['output']>;
  paidBalance?: Maybe<Scalars['Int']['output']>;
  paymentFrequency: PaymentFrequency;
  paymentMethodLast4?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  product: PaymentPlanItem;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
  servicer?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  toDate: Scalars['Date']['output'];
  totalAmount: Scalars['Int']['output'];
};

export type RefundLoanApplicationInput = {
  apiKey: Scalars['String']['input'];
  opportunityId: Scalars['String']['input'];
  refundAmount: Scalars['Float']['input'];
};

export type RefundLoanInput = {
  amountInCents?: InputMaybe<Scalars['Int']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  lenderName: LenderName;
  loanId: Scalars['String']['input'];
};

export type RefundLoanOutput = {
  __typename?: 'RefundLoanOutput';
  success: Scalars['Boolean']['output'];
};

export type RefundMetadata = {
  __typename?: 'RefundMetadata';
  calculatedRefundAmount?: Maybe<Scalars['Float']['output']>;
  manualRefundAmount?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opportunityId: Scalars['String']['output'];
  reason: TransactionRefundReason;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
  services: Array<ServiceMetadata>;
  sfAccountId: Scalars['String']['output'];
  sfAccountName: Scalars['String']['output'];
  ticketId: Scalars['String']['output'];
};

export enum RefundReason {
  CloseLostOpp = 'Close_Lost_Opp',
  DisengagementRequest = 'Disengagement_Request',
  DowngradeOrScopeSwap = 'Downgrade_Or_Scope_Swap',
  Downpayment = 'Downpayment',
  Escalation = 'Escalation',
  LssFee = 'LSS_Fee',
  NegativeReviewOrEscalation = 'Negative_Review_Or_Escalation',
  PaymentDateIssue = 'Payment_Date_Issue',
  Subzee = 'Subzee'
}

export enum RefundTicketStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  New = 'New'
}

export type RefundTransaction = {
  amountToRefund: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  loanId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider: TransactionPaymentProvider;
  provider: TransactionsProvider;
  status: TransactionsStatus;
  type: TransactionsType;
};

export type RefundTransactionResult = {
  __typename?: 'RefundTransactionResult';
  id: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RegisterStripeCustomerInput = {
  amountDue?: InputMaybe<Scalars['String']['input']>;
  customerDetails: CustomerDetails;
  description?: InputMaybe<StripeDescription>;
  metadata?: InputMaybe<StripeMetadata>;
};

export type RegisterStripeCustomerResponse = {
  __typename?: 'RegisterStripeCustomerResponse';
  clientSecret: Scalars['String']['output'];
};

export type RejectedCustomer = {
  __typename?: 'RejectedCustomer';
  customerRejectionType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  practiceArea?: Maybe<PracticeArea>;
};

export type RejectedLssSummaryInput = {
  caseId: Scalars['ID']['input'];
  meetingTime: Scalars['DateTime']['input'];
  other?: InputMaybe<Scalars['String']['input']>;
  reason: LssRejectionReason;
};

export type RenderContractInput = {
  contractType: ContractType;
  opportunityId: Scalars['String']['input'];
  tenant?: InputMaybe<ElTenant>;
};

export type RenderContractResponse = {
  __typename?: 'RenderContractResponse';
  contractHtml: Scalars['String']['output'];
};

export type RenderDraftInput = {
  elType?: InputMaybe<ElType>;
  opportunityId: Scalars['String']['input'];
  overrides?: InputMaybe<ElOverrides>;
  payInFull?: InputMaybe<Scalars['Boolean']['input']>;
  selfServe?: InputMaybe<Scalars['Boolean']['input']>;
  tenant?: InputMaybe<ElTenant>;
};

export type RequiredDocument = {
  __typename?: 'RequiredDocument';
  classification: DocumentClassification;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalServiceExternalId?: Maybe<Scalars['String']['output']>;
};

export type RescheduleLssInput = {
  caseId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type RescheduleLssMeetingInput = {
  caseId: Scalars['ID']['input'];
  meetingTime: Scalars['DateTime']['input'];
  reason: Scalars['String']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export type RunCoveredCarePrequalInput = {
  opportunityId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type RunCreditCheckInput = {
  address: CreditCheckAddressInput;
  dateOfBirth: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SfEvent = {
  __typename?: 'SFEvent';
  assignedAttorney: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type SfIntakeData = {
  __typename?: 'SFIntakeData';
  charges?: Maybe<Scalars['String']['output']>;
  clientFirstName?: Maybe<Scalars['String']['output']>;
  clientLastName: Scalars['String']['output'];
  county: Scalars['String']['output'];
  courtCaseId?: Maybe<Scalars['String']['output']>;
  courtDate?: Maybe<Scalars['String']['output']>;
  criticalDates?: Maybe<Array<SfIntakeDataCriticalDate>>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  inTakeAgentName?: Maybe<Scalars['String']['output']>;
  inTakeDate?: Maybe<Scalars['String']['output']>;
  inTakeNotes?: Maybe<Scalars['String']['output']>;
  opposingPartyFirstName?: Maybe<Scalars['String']['output']>;
  opposingPartyLastName?: Maybe<Scalars['String']['output']>;
  practice: Scalars['String']['output'];
  priors?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type SfIntakeDataCriticalDate = {
  __typename?: 'SFIntakeDataCriticalDate';
  criticalDate: Scalars['String']['output'];
  criticalDateType: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type SfLegalService = {
  __typename?: 'SFLegalService';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SfService = {
  __typename?: 'SFService';
  addendumProduct?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serviceCode?: Maybe<Scalars['String']['output']>;
};

export type SfServicesInput = {
  practice: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type SfUpdateEventInput = {
  eventId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type ScheduleLssMeetingReminderInput = {
  caseId: Scalars['ID']['input'];
};

export type ScheduledMeeting = {
  __typename?: 'ScheduledMeeting';
  createdAt?: Maybe<Scalars['String']['output']>;
  meetingTime?: Maybe<Scalars['String']['output']>;
};

export type SendAddServicesRequestInput = {
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['PhoneNumber']['input'];
};

export type SendAttorneyFeedbackInput = {
  feedback: Scalars['String']['input'];
};

export type SendPayoutMailResult = {
  __typename?: 'SendPayoutMailResult';
  attorneyId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SendPayoutMailsInput = {
  billingDate: Scalars['Date']['input'];
};

export type SendPayoutMailsResponse = {
  __typename?: 'SendPayoutMailsResponse';
  billingDate: Scalars['Date']['output'];
  results: Array<Maybe<SendPayoutMailResult>>;
};

export type ServiceMetadata = {
  __typename?: 'ServiceMetadata';
  internalId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  priceAfterDiscount: Scalars['Int']['output'];
  sfServiceId: Scalars['ID']['output'];
};

export type ServiceOnOpportunity = {
  __typename?: 'ServiceOnOpportunity';
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priceAfterDiscountInCents: Scalars['Int']['output'];
  priceInCents: Scalars['Int']['output'];
};

export type ServicePaymentData = {
  __typename?: 'ServicePaymentData';
  clientFacingDescription?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downgrade: Scalars['Boolean']['output'];
  serviceId: Scalars['String']['output'];
  serviceName: Scalars['String']['output'];
  servicePrice: Scalars['String']['output'];
  servicePriceAfterDiscount: Scalars['String']['output'];
};

export type ServicesPaymentDataForOpportunity = {
  __typename?: 'ServicesPaymentDataForOpportunity';
  opportunityId: Scalars['String']['output'];
  servicesPaymentData?: Maybe<Array<Maybe<ServicePaymentData>>>;
  totalBalance?: Maybe<Scalars['Int']['output']>;
  totalDiscount?: Maybe<Scalars['Int']['output']>;
};

export type SetNotificationsAllowedInput = {
  appBundleId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  isAllowed: Scalars['Boolean']['input'];
};

export type SetUserInput = {
  address?: InputMaybe<UwAddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type SignDocumentLinks = {
  __typename?: 'SignDocumentLinks';
  downloadUrl: Scalars['String']['output'];
  signUrl: Scalars['String']['output'];
};

export type SignDocumentParameters = {
  requestId: Scalars['String']['input'];
  signerId: Scalars['String']['input'];
};

export type SignedInput = {
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type SigningRequestDetails = {
  __typename?: 'SigningRequestDetails';
  completedBy: Array<Maybe<Scalars['String']['output']>>;
  contractId: Scalars['String']['output'];
  contractTemplateName: Scalars['String']['output'];
  contractVersion: Scalars['String']['output'];
  downloadLink: Scalars['String']['output'];
  renderData: Scalars['String']['output'];
  signingId: Scalars['String']['output'];
  signingUrl: Scalars['String']['output'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortInput = {
  direction?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export type SpecificCaseEventsFilterInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Stage = {
  __typename?: 'Stage';
  /** @deprecated Not in use */
  date?: Maybe<Scalars['Date']['output']>;
  value: StageEnum;
};

export enum StageEnum {
  /** @deprecated Replaced with new enums */
  AttDraftFormPrep = 'ATT_DRAFT_FORM_PREP',
  /** @deprecated Replaced with new enums */
  CaseClosure = 'CASE_CLOSURE',
  /** @deprecated Replaced with new enums */
  CaseCompleted = 'CASE_COMPLETED',
  /** @deprecated Replaced with new enums */
  CaseOnboarding = 'CASE_ONBOARDING',
  /** @deprecated Replaced with new enums */
  CompleteScope = 'COMPLETE_SCOPE',
  /** @deprecated Replaced with new enums */
  CourtHearing = 'COURT_HEARING',
  /** @deprecated Replaced with new enums */
  CourtPrep = 'COURT_PREP',
  /** @deprecated Replaced with new enums */
  Disengaged = 'DISENGAGED',
  /** @deprecated Replaced with new enums */
  DocsFiling = 'DOCS_FILING',
  /** @deprecated Replaced with new enums */
  DocumentService = 'DOCUMENT_SERVICE',
  /** @deprecated Replaced with new enums */
  FilePetitionToCourt = 'FILE_PETITION_TO_COURT',
  /** @deprecated Replaced with new enums */
  FileProofToCourt = 'FILE_PROOF_TO_COURT',
  /** @deprecated Replaced with new enums */
  FormPrep = 'FORM_PREP',
  /** @deprecated Replaced with new enums */
  HearingPrep = 'HEARING_PREP',
  IntroductionCall = 'INTRODUCTION_CALL',
  /** @deprecated Replaced with new enums */
  LpPacketReady = 'LP_PACKET_READY',
  /** @deprecated Replaced with new enums */
  LpPacketSent = 'LP_PACKET_SENT',
  NotStarted = 'NOT_STARTED',
  /** @deprecated Replaced with new enums */
  ObtainConformedCopies = 'OBTAIN_CONFORMED_COPIES',
  /** @deprecated Replaced with new enums */
  Offboarding = 'OFFBOARDING',
  Onboarding = 'ONBOARDING',
  /** @deprecated Replaced with new enums */
  OnboardingCall = 'ONBOARDING_CALL',
  /** @deprecated Replaced with new enums */
  OnboardingClientForms = 'ONBOARDING_CLIENT_FORMS',
  /** @deprecated Replaced with new enums */
  OnboardingLegalForms = 'ONBOARDING_LEGAL_FORMS',
  /** @deprecated Replaced with new enums */
  PreOnboarding = 'PRE_ONBOARDING',
  Rejected = 'REJECTED',
  /** @deprecated Replaced with new enums */
  ServeToOpposingParty = 'SERVE_TO_OPPOSING_PARTY',
  ServiceDelivery = 'SERVICE_DELIVERY',
  WrappingUp = 'WRAPPING_UP'
}

export type StartLoanApplicationInput = {
  amountInCents?: InputMaybe<Scalars['Int']['input']>;
  lenderName: LenderName;
  opportunityId: Scalars['String']['input'];
  prequalApplicationId?: InputMaybe<Scalars['String']['input']>;
};

export type StartLoanApplicationOutput = {
  __typename?: 'StartLoanApplicationOutput';
  id: Scalars['String']['output'];
  lender: LenderName;
  uri: Scalars['String']['output'];
};

export type StripeData = {
  __typename?: 'StripeData';
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type StripeDescription = {
  deal_id?: InputMaybe<Scalars['String']['input']>;
  practiceArea?: InputMaybe<Scalars['String']['input']>;
};

export type StripeMetadata = {
  deal_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  hubspot_link?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitRequestForDpPolicyChangeInput = {
  cardType: Scalars['String']['input'];
  minDpAmount: Scalars['Float']['input'];
  minDpPercent: Scalars['Float']['input'];
  opportunityId: Scalars['String']['input'];
  policyMinDpPercent: Scalars['Float']['input'];
  reason: Scalars['String']['input'];
  totalBookings: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type SubmitRequestForDpPolicyChangeResult = {
  __typename?: 'SubmitRequestForDpPolicyChangeResult';
  approved: Scalars['Boolean']['output'];
};

export type SuggestedThirdPartyProviderResponse = {
  __typename?: 'SuggestedThirdPartyProviderResponse';
  creditCheckErrorCode?: Maybe<CreditCheckErrorCode>;
  provider?: Maybe<ThirdPartyProviderType>;
};

export type SyncCaseFromOpportunityInput = {
  externalId: Scalars['String']['input'];
};

export type SyncClosedCaseInput = {
  caseId: Scalars['ID']['input'];
};

export type SyncDeviceResponse = {
  __typename?: 'SyncDeviceResponse';
  isSupported?: Maybe<Scalars['Boolean']['output']>;
};

export type SyncDocumentToDriveInput = {
  documentId: Scalars['ID']['input'];
  options?: InputMaybe<SyncDocumentToDriveOptions>;
};

export type SyncDocumentToDriveOptions = {
  isNotifyAttorneyOnAttorneyFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyAttorneyOnCaseFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyParalegalOnAttorneyFolder?: InputMaybe<Scalars['Boolean']['input']>;
  isNotifyParalegalOnCaseFolder?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Team {
  Payments = 'PAYMENTS'
}

export type ThirdPartyFinancedAmount = {
  __typename?: 'ThirdPartyFinancedAmount';
  amount?: Maybe<Scalars['Int']['output']>;
};

export enum ThirdPartyProviderType {
  Affirm = 'Affirm',
  Concora = 'Concora',
  Covered = 'Covered',
  Genesis = 'Genesis',
  InHouse = 'InHouse',
  Klarna = 'Klarna',
  Paypal = 'Paypal',
  Wisetack = 'Wisetack'
}

export type Ticket = {
  __typename?: 'Ticket';
  comments: Array<TicketComment>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
};

export type TicketComment = {
  __typename?: 'TicketComment';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sentAt: Scalars['String']['output'];
  sentByUser: Scalars['Boolean']['output'];
};

export enum TouchpointOptions {
  Call = 'CALL',
  Email = 'EMAIL',
  Slack = 'SLACK',
  Sms = 'SMS'
}

export type TransactionPaymentMethod = {
  __typename?: 'TransactionPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  type: TransactionPaymentMethodType;
};

export enum TransactionPaymentMethodType {
  Ach = 'Ach',
  Bank = 'Bank',
  Card = 'Card',
  Credit = 'Credit',
  Unknown = 'Unknown',
  Wire = 'Wire'
}

export enum TransactionPaymentProvider {
  Affirm = 'Affirm',
  Covered = 'Covered',
  Genesis = 'Genesis',
  Klarna = 'Klarna',
  Paypal = 'Paypal',
  Peach = 'Peach',
  Stripe = 'Stripe',
  Subzee = 'Subzee',
  Wisetack = 'Wisetack'
}

export enum TransactionRefundReason {
  CloseLostOpp = 'Close_Lost_Opp',
  DisengagementRequest = 'Disengagement_Request',
  DowngradeOrScopeSwap = 'Downgrade_Or_Scope_Swap',
  Downpayment = 'Downpayment',
  Escalation = 'Escalation',
  LssFee = 'LSS_Fee',
  NegativeReviewOrEscalation = 'Negative_Review_Or_Escalation',
  PaymentDateIssue = 'Payment_Date_Issue',
  Subzee = 'Subzee'
}

export enum TransactionsProvider {
  Affirm = 'Affirm',
  Covered = 'Covered',
  Genesis = 'Genesis',
  Klarna = 'Klarna',
  Marble = 'MARBLE',
  Pay = 'PAY',
  Paypal = 'Paypal',
  Ric = 'RIC',
  Wisetack = 'Wisetack'
}

export enum TransactionsStatus {
  Completed = 'Completed',
  Disputed = 'Disputed',
  Failed = 'Failed',
  FullRefund = 'FullRefund',
  PartialRefund = 'PartialRefund',
  Pending = 'Pending'
}

export enum TransactionsType {
  Credit = 'Credit',
  DownPayment = 'DownPayment',
  FilingServingFee = 'FilingServingFee',
  Lss = 'Lss',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  OneTime = 'OneTime'
}

export type TryStartCaseInput = {
  opportunityId: Scalars['String']['input'];
};

export type TryStartCaseResponse = {
  __typename?: 'TryStartCaseResponse';
  success: Scalars['Boolean']['output'];
};

export type TwiceMonthly = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type TwiceMonthlyOutput = {
  __typename?: 'TwiceMonthlyOutput';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export enum TypeOfFee {
  Filing = 'FILING',
  FilingAndServing = 'FILING_AND_SERVING',
  Serving = 'SERVING'
}

export type UtmInput = {
  adgroupId?: InputMaybe<Scalars['String']['input']>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marbleCategory?: InputMaybe<Scalars['String']['input']>;
  matchLocation?: InputMaybe<Scalars['String']['input']>;
  matchType?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  textAdgroupId?: InputMaybe<Scalars['String']['input']>;
  textCampaignId?: InputMaybe<Scalars['String']['input']>;
  ttclid?: InputMaybe<Scalars['String']['input']>;
};

export type UwAddress = {
  __typename?: 'UWAddress';
  apartment?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type UwAddressInput = {
  apartment?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UwUser = {
  __typename?: 'UWUser';
  address?: Maybe<UwAddress>;
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  monthlyIncome?: Maybe<Scalars['Int']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  thirdPartyPayerForExternalId?: Maybe<Scalars['Int']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UnderwritingFinancingOptionInput = {
  agentId?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UpdateAttorneyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<AddressInput>;
  attorneyType?: InputMaybe<AttorneyType>;
  barLicenseExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  barLicenseId?: InputMaybe<Scalars['String']['input']>;
  barNumber?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  cafNumber?: InputMaybe<Scalars['String']['input']>;
  calendlyEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  calendlyLink?: InputMaybe<Scalars['String']['input']>;
  caseCapacity?: InputMaybe<Scalars['Int']['input']>;
  casesOffLimits?: InputMaybe<Array<InputMaybe<CasesOffLimits>>>;
  casesOffLimitsOther?: InputMaybe<Scalars['String']['input']>;
  conservatorship?: InputMaybe<Scalars['Boolean']['input']>;
  contestedDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  contractLink?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  familyCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  guardianship?: InputMaybe<Scalars['Boolean']['input']>;
  handlesCases?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inHouse?: InputMaybe<Scalars['Boolean']['input']>;
  joinedAt?: InputMaybe<Scalars['DateTime']['input']>;
  juvenileCrime?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  licensingJurisdiction?: InputMaybe<Scalars['String']['input']>;
  litigation?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Scalars['JSON']['input']>;
  marbleId?: InputMaybe<Scalars['String']['input']>;
  matrimonialCourtNY?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<NameInput>;
  numberOfActiveCases?: InputMaybe<Scalars['Int']['input']>;
  numberOfAssociates?: InputMaybe<Scalars['Int']['input']>;
  numberOfLegalSecretaries?: InputMaybe<Scalars['Int']['input']>;
  numberOfParalegals?: InputMaybe<Scalars['Int']['input']>;
  performsLSS?: InputMaybe<Scalars['Boolean']['input']>;
  phones?: InputMaybe<PhonesInput>;
  practiceAreas?: InputMaybe<Array<PracticeArea>>;
  prenup?: InputMaybe<Scalars['Boolean']['input']>;
  ptin?: InputMaybe<Scalars['String']['input']>;
  shortDeadline?: InputMaybe<Scalars['Boolean']['input']>;
  toggleHandlesCasesAt?: InputMaybe<Scalars['Date']['input']>;
  togglePerformsLSSAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateCaseCourtInfoInput = {
  caseId: Scalars['ID']['input'];
  caseNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCaseDocumentFormFieldsInput = {
  fields: Array<CaseDocumentFormFieldsUpdateInput>;
  id: Scalars['ID']['input'];
};

export type UpdateCaseDocumentFormStatusInput = {
  id: Scalars['ID']['input'];
  newStatus: CaseDocumentStatus;
};

export type UpdateCaseDocumentTypeInput = {
  classificationId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};

export type UpdateCaseEventInput = {
  arbitraryLocation?: InputMaybe<Scalars['String']['input']>;
  caseId?: InputMaybe<Scalars['ID']['input']>;
  eventSubtype?: InputMaybe<CaseEventSubtype>;
  eventType?: InputMaybe<CaseEventType>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<CaseEventLocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  occursAt?: InputMaybe<Scalars['DateTime']['input']>;
  occursAtTime?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<CaseEventSource>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  virtualLocation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCaseTaskInput = {
  id: Scalars['String']['input'];
  status: CaseTaskStatusInput;
};

export type UpdateDefaultPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
  sfAccountId: Scalars['String']['input'];
  signature: Scalars['String']['input'];
};

export type UpdateDocumentSharingWithClientPayload = {
  id: Scalars['ID']['input'];
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFormTemplateInput = {
  county?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<PracticeArea>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLegalProductInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSfRefundTicketInput = {
  accountId: Scalars['String']['input'];
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  refundAmountInDollars?: InputMaybe<Scalars['Float']['input']>;
  refundReason?: InputMaybe<RefundReason>;
  signature: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
};

export type UpdateUserInput = {
  address?: InputMaybe<UwAddressInput>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  monthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWebLeadInput = {
  AdditionalQuestion__c?: InputMaybe<Scalars['String']['input']>;
  Funnel_Parameters__c?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateWhatIsNextInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  value: WhatIsNextEnum;
};

export type UpdatedCaseDocumentStatus = {
  __typename?: 'UpdatedCaseDocumentStatus';
  id: Scalars['ID']['output'];
  status: CaseDocumentStatus;
};

export type UpsertCaseTaskResult = {
  __typename?: 'UpsertCaseTaskResult';
  case?: Maybe<Case>;
  caseTask: CaseTask;
};

export type UpsertDiyFunnelData = {
  customerId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAfterOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  isAlreadyViewed?: InputMaybe<Scalars['Boolean']['input']>;
  selectedService?: InputMaybe<Scalars['String']['input']>;
  stepsData?: InputMaybe<Scalars['JSON']['input']>;
};

export type UserFinancingOptionOrNull = {
  __typename?: 'UserFinancingOptionOrNull';
  amountToFinance?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  downPayment?: Maybe<DownPayment>;
  installment?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<FinancingOptionProvider>;
  status: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserRoleName = {
  __typename?: 'UserRoleName';
  name: Scalars['String']['output'];
};

export enum UserType {
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER'
}

export type UsersWithCalendarIssues = {
  __typename?: 'UsersWithCalendarIssues';
  calendarsNotConfigured: Array<Maybe<Scalars['String']['output']>>;
  calendarsToReconnect: Array<Maybe<Scalars['String']['output']>>;
};

export enum VerificationIdentifierType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type VoucherDiscount = {
  __typename?: 'VoucherDiscount';
  code?: Maybe<Scalars['String']['output']>;
  percentOff?: Maybe<Scalars['Int']['output']>;
};

export type WhatIsNext = {
  __typename?: 'WhatIsNext';
  date?: Maybe<Scalars['Date']['output']>;
  value: WhatIsNextEnum;
};

export enum WhatIsNextEnum {
  CaseOnboarding = 'CASE_ONBOARDING',
  CourtHearing = 'COURT_HEARING',
  DocumentSignature = 'DOCUMENT_SIGNATURE',
  DocumentUpload = 'DOCUMENT_UPLOAD',
  EmptyState = 'EMPTY_STATE',
  MeetingWithAnAttorney = 'MEETING_WITH_AN_ATTORNEY',
  MeetingWithTheCaseManager = 'MEETING_WITH_THE_CASE_MANAGER',
  PendingOnboardingDocVerification = 'PENDING_ONBOARDING_DOC_VERIFICATION'
}

export type WireTransferMethod = PaymentMethod & {
  __typename?: 'WireTransferMethod';
  bankName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type WisetackLoanAmountStatus = {
  __typename?: 'WisetackLoanAmountStatus';
  status: LoanAmountStatus;
};

export type WisetackLoanLink = {
  __typename?: 'WisetackLoanLink';
  url: Scalars['String']['output'];
};

export type WisetackPrequalLink = {
  __typename?: 'WisetackPrequalLink';
  url: Scalars['String']['output'];
};

export type CreateWebLeadMutationVariables = Exact<{
  input: CreateWebLeadInput;
}>;


export type CreateWebLeadMutation = {
  __typename?: 'Mutation',
  createWebLead: {
    __typename?: 'Lead',
    id: string
  }
};

export type UpdateWebLeadMutationVariables = Exact<{
  input: UpdateWebLeadInput;
}>;


export type UpdateWebLeadMutation = {
  __typename?: 'Mutation',
  updateWebLead: {
    __typename?: 'Lead',
    id: string
  }
};

export type CreateRejectedCustomerMutationVariables = Exact<{
  rejectedCustomer: CreateRejectedCustomerInput;
}>;


export type CreateRejectedCustomerMutation = {
  __typename?: 'Mutation',
  createRejectedCustomer?: {
    __typename?: 'RejectedCustomer',
    id: string,
    email: string
  } | null | undefined
};

export type GetIntakeMeetingSlotsQueryVariables = Exact<{
  input: IntakeMeetingSlotsInput;
}>;


export type GetIntakeMeetingSlotsQuery = {
  __typename?: 'Query',
  intakeMeetingSlots: {
    __typename?: 'IntakeMeetingSlots',
    slotTimeSpan: number,
    slots: Array<string>
  }
};

export type GetLeadIdByFunnelActionIdQueryVariables = Exact<{
  funnelActionId: Scalars['String']['input'];
}>;


export type GetLeadIdByFunnelActionIdQuery = {
  __typename?: 'Query',
  leadIdByFunnelActionId?: {
    __typename?: 'Lead',
    id: string
  } | null | undefined
};

export type RegisterStripeCustomerMutationVariables = Exact<{
  input: RegisterStripeCustomerInput;
}>;


export type RegisterStripeCustomerMutation = {
  __typename?: 'Mutation',
  registerStripeCustomer: {
    __typename?: 'RegisterStripeCustomerResponse',
    clientSecret: string
  }
};

export type AddPaymentMutationVariables = Exact<{
  input: AddPaymentInput;
}>;


export type AddPaymentMutation = {
  __typename?: 'Mutation',
  addPayment: boolean
};


export const CreateWebLeadDocument = gql`
    mutation CreateWebLead($input: CreateWebLeadInput!) {
  createWebLead(input: $input) {
    id
  }
}
    `;
export type CreateWebLeadMutationFn = Apollo.MutationFunction<CreateWebLeadMutation, CreateWebLeadMutationVariables>;

/**
 * __useCreateWebLeadMutation__
 *
 * To run a mutation, you first call `useCreateWebLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebLeadMutation, { data, loading, error }] = useCreateWebLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebLeadMutation, CreateWebLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebLeadMutation, CreateWebLeadMutationVariables>(CreateWebLeadDocument, options);
      }
export type CreateWebLeadMutationHookResult = ReturnType<typeof useCreateWebLeadMutation>;
export type CreateWebLeadMutationResult = Apollo.MutationResult<CreateWebLeadMutation>;
export type CreateWebLeadMutationOptions = Apollo.BaseMutationOptions<CreateWebLeadMutation, CreateWebLeadMutationVariables>;
export const UpdateWebLeadDocument = gql`
    mutation UpdateWebLead($input: UpdateWebLeadInput!) {
  updateWebLead(input: $input) {
    id
  }
}
    `;
export type UpdateWebLeadMutationFn = Apollo.MutationFunction<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>;

/**
 * __useUpdateWebLeadMutation__
 *
 * To run a mutation, you first call `useUpdateWebLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebLeadMutation, { data, loading, error }] = useUpdateWebLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebLeadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>(UpdateWebLeadDocument, options);
      }
export type UpdateWebLeadMutationHookResult = ReturnType<typeof useUpdateWebLeadMutation>;
export type UpdateWebLeadMutationResult = Apollo.MutationResult<UpdateWebLeadMutation>;
export type UpdateWebLeadMutationOptions = Apollo.BaseMutationOptions<UpdateWebLeadMutation, UpdateWebLeadMutationVariables>;
export const CreateRejectedCustomerDocument = gql`
    mutation CreateRejectedCustomer($rejectedCustomer: CreateRejectedCustomerInput!) {
  createRejectedCustomer(rejectedCustomer: $rejectedCustomer) {
    id
    email
  }
}
    `;
export type CreateRejectedCustomerMutationFn = Apollo.MutationFunction<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>;

/**
 * __useCreateRejectedCustomerMutation__
 *
 * To run a mutation, you first call `useCreateRejectedCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRejectedCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRejectedCustomerMutation, { data, loading, error }] = useCreateRejectedCustomerMutation({
 *   variables: {
 *      rejectedCustomer: // value for 'rejectedCustomer'
 *   },
 * });
 */
export function useCreateRejectedCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>(CreateRejectedCustomerDocument, options);
      }
export type CreateRejectedCustomerMutationHookResult = ReturnType<typeof useCreateRejectedCustomerMutation>;
export type CreateRejectedCustomerMutationResult = Apollo.MutationResult<CreateRejectedCustomerMutation>;
export type CreateRejectedCustomerMutationOptions = Apollo.BaseMutationOptions<CreateRejectedCustomerMutation, CreateRejectedCustomerMutationVariables>;
export const GetIntakeMeetingSlotsDocument = gql`
    query GetIntakeMeetingSlots($input: IntakeMeetingSlotsInput!) {
  intakeMeetingSlots: getIntakeMeetingSlots(input: $input) {
    slotTimeSpan
    slots
  }
}
    `;

/**
 * __useGetIntakeMeetingSlotsQuery__
 *
 * To run a query within a React component, call `useGetIntakeMeetingSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntakeMeetingSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntakeMeetingSlotsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntakeMeetingSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables> & ({ variables: GetIntakeMeetingSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
      }
export function useGetIntakeMeetingSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
        }
export function useGetIntakeMeetingSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>(GetIntakeMeetingSlotsDocument, options);
        }
export type GetIntakeMeetingSlotsQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsQuery>;
export type GetIntakeMeetingSlotsLazyQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsLazyQuery>;
export type GetIntakeMeetingSlotsSuspenseQueryHookResult = ReturnType<typeof useGetIntakeMeetingSlotsSuspenseQuery>;
export type GetIntakeMeetingSlotsQueryResult = Apollo.QueryResult<GetIntakeMeetingSlotsQuery, GetIntakeMeetingSlotsQueryVariables>;
export function refetchGetIntakeMeetingSlotsQuery(variables: GetIntakeMeetingSlotsQueryVariables) {
      return { query: GetIntakeMeetingSlotsDocument, variables: variables }
    }
export const GetLeadIdByFunnelActionIdDocument = gql`
    query GetLeadIdByFunnelActionId($funnelActionId: String!) {
  leadIdByFunnelActionId: getLeadIdByFunnelActionId(
    funnelActionId: $funnelActionId
  ) {
    id
  }
}
    `;

/**
 * __useGetLeadIdByFunnelActionIdQuery__
 *
 * To run a query within a React component, call `useGetLeadIdByFunnelActionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadIdByFunnelActionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadIdByFunnelActionIdQuery({
 *   variables: {
 *      funnelActionId: // value for 'funnelActionId'
 *   },
 * });
 */
export function useGetLeadIdByFunnelActionIdQuery(baseOptions: Apollo.QueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables> & ({ variables: GetLeadIdByFunnelActionIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
      }
export function useGetLeadIdByFunnelActionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
        }
export function useGetLeadIdByFunnelActionIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>(GetLeadIdByFunnelActionIdDocument, options);
        }
export type GetLeadIdByFunnelActionIdQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdQuery>;
export type GetLeadIdByFunnelActionIdLazyQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdLazyQuery>;
export type GetLeadIdByFunnelActionIdSuspenseQueryHookResult = ReturnType<typeof useGetLeadIdByFunnelActionIdSuspenseQuery>;
export type GetLeadIdByFunnelActionIdQueryResult = Apollo.QueryResult<GetLeadIdByFunnelActionIdQuery, GetLeadIdByFunnelActionIdQueryVariables>;
export function refetchGetLeadIdByFunnelActionIdQuery(variables: GetLeadIdByFunnelActionIdQueryVariables) {
      return { query: GetLeadIdByFunnelActionIdDocument, variables: variables }
    }
export const RegisterStripeCustomerDocument = gql`
    mutation RegisterStripeCustomer($input: RegisterStripeCustomerInput!) {
  registerStripeCustomer(input: $input) {
    clientSecret
  }
}
    `;
export type RegisterStripeCustomerMutationFn = Apollo.MutationFunction<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>;

/**
 * __useRegisterStripeCustomerMutation__
 *
 * To run a mutation, you first call `useRegisterStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStripeCustomerMutation, { data, loading, error }] = useRegisterStripeCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterStripeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>(RegisterStripeCustomerDocument, options);
      }
export type RegisterStripeCustomerMutationHookResult = ReturnType<typeof useRegisterStripeCustomerMutation>;
export type RegisterStripeCustomerMutationResult = Apollo.MutationResult<RegisterStripeCustomerMutation>;
export type RegisterStripeCustomerMutationOptions = Apollo.BaseMutationOptions<RegisterStripeCustomerMutation, RegisterStripeCustomerMutationVariables>;
export const AddPaymentDocument = gql`
    mutation AddPayment($input: AddPaymentInput!) {
  addPayment(input: $input)
}
    `;
export type AddPaymentMutationFn = Apollo.MutationFunction<AddPaymentMutation, AddPaymentMutationVariables>;

/**
 * __useAddPaymentMutation__
 *
 * To run a mutation, you first call `useAddPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMutation, { data, loading, error }] = useAddPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMutation, AddPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentMutation, AddPaymentMutationVariables>(AddPaymentDocument, options);
      }
export type AddPaymentMutationHookResult = ReturnType<typeof useAddPaymentMutation>;
export type AddPaymentMutationResult = Apollo.MutationResult<AddPaymentMutation>;
export type AddPaymentMutationOptions = Apollo.BaseMutationOptions<AddPaymentMutation, AddPaymentMutationVariables>;