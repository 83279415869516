type CheckmarkProps = React.SVGProps<SVGSVGElement> & {
  checkClassNames?: string;
  circleClassNames?: string;
};

export function Checkmark({
  circleClassNames,
  checkClassNames,
}: CheckmarkProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      className={circleClassNames}
    >
      <path d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3117 6.19161C13.5628 6.44709 13.5628 6.8613 13.3117 7.11678L9.07799 11.4252C8.32484 12.1916 7.10373 12.1916 6.35058 11.4252L4.68829 9.73356C4.43724 9.47808 4.43724 9.06387 4.68829 8.80839C4.93934 8.55291 5.34637 8.55291 5.59743 8.80839L7.25972 10.5C7.51077 10.7555 7.9178 10.7555 8.16885 10.5L12.4026 6.19161C12.6536 5.93613 13.0607 5.93613 13.3117 6.19161Z"
        className={checkClassNames}
      />
    </svg>
  );
}
