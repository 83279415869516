export function getImmigrationCompetenciesKey({
  practice,
  serviceType,
  sponsorType,
}: {
  practice?: string;
  serviceType?: string;
  sponsorType?: string;
}): string {
  if (!practice || !serviceType) {
    return "";
  }

  const isNewGreenCard = serviceType === "New green card application";

  const competenciesKey =
    isNewGreenCard && sponsorType
      ? `${serviceType}/${sponsorType}`
      : serviceType;

  return competenciesKey.toLowerCase().replace(/\s+/g, "-");
}
