export const Favicon = () => {
  return (
    <>
      <link rel="icon" href="/favicon/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/favicon/favicon.svg" type="image/svg+xml" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
    </>
  );
};
