import { useEffect, useState } from "react";
import { StatsigClient } from "@statsig/js-client";
import { StatsigProvider } from "@statsig/react-bindings";

import { analytics } from "@flare/analytics";

import type { StatsigProps } from "./statsig-server-util";

/**
 * A React component that provides a Statsig client to its children for A/B testing and experimentation.
 *
 * The component initializes the Statsig client on the client side, either using server-side props
 * or by creating a new instance based on an anonymous user ID retrieved from Segment. It wraps its children
 * with the `StatsigProvider` component if the client is successfully initialized, allowing them to access
 * the Statsig features.
 */
export function ExperimentProvider({
  children,
  statsigProps,
}: {
  children: React.ReactNode;
  statsigProps?: StatsigProps;
}) {
  // Initialize Statsig client on the client side via server-side props
  const [client, setClient] = useState(() => {
    if (!statsigProps) {
      return null;
    }

    const { user, values } = statsigProps;

    const inst = new StatsigClient(
      process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY as string,
      user,
    );

    inst.dataAdapter.setData(values);
    inst.initializeSync();

    return inst;
  });

  // Initialize Statsig client on the client side
  useEffect(() => {
    async function initStatsigOnClient() {
      const segmentAnonymousId = await analytics.getAnonymousId();

      const inst = new StatsigClient(
        process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY as string,
        { userID: segmentAnonymousId as string },
      );

      await inst.initializeAsync();

      setClient(inst);
    }

    if (!client) {
      initStatsigOnClient();
    }
  }, [client]);

  return client ? (
    <StatsigProvider client={client}>{children}</StatsigProvider>
  ) : (
    children
  );
}
