import { Builder } from "@builder.io/react";

import { V2_HEADING } from "@/builder/components-sections";
import type { HeadingProps } from "@/components/v2/Heading/Heading";
import { Heading } from "@/components/v2/Heading/Heading";

import type { BuilderAttributes } from "../../types";

type BuilderHeadingProps = HeadingProps & {
  attributes?: BuilderAttributes;
};

export const BuilderHeading = ({
  alignment = "start",
  as,
  balanceText,
  children,
  color = "blue",
  variant = "h1",
  attributes,
}: BuilderHeadingProps) => {
  const { key, ...restAttributes } = attributes || {};
  return (
    <Heading
      alignment={alignment}
      as={as}
      balanceText={balanceText}
      color={color}
      variant={variant}
      key={key}
      {...restAttributes}
    >
      {children}
    </Heading>
  );
};

Builder.registerComponent(BuilderHeading, {
  name: V2_HEADING,
  noWrap: true,
  inputs: [
    {
      name: "balanceText",
      type: "boolean",
      defaultValue: false,
      helperText:
        "Balances the number of characters on each line, enhancing layout quality and legibility",
    },
    {
      name: "children",
      friendlyName: "Text",
      type: "string",
      defaultValue: "Text placeholder",
      helperText: "Displayed text",
    },
    {
      name: "variant",
      type: "string",
      defaultValue: "h1",
      enum: ["h1", "h2", "h3", "h4", "h5"],
      helperText: "Variant of the text",
    },
    {
      name: "color",
      type: "string",
      defaultValue: "blue",
      enum: ["blue", "beige", "green", "white"],
      helperText: "Color of the text",
    },
  ],
});
